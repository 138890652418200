import axios from "axios";

const adminAxios = axios.create();
const contarctAxios = axios.create();
const sinistreAxios = axios.create();
// protectedAxios.defaults.headers.common["Authorization"] =
//   "Bearer " + localStorage.getItem("jwt");

adminAxios.defaults.baseURL =
  "https://be.garanty.tn:8443/garantie-api-admin/v1";
//  "http://38.242.231.224:8080/garantie-api-admin/v1";

adminAxios.interceptors.response.use(
  function (response) {
    // console.log("--->", response);
    return response;
  },
  function (error) {
    //console.log(error);
    // return <Redirect to="pages/errors/error500"></Redirect>;

    return Promise.reject(error);
  }
);

adminAxios.interceptors.request.use(
  function (request) {
    const isLogged = sessionStorage.getItem("token");
    if (isLogged) {
      request.headers.common.Authorization = `Bearer ${isLogged}`;
    }
    return request;
  },
  function (error) {
    //console.log(error);
    // return <Redirect to="pages/errors/error500"></Redirect>;

    return Promise.reject(error);
  }
);

contarctAxios.defaults.baseURL =
  "https://be.garanty.tn:8443/garantie-api-contract/v1";
//  "http://38.242.231.224:8080/garantie-api-contract/v1";

contarctAxios.interceptors.response.use(
  function (response) {
    // console.log("--->", response);
    return response;
  },
  function (error) {
    //console.log(error);
    // return <Redirect to="pages/errors/error500"></Redirect>;

    return Promise.reject(error);
  }
);

contarctAxios.interceptors.request.use(
  function (request) {
    const isLogged = sessionStorage.getItem("token");
    if (isLogged) {
      request.headers.common.Authorization = `Bearer ${isLogged}`;
    }
    return request;
  },
  function (error) {
    return Promise.reject(error);
  }
);

sinistreAxios.defaults.baseURL =
  "https://be.garanty.tn:8443/garantie-api-sinistre/v1";
//  "http://38.242.231.224:8080/garantie-api-sinistre/v1";

sinistreAxios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

sinistreAxios.interceptors.request.use(
  function (request) {
    const isLogged = sessionStorage.getItem("token");
    if (isLogged) {
      request.headers.common.Authorization = `Bearer ${isLogged}`;
    }
    return request;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export { adminAxios, contarctAxios, sinistreAxios };
