import React, { useState } from "react";
import NumberFormat from "react-number-format";
import { useHistory, useLocation } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import LoadingPage from "../../components/LoadingPage";
import Breadcrumb from "../../layout/breadcrumb";
import contractServices from "../../services/contract/contractServices";
import "./style.css";
import FileSaver from "file-saver";
import moment from "moment";
const VueContract = () => {
  const history = useHistory();
  const location = useLocation();
  const [contract, setContract] = useState(null);
  const [loading, setLoading] = useState(false);
  const row = location.state?.row;
  const contrat = location.state?.contrat;
  const [client, setClient] = useState(null);
  const role = localStorage.getItem("role");
  const uid = localStorage.getItem("id");
  React.useEffect(() => {
    if (contrat && row) {
      console.log("hello");
      contractServices.getCustomerByCin(row.cin, uid).then((res) => {
        console.log(res);
        setClient({
          data: res.data,
        });
      });
    }
    if (row?.idcontract) {
      contractServices.getContractbyId(row.idcontract).then((res) => {
        console.log(JSON.stringify(res.data.totalAmount) + "DT");
        setContract(res.data);
      });
    }
  }, []);
  const downloadPdf = (data, el) => {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });
    FileSaver.saveAs(blob, "contrat " + el.num);
  };
  const onSubmit = () => {
    setLoading(true);
    contractServices.generateContratBase64(row.idcontract).then(async (res) => {
      const words = res.data.split("%%EOF");
      await downloadPdf(words[1], contract);
      setLoading(false);
    });
  };
  return (
    <div>
      <Breadcrumb
        parent={contrat ? "Contrats" : "Historique"}
        title={"Contrat N° " + contract?.num || ""}
      />
      <Container fluid={true}>
        <Card className="shadow-lg p-0 shadow-showcase">
          {loading ? (
            <LoadingPage />
          ) : (
            <>
              {contract ? (
                <CardBody className="pt-0" hidden="">
                  <div
                    style={{
                      textAlign: "end",
                      marginTop: "15px",
                    }}
                  >
                    <Button
                      disabled={!client}
                      onClick={() => {
                        history.push({
                          pathname:
                            "/gest_client_contrat/clients/Update_client",
                          state: {
                            back: true,
                            row: {
                              ...client.data,
                            },
                          },
                        });
                      }}
                      color="primary"
                      outline
                    >
                      Fiche Client
                    </Button>
                  </div>
                  {contract.status === "C" ? (
                    <div>
                      <h2 style={{ textAlign: "center", color: "red" }}>
                        Contrat Annulé
                      </h2>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div
                    className="card-box "
                    style={{
                      flexDirection: "column",
                      width: "100%",
                      top: "-80px",
                      backgroundColor: "white",
                      padding: "100px",
                    }}
                  >
                    <BandTitle text={"ADHERANT N°" + contract?.num} />
                    <div
                      className="row row-member"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "50px",
                      }}
                    >
                      <RowValues
                        label="Nom :"
                        value={contract?.customer?.lastName}
                      />
                      <RowValues
                        label="Prénom :"
                        value={contract?.customer?.firstName}
                      />
                      <RowValues
                        label="Adresse :"
                        value={contract?.customer?.adress}
                      />
                      <RowValues
                        label="Code postal :"
                        value={contract?.customer?.postalCode}
                      />
                      <RowValues
                        label="Ville :"
                        value={contract?.customer?.city}
                      />
                      <RowValues
                        label="E-mail :"
                        value={contract?.customer?.email}
                      />
                      <RowValues
                        label="Cin :"
                        value={contract?.customer?.cin}
                      />
                      {client?.data?.typeCustomer === "M" ? (
                        <>
                          <RowValues
                            label="Raison Social :"
                            value={client?.data?.socialReason}
                          />
                          <RowValues
                            label="Num Fiscale :"
                            value={client?.data?.taxRegistrationNumber}
                          />
                        </>
                      ) : contract?.customer?.typeCustomer === "M" ? (
                        <>
                          <RowValues
                            label="Raison Social :"
                            value={contract?.customer?.socialReason}
                          />
                          <RowValues
                            label="Num Fiscale :"
                            value={contract?.customer?.taxRegistrationNumber}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <BandTitle text="CONDITION D'ASSURANCE" />
                    <div
                      className="row row-member"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "50px",
                      }}
                    >
                      <RowValues
                        label="Produit d'assurance :"
                        value={contract?.formula.family.familyName}
                      />
                      <RowValues
                        label="Date d'adhésion :"
                        value={new Date(
                          contract?.startDate
                        ).toLocaleDateString()}
                      />
                      <RowValues
                        label="Date d'effet de la couverture :"
                        value={new Date(
                          contract?.startDate
                        ).toLocaleDateString()}
                      />
                      <RowValues
                        label="Date de fin (ou renouvellement) :"
                        value={new Date(contract?.endDate).toLocaleDateString()}
                      />

                      <RowValues
                        label="Formule de garantie :"
                        value={contract?.formula?.name}
                      />

                      <RowValues
                        label="Plafond de garantie :"
                        value={contract?.formula?.ceilingGuarantee + " DT"}
                      />
                      <RowValues
                        label="Moyen de paiement :"
                        value={contract?.paymentType?.name}
                      />
                      <RowValues
                        label="Cotisation (annuelle) :"
                        value={(contract?.totalAmount).toFixed(3) + " DT"}
                      />
                      <RowValues
                        label="Canal de vente :"
                        value={row?.canalVente}
                      />
                    </div>
                    {row?.canalVente === "Distributeur" || row?.disrtibutor ? (
                      <>
                        <BandTitle text="CARACTERISTIQUES DU VENDEUR" />
                        <div
                          className="row row-member mb-5"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginLeft: "50px",
                          }}
                        >
                          <RowValues
                            label="Type :"
                            value={contract?.disrtibutor?.type}
                          />
                          <RowValues
                            label="Raison Social :"
                            value={contract?.disrtibutor?.socialReason}
                          />
                          {/* {role === "FrontOffice" ? <></> :  <RowValues
                        label="Commission :"
                        value={contract?.disrtibutor?.commission}
                      />} */}
                          <RowValues
                            label="Date de création :"
                            value={moment(
                              contract?.disrtibutor?.dateCreation
                            ).format("DD/MM/yyyy")}
                          />
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <Row style={{ display: "flex", justifyContent: "center" }}>
                    {contract ? (
                      <Col
                        md="5 mb-6"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          color="primary"
                          className="float-right"
                          onClick={() => {
                            // contrat
                            //   ? history.push("/gest_client_contrat/contrats")
                               history.goBack();
                          }}
                          style={{ width: "55%", height: "45px" }}
                        >
                          Retour
                        </Button>
                      </Col>
                    ) : (
                      <></>
                    )}
                    <Col
                      md="5 mb-6"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Button
                        color="primary"
                        className="float-right"
                        onClick={onSubmit}
                        style={{ width: "55%", height: "45px" }}
                      >
                        Génération B.A
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              ) : (
                <LoadingPage />
              )}
            </>
          )}
        </Card>
      </Container>
    </div>
  );
};

export default VueContract;
function BandTitle({ text }) {
  return (
    <div
      style={{
        // paddingLeft: "22px",
        font: " normal normal bold 16px/25px Century Gothic",
        height: "50px",
        // width: "90%",
        borderRadius: "10px",
        backgroundColor: "#F4F2FF",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "30px",
        marginTop: "30px",
      }}
    >
      {text}
    </div>
  );
}

const RowValues = ({ label, value, number }) => {
  return (
    <div className="row mt-2">
      <div className="col-6">
        <p className="label-member"> {label}</p>
      </div>
      <div className="col-6">
        {number ? (
          <NumberFormat
            className="value-memberC"
            style={{ marginLeft: "1px" }}
            value={value}
            displayType={"text"}
            thousandSeparator={true}
          />
        ) : (
          <p className="value-member"> {value}</p>
        )}
      </div>
    </div>
  );
};
