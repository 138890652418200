import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import IconButton from "@material-ui/core/IconButton";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Badge,
} from "reactstrap";

import Breadcrumb from "../../../layout/breadcrumb";
import { useHistory } from "react-router-dom";

import SweetAlert from "sweetalert2";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { fetchTypeUser } from "../../../redux/typeUser/typeUserActions";
import Switch from "react-switch";

const UserTypeList = () => {
  const history = useHistory();
  const profil = localStorage.getItem("profil");
  const listTypeUsers = useSelector(
    ({ typeUserReducers }) => typeUserReducers.typeUsers
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTypeUser());
  }, []);

  const columns = [
    {
      name: "Désignation",
      selector: "designation",
      sortable: true,
    },
    {
      name: "Code",
      selector: "code",
      sortable: true,
    },

    {
      name: "Actif",
      cell: (row) => (
        <div>
          <Switch id="factif" name="factif" checked={false} onChange="" />
        </div>
      ),
    },
    profil !== "User"
      ? {
          name: "action",
          cell: (row) => (
            <div>
              <IconButton
                color="primary"
                onClick={() => {
                  console.log(row);
                  history.push({
                    pathname: "/gest_utilisateur/user_type/Update_user_type",
                    state: { row },
                  });
                }}
              >
                <i className="icofont icofont-ui-edit"></i>
              </IconButton>
            </div>
          ),

          button: true,
        }
      : "",
  ];

  return (
    <div>
      <Breadcrumb parent={"Type Utilisateur"} title={"list Des Types"} />
      <Container fluid={true}>
        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader
            className="ml-2"
            style={{ cursor: "pointer", padding: "10px" }}
            onClick={() => {}}
          >
            <span className="float-left">
              <h6 className="mt-1">{"filter"}</h6>
            </span>

            <span className="float-right ">
              <h4>
                {" "}
                <i className="fa fa-angle-down"></i> :{" "}
                <i className="fa fa-angle-up"></i>
              </h4>
            </span>
          </CardHeader>
        </Card>

        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader className="m-0  align-button ">
            <Row>
              <Col className="text-right">
                <span>
                  {profil === "User" ? (
                    <></>
                  ) : (
                    <Button
                      className="btn-pill btn-air-primary "
                      color="primary"
                      onClick={() => {
                        history.push(
                          "/gest_utilisateur/user_type/new_user_type"
                        );
                      }}
                    >
                      + {"Nouveau Type"}
                    </Button>
                  )}
                </span>
              </Col>
            </Row>
          </CardHeader>

          <CardBody className="pt-2">
            <DataTable
              noHeader
              pagination
              paginationComponentOptions={{
                rowsPerPageText: "Afficher",
                rangeSeparatorText: "/",
                selectAllRowsItem: true,
                selectAllRowsItemText: "all",
              }}
              columns={columns}
              data={listTypeUsers}
              pointerOnHover={true}
              progressPending=""
              progressComponent={
                <div className="loader-box">
                  <div className="loader-1"></div>
                </div>
              }
            ></DataTable>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default UserTypeList;
