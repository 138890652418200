import React, { useState, useEffect, Fragment } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  Media,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  Label,
  Input,
} from "reactstrap";

import { useHistory } from "react-router-dom";
import Dropzone from "react-dropzone-uploader";
import { findLastIndex } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { addFamily } from "../../../redux/family/familyActions";

const CreateFamily = () => {
  const history = useHistory();
  const [family, setFamily] = useState({
    dateCreation: new Date().toISOString(),
    familyName: "",
    isenabled: 1,
  });
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const familyAdd = useSelector((familyReducers) => familyReducers.familys);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFamily((family) => ({ ...family, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (family.familyName) {
      await dispatch(addFamily(family));
      toast.success("family created successfuly");
      history.push("/gest_fromule_product/familles");
    }
  };

  return (
    <Fragment>
      <Breadcrumb parent="Familles" title="Nouvelle Famille" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <h5>Famille</h5>
              </CardHeader>
              <CardBody>
                <Form
                  className="needs-validation"
                  noValidate=""
                  onSubmit={handleSubmit}
                >
                  <div className="form-row">
                    <Col md="6 mb-4">
                      <Label htmlFor="validationCustom01">
                        Nom de Famille
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="familyName"
                        type="text"
                        placeholder="Nom de Famille"
                        innerRef=""
                        value={family.familyName}
                        onChange={handleChange}
                      />
                      <span></span>
                    </Col>
                    <Col md="6 mb-4">
                      <Label htmlFor="validationCustom02">
                        Description
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="Description"
                        type="text"
                        placeholder="Description"
                        innerRef=""
                      />
                      <span></span>
                    </Col>
                  </div>

                  <Row>
                    <Col>
                      <Button
                        color="danger"
                        type="reset"
                        className="float-left"
                        onClick={() =>
                          history.push("/gest_fromule_product/familles")
                        }
                      >
                        Retour
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        color="primary"
                        type="submit"
                        className="float-right"
                      >
                        Validé
                      </Button>
                    </Col>
                  </Row>
                  {/* <Button color="primary">{config.button}</Button>
                  <Button color="primary">{config.button}</Button> */}
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CreateFamily;
