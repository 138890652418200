import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import IconButton from "@material-ui/core/IconButton";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Badge,
} from "reactstrap";

import Breadcrumb from "../../../layout/breadcrumb";
import { useHistory } from "react-router-dom";

import SweetAlert from "sweetalert2";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import Switch from "react-switch";
import BudgeComponent from "../../../components/BudgeComponent";

const ReparateurList = () => {
  const history = useHistory();
  const role = localStorage.getItem("role");
  const [listFamily, setListFamily] = useState([]);
  const [originList, setOriginList] = useState([]);
  const [Verticalcenter, setVerticalcenter] = useState(false);
  const Verticalcentermodaltoggle = (row) => {
    setVerticalcenter(!Verticalcenter);
    console.log(row);
    SweetAlert.fire({
      title: "Are you sure you want  delete  " + row.name,
      cancelButtonText: "No",
      confirmButtonText: "yes",
      reverseButtons: true,
      showCancelButton: true,
      // cancelButtonColor:
      confirmButtonColor: "#F00",
    }).then((result) => {
      if (result.isConfirmed) {
        // entrepriseService.deleteEntreprise(row.idComp).then((result) => {
        //   console.log("here", result);
        if (result.result === 3) {
          toast.success("Activity successfully deleted");
          setOriginList(originList.filter((el) => el.idComp !== row.idComp));
          setListFamily(listFamily.filter((el) => el.idComp !== row.idComp));
        }
        // });
      }
    });
  };

  const data = [{}, {}];

  const columns = [
    {
      name: "Reparateur",
      selector: "name",
      sortable: true,
    },
    {
      name: "Adresse",
      selector: "adress",
      sortable: true,
    },
    // {
    //   name: "Adresse",
    //   selector: "adress",
    //   sortable: true
    // },
    {
      name: "Raison Social",
      selector: "rsc",
      sortable: true,
    },
    {
      name: "Téléphone",
      selector: "tel",
      sortable: true,
    },

    {
      name: "Actif",
      cell: (row) => (
        <BudgeComponent status={row.isActive} />

        // <div>
        //   <Switch id="factif" name="factif" checked={false} onChange="" />
        // </div>
      ),
    },
    {
      name: "ction",
      cell: (row) => (
        <div>
          <IconButton
            color="primary"
            onClick={() => {
              console.log(row);
              history.push({
                pathname: "/gest_intervenant/reparateur/update_reparateur",
                state: { row, update: true },
              });
            }}
          >
            <i className="icofont icofont-ui-edit"></i>
          </IconButton>
          <IconButton
            color="primary"
            onClick={() => {
              history.push({
                pathname: "/gest_intervenant/reparateur/update_reparateur",
                state: { row },
              });
            }}
          >
            <i className="icofont icofont-eye-alt"></i>
          </IconButton>
        </div>
      ),

      button: true,
    },
  ];

  const columnsC = [
    {
      name: "Reparateur",
      selector: "name",
      sortable: true,
    },
    {
      name: "Adresse",
      selector: "adress",
      sortable: true,
    },
    {
      name: "Raison Social",
      selector: "rsc",
      sortable: true,
    },
    {
      name: "Mobile",
      selector: "tel",
      sortable: true,
    },
  ];

  return (
    <div>
      <Breadcrumb parent={"Reparateur"} title={"Liste des réparateurs"} />
      <Container fluid={true}>
        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader
            className="ml-2"
            style={{ cursor: "pointer", padding: "10px" }}
            onClick={() => {}}
          >
            <span className="float-left">
              <h6 className="mt-1">{"Filtrer"}</h6>
            </span>

            <span className="float-right ">
              <h4>
                {" "}
                <i className="fa fa-angle-down"></i> :{" "}
                <i className="fa fa-angle-up"></i>
              </h4>
            </span>
          </CardHeader>

          <CardBody className="pt-0" hidden="">
            <Form className="theme-form">
              <Row>
                <Col md="12 mb-2">
                  <FormGroup>
                    <Label className="col-form-label "></Label>
                    <Input
                      className="form-control"
                      type="text"
                      onChange=""
                      value=""
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
            <Button
              className="btn-pill btn-air-success"
              color="success"
              onClick=""
            >
              Recherche
            </Button>
          </CardBody>
        </Card>

        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader className="m-0 pb-0">
            <Row>
              {role === "Courtier " ? (
                <></>
              ) : (
                <Col className="text-right">
                  <span>
                    <Button
                      className="btn-pill btn-air-primary "
                      color="primary"
                      onClick={() => {
                        history.push(
                          "/gest_intervenant/reparateur/new_reparateur"
                        );
                      }}
                    >
                      + {"Nouveau Repatareur"}
                    </Button>
                  </span>
                  &nbsp;
                  {/* <span>{listFamily.length}</span> */}
                </Col>
              )}
            </Row>
          </CardHeader>

          <CardBody className="pt-2">
            <DataTable
              noHeader
              pagination
              paginationComponentOptions={{
                rowsPerPageText: "Afficher",
                rangeSeparatorText: "/",
                selectAllRowsItem: true,
                selectAllRowsItemText: "all",
              }}
              columns={role === "Courtier " ? columnsC : columns}
              data={data}
              pointerOnHover={true}
              progressPending=""
              progressComponent={
                <div className="loader-box">
                  <div className="loader-1"></div>
                </div>
              }
            ></DataTable>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default ReparateurList;
