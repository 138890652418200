import { adminAxios } from "../../config/axiosConfig/InstancesAxios";

export default {
  getAllDistributors: (uid) => {
    return adminAxios.post("/getAllDistributors", null, { params: { uid } });
  },

  createDistrubitor: (Model) => {
    return adminAxios.post("/createDisrtibutor", Model);
  },

  updateDistributor: (Model) => {
    return adminAxios.post("/updateDisrtibutor", Model);
  },

  createDistributorUser: (Model) => {
    return adminAxios.post("/createDistributorUser", Model);
  },
  getallDistbyParentid: (idparent) => {
    return adminAxios.post("/getAlldisributorsbyParent", null, {
      params: { idparent },
    });
  },
  sendMail: (Model) => {
    return adminAxios.post("/sendMail", Model);
  },
  getDisrtibutorbyId: (idDisrtibutor) => {
    return adminAxios.post("/getDisrtibutorbyId", null, {
      params: { idDisrtibutor },
    });
  },
  excel: (iduser) => {
    return adminAxios.post("/excel",  null, {
      params: { iduser },
    });
  },
};
