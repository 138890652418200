import React, { useEffect } from 'react'
import userGarantieService from '../../../services/userGarantie/userGarantieService'

const EditAccount = () => {
    // const idUser = localStorage.getItem("id")
    // useEffect(()=>{
    //     userGarantieService.getuserbyid(JSON.parse(JSON.stringify({idUser})))
    // },[])
  return (
    <div>EditAccount</div>
  )
}

export default EditAccount