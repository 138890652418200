import { contarctAxios } from "../../config/axiosConfig/InstancesAxios";
import { adminAxios } from "../../config/axiosConfig/InstancesAxios";
import { sinistreAxios } from "../../config/axiosConfig/InstancesAxios";
export default {
  getSinistreById : (id_sinister) =>{
    return sinistreAxios.post("/getSinistreById", null, {
      params: { id_sinister },
    });
  },
  getAllSinistre: (iduser) => {
    return sinistreAxios.post("/getAllSinisters", null, {
      params: { iduser },
    });
  },
  getAllSinistreInclusion: (iduser) => {
    return sinistreAxios.post("/getAllSinistersInclusion", null, {
      params: { iduser },
    });
  },
  createSinistre: (data) => {
    return sinistreAxios.post("/createSinistre", data);
  },
  getSinistreByCustomer: (Id_customer) => {
    return sinistreAxios.post("/getSinistreByCustomer", null, {
      params: { Id_customer },
    });
  },
  UpdateSinistre: (data) => {
    return sinistreAxios.post("/updateSinistre", data);
  },
  GenerateConstatSinistre : (isSinistre) => {
    return sinistreAxios.post("/generateConstatSinistre",null, 
      {params: {isSinistre}
    });
  },
  generateDechargeSinistre : (isSinistre) => {
    return sinistreAxios.post("/generateDechargeSinistre",null, 
      {params: {isSinistre}
    });
  },
  generaterefundreceipt : (isSinistre) => {
    return sinistreAxios.post("/generaterefundreceipt",null, 
      {params: {isSinistre}
    });
  },
  generatepaymentreceipt : (isSinistre) => {
    return sinistreAxios.post("/generatepaymentreceipt",null, 
      {params: {isSinistre}
    });
  },
  exportsinisters: (data) => {
    return sinistreAxios.post("/exportsinisters", data);
  },
  
};
