import React, { useState, useEffect, Fragment } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  Media,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

import { useHistory } from "react-router-dom";
import Dropzone from "react-dropzone-uploader";
import { findLastIndex } from "lodash";

const UpdateReparateur = ({ location, i18n }) => {
  const history = useHistory();
  const update = location.state.update;
  const [file, setFile] = useState(false);
  useEffect(() => {}, []);
  // const { register, handleSubmit, errors } = useForm(
  //   location.state
  //     ? {
  //         defaultValues: {
  //           abbreviation: location.state.row.abbreviation,
  //           address: location.state.row.adresse,
  //           latitude: location.state.row.coordonneeX,
  //           longitude: location.state.row.coordonneeY,
  //           color1: location.state.row.couleur1,
  //           color2: location.state.row.couleur2,
  //           actif: location.state.row.isActif,
  //           logo: location.state.row.logo,
  //           trn: location.state.row.mf,
  //           sc: location.state.row.rsc,
  //         },
  //       }
  //     : {}
  // );

  // const [logo, setLogo] = useState(location.state ? location.state.row.logo : "");

  // const config = location.state ? { title: t("update") + " " + t("company"), button: t("update") } : { title: t("newCompany"), button: t("save") };

  // const onSubmit = async (data) => {
  //   if (data !== "") {
  //     let dataToAdd = {
  //       abbreviation: data.abbreviation,
  //       adresse: data.address,
  //       coordonneeX: data.latitude,
  //       coordonneeY: data.longitude,
  //       couleur1: data.color1,
  //       couleur2: data.color2,
  //       isActif: data.actif ? 1 : 0,
  //       isDeleted: 0,
  //       logo: logo,
  //       mf: data.trn,
  //       rsc: data.sc,
  //       dateCreation: new Date().toISOString(),
  //     };
  //     // if (file) {
  //     //   let resultUpload = await nmcService.uploadFile(file);
  //     //   if (resultUpload.result === 1) {
  //     //     dataToAdd.logo = urlUpload + file.name;
  //     //   }
  //     // }
  //     // if (location.state) {
  //     //   dataToAdd.idComp = location.state.row.idComp;
  //     //   let result = await entrepriseService.updateEntreprise(dataToAdd);
  //     //   if (result.result === 2) {
  //     //     toast.success("Company successfully updated");
  //     //     history.push("/companies");
  //     //   }
  //     // }
  //       // else {
  //       // let result = await entrepriseService.createEntreprise(dataToAdd);
  //       // if (result.result === 1) {
  //       //   toast.success("Company successfully created");
  //         history.push("/companies");
  //       // }
  //     // }
  //   } else {
  //     errors.showMessages();
  //   }
  // };

  return (
    <Fragment>
      <Breadcrumb
        parent="Reparateur"
        title={update ? "Modifier Reparateur" : "Reparateur"}
      />
      <Container fluid={true}>
        <Form className="needs-validation" noValidate="" onSubmit="">
          <Row>
            <Col>
              <Card className="shadow-lg p-0 shadow-showcase">
                <CardHeader className="b-l-primary pb-2">
                  {/* <h5>Info Generale</h5> */}
                </CardHeader>
                <CardBody className="pt-1">
                  <div className="form-row">
                    <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                        Reparateur
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="reparateur"
                        type="text"
                        placeholder="Reparateur"
                        innerRef=""
                        disabled={!update}
                      />
                      <span></span>
                    </Col>
                    <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                        Raison Social
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="rsc"
                        type="text"
                        placeholder="raison social"
                        innerRef=""
                        disabled={!update}
                      />
                      <span></span>
                    </Col>
                    <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                        Adresse
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="Adresse"
                        type="text"
                        placeholder="Adresse"
                        innerRef=""
                        disabled={!update}
                      />
                      <span></span>
                    </Col>
                    <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                        Matricule Fiscale
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="ident_tax"
                        type="text"
                        placeholder="Matricule Fiscale"
                        innerRef=""
                        disabled={!update}
                      />
                      <span></span>
                    </Col>
                    <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                        Téléphone
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="tel"
                        type="text"
                        placeholder="Téléphone"
                        innerRef=""
                        disabled={!update}
                      />
                      <span></span>
                    </Col>

                    {/* <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                        Description
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="description"
                        type="text"
                        placeholder="Description"
                        innerRef=""
                      />
                      <span></span>
                    </Col> */}
                    <Col md="4 mb-3">
                      <div
                        class="form-check form-switch"
                        style={{
                          // display: "flex",
                          // justifyContent: "center",
                          // textAlign: "center",
                          marginTop: "34px",
                          paddingLeft: "40px",
                        }}
                      >
                        <input
                          class="form-check-input"
                          // style={{ marginTop: "7%" }}
                          name="isActive"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                          // checked={false}
                          value="0"
                          disabled={!update}
                        />
                        <label
                          class="form-check-label"
                          for="flexSwitchCheckDefault"
                        >
                          Actif
                        </label>
                      </div>
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* <Row>
            <Col>
              <Card className="shadow-lg p-0 shadow-showcase">
                <CardHeader className="b-l-secondary pb-2">
                  <h5> connexion</h5>
                </CardHeader>
                <CardBody className="pt-1">
                  <div className="form-row">
                    <Col md="3 mb-2">
                      <Label htmlFor="validationCustom01">E-mail</Label>
                      <span style={{ color: "red" }}>*</span>
                      <Input
                        className="form-control"
                        name="email"
                        type="text"
                        placeholder="E-mail"
                        innerRef=""
                        // value={login}
                        // onChange={(e) => setLogin(e.target.value)}
                      />

                      {/* <div className="valid-feedback">Looks good!</div> */}
          {/* </Col>
                    <Col md="3 mb-2">
                      <Label htmlFor="validationCustom02">psw</Label>
                      <span style={{ color: "red" }}>*</span>
                      <Input
                        className="form-control"
                        name="password"
                        type="text"
                        placeholder="password"
                        innerRef=""
                        // value={password}
                        // onChange={(e) => setPassword(e.target.value)}
                      />

                      {/* <div className="valid-feedback">Looks good!</div> */}
          {/* </Col> */}
          {/* <Col md="3 mb-2">
                      <Label htmlFor="validationCustom02">confirmation</Label>
                      <span style={{ color: "red" }}>*</span>
                      <Input
                        className="form-control"
                        name="confirmPassword"
                        type="text"
                        placeholder="confirm Password"
                        innerRef=""
                        onChange=""
                      />
                    </Col>
                    <Col md="3 m-auto">
                      <Button
                        className="btn-pill btn-air-primary float-right"
                        color="primary"
                        onClick="">
                        generatePassword
                      </Button>
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row> */}

          <Row>
            <Col>
              <Button
                color="danger"
                type="reset"
                className="float-left"
                onClick={() => history.push("/gest_intervenant/reparateur")}
              >
                Retour
              </Button>
            </Col>
            <Col>
              {update && (
                <Button color="primary" type="submit" className="float-right">
                  Valider
                </Button>
              )}
            </Col>
          </Row>
          {/* <Button color="primary">{config.button}</Button>
                  <Button color="primary">{config.button}</Button> */}
        </Form>
      </Container>
    </Fragment>
  );
};

export default UpdateReparateur;
