import React, { useState, useEffect, Fragment } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  Media,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import assuranceService from "../../../services/assurance/assuranceService";

import { useHistory, useLocation } from "react-router-dom";
import Dropzone from "react-dropzone-uploader";
import { findLastIndex } from "lodash";

const UpdateAssurance = () => {
  let location = useLocation();
  const history = useHistory();
  const update = location.state?.update;
  const [assurance, setAssurance] = useState(
    location.state
      ? {
          dateCreation: new Date().toISOString(),
          isActive: location.state.row.isActive,
          idinsurance: location.state.row.idinsurance,
          phone: location.state.row.phone,
          socialReason: location.state.row.socialReason,
          taxIndetification: location.state.row.taxIndetification,
        }
      : {}
  );

  const [checked, setChecked] = useState(true);
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAssurance((assurance) => ({ ...assurance, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSubmitted(true);
    if (assurance.socialReason) {
      await assuranceService.updateAssurance(assurance);
      toast.success("Assurance updated successfuly");
      history.push("/gest_intervenant/assurance");
    }
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="Assurance"
        title={update ? "Modifier Assurance" : "Assurance"}
      />
      <Container fluid={true}>
        <Form
          className="needs-validation"
          noValidate=""
          onSubmit={handleSubmit}
        >
          <Row>
            <Col>
              <Card className="shadow-lg p-0 shadow-showcase">
                <CardHeader className="b-l-primary pb-2">
                  {/* <h5>Info Generale</h5> */}
                </CardHeader>
                <CardBody className="pt-1">
                  <div className="form-row">
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom01">
                        Assurance
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="socialReason"
                        type="text"
                        placeholder="raison social"
                        innerRef=""
                        defaultValue={assurance.socialReason}
                        onChange={handleChange}
                        disabled={!update}
                      />
                      <span></span>
                    </Col>
                    <Col md="3 mb-3">
                      <Label htmlFor="validationCustom01">
                        Matricule Fiscale
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="taxIndetification"
                        type="text"
                        placeholder="Identification Tax"
                        innerRef=""
                        defaultValue={assurance.taxIndetification}
                        onChange={handleChange}
                        disabled={!update}
                      />
                      <span></span>
                    </Col>
                    <Col md="3 mb-3">
                      <Label htmlFor="validationCustom01">
                        Téléphone
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="phone"
                        type="text"
                        placeholder="Téléphone"
                        innerRef=""
                        defaultValue={assurance.phone}
                        onChange={handleChange}
                        disabled={!update}
                      />
                      <span></span>
                    </Col>
                    <Col md="1 mb-3">
                      <div
                        class="form-check form-switch"
                        style={{
                          textAlign: "center",
                          marginTop: "32px",
                        }}
                      >
                        <input
                          class="form-check-input"
                          // style={{ marginTop: "7%" }}
                          disabled={!update}
                          name="isActive"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                          checked={checked}
                          value="0"
                          onClick={(e) =>
                            checked
                              ? setChecked(false) & handleChange(e)
                              : setChecked(true) &
                                setAssurance((assurance) => ({
                                  ...assurance,
                                  isActive: 1,
                                }))
                          }
                        />
                        <label
                          class="form-check-label"
                          for="flexSwitchCheckDefault"
                        >
                          Actif
                        </label>
                      </div>
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Button
                color="danger"
                type="reset"
                className="float-left"
                onClick={() => history.push("/gest_intervenant/assurance")}
              >
                Retour
              </Button>
            </Col>
            <Col>
              {update && (
                <Button color="primary" type="submit" className="float-right">
                  Valider
                </Button>
              )}
            </Col>
          </Row>
          {/* <Button color="primary">{config.button}</Button>
                  <Button color="primary">{config.button}</Button> */}
        </Form>
      </Container>
    </Fragment>
  );
};

export default UpdateAssurance;
