import React, { useState, useEffect, Fragment } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  Media,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Dropzone from "react-dropzone-uploader";
import { findLastIndex } from "lodash";
import { addCategory } from "../../../redux/category/categoryActions";
import { fetchFamily } from "../../../redux/family/familyActions";

const CreateCategory = ({ location, i18n }) => {
  const history = useHistory();

  const [category, setCategory] = useState({
    dateCreation: new Date().toISOString(),
    description: "",
    idfamily: "",
    isVisible: 1,
    name: ""
  });

  const [option, setListOption] = useState([]);

  useEffect(() => {
    dispatch(fetchFamily());
  }, []);

  const listFamily = useSelector(({ familyReducers }) => {
    console.log(option);
    return familyReducers.familys;
  });

  useEffect(() => {
    setListOption(
      listFamily.map((el) => ({
        value: el.idfamily,
        label: el.familyName
      }))
    );
  }, [listFamily]);

  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);

  const [selectOption, setOptionSelected] = useState(null);

  const categoryAdd = useSelector(
    (categoryReducer) => categoryReducer.category
  );

  const onchangeSelect = (item) => {
    console.log(item);
    setOptionSelected(item);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCategory((category) => ({ ...category, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSubmitted(true);
    if (category.name && category.description) {
      await dispatch(
        addCategory({ ...category, idfamily: selectOption.value })
      );
    }
    toast.success("category created successfuly");
    history.push("/gest_fromule_product/Catégories");
  };

  return (
    <Fragment>
      <Breadcrumb parent="Categories" title="Nouvelle Catégorie" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <h5>Categorie</h5>
              </CardHeader>
              <CardBody>
                <Form
                  className="needs-validation"
                  noValidate=""
                  onSubmit={handleSubmit}>
                  <div className="form-row">
                    <Col md="6 mb-4">
                      <Label htmlFor="validationCustom02">
                        Famille
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Select
                        value={selectOption}
                        onChange={onchangeSelect}
                        options={option}
                      />
                    </Col>
                    <Col md="6 mb-4">
                      <Label htmlFor="validationCustom02">
                        Catégorie
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="name"
                        type="text"
                        placeholder="Catégorie"
                        value={category.name}
                        onChange={handleChange}
                      />
                      <span></span>
                    </Col>
                    <Col md="6 mb-4">
                      <Label htmlFor="validationCustom02">
                        Description
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="description"
                        type="text"
                        placeholder="Description"
                        value={category.description}
                        onChange={handleChange}
                      />
                      <span></span>
                    </Col>
                  </div>

                  <Row>
                    <Col>
                      <Button
                        color="danger"
                        type="reset"
                        className="float-left"
                        onClick={() =>
                          history.push("/gest_fromule_product/Catégories")
                        }>
                        Retour
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        color="primary"
                        type="submit"
                        className="float-right">
                        Validé
                      </Button>
                    </Col>
                  </Row>
                  {/* <Button color="primary">{config.button}</Button>
                  <Button color="primary">{config.button}</Button> */}
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CreateCategory;
