import assuranceService from "../../services/assurance/assuranceService";

export const FETCH_ASSURANCES_REQUEST = "FETCH_ASSURANCES_REQUEST";
export const FETCH_ASSURANCES_SUCCESS = "FETCH_ASSURANCES_SUCCESS";
export const FETCH_ASSURANCES_FAILURE = "FETCH_ASSURANCES_FAILURE";



export const fetchAssurance = () => {
  return (dispatch) => {
    const uid = localStorage.getItem('id')
    dispatch(fetchAssurancesRequest());
    assuranceService
      .getAllAssurances(uid)
      .then((response) => {
        // response.data is the 
     
        const assurance = response.data;
        dispatch(fetchAssurancesSuccess(assurance));
      })
      .catch((error) => {
        // error.message is the error message
        dispatch(fetchAssurancesFailure(error.message));
      });
  };
};

export const fetchAssurancesRequest = () => {
  return {
    type: FETCH_ASSURANCES_REQUEST
  };
};

export const fetchAssurancesSuccess = (assurances) => {
  return {
    type: FETCH_ASSURANCES_SUCCESS,
    payload: assurances
  };
};

export const fetchAssurancesFailure = (error) => {
  return {
    type: FETCH_ASSURANCES_FAILURE,
    payload: error
  };
};


