import { adminAxios } from "../../config/axiosConfig/InstancesAxios";

export default {
  getAllProduct: () => {
    return adminAxios.post("/getAllProducts", {});
  },

  createNewProduct: (ProductModel) => {
    return adminAxios.post("/createProduct", ProductModel);
  },
  getcontractProductByIdContract: (id_contract) => {
    return adminAxios.post("/getcontractProductByIdContract", null, {
      params: { id_contract },
    });
  },
};
