import courtierService from "../../services/courtier/courtierService";

export const FETCH_COURTIERS_REQUEST = "FETCH_COURTIERS_REQUEST";
export const FETCH_COURTIERS_SUCCESS = "FETCH_COURTIERS_SUCCESS";
export const FETCH_COURTIERS_FAILURE = "FETCH_COURTIERS_FAILURE";
export const ADD_COURTIERS_REQUEST = "ADD_COURTIERS_REQUEST";
export const ADD_COURTIERS_SUCCESS = "ADD_COURTIERS_SUCCESS";
export const ADD_COURTIERS_FAILURE = "ADD_COURTIERS_FAILURE";

export const fetchCourtier = () => {
  return (dispatch) => {
    const uid = localStorage.getItem('id')
    dispatch(fetchCourtiersRequest());
    courtierService
      .getAllCourtier(uid)
      .then((response) => {
        // response.data is the CourtierS
        console.log("hello",response)
        const courtiers = response.data;
        dispatch(fetchCourtiersSuccess(courtiers));
      })
      .catch((error) => {
        // error.message is the error message
        dispatch(fetchCourtiersFailure(error.message));
      });
  };
};

export const fetchCourtiersRequest = () => {
  return {
    type: FETCH_COURTIERS_REQUEST
  };
};

export const fetchCourtiersSuccess = (courtiers) => {
  return {
    type: FETCH_COURTIERS_SUCCESS,
    payload: courtiers
  };
};

export const fetchCourtiersFailure = (error) => {
  return {
    type: FETCH_COURTIERS_FAILURE,
    payload: error
  };
};

export const addCourtier = (data) => {
  return (dispatch) => {
    dispatch(addCourtiersRequest(data));
    courtierService
    .createNewCourtier(data)
    .then(
      (response) => {
        dispatch(addCourtiersSuccess(response.data));
      },
      (error) => {
        dispatch(addCourtiersFailure(error.messege));
      }
    );
  };
};

export const addCourtiersRequest = () => {
  return {
    type: ADD_COURTIERS_REQUEST
  };
};

export const addCourtiersSuccess = (courtier) => {
  return {
    type: ADD_COURTIERS_SUCCESS,
    payload: courtier
  };
};

export const addCourtiersFailure = (error) => {
  return {
    type: ADD_COURTIERS_FAILURE,
    payload: error
  };
};