import React, { useEffect } from "react";
import { saveAs } from "file-saver";
import Breadcrumbs from "../../layout/breadcrumb";
import { Card, CardBody, CardHeader, Container, Form } from "reactstrap";
import distrbutorServices from "../../services/distribitor/distributorService";
import familyService from "../../services/family/familyService"
const Notice = () => {
  useEffect(() => {
    distrbutorServices.getDisrtibutorbyId(localStorage.getItem("idDist")).then(res=> {
      if(res.status === 200 ){
        JSON.parse(res.data.typesale).forEach(async (element) => {

          familyService.getFamilybyId(element).then(res=>{
            console.log(res.data);
            if(res.data.familyName === "Multimédia"){
              saveAs(
                `${process.env.PUBLIC_URL}/assets/imd/NI Garantiedes Smartphones et des Appareils Multimédias.pdf`,
                "NI Garantiedes Smartphones et des Appareils Multimédias.pdf"
              );
              return;
            }else if(res.data.familyName === "Électroménager"){
              saveAs(
                `${process.env.PUBLIC_URL}/assets/imd/NI Garantie Casse Accidentelle électroménagers Carte Assuance.pdf`,
                "NI Garantie Casse Accidentelle électroménagers Carte Assuance.pdf"
              );
              return;
            }else if(res.data.familyName === "Optique"){
              saveAs(
                `${process.env.PUBLIC_URL}/assets/imd/lunettes.pdf`,
                "NoticeOptique.pdf"
              );
              return;
            }
          })
          return;
        });
      }
    })
  }, []);
  return (
    <div>
      
      <Container fluid={true}>
        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader>
           
          </CardHeader>

          <CardBody className="pt-0">
            <h4 style={{textAlign:"center"}}>Téléchargement effectué avec succès</h4>
          </CardBody>

          <CardBody className="pt-2"></CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default Notice;
