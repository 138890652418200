import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import IconButton from "@material-ui/core/IconButton";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Badge,
} from "reactstrap";

import Breadcrumb from "../../../layout/breadcrumb";
import { useHistory } from "react-router-dom";

import SweetAlert from "sweetalert2";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import Switch from "react-switch";

const InterventionList = () => {
  const history = useHistory();

  const [listSinister, setListSinister] = useState([]);
  const [originList, setOriginList] = useState([]);
  const [Verticalcenter, setVerticalcenter] = useState(false);
  const Verticalcentermodaltoggle = (row) => {
    setVerticalcenter(!Verticalcenter);
    SweetAlert.fire({
      title: "Are you sure you want  delete  " + row.designation,
      cancelButtonText: "No",
      confirmButtonText: "yes",
      reverseButtons: true,
      showCancelButton: true,
      // cancelButtonColor:
      confirmButtonColor: "#F00",
    }).then((result) => {
      if (result.isConfirmed) {
        // entrepriseService.deleteEntreprise(row.idComp).then((result) => {
        //   console.log("here", result);
        if (result.result === 3) {
          toast.success("Activity successfully deleted");
          setOriginList(originList.filter((el) => el.idComp !== row.idComp));
          setListSinister(
            listSinister.filter((el) => el.idComp !== row.idComp)
          );
        }
        // });
      }
    });
  };

  const data = [{}];

  const columns = [
    {
      name: "Num",
      selector: "name",
      sortable: true,
    },
    {
      name: "Num De Sinistre",
      selector: "name",
      sortable: true,
    },
    {
      name: "Reparateur",
      selector: "reparateur",
      sortable: true,
    },
    {
      name: "Date De Debut",
      selector: "date_debut",
      sortable: true,
    },
    {
      name: "Date De Fin",
      selector: "date_fin",
      sortable: true,
    },
    {
      name: "IsActif",
      cell: (row) => (
        <div>
          <Switch id="factif" name="factif" checked={false} onChange="" />
        </div>
      ),
    },
    {
      name: "action",
      cell: (row) => (
        <div>
          <IconButton
            color="primary"
            onClick={() => {
              console.log(row);
              history.push({
                pathname: "/gest_sinistre/interventions/update_intervention",
                state: { row },
              });
            }}
          >
            <i className="icofont icofont-ui-edit"></i>
          </IconButton>
        </div>
      ),

      button: true,
    },
  ];

  return (
    <div>
      <Breadcrumb parent={"Interventions"} title={"List Des Interventions"} />
      <Container fluid={true}>
        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader
            className="ml-2"
            style={{ cursor: "pointer", padding: "10px" }}
            onClick={() => {}}
          >
            <span className="float-left">
              <h6 className="mt-1">{"filter"}</h6>
            </span>

            <span className="float-right ">
              <h4>
                <i className="fa fa-angle-down"></i> :{" "}
                <i className="fa fa-angle-up"></i>
              </h4>
            </span>
          </CardHeader>

          {/* <CardBody className="pt-0" hidden="">
            <Form className="theme-form">
              <Row>
                <Col md="3 mb-2">
                  <FormGroup>
                    <Label className="col-form-label ">{t("rs")}</Label>
                    <Input className="form-control" type="text" onChange={(e) => setRsc(e.target.value)} value={rsc} />
                  </FormGroup>
                </Col>
                <Col md="3 mb-2">
                  <FormGroup>
                    <Label className="col-form-label ">{t("abbreviation")}</Label>
                    <Input className="form-control" type="text" onChange={(e) => setAbr(e.target.value)} value={abr} />
                  </FormGroup>
                </Col>
                <Col md="3 mb-2">
                  <FormGroup>
                    <label className="col-form-label">{"crDate"}</label>

                    <DatePicker
                      className="form-control digits"
                      selected={startDate}
                      onChange={(e) => {
                        console.log(e);
                        setstartDate(e);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="3 mb-3">
                  <FormGroup>
                    <Label className="col-form-label">{t("actif")} </Label>
                    <Input type="select" name="select" className="form-control digits" defaultValue="1" onChange={(e) => setSelected(e.target.value)}>
                      <option value={1}> {"yes"}</option>
                      <option value={0}>{"no"}</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
            <Button className="btn-pill btn-air-success" color="success" onClick={(e) => filterData()}>
              {t("search")}
            </Button>
          </CardBody> */}
        </Card>

        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader className="m-0  align-button ">
            <Row>
              <Col className="text-right">
                <span>
                  <Button
                    className="btn-pill btn-air-primary "
                    color="primary"
                    onClick={() => {
                      history.push(
                        "/gest_sinistre/interventions/new_intervention"
                      );
                    }}
                  >
                    + {"Nouvelle Intervention"}
                  </Button>
                </span>
                &nbsp;
                {/* <span>{listSinister.length}</span> */}
              </Col>
            </Row>
          </CardHeader>

          <CardBody className="pt-2">
            <DataTable
              noHeader
              pagination
              paginationComponentOptions={{
                rowsPerPageText: "Afficher",
                rangeSeparatorText: "/",
                selectAllRowsItem: true,
                selectAllRowsItemText: "all",
              }}
              columns={columns}
              data={data}
              pointerOnHover={true}
              progressPending=""
              progressComponent={
                <div className="loader-box">
                  <div className="loader-1"></div>
                </div>
              }
            ></DataTable>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default InterventionList;
