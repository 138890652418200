import React, { Fragment, useState, useEffect } from "react";
import {
  MENUITEMS,
  DISTMENUITEMS,
  DISTMENUITEMSAD,
  BROKERMENUS,
  ASSURANCEMENUS,
  SOUSDISTMENUITEMS,
  FRONTOFFICE
} from "./menu";
import { Link, useHistory } from "react-router-dom";
import logogarantie from "../../assets/images/logo/LogoGaranty.png";
import { Users } from "react-feather";
import logo from "../../assets/images/logo/Tracé 143@2x.png";

const Sidebar = (props) => {
  const role = localStorage.getItem("role");
  const profil = localStorage.getItem("profil")
  const dist = JSON.parse(localStorage.getItem("sous-rev"));
  const history = useHistory();
  const [distMenu, setDistMenu] = useState(DISTMENUITEMS);
  const [distMenuAd, setDistMenuAd] = useState(DISTMENUITEMSAD)
  const [sousDistMenu, steSousDistMenu] = useState(SOUSDISTMENUITEMS);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const [frontOffice, setFrontOffice] = useState(FRONTOFFICE);
  const [brokermenu, setBrokerMenu] = useState(BROKERMENUS);
  const [assurancemenu, setAssuranceMenu] = useState(ASSURANCEMENUS);
  useEffect(() => {
    const currentUrl = window.location.pathname;
    if (role === "Distributeur" && dist) {
      sousDistMenu?.filter((items) => {
        if (items.path === currentUrl) setNavActive(items);
        if (!items.children) return false;
        items.children.filter((subItems) => {
          if (subItems.path === currentUrl) setNavActive(subItems);
          if (!subItems.children) return false;
          subItems.children.filter((subSubItems) => {
            if (subSubItems.path === currentUrl) {
              setNavActive(subSubItems);
              return true;
            } else {
              return false;
            }
          });
          return subItems;
        });
        return items;
      });
    } else if (role === "Distributeur" && profil === "Admin") {
      distMenuAd?.filter((items) => {
        if (items.path === currentUrl) setNavActive(items);
        if (!items.children) return false;
        items.children.filter((subItems) => {
          if (subItems.path === currentUrl) setNavActive(subItems);
          if (!subItems.children) return false;
          subItems.children.filter((subSubItems) => {
            if (subSubItems.path === currentUrl) {
              setNavActive(subSubItems);
              return true;
            } else {
              return false;
            }
          });
          return subItems;
        });
        return items;
      });
    }else if (role === "Distributeur") {
      distMenu?.filter((items) => {
        if (items.path === currentUrl) setNavActive(items);
        if (!items.children) return false;
        items.children.filter((subItems) => {
          if (subItems.path === currentUrl) setNavActive(subItems);
          if (!subItems.children) return false;
          subItems.children.filter((subSubItems) => {
            if (subSubItems.path === currentUrl) {
              setNavActive(subSubItems);
              return true;
            } else {
              return false;
            }
          });
          return subItems;
        });
        return items;
      });
    } else if (role === "Assurance ") {
      mainmenu?.filter((items) => {
        if (items.path === currentUrl) setNavActive(items);
        if (!items.children) return false;
        items.children.filter((subItems) => {
          if (subItems.path === currentUrl) setNavActive(subItems);
          if (!subItems.children) return false;
          subItems.children.filter((subSubItems) => {
            if (subSubItems.path === currentUrl) {
              setNavActive(subSubItems);
              return true;
            } else {
              return false;
            }
          });
          return subItems;
        });
        return items;
      });
    } else if (role === "Courtier") {
      brokermenu?.filter((items) => {
        if (items.path === currentUrl) setNavActive(items);
        if (!items.children) return false;
        items.children.filter((subItems) => {
          if (subItems.path === currentUrl) setNavActive(subItems);
          if (!subItems.children) return false;
          subItems.children.filter((subSubItems) => {
            if (subSubItems.path === currentUrl) {
              setNavActive(subSubItems);
              return true;
            } else {
              return false;
            }
          });
          return subItems;
        });
        return items;
      });
    }else if (role === "FrontOffice") {
      frontOffice?.filter((items) => {
        if (items.path === currentUrl) setNavActive(items);
        if (!items.children) return false;
        items.children.filter((subItems) => {
          if (subItems.path === currentUrl) setNavActive(subItems);
          if (!subItems.children) return false;
          subItems.children.filter((subSubItems) => {
            if (subSubItems.path === currentUrl) {
              setNavActive(subSubItems);
              return true;
            } else {
              return false;
            }
          });
          return subItems;
        });
        return items;
      });
    }
    // eslint-disable-next-line
  }, []);

  const setNavActive = (item) => {
    if(role === "Garanty"){
      MENUITEMS?.filter((menuItem) => {
        if (menuItem !== item) menuItem.active = false;
        if (menuItem.children && menuItem.children.includes(item))
          menuItem.active = true;
        if (menuItem.children) {
          menuItem.children.filter((submenuItems) => {
            if (submenuItems.children && submenuItems.children.includes(item)) {
              menuItem.active = true;
              submenuItems.active = true;
              return true;
            } else {
              return false;
            }
          });
        }
        return menuItem;
      });
      item.active = !item.active;
      setMainMenu({ mainmenu: MENUITEMS });
    }else if (role === "Assurance ") {
      ASSURANCEMENUS?.filter((menuItem) => {
        if (menuItem !== item) menuItem.active = false;
        if (menuItem.children && menuItem.children.includes(item))
          menuItem.active = true;
        if (menuItem.children) {
          menuItem.children.filter((submenuItems) => {
            if (submenuItems.children && submenuItems.children.includes(item)) {
              menuItem.active = true;
              submenuItems.active = true;
              return true;
            } else {
              return false;
            }
          });
        }
        return menuItem;
      });
      item.active = !item.active;
      setAssuranceMenu({ assurancemenu: ASSURANCEMENUS });
    } else if (role === "Courtier ") {
      BROKERMENUS?.filter((menuItem) => {
        if (menuItem !== item) menuItem.active = false;
        if (menuItem.children && menuItem.children.includes(item))
          menuItem.active = true;
        if (menuItem.children) {
          menuItem.children.filter((submenuItems) => {
            if (submenuItems.children && submenuItems.children.includes(item)) {
              menuItem.active = true;
              submenuItems.active = true;
              return true;
            } else {
              return false;
            }
          });
        }
        return menuItem;
      });
      item.active = !item.active;
      setBrokerMenu({ brokermenu: BROKERMENUS });
    } else if (role === "FrontOffice") {
      FRONTOFFICE?.filter((menuItem) => {
        if (menuItem !== item) menuItem.active = false;
        if (menuItem.children && menuItem.children.includes(item))
          menuItem.active = true;
        if (menuItem.children) {
          menuItem.children.filter((submenuItems) => {
            if (submenuItems.children && submenuItems.children.includes(item)) {
              menuItem.active = true;
              submenuItems.active = true;
              return true;
            } else {
              return false;
            }
          });
        }
        return menuItem;
      });
      item.active = !item.active;
      setFrontOffice({ frontOffice: FRONTOFFICE });
    }
    else if (role === "Distributeur" && dist && profil == "Admin") {
      SOUSDISTMENUITEMS?.filter((menuItem) => {
        if (menuItem !== item) menuItem.active = false;
        if (menuItem.children && menuItem.children.includes(item))
          menuItem.active = true;
        if (menuItem.children) {
          menuItem.children.filter((submenuItems) => {
            if (submenuItems.children && submenuItems.children.includes(item)) {
              menuItem.active = true;
              submenuItems.active = true;
              return true;
            } else {
              return false;
            }
          });
        }
        return menuItem;
      });
      item.active = !item.active;
      steSousDistMenu({ sousDistMenu: SOUSDISTMENUITEMS });
    } else if (role === "Distributeur" && profil == "Admin") {
      DISTMENUITEMSAD?.filter((menuItem) => {
        if (menuItem !== item) menuItem.active = false;
        if (menuItem.children && menuItem.children.includes(item))
          menuItem.active = true;
        if (menuItem.children) {
          menuItem.children.filter((submenuItems) => {
            if (submenuItems.children && submenuItems.children.includes(item)) {
              menuItem.active = true;
              submenuItems.active = true;
              return true;
            } else {
              return false;
            }
          });
        }
        return menuItem;
      });
      item.active = !item.active;
      setDistMenuAd({ distMenuAd: DISTMENUITEMSAD });
    } else if (role === "Distributeur") {
      DISTMENUITEMS?.filter((menuItem) => {
        if (menuItem !== item) menuItem.active = false;
        if (menuItem.children && menuItem.children.includes(item))
          menuItem.active = true;
        if (menuItem.children) {
          menuItem.children.filter((submenuItems) => {
            if (submenuItems.children && submenuItems.children.includes(item)) {
              menuItem.active = true;
              submenuItems.active = true;
              return true;
            } else {
              return false;
            }
          });
        }
        return menuItem;
      });
      item.active = !item.active;
      setDistMenu({ distMenu: DISTMENUITEMS });
    } 
  };

  const toggletNavActive = (item) => {
   if(role ===  "Garanty") {
      if (!item.active) {
        MENUITEMS?.forEach((a) => {
          if (MENUITEMS.includes(item)) a.active = false;
          if (!a.children) return false;
          a.children.forEach((b) => {
            if (a.children.includes(item)) {
              b.active = false;
            }
            if (!b.children) return false;
            b.children.forEach((c) => {
              if (b.children.includes(item)) {
                c.active = false;
              }
            });
          });
        });
      }
      item.active = !item.active;
      setMainMenu({ mainmenu: MENUITEMS });
    }else if (role === "Assurance ") {
      if (!item.active) {
        ASSURANCEMENUS?.forEach((a) => {
          if (ASSURANCEMENUS.includes(item)) a.active = false;
          if (!a.children) return false;
          a.children.forEach((b) => {
            if (a.children.includes(item)) {
              b.active = false;
            }
            if (!b.children) return false;
            b.children.forEach((c) => {
              if (b.children.includes(item)) {
                c.active = false;
              }
            });
          });
        });
      }
      item.active = !item.active;
      setAssuranceMenu({ assurancemenu: ASSURANCEMENUS });
    }else if (role === "Courtier ") {
      if (!item.active) {
        BROKERMENUS?.forEach((a) => {
          if (BROKERMENUS.includes(item)) a.active = false;
          if (!a.children) return false;
          a.children.forEach((b) => {
            if (a.children.includes(item)) {
              b.active = false;
            }
            if (!b.children) return false;
            b.children.forEach((c) => {
              if (b.children.includes(item)) {
                c.active = false;
              }
            });
          });
        });
      }
      item.active = !item.active;
      setBrokerMenu({ brokerMenu: BROKERMENUS });
    }  else if (role === "FrontOffice") {
      if (!item.active) {
        FRONTOFFICE?.forEach((a) => {
          if (FRONTOFFICE.includes(item)) a.active = false;
          if (!a.children) return false;
          a.children.forEach((b) => {
            if (a.children.includes(item)) {
              b.active = false;
            }
            if (!b.children) return false;
            b.children.forEach((c) => {
              if (b.children.includes(item)) {
                c.active = false;
              }
            });
          });
        });
      }
      item.active = !item.active;
      setFrontOffice({ frontOffice: FRONTOFFICE });
    }
    else if (role === "Distributeur" && dist && profil == "Admin") {
      if (!item.active) {
        SOUSDISTMENUITEMS?.forEach((a) => {
          if (SOUSDISTMENUITEMS.includes(item)) a.active = false;
          if (!a.children) return false;
          a.children.forEach((b) => {
            if (a.children.includes(item)) {
              b.active = false;
            }
            if (!b.children) return false;
            b.children.forEach((c) => {
              if (b.children.includes(item)) {
                c.active = false;
              }
            });
          });
        });
      }
      item.active = !item.active;
      steSousDistMenu({ sousDistMenu: SOUSDISTMENUITEMS });
    } else if ("Distributeur" && profil == "Admin") {
      if (!item.active) {
        DISTMENUITEMSAD?.forEach((a) => {
          if (DISTMENUITEMSAD.includes(item)) a.active = false;
          if (!a.children) return false;
          a.children.forEach((b) => {
            if (a.children.includes(item)) {
              b.active = false;
            }
            if (!b.children) return false;
            b.children.forEach((c) => {
              if (b.children.includes(item)) {
                c.active = false;
              }
            });
          });
        });
      }
      item.active = !item.active;
      setDistMenu({ distMenu: DISTMENUITEMS });
    }else if (role === "Distributeur") {
      if (!item.active) {
        DISTMENUITEMS?.forEach((a) => {
          if (DISTMENUITEMS.includes(item)) a.active = false;
          if (!a.children) return false;
          a.children.forEach((b) => {
            if (a.children.includes(item)) {
              b.active = false;
            }
            if (!b.children) return false;
            b.children.forEach((c) => {
              if (b.children.includes(item)) {
                c.active = false;
              }
            });
          });
        });
      }
      item.active = !item.active;
      setDistMenu({ distMenu: DISTMENUITEMS });
    } 
  };

  return (
    <Fragment>
      <header className="main-nav">
        <div className="logo-wrapper">
          <Link
            onClick={() => {
              if (role === "Assurance ") {
                history.push(`${process.env.PUBLIC_URL}/dashboard/assurance`);
              }  else if (role === "FrontOffice") {
                history.push(
                  `${process.env.PUBLIC_URL}/gest_client_contrat/clients`
                );
              } else if (role === "Courtier ") {
                history.push(`${process.env.PUBLIC_URL}/dashboard/courtier`);
              } else if (role === "Distributeur" && profil ==="Admin") {
                history.push(
                  `${process.env.PUBLIC_URL}/dashboard/distributeur`
                );
              }else if (role === "Distributeur" && profil ==="User") {
                history.push(
                  `${process.env.PUBLIC_URL}/new-sale`
                );
              }else {
                history.push(`${process.env.PUBLIC_URL}/dashboard/default`);
              }
            }}
          >
            <img className="img-fluid" src={logogarantie} alt="" />
          </Link>
        </div>
        <nav>
          <div className="main-navbar">
            <div id="mainnav">
              <ul className="nav-menu custom-scrollbar">
                <li className="back-btn">
                  <div className="mobile-back text-right">
                    <span>Back</span>
                    <i
                      className="fa fa-angle-right pl-2"
                      aria-hidden="true"
                    ></i>
                  </div>
                </li>
                {role === "Distributeur" && dist && profil == "Admin"
                  ? SOUSDISTMENUITEMS?.map((menuItem, i) => (
                      <li className="dropdown" key={i}>
                        {menuItem.type === "sub" ? (
                          <a
                            className={`nav-link menu-title  ${
                              menuItem.active ? "active" : ""
                            }`}
                            href="#javascript"
                            onClick={() => toggletNavActive(menuItem)}
                          >
                            <menuItem.icon />
                            <span>{menuItem.title}</span>
                            <div className="according-menu">
                              {menuItem.active ? (
                                <i className="fa fa-angle-down"></i>
                              ) : (
                                <i className="fa fa-angle-right"></i>
                              )}
                            </div>
                          </a>
                        ) : menuItem.type === "subLink" ? (
                          <Link
                            to={menuItem.path}
                            className={`nav-link menu-title  ${
                              menuItem.active ? "active" : ""
                            }`}
                            href="#javascript"
                            onClick={() => toggletNavActive(menuItem)}
                          >
                            <menuItem.icon />
                            <span>{menuItem.title}</span>
                          </Link>
                        ) : (
                          ""
                        )}
                        {menuItem.children ? (
                          <ul
                            className="nav-submenu menu-content"
                            style={
                              menuItem.active
                                ? {
                                    opacity: 1,
                                    transition: "opacity 500ms ease-in",
                                  }
                                : { display: "none" }
                            }
                          >
                            {menuItem.children.map((childrenItem, index) => {
                              return (
                                <li key={index}>
                                  {childrenItem.type === "sub" ? (
                                    <a
                                      className={`${
                                        childrenItem.active ? "active" : ""
                                      }`}
                                      href="#javascript"
                                      onClick={() =>
                                        toggletNavActive(childrenItem)
                                      }
                                    >
                                      {childrenItem.title}
                                      <span className="sub-arrow">
                                        <i className="fa fa-chevron-right"></i>
                                      </span>
                                      <div className="according-menu">
                                        {childrenItem.active ? (
                                          <i className="fa fa-angle-down"></i>
                                        ) : (
                                          <i className="fa fa-angle-right"></i>
                                        )}
                                      </div>
                                    </a>
                                  ) : (
                                    ""
                                  )}

                                  {childrenItem.type === "link" ? (
                                    <Link
                                      to={childrenItem.path}
                                      className={`${
                                        childrenItem.active ? "active" : ""
                                      }`}
                                      onClick={() =>
                                        toggletNavActive(childrenItem)
                                      }
                                    >
                                      {childrenItem.title}
                                    </Link>
                                  ) : (
                                    ""
                                  )}

                                  {childrenItem.type === "exteral_link" ? (
                                    <a
                                      href={childrenItem.path}
                                      className={
                                        childrenItem.active ? "active" : ""
                                      }
                                    >
                                      {childrenItem.title}
                                    </a>
                                  ) : (
                                    ""
                                  )}

                                  {childrenItem.children ? (
                                    <ul
                                      className="nav-sub-childmenu submenu-content"
                                      style={
                                        childrenItem.active
                                          ? { display: "block" }
                                          : { display: "none" }
                                      }
                                    >
                                      {childrenItem.children.map(
                                        (childrenSubItem, key) => (
                                          <li key={key}>
                                            {childrenSubItem.type === "link" ? (
                                              <Link
                                                to={childrenSubItem.path}
                                                className={`${
                                                  childrenSubItem.active
                                                    ? "active"
                                                    : ""
                                                }`}
                                                onClick={() =>
                                                  toggletNavActive(
                                                    childrenSubItem
                                                  )
                                                }
                                              >
                                                {childrenSubItem.title}
                                              </Link>
                                            ) : (
                                              ""
                                            )}
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                        ) : (
                          ""
                        )}
                      </li>
                    ))
                  : 
                  role === "Distributeur" && profil == "Admin"
                  ? DISTMENUITEMSAD?.map((menuItem, i) => (
                      <li className="dropdown" key={i}>
                        {menuItem.type === "sub" ? (
                          <a
                            className={`nav-link menu-title  ${
                              menuItem.active ? "active" : ""
                            }`}
                            href="#javascript"
                            onClick={() => toggletNavActive(menuItem)}
                          >
                            <menuItem.icon />
                            <span>{menuItem.title}</span>
                            <div className="according-menu">
                              {menuItem.active ? (
                                <i className="fa fa-angle-down"></i>
                              ) : (
                                <i className="fa fa-angle-right"></i>
                              )}
                            </div>
                          </a>
                        ) : menuItem.type === "subLink" ? (
                          <Link
                            to={menuItem.path}
                            className={`nav-link menu-title  ${
                              menuItem.active ? "active" : ""
                            }`}
                            href="#javascript"
                            onClick={() => toggletNavActive(menuItem)}
                          >
                            <menuItem.icon />
                            <span>{menuItem.title}</span>
                          </Link>
                        ) : (
                          ""
                        )}
                        {menuItem.children ? (
                          <ul
                            className="nav-submenu menu-content"
                            style={
                              menuItem.active
                                ? {
                                    opacity: 1,
                                    transition: "opacity 500ms ease-in",
                                  }
                                : { display: "none" }
                            }
                          >
                            {menuItem.children.map((childrenItem, index) => {
                              return (
                                <li key={index}>
                                  {childrenItem.type === "sub" ? (
                                    <a
                                      className={`${
                                        childrenItem.active ? "active" : ""
                                      }`}
                                      href="#javascript"
                                      onClick={() =>
                                        toggletNavActive(childrenItem)
                                      }
                                    >
                                      {childrenItem.title}
                                      <span className="sub-arrow">
                                        <i className="fa fa-chevron-right"></i>
                                      </span>
                                      <div className="according-menu">
                                        {childrenItem.active ? (
                                          <i className="fa fa-angle-down"></i>
                                        ) : (
                                          <i className="fa fa-angle-right"></i>
                                        )}
                                      </div>
                                    </a>
                                  ) : (
                                    ""
                                  )}

                                  {childrenItem.type === "link" ? (
                                    <Link
                                      to={childrenItem.path}
                                      className={`${
                                        childrenItem.active ? "active" : ""
                                      }`}
                                      onClick={() =>
                                        toggletNavActive(childrenItem)
                                      }
                                    >
                                      {childrenItem.title}
                                    </Link>
                                  ) : (
                                    ""
                                  )}

                                  {childrenItem.type === "exteral_link" ? (
                                    <a
                                      href={childrenItem.path}
                                      className={
                                        childrenItem.active ? "active" : ""
                                      }
                                    >
                                      {childrenItem.title}
                                    </a>
                                  ) : (
                                    ""
                                  )}

                                  {childrenItem.children ? (
                                    <ul
                                      className="nav-sub-childmenu submenu-content"
                                      style={
                                        childrenItem.active
                                          ? { display: "block" }
                                          : { display: "none" }
                                      }
                                    >
                                      {childrenItem.children.map(
                                        (childrenSubItem, key) => (
                                          <li key={key}>
                                            {childrenSubItem.type === "link" ? (
                                              <Link
                                                to={childrenSubItem.path}
                                                className={`${
                                                  childrenSubItem.active
                                                    ? "active"
                                                    : ""
                                                }`}
                                                onClick={() =>
                                                  toggletNavActive(
                                                    childrenSubItem
                                                  )
                                                }
                                              >
                                                {childrenSubItem.title}
                                              </Link>
                                            ) : (
                                              ""
                                            )}
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                        ) : (
                          ""
                        )}
                      </li>
                    ))
                    :
                 role === "Distributeur"
                  ? DISTMENUITEMS?.map((menuItem, i) => (
                      <li className="dropdown" key={i}>
                        {menuItem.type === "sub" ? (
                          <a
                            className={`nav-link menu-title  ${
                              menuItem.active ? "active" : ""
                            }`}
                            href="#javascript"
                            onClick={() => toggletNavActive(menuItem)}
                          >
                            <menuItem.icon />
                            <span>{menuItem.title}</span>
                            <div className="according-menu">
                              {menuItem.active ? (
                                <i className="fa fa-angle-down"></i>
                              ) : (
                                <i className="fa fa-angle-right"></i>
                              )}
                            </div>
                          </a>
                        ) : menuItem.type === "subLink" ? (
                          <Link
                            to={menuItem.path}
                            className={`nav-link menu-title  ${
                              menuItem.active ? "active" : ""
                            }`}
                            href="#javascript"
                            onClick={() => toggletNavActive(menuItem)}
                          >
                            <menuItem.icon />
                            <span>{menuItem.title}</span>
                          </Link>
                        ) : (
                          ""
                        )}
                        {menuItem.children ? (
                          <ul
                            className="nav-submenu menu-content"
                            style={
                              menuItem.active
                                ? {
                                    opacity: 1,
                                    transition: "opacity 500ms ease-in",
                                  }
                                : { display: "none" }
                            }
                          >
                            {menuItem.children.map((childrenItem, index) => {
                              return (
                                <li key={index}>
                                  {childrenItem.type === "sub" ? (
                                    <a
                                      className={`${
                                        childrenItem.active ? "active" : ""
                                      }`}
                                      href="#javascript"
                                      onClick={() =>
                                        toggletNavActive(childrenItem)
                                      }
                                    >
                                      {childrenItem.title}
                                      <span className="sub-arrow">
                                        <i className="fa fa-chevron-right"></i>
                                      </span>
                                      <div className="according-menu">
                                        {childrenItem.active ? (
                                          <i className="fa fa-angle-down"></i>
                                        ) : (
                                          <i className="fa fa-angle-right"></i>
                                        )}
                                      </div>
                                    </a>
                                  ) : (
                                    ""
                                  )}

                                  {childrenItem.type === "link" ? (
                                    <Link
                                      to={childrenItem.path}
                                      className={`${
                                        childrenItem.active ? "active" : ""
                                      }`}
                                      onClick={() =>
                                        toggletNavActive(childrenItem)
                                      }
                                    >
                                      {childrenItem.title}
                                    </Link>
                                  ) : (
                                    ""
                                  )}

                                  {childrenItem.type === "exteral_link" ? (
                                    <a
                                      href={childrenItem.path}
                                      className={
                                        childrenItem.active ? "active" : ""
                                      }
                                    >
                                      {childrenItem.title}
                                    </a>
                                  ) : (
                                    ""
                                  )}

                                  {childrenItem.children ? (
                                    <ul
                                      className="nav-sub-childmenu submenu-content"
                                      style={
                                        childrenItem.active
                                          ? { display: "block" }
                                          : { display: "none" }
                                      }
                                    >
                                      {childrenItem.children.map(
                                        (childrenSubItem, key) => (
                                          <li key={key}>
                                            {childrenSubItem.type === "link" ? (
                                              <Link
                                                to={childrenSubItem.path}
                                                className={`${
                                                  childrenSubItem.active
                                                    ? "active"
                                                    : ""
                                                }`}
                                                onClick={() =>
                                                  toggletNavActive(
                                                    childrenSubItem
                                                  )
                                                }
                                              >
                                                {childrenSubItem.title}
                                              </Link>
                                            ) : (
                                              ""
                                            )}
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                        ) : (
                          ""
                        )}
                      </li>
                    ))
                    
                  : role === "Courtier "
                  ? BROKERMENUS?.map((menuItem, i) => (
                      <li className="dropdown" key={i}>
                        {menuItem.type === "sub" ? (
                          <a
                            className={`nav-link menu-title  ${
                              menuItem.active ? "active" : ""
                            }`}
                            href="#javascript"
                            onClick={() => toggletNavActive(menuItem)}
                          >
                            <menuItem.icon />
                            <span>{menuItem.title}</span>
                            <div className="according-menu">
                              {menuItem.active ? (
                                <i className="fa fa-angle-down"></i>
                              ) : (
                                <i className="fa fa-angle-right"></i>
                              )}
                            </div>
                          </a>
                        ) : menuItem.type === "subLink" ? (
                          <Link
                            to={menuItem.path}
                            className={`nav-link menu-title  ${
                              menuItem.active ? "active" : ""
                            }`}
                            href="#javascript"
                            onClick={() => toggletNavActive(menuItem)}
                          >
                            <menuItem.icon />
                            <span>{menuItem.title}</span>
                          </Link>
                        ) : (
                          ""
                        )}
                        {menuItem.children ? (
                          <ul
                            className="nav-submenu menu-content"
                            style={
                              menuItem.active
                                ? {
                                    opacity: 1,
                                    transition: "opacity 500ms ease-in",
                                  }
                                : { display: "none" }
                            }
                          >
                            {menuItem.children.map((childrenItem, index) => {
                              return (
                                <li key={index}>
                                  {childrenItem.type === "sub" ? (
                                    <a
                                      className={`${
                                        childrenItem.active ? "active" : ""
                                      }`}
                                      href="#javascript"
                                      onClick={() =>
                                        toggletNavActive(childrenItem)
                                      }
                                    >
                                      {childrenItem.title}
                                      <span className="sub-arrow">
                                        <i className="fa fa-chevron-right"></i>
                                      </span>
                                      <div className="according-menu">
                                        {childrenItem.active ? (
                                          <i className="fa fa-angle-down"></i>
                                        ) : (
                                          <i className="fa fa-angle-right"></i>
                                        )}
                                      </div>
                                    </a>
                                  ) : (
                                    ""
                                  )}

                                  {childrenItem.type === "link" ? (
                                    <Link
                                      to={childrenItem.path}
                                      className={`${
                                        childrenItem.active ? "active" : ""
                                      }`}
                                      onClick={() =>
                                        toggletNavActive(childrenItem)
                                      }
                                    >
                                      {childrenItem.title}
                                    </Link>
                                  ) : (
                                    ""
                                  )}

                                  {childrenItem.type === "exteral_link" ? (
                                    <a
                                      href={childrenItem.path}
                                      className={
                                        childrenItem.active ? "active" : ""
                                      }
                                    >
                                      {childrenItem.title}
                                    </a>
                                  ) : (
                                    ""
                                  )}

                                  {childrenItem.children ? (
                                    <ul
                                      className="nav-sub-childmenu submenu-content"
                                      style={
                                        childrenItem.active
                                          ? { display: "block" }
                                          : { display: "none" }
                                      }
                                    >
                                      {childrenItem.children.map(
                                        (childrenSubItem, key) => (
                                          <li key={key}>
                                            {childrenSubItem.type === "link" ? (
                                              <Link
                                                to={childrenSubItem.path}
                                                className={`${
                                                  childrenSubItem.active
                                                    ? "active"
                                                    : ""
                                                }`}
                                                onClick={() =>
                                                  toggletNavActive(
                                                    childrenSubItem
                                                  )
                                                }
                                              >
                                                {childrenSubItem.title}
                                              </Link>
                                            ) : (
                                              ""
                                            )}
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                        ) : (
                          ""
                        )}
                      </li>
                    ))
                  : role === "Assurance "
                  ? ASSURANCEMENUS?.map((menuItem, i) => (
                      <li className="dropdown" key={i}>
                        {menuItem.type === "sub" ? (
                          <a
                            className={`nav-link menu-title  ${
                              menuItem.active ? "active" : ""
                            }`}
                            href="#javascript"
                            onClick={() => toggletNavActive(menuItem)}
                          >
                            <menuItem.icon />
                            <span>{menuItem.title}</span>
                            <div className="according-menu">
                              {menuItem.active ? (
                                <i className="fa fa-angle-down"></i>
                              ) : (
                                <i className="fa fa-angle-right"></i>
                              )}
                            </div>
                          </a>
                        ) : menuItem.type === "subLink" ? (
                          <Link
                            to={menuItem.path}
                            className={`nav-link menu-title  ${
                              menuItem.active ? "active" : ""
                            }`}
                            href="#javascript"
                            onClick={() => toggletNavActive(menuItem)}
                          >
                            <menuItem.icon />
                            <span>{menuItem.title}</span>
                          </Link>
                        ) : (
                          ""
                        )}
                        {menuItem.children ? (
                          <ul
                            className="nav-submenu menu-content"
                            style={
                              menuItem.active
                                ? {
                                    opacity: 1,
                                    transition: "opacity 500ms ease-in",
                                  }
                                : { display: "none" }
                            }
                          >
                            {menuItem.children.map((childrenItem, index) => {
                              return (
                                <li key={index}>
                                  {childrenItem.type === "sub" ? (
                                    <a
                                      className={`${
                                        childrenItem.active ? "active" : ""
                                      }`}
                                      href="#javascript"
                                      onClick={() =>
                                        toggletNavActive(childrenItem)
                                      }
                                    >
                                      {childrenItem.title}
                                      <span className="sub-arrow">
                                        <i className="fa fa-chevron-right"></i>
                                      </span>
                                      <div className="according-menu">
                                        {childrenItem.active ? (
                                          <i className="fa fa-angle-down"></i>
                                        ) : (
                                          <i className="fa fa-angle-right"></i>
                                        )}
                                      </div>
                                    </a>
                                  ) : (
                                    ""
                                  )}

                                  {childrenItem.type === "link" ? (
                                    <Link
                                      to={childrenItem.path}
                                      className={`${
                                        childrenItem.active ? "active" : ""
                                      }`}
                                      onClick={() =>
                                        toggletNavActive(childrenItem)
                                      }
                                    >
                                      {childrenItem.title}
                                    </Link>
                                  ) : (
                                    ""
                                  )}

                                  {childrenItem.type === "exteral_link" ? (
                                    <a
                                      href={childrenItem.path}
                                      className={
                                        childrenItem.active ? "active" : ""
                                      }
                                    >
                                      {childrenItem.title}
                                    </a>
                                  ) : (
                                    ""
                                  )}

                                  {childrenItem.children ? (
                                    <ul
                                      className="nav-sub-childmenu submenu-content"
                                      style={
                                        childrenItem.active
                                          ? { display: "block" }
                                          : { display: "none" }
                                      }
                                    >
                                      {childrenItem.children.map(
                                        (childrenSubItem, key) => (
                                          <li key={key}>
                                            {childrenSubItem.type === "link" ? (
                                              <Link
                                                to={childrenSubItem.path}
                                                className={`${
                                                  childrenSubItem.active
                                                    ? "active"
                                                    : ""
                                                }`}
                                                onClick={() =>
                                                  toggletNavActive(
                                                    childrenSubItem
                                                  )
                                                }
                                              >
                                                {childrenSubItem.title}
                                              </Link>
                                            ) : (
                                              ""
                                            )}
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                        ) : (
                          ""
                        )}
                      </li>
                    ))
                    : role === "FrontOffice"
                  ? FRONTOFFICE?.map((menuItem, i) => (
                      <li className="dropdown" key={i}>
                        {menuItem.type === "sub" ? (
                          <a
                            className={`nav-link menu-title  ${
                              menuItem.active ? "active" : ""
                            }`}
                            href="#javascript"
                            onClick={() => toggletNavActive(menuItem)}
                          >
                            <menuItem.icon />
                            <span>{menuItem.title}</span>
                            <div className="according-menu">
                              {menuItem.active ? (
                                <i className="fa fa-angle-down"></i>
                              ) : (
                                <i className="fa fa-angle-right"></i>
                              )}
                            </div>
                          </a>
                        ) : menuItem.type === "subLink" ? (
                          <Link
                            to={menuItem.path}
                            className={`nav-link menu-title  ${
                              menuItem.active ? "active" : ""
                            }`}
                            href="#javascript"
                            onClick={() => toggletNavActive(menuItem)}
                          >
                            <menuItem.icon />
                            <span>{menuItem.title}</span>
                          </Link>
                        ) : (
                          ""
                        )}
                        {menuItem.children ? (
                          <ul
                            className="nav-submenu menu-content"
                            style={
                              menuItem.active
                                ? {
                                    opacity: 1,
                                    transition: "opacity 500ms ease-in",
                                  }
                                : { display: "none" }
                            }
                          >
                            {menuItem.children.map((childrenItem, index) => {
                              return (
                                <li key={index}>
                                  {childrenItem.type === "sub" ? (
                                    <a
                                      className={`${
                                        childrenItem.active ? "active" : ""
                                      }`}
                                      href="#javascript"
                                      onClick={() =>
                                        toggletNavActive(childrenItem)
                                      }
                                    >
                                      {childrenItem.title}
                                      <span className="sub-arrow">
                                        <i className="fa fa-chevron-right"></i>
                                      </span>
                                      <div className="according-menu">
                                        {childrenItem.active ? (
                                          <i className="fa fa-angle-down"></i>
                                        ) : (
                                          <i className="fa fa-angle-right"></i>
                                        )}
                                      </div>
                                    </a>
                                  ) : (
                                    ""
                                  )}

                                  {childrenItem.type === "link" ? (
                                    <Link
                                      to={childrenItem.path}
                                      className={`${
                                        childrenItem.active ? "active" : ""
                                      }`}
                                      onClick={() =>
                                        toggletNavActive(childrenItem)
                                      }
                                    >
                                      {childrenItem.title}
                                    </Link>
                                  ) : (
                                    ""
                                  )}

                                  {childrenItem.type === "exteral_link" ? (
                                    <a
                                      href={childrenItem.path}
                                      className={
                                        childrenItem.active ? "active" : ""
                                      }
                                    >
                                      {childrenItem.title}
                                    </a>
                                  ) : (
                                    ""
                                  )}

                                  {childrenItem.children ? (
                                    <ul
                                      className="nav-sub-childmenu submenu-content"
                                      style={
                                        childrenItem.active
                                          ? { display: "block" }
                                          : { display: "none" }
                                      }
                                    >
                                      {childrenItem.children.map(
                                        (childrenSubItem, key) => (
                                          <li key={key}>
                                            {childrenSubItem.type === "link" ? (
                                              <Link
                                                to={childrenSubItem.path}
                                                className={`${
                                                  childrenSubItem.active
                                                    ? "active"
                                                    : ""
                                                }`}
                                                onClick={() =>
                                                  toggletNavActive(
                                                    childrenSubItem
                                                  )
                                                }
                                              >
                                                {childrenSubItem.title}
                                              </Link>
                                            ) : (
                                              ""
                                            )}
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                        ) : (
                          ""
                        )}
                      </li>
                    ))
                  : MENUITEMS?.map((menuItem, i) => (
                      <li className="dropdown" key={i}>
                        {menuItem.type === "sub" ? (
                          <a
                            className={`nav-link menu-title  ${
                              menuItem.active ? "active" : ""
                            }`}
                            href="#javascript"
                            onClick={() => toggletNavActive(menuItem)}
                          >
                            <menuItem.icon />
                            <span>{menuItem.title}</span>
                            <div className="according-menu">
                              {menuItem.active ? (
                                <i className="fa fa-angle-down"></i>
                              ) : (
                                <i className="fa fa-angle-right"></i>
                              )}
                            </div>
                          </a>
                        ) : menuItem.type === "subLink" ? (
                          <Link
                            to={menuItem.path}
                            className={`nav-link menu-title  ${
                              menuItem.active ? "active" : ""
                            }`}
                            href="#javascript"
                            onClick={() => toggletNavActive(menuItem)}
                          >
                            <menuItem.icon />
                            <span>{menuItem.title}</span>
                          </Link>
                        ) : (
                          ""
                        )}
                        {menuItem.children ? (
                          <ul
                            className="nav-submenu menu-content"
                            style={
                              menuItem.active
                                ? {
                                    opacity: 1,
                                    transition: "opacity 500ms ease-in",
                                  }
                                : { display: "none" }
                            }
                          >
                            {menuItem.children.map((childrenItem, index) => {
                              return (
                                <li key={index}>
                                  {childrenItem.type === "sub" ? (
                                    <a
                                      className={`${
                                        childrenItem.active ? "active" : ""
                                      }`}
                                      href="#javascript"
                                      onClick={() =>
                                        toggletNavActive(childrenItem)
                                      }
                                    >
                                      {childrenItem.title}
                                      <span className="sub-arrow">
                                        <i className="fa fa-chevron-right"></i>
                                      </span>
                                      <div className="according-menu">
                                        {childrenItem.active ? (
                                          <i className="fa fa-angle-down"></i>
                                        ) : (
                                          <i className="fa fa-angle-right"></i>
                                        )}
                                      </div>
                                    </a>
                                  ) : (
                                    ""
                                  )}

                                  {childrenItem.type === "link" ? (
                                    <Link
                                      to={childrenItem.path}
                                      className={`${
                                        childrenItem.active ? "active" : ""
                                      }`}
                                      onClick={() =>
                                        toggletNavActive(childrenItem)
                                      }
                                    >
                                      {childrenItem.title}
                                    </Link>
                                  ) : (
                                    ""
                                  )}

                                  {childrenItem.type === "exteral_link" ? (
                                    <a
                                      href={childrenItem.path}
                                      className={
                                        childrenItem.active ? "active" : ""
                                      }
                                    >
                                      {childrenItem.title}
                                    </a>
                                  ) : (
                                    ""
                                  )}

                                  {childrenItem.children ? (
                                    <ul
                                      className="nav-sub-childmenu submenu-content"
                                      style={
                                        childrenItem.active
                                          ? { display: "block" }
                                          : { display: "none" }
                                      }
                                    >
                                      {childrenItem.children.map(
                                        (childrenSubItem, key) => (
                                          <li key={key}>
                                            {childrenSubItem.type === "link" ? (
                                              <Link
                                                to={childrenSubItem.path}
                                                className={`${
                                                  childrenSubItem.active
                                                    ? "active"
                                                    : ""
                                                }`}
                                                onClick={() =>
                                                  toggletNavActive(
                                                    childrenSubItem
                                                  )
                                                }
                                              >
                                                {childrenSubItem.title}
                                              </Link>
                                            ) : (
                                              ""
                                            )}
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                        ) : (
                          ""
                        )}
                      </li>
                    ))}
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </Fragment>
  );
};

export default Sidebar;
