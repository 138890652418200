import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../layout/breadcrumb";
import distributorService from "../../services/distribitor/distributorService";
const UpdateSousDist = () => {
  const location = useLocation();
  useEffect(()=>{
    if(localStorage.getItem("role") !== "Distributeur"){
      history.goBack()
    }else if(localStorage.getItem("role") == "Distributeur" && !JSON.parse(localStorage.getItem("sous-rev"))){
      history.goBack()
    }
  },[])
  const update = location.state?.update;
  const history = useHistory();
  const [distribitor, setDistribitor] = useState(
    location.state
      ? {
          commission: location.state.row.commission,
          commissiontype: location.state.row.commissiontype,
          dateCreation: new Date().toISOString(),
          isActive: location.state.row.isActive,
          isDeleted: location.state.row.isDeleted,
          parentid: localStorage.getItem("idDist"),
          phone: location.state.row.phone,
          socialReason: location.state.row.socialReason,
          subdistributor: false,
          type: location.state.row.type,
        }
      : {}
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDistribitor((distribitor) => ({ ...distribitor, [name]: value }));
  };
  //   console.log(distribitor);
  const valider = () => {
    distributorService.createDistributorUser(distribitor).then((res) => {
      if (res.status == 200) {
        history.push("/sous-dist-list");
      }
    });
  };
  return (
    <Fragment>
      <Breadcrumbs
        parent="Revendeur"
        title={update ? "Modifier Revendeur" : "Revendeur"}
      />
      <Container fluid={true}>
        <Form className="needs-validation" noValidate="">
          <Row>
            <Col>
              <Card className="shadow-lg p-0 shadow-showcase">
                <CardHeader className="b-l-primary pb-2">
                  <h5>Détails du compte</h5>
                </CardHeader>
                <CardBody className="pt-1">
                  <div className="form-row">
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom01">
                        Revendeur
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="socialReason"
                        type="text"
                        placeholder="Revendeur"
                        innerRef=""
                        defaultValue={distribitor.socialReason}
                        onChange={handleChange}
                        disabled={!update}
                      />
                      <span></span>
                    </Col>

                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom01">
                      Description
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="type"
                        type="text"
                        placeholder="Description"
                        innerRef=""
                        defaultValue={distribitor.type}
                        onChange={handleChange}
                        disabled={!update}
                      />
                      <span></span>
                    </Col>
                    {/* <Col md="4 mb-3">
                      <Label htmlFor="validationCustom01">
                        Commission
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="commissiontype"
                        type="text"
                        placeholder="commission"
                        innerRef=""
                        defaultValue={distribitor.commissiontype}
                        onChange={handleChange}
                        disabled={!update}
                      />
                      <span></span>
                    </Col> */}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
        <Row>
          <Col>
            <Button
              color="danger"
              type="reset"
              className="float-left"
              onClick={() => history.push("/sous-dist-list")}
            >
              Retour
            </Button>
          </Col>
          <Col>
            {update ? (
              <Button color="primary" className="float-right" onClick={valider}>
                Valider
              </Button>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default UpdateSousDist;
