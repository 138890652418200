import React from "react";

const CardDash = ({ name, total, icon, pointer, onClick , style }) => {
  return (
    <div
      className={style ? "col-5 col-md-5 col-lg-5" : "col-4 col-md-4 col-lg-4"}
      style={{ display: "flex", justifyContent: "center" }}
    >
      <div className="chart_data_right col-11 col-md-11 col-lg-11 ">
        <div
          className="card"
          style={{
            boxShadow: "0 0 10px 0 #2c323f",
            cursor: pointer ? "pointer" : "default",
          }}
          onClick={onClick}
        >
          <div className="card-body">
            <div className="media align-items-center">
              <div className="media-body right-chart-content">
                <h4>
                  {total}
                  {/* <span className="new-box">Hot</span> */}
                </h4>
                <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                  {name}
                </span>
                <img
                  src={icon}
                  style={{
                    width: "15%",
                    float: "right",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardDash;
