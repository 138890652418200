import React, { useState, useEffect, Fragment } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  Media,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

import { useHistory } from "react-router-dom";
import Dropzone from "react-dropzone-uploader";
import { findLastIndex } from "lodash";
import Switch from "react-switch";

const UpdateBreakdown = ({ location, i18n }) => {
  const update = location.state.update;
  const history = useHistory();
  const [file, setFile] = useState(false);
  useEffect(() => {}, []);
  // const { register, handleSubmit, errors } = useForm(
  //   location.state
  //     ? {
  //         defaultValues: {
  //           abbreviation: location.state.row.abbreviation,
  //           address: location.state.row.adresse,
  //           latitude: location.state.row.coordonneeX,
  //           longitude: location.state.row.coordonneeY,
  //           color1: location.state.row.couleur1,
  //           color2: location.state.row.couleur2,
  //           actif: location.state.row.isActif,
  //           logo: location.state.row.logo,
  //           trn: location.state.row.mf,
  //           sc: location.state.row.rsc,
  //         },
  //       }
  //     : {}
  // );

  // const [logo, setLogo] = useState(location.state ? location.state.row.logo : "");

  // const config = location.state ? { title: t("update") + " " + t("company"), button: t("update") } : { title: t("newCompany"), button: t("save") };

  // const onSubmit = async (data) => {
  //   if (data !== "") {
  //     let dataToAdd = {
  //       abbreviation: data.abbreviation,
  //       adresse: data.address,
  //       coordonneeX: data.latitude,
  //       coordonneeY: data.longitude,
  //       couleur1: data.color1,
  //       couleur2: data.color2,
  //       isActif: data.actif ? 1 : 0,
  //       isDeleted: 0,
  //       logo: logo,
  //       mf: data.trn,
  //       rsc: data.sc,
  //       dateCreation: new Date().toISOString(),
  //     };
  //     // if (file) {
  //     //   let resultUpload = await nmcService.uploadFile(file);
  //     //   if (resultUpload.result === 1) {
  //     //     dataToAdd.logo = urlUpload + file.name;
  //     //   }
  //     // }
  //     // if (location.state) {
  //     //   dataToAdd.idComp = location.state.row.idComp;
  //     //   let result = await entrepriseService.updateEntreprise(dataToAdd);
  //     //   if (result.result === 2) {
  //     //     toast.success("Company successfully updated");
  //     //     history.push("/companies");
  //     //   }
  //     // }
  //       // else {
  //       // let result = await entrepriseService.createEntreprise(dataToAdd);
  //       // if (result.result === 1) {
  //       //   toast.success("Company successfully created");
  //         history.push("/companies");
  //       // }
  //     // }
  //   } else {
  //     errors.showMessages();
  //   }
  // };

  return (
    <Fragment>
      <Breadcrumb
        parent="Type de panne"
        title={update ? "Modifier Panne" : "Panne"}
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <h5>Type de panne</h5>
              </CardHeader>
              <CardBody>
                <Form className="needs-validation" noValidate="" onSubmit="">
                  <div className="form-row">
                    <Col md="6 mb-4">
                      <Label htmlFor="validationCustom01">
                        Non De Panne
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="sc"
                        type="text"
                        placeholder="Non De Panne"
                        innerRef=""
                        disabled={!update}
                      />
                      <span></span>
                    </Col>
                    <Col md="6 mb-4">
                      <Label htmlFor="validationCustom02">
                        Description
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="Description"
                        type="text"
                        placeholder="Description"
                        innerRef=""
                        disabled={!update}
                      />
                      <span></span>
                    </Col>
                  </div>

                  <Row>
                    <Col>
                      <Button
                        color="danger"
                        type="reset"
                        className="float-left"
                        onClick={() =>
                          history.push("/gest_fromule_product/Type_De_Panne")
                        }
                      >
                        Retour
                      </Button>
                    </Col>
                    <Col>
                      {update && (
                        <Button
                          color="primary"
                          type="submit"
                          className="float-right"
                        >
                          Validé
                        </Button>
                      )}
                    </Col>
                  </Row>
                  {/* <Button color="primary">{config.button}</Button>
                  <Button color="primary">{config.button}</Button> */}
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default UpdateBreakdown;
