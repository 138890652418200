import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  ADD_USERS_REQUEST,
} from "./usersActions";

const initialState = {
  loading: false,
  users: [],
  user:[],
  error: ""
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_USERS_SUCCESS:
      return {
        loading: false,
        users: action.payload,
        user:[],
        error: ""
      };
    case FETCH_USERS_FAILURE:
      return {
        loading: false,
        users: [],
        user:[],
        error: action.payload
      };

    case ADD_USERS_REQUEST:
      return {
        registering: true,
        users:[],
        user:action.payload,
        error: ""
      };
   
    default:
      return state;
  }
};

export default reducer;
