import React, { useState, useEffect, Fragment } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Select from "react-select";
import {
  Media,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import distributorService from "../../../services/distribitor/distributorService";
import { useHistory, useLocation } from "react-router-dom";
import Dropzone from "react-dropzone-uploader";
import { findLastIndex } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { fetchFamily } from "../../../redux/family/familyActions";
const UpdateRevendeur = () => {
  const dispatch = useDispatch();
  let location = useLocation();
  const update = location.state.update;
  const history = useHistory();
  const listFamily = useSelector(({ familyReducers }) => {
    return familyReducers.familys;
  });
  const [selectOption, setOptionSelected] = useState(null);
  const [distribitor, setDistribitor] = useState(
    location.state
      ? {
          commission: location.state.row.commission,
          dateCreation: new Date().toISOString(),
          iddisributor: location.state.row.iddisributor,
          isActive: location.state.row.isActive,
          isDeleted: location.state.row.isDeleted,
          socialReason: location.state.row.socialReason,
          type: location.state.row.type,
          commissiontype: location.state.row.commissiontype,
          typesale: JSON.parse(location.state.row.typesale),
          subdistributor: location.state.row.subdistributor,
        }
      : {}
  );
  const [submitted, setSubmitted] = useState(false);
  const [option, setListOption] = useState([]);
  const [checkedActif, setCheckedActif] = useState(true);
  const [checked, setChecked] = useState(false);
  React.useEffect(() => {
    dispatch(fetchFamily());
    if (location.state?.row) {
      let famlistselected = []
      listFamily?.map((el) => {
          JSON.parse(location.state.row.typesale)?.forEach((ele) => {
            if (el.idfamily == ele) {
              famlistselected.push(el)
            }
          })
          setOptionSelected(famlistselected.map((fa)=>({
            value: fa.idfamily,
            label: fa.familyName,
          })))
      });
    }
  }, []);
  useEffect(() => {
    setListOption(
      listFamily?.map((el) => ({
        value: el.idfamily,
        label: el.familyName,
      }))
    );
  }, [listFamily]);
  const onchangeSelect = (item) => {
    setOptionSelected(item);
    let typeList = [];
    item.forEach((element) => {
      typeList.push(element.value);
    });
    setDistribitor((distribitor) => ({
      ...distribitor,
      typesale: JSON.stringify(typeList),
    }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setDistribitor((distribitor) => ({ ...distribitor, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setSubmitted(true);
    console.log(distribitor);
    distributorService.updateDistributor(distribitor).then((res) => {
      if (res.status === 200) {
        toast.success("Le revendeur a été mise à jour avec succès");
        history.push("/gest_intervenant/revendeurs");
      }
    });
  };
  useEffect(() => {}, []);

  return (
    <Fragment>
      <Breadcrumb
        parent="Revendeur"
        title={update ? "Modifier Revendeur" : "Revendeur"}
      />
      <Container fluid={true}>
        <Form
          className="needs-validation"
          noValidate=""
          onSubmit={handleSubmit}
        >
          <Row>
            <Col>
              <Card className="shadow-lg p-0 shadow-showcase">
                <CardHeader className="b-l-primary pb-2">
                  {/* <h5>Info Generale</h5> */}
                </CardHeader>
                <CardBody className="pt-1">
                  <div className="form-row">
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom01">
                        Revendeur
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        required
                        className="form-control"
                        name="socialReason"
                        type="text"
                        placeholder="raison social"
                        innerRef=""
                        defaultValue={distribitor.socialReason}
                        onChange={handleChange}
                        disabled={!update}
                      />
                      <span></span>
                    </Col>
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom01">
                        Type
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        required
                        className="form-control"
                        name="type"
                        type="text"
                        placeholder="type"
                        innerRef=""
                        defaultValue={distribitor.type}
                        onChange={handleChange}
                        disabled={!update}
                      />
                      <span></span>
                    </Col>
                    <Col md="4 mb-4">
                      <Label
                        style={{ fontSize: "14px", fontWeight: "400" }}
                        htmlFor="validationCustom01"
                      >
                        Type Vente Produit
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Select
                        value={selectOption}
                        onChange={onchangeSelect}
                        options={option}
                        isDisabled={!update}
                        isMulti
                      />
                      <span></span>
                    </Col>
                    <Col md="4 mb-3">
                      <Label
                        htmlFor="validationCustom01"
                        style={{ fontSize: "14px", fontWeight: "400" }}
                      >
                        Type Commission
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <select
                        name="commissiontype"
                        style={{
                          height: "38px",
                          borderRadius: "5px",
                          border: "1px solid",
                          borderColor: "#b8b8b8",
                          fontSize: "large",
                          position: "relative",
                          width: "100%",
                          paddingLeft: "10px",
                        }}
                        value={distribitor.commissiontype}
                        onChange={handleChange}
                        disabled={!update}
                      >
                        <option value="%">%</option>
                        <option value="forfait">forfait</option>
                      </select>
                      <span></span>

                      <span></span>
                    </Col>
                    <Col md="4 mb-3">
                      <Label
                        htmlFor="validationCustom01"
                        style={{ fontSize: "14px", fontWeight: "400" }}
                      >
                        Commission
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="commission"
                        type="text"
                        placeholder="Commission"
                        innerRef=""
                        value={distribitor.commission}
                        onChange={handleChange}
                        disabled={!update}
                      />
                      <span></span>
                    </Col>
                    <Col md="4 mb-3">
                      <div
                        class="form-check form-switch"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          textAlign: "center",
                          marginTop: "20px",
                          padding: "0",
                        }}
                      >
                        <label
                          class="form-check-label"
                          for="flexSwitchCheckDefault"
                        >
                          Actif
                        </label>
                        <input
                          class="form-check-input"
                          style={{ marginTop: "25px", marginLeft: "4px" }}
                          name="isActive"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                          checked={distribitor.isActive == 1 ? true : false}
                          value="0"
                          onClick={() => {
                            if (checkedActif) {
                              setCheckedActif(false);
                              setDistribitor((distribitor) => ({
                                ...distribitor,
                                isActive: "0",
                              }));
                            } else {
                              setCheckedActif(true);
                              setDistribitor((distribitor) => ({
                                ...distribitor,
                                isActive: "1",
                              }));
                            }
                          }}
                          disabled={!update}
                        />
                      </div>
                    </Col>
                    <Col md="4 mb-3">
                      <div
                        class="form-check form-switch"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          textAlign: "center",
                          marginTop: "20px",
                          padding: "0",
                        }}
                      >
                        <label
                          class="form-check-label"
                          for="flexSwitchCheckDefault"
                        >
                          Avoir des Sous-revendeurs
                        </label>
                        <input
                          class="form-check-input"
                          style={{ marginTop: "25px", marginLeft: "4px" }}
                          name="isActive"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                          checked={distribitor.subdistributor}
                          value="0"
                          onClick={() => {
                            if (checked) {
                              setChecked(false);
                              setDistribitor((distribitor) => ({
                                ...distribitor,
                                subdistributor: false,
                              }));
                            } else {
                              setChecked(true);
                              setDistribitor((distribitor) => ({
                                ...distribitor,
                                subdistributor: true,
                              }));
                            }
                          }}
                          disabled={!update}
                        />
                      </div>
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Button
                color="danger"
                type="reset"
                className="float-left"
                onClick={() => history.push("/gest_intervenant/revendeurs")}
              >
                Retour
              </Button>
            </Col>
            <Col>
              {update && (
                <Button color="primary" type="submit" className="float-right">
                  Valider
                </Button>
              )}
            </Col>
          </Row>
          {/* <Button color="primary">{config.button}</Button>
                  <Button color="primary">{config.button}</Button> */}
        </Form>
      </Container>
    </Fragment>
  );
};

export default UpdateRevendeur;
