import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import Select from "react-select";
import Breadcrumb from "../../layout/breadcrumb";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { saveContractDetails } from "../../redux/revendeur/distributorActions";
import contractServices from "../../services/contract/contractServices";
import { toast } from "react-toastify";
const CustomerCard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectOption, setOptionSelected] = useState(null);
  const [option, setListOption] = useState([
    { label: "Cin", value: "Cin" },
    { label: "Passport", value: "Passport" },
    { label: "Carte séjour", value: "Carte séjour" },
  ]);
  const [productNbr, setProductNbr] = useState({ nbrProduct: "" });
  const [modal, setModal] = useState(false);
  const [Verticalcenter, setVerticalcenter] = useState(false);
  const Verticalcentermodaltoggle = () => {
    console.log(productNbr);
    setVerticalcenter(!Verticalcenter);
  };
  const handleModelChange = (e) => {
    const { name, value } = e.target;
    setProductNbr((productNbr) => ({ ...productNbr, [name]: value }));
  };
  const contractDetails = useSelector(({ distributorReducers }) => {
    return distributorReducers.contarctDetails;
  });
  const [customer, setCustomer] = useState(
    contractDetails[0]?.typeCustomer === "M"
      ? {
          typeCustomer: contractDetails[0]?.typeCustomer,
          taxRegistrationNumber: contractDetails[0]?.taxRegistrationNumber,
        }
      : {
          typeCustomer: contractDetails[0]?.typeCustomer,
          cin: contractDetails[0]?.cin,
        }
  );
  const [disabled, setDisabled] = useState(false);
  const [detailsCustomer, setDetailsCustomer] = useState();
  useEffect(() => {
    const uid = localStorage.getItem("id");
    if (localStorage.getItem("role") !== "Distributeur" && !(localStorage.getItem("role") === "FrontOffice") ) {
      history.goBack();
    } else if (!contractDetails[1]) {
      history.push("/new-sale");
    }
    contractServices
      .getCustomerByCin(contractDetails[0]?.cin, uid)
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          setDisabled(true);
          localStorage.setItem("customer", JSON.stringify(res.data));
          setCustomer(res.data);
          setDetailsCustomer(res.data);
        }
      });
  }, [contractDetails[0]]);

  const onChangeSelect = (item) => {
    setOptionSelected(item);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomer((detailsCustomer) => ({
      ...detailsCustomer,
      [name]: value,
    }));
  };
  const detais = [contractDetails[1], customer];
  const handleSubmit = (e) => {
    e.preventDefault();
    if (contractDetails[1]?.name === "Ifrah ou Farah" || contractDetails[1]?.family?.familyName === "Pack Électroménager") {
      Verticalcentermodaltoggle();
    } else {
      if (customer.cin && customer.cin.length === 8) {
        dispatch(saveContractDetails(detais));
        history.push("/info-product");
      } else if (customer.typeCustomer == "M") {
        dispatch(saveContractDetails(detais));
        history.push("/info-product");
      } else {
        toast.error("Num CIN obligatoire et doit etre 8 chiffres");
      }
    }
  };
  const continuer = (e) => {
    e.preventDefault();
    if (customer.cin && customer.cin.length === 8) {
      dispatch(
        saveContractDetails([
          contractDetails[1],
          customer,
          JSON.parse(productNbr?.nbrProduct),
        ])
      );
      history.push("/info-product");
    } else if (customer.typeCustomer == "M") {
      dispatch(
        saveContractDetails([
          contractDetails[1],
          customer,
          JSON.parse(productNbr?.nbrProduct),
        ])
      );
      history.push("/info-product");
    } else {
      toast.error("Num CIN obligatoire et doit etre 8 chiffres");
    }
    setVerticalcenter(!Verticalcenter);
  };
  return (
    <div>
      <Breadcrumb parent={"Nouvelle Vente"} title={"Fiche Client"} />
      <Container fluid={true}>
        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader className="m-0 pb-0">
            <Row>
              <Col className="text-right"></Col>
            </Row>
          </CardHeader>

          <CardBody className="pt-2">
            <Form className="needs-validation" onSubmit={handleSubmit}>
              <div className="form-row">
                <Col md="5 mb-6">
                  <Label
                    style={{ fontSize: "16px", fontWeight: "400" }}
                    htmlFor="validationCustom01"
                  >
                    Nom
                    <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    style={{ height: "50px" }}
                    className="form-control"
                    name="lastName"
                    type="text"
                    placeholder="Nom"
                    disabled={detailsCustomer?.lastName ? disabled : false}
                    value={customer?.lastName}
                    onChange={handleChange}
                    required={true}
                  />
                  <span></span>
                </Col>
                <Col md="5 mb-4">
                  <Label
                    style={{ fontSize: "16px", fontWeight: "400" }}
                    htmlFor="validationCustom01"
                  >
                    Prénom
                    <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    style={{ height: "50px" }}
                    className="form-control"
                    name="firstName"
                    type="text"
                    placeholder="Prénom"
                    disabled={detailsCustomer?.firstName ? disabled : false}
                    value={customer?.firstName}
                    onChange={handleChange}
                    required={true}
                  />
                  <span></span>
                </Col>
                <Col md="5 mb-4">
                  <Label
                    style={{ fontSize: "16px", fontWeight: "400" }}
                    htmlFor="validationCustom02"
                  >
                    Date de naissance
                    <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    style={{ height: "50px" }}
                    className="form-control"
                    name="birthdate"
                    type="date"
                    placeholder="Date de naissance"
                    disabled={detailsCustomer?.birthdate ? disabled : false}
                    value={customer?.birthdate}
                    onChange={handleChange}
                    required={true}
                  />
                  <span></span>
                </Col>
                <Col md="5 mb-6">
                  <Label
                    style={{ fontSize: "16px", fontWeight: "400" }}
                    htmlFor="validationCustom02"
                  >
                    Nationalité
                    <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    style={{ height: "50px" }}
                    className="form-control"
                    name="nationalty"
                    type="text"
                    placeholder="Nationalité"
                    disabled={detailsCustomer?.idcountry ? disabled : false}
                    value={
                      customer?.idcountry || contractDetails[1]?.nationalty
                    }
                    onChange={handleChange}
                    required={true}
                  />
                  <span></span>
                </Col>
                <Col md="5 mb-6">
                  <Label
                    style={{ fontSize: "16px", fontWeight: "400" }}
                    htmlFor="validationCustom01"
                  >
                    Adresse
                    <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    style={{ height: "50px" }}
                    className="form-control"
                    name="adress"
                    type="text"
                    placeholder="Adresse"
                    disabled={detailsCustomer?.adress ? disabled : false}
                    value={customer?.adress || contractDetails[1]?.adress}
                    onChange={handleChange}
                    required={true}
                  />
                  <span></span>
                </Col>
                <Col md="5 mb-4">
                  <Label
                    style={{ fontSize: "16px", fontWeight: "400" }}
                    htmlFor="validationCustom01"
                  >
                    Rue
                    <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    style={{ height: "50px" }}
                    className="form-control"
                    name="street"
                    type="text"
                    placeholder="Rue"
                    disabled={detailsCustomer?.street ? disabled : false}
                    value={customer?.street || contractDetails[1]?.street}
                    onChange={handleChange}
                    required={true}
                  />
                  <span></span>
                </Col>
                <Col md="5 mb-4">
                  <Label
                    style={{ fontSize: "16px", fontWeight: "400" }}
                    htmlFor="validationCustom02"
                  >
                    Code Postal
                    <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    style={{ height: "50px" }}
                    className="form-control"
                    name="postalCode"
                    type="text"
                    placeholder="Code Postal"
                    disabled={detailsCustomer?.postalCode ? disabled : false}
                    value={
                      customer?.postalCode || contractDetails[1]?.postalCode
                    }
                    onChange={handleChange}
                    required={true}
                  />
                  <span></span>
                </Col>
                <Col md="5 mb-6">
                  <Label
                    style={{ fontSize: "16px", fontWeight: "400" }}
                    htmlFor="validationCustom02"
                  >
                    Ville
                    <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    style={{ height: "50px" }}
                    className="form-control"
                    name="city"
                    type="text"
                    placeholder="Ville"
                    disabled={detailsCustomer?.city ? disabled : false}
                    value={customer?.city || contractDetails[1]?.city}
                    onChange={handleChange}
                    required={true}
                  />
                  <span></span>
                </Col>
                <Col md="5 mb-6">
                  <Label
                    style={{ fontSize: "16px", fontWeight: "400" }}
                    htmlFor="validationCustom01"
                  >
                    E-mail
                    <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    style={{ height: "50px" }}
                    className="form-control"
                    name="email"
                    type="email"
                    placeholder="E-mail"
                    disabled={detailsCustomer?.email ? disabled : false}
                    value={customer?.email}
                    onChange={handleChange}
                    required={true}
                  />
                  <span></span>
                </Col>
                <Col md="5 mb-4">
                  <Label
                    style={{ fontSize: "16px", fontWeight: "400" }}
                    htmlFor="validationCustom01"
                  >
                    Télephone
                    <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    style={{ height: "50px" }}
                    className="form-control"
                    name="phone"
                    type="number"
                    placeholder="Télephone"
                    disabled={detailsCustomer?.phone ? disabled : false}
                    value={customer?.phone}
                    onChange={handleChange}
                    required={true}
                  />
                  <span></span>
                </Col>
                {customer.typeCustomer == "M" ? (
                  <></>
                ) : (
                  <>
                    <Col md="5 mb-4">
                      <Label
                        style={{ fontSize: "16px", fontWeight: "400" }}
                        htmlFor="validationCustom02"
                      >
                        Nature de la pièce
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Select
                        className="select-heghit"
                        // value={selectOption}
                        onChange={onChangeSelect}
                        defaultValue={{ label: "Cin", value: "Cin" }}
                        options={option}
                      />
                      <span></span>
                    </Col>

                    <Col md="5 mb-4">
                      <Label
                        style={{ fontSize: "16px", fontWeight: "400" }}
                        htmlFor="validationCustom02"
                      >
                        {selectOption ? selectOption.label : "Cin"}
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        style={{ height: "50px" }}
                        className="form-control"
                        name="cin"
                        type="text"
                        placeholder={selectOption?.label}
                        disabled={detailsCustomer?.cin ? disabled : false}
                        value={contractDetails[0]?.cin}
                        onChange={handleChange}
                        required={true}
                      />
                      <span></span>
                    </Col>
                  </>
                )}

                {customer.typeCustomer == "M" ? (
                  <>
                    <Col md="5 mb-6">
                      <Label
                        style={{ fontSize: "16px", fontWeight: "400" }}
                        htmlFor="validationCustom02"
                      >
                        Raison Social
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        style={{ height: "50px" }}
                        className="form-control"
                        name="socialReason"
                        type="text"
                        placeholder="Raison Social"
                        disabled={
                          detailsCustomer?.socialReason ? disabled : false
                        }
                        value={customer.socialReason}
                        onChange={handleChange}
                        required={true}
                      />
                      <span></span>
                    </Col>
                    <Col md="5 mb-6">
                      <Label
                        style={{ fontSize: "16px", fontWeight: "400" }}
                        htmlFor="validationCustom02"
                      >
                        Numéro Fiscale
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        style={{ height: "50px" }}
                        className="form-control"
                        name="taxRegistrationNumber"
                        type="text"
                        placeholder="Numéro Fiscale"
                        disabled={
                          detailsCustomer?.taxRegistrationNumber
                            ? disabled
                            : false
                        }
                        value={contractDetails[0]?.taxRegistrationNumber}
                        onChange={handleChange}
                        required={true}
                      />
                      <span></span>
                    </Col>
                    <Col md="5 mb-6">
                      <Label
                        style={{ fontSize: "16px", fontWeight: "400" }}
                        htmlFor="validationCustom02"
                      >
                        Cin
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        style={{ height: "50px" }}
                        className="form-control"
                        name="cin"
                        type="number"
                        placeholder="cin"
                        disabled={
                          detailsCustomer?.cin
                            ? disabled
                            : false
                        }
                        value={contractDetails[0]?.cin ? contractDetails[0]?.cin : customer.cin}
                        onChange={handleChange}
                        required={true}
                      />
                      <span></span>
                    </Col>
                  </>
                ) : (
                  <></>
                )}
              </div>

              <Row>
                <Col md="5 mb-6">
                  <Button
                    color="danger"
                    className="float-left"
                    style={{ width: "55%", height: "45px" }}
                    onClick={() => {
                      history.push("/new-sale");
                    }}
                  >
                    Retour
                  </Button>
                </Col>
                <Col md="5 mb-6">
                  <Button
                    color="primary"
                    className="float-right"
                    type="submit"
                    style={{ width: "55%", height: "45px" }}
                  >
                    Valider
                  </Button>
                </Col>
              </Row>
            </Form>
            {contractDetails[1]?.name === "Ifrah ou Farah" || contractDetails[1]?.family?.familyName === "Pack Électroménager" ? (
              <Modal
                isOpen={Verticalcenter}
                toggle={Verticalcentermodaltoggle}
                centered
              >
                <Form className="needs-validation" onSubmit={continuer}>
                  <ModalHeader toggle={Verticalcentermodaltoggle}>
                    Nombre de produits à assurer
                  </ModalHeader>
                  <ModalBody>
                    <Col md="10 mb-4">
                      <Label htmlFor="validationCustom01">
                        l'offre {contractDetails[1]?.name} permet d'assurer
                        jusqu'à {contractDetails[1]?.nbrProductCovered} produit
                        <br />
                        Prière de spécifier le nombre de produits à assurer:
                      </Label>
                      <Input
                        className="form-control"
                        name={"nbrProduct"}
                        type="number"
                        max={contractDetails[1]?.nbrProductCovered}
                        min={1}
                        placeholder="X"
                        value={productNbr.nbrProduct}
                        onChange={handleModelChange}
                        required={true}
                      />
                    </Col>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" type="submit">
                      Continuer
                    </Button>
                  </ModalFooter>
                </Form>
              </Modal>
            ) : (
              <></>
            )}
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default CustomerCard;
