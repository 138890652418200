import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import IconButton from "@material-ui/core/IconButton";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Badge,
} from "reactstrap";

import Breadcrumb from "../../../layout/breadcrumb";
import { useHistory } from "react-router-dom";
import { fetchCategory } from "../../../redux/category/categoryActions";
import SweetAlert from "sweetalert2";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";
import BudgeComponent from "../../../components/BudgeComponent";

const CategorieList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const listCategroy = useSelector(
    ({ categoryReducer }) => categoryReducer.categorys
  );
  const profil = localStorage.getItem("profil");
  useEffect(() => {
    dispatch(fetchCategory());
  }, []);

  const columns = [
    {
      name: "Catégorie",
      selector: "name",
      sortable: true,
    },
    {
      name: "Famille",
      selector: "family.familyName",
      sortable: true,
    },
    // {
    //   name: "Description",
    //   selector: "description",
    //   sortable: true
    // },
    {
      name: "Actif",
      cell: (row) => (
        <BudgeComponent status={row.isVisible} />

        // <div class="form-check form-switch">
        //   <input
        //     class="form-check-input"
        //     name="isActive"
        //     type="checkbox"
        //     id={row.iduserG}
        //     // checked={checked}
        //     value="0"
        //     // onClick={() => setChecked(false) & console.log(row,"---",checked) }
        //   />
        // </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          {profil === "User" ? (
            <IconButton
              color="primary"
              onClick={() => {
                history.push({
                  pathname: "/gest_fromule_product/Catégories/update_Category",
                  state: { row },
                });
              }}
            >
              <i className="icofont icofont-eye-alt"></i>
            </IconButton>
          ) : (
            <>
              <IconButton
                color="primary"
                onClick={() => {
                  console.log(row);
                  history.push({
                    pathname:
                      "/gest_fromule_product/Catégories/update_Category",
                    state: { row, update: true },
                  });
                }}
              >
                <i className="icofont icofont-ui-edit"></i>
              </IconButton>
              <IconButton
                color="primary"
                onClick={() => {
                  history.push({
                    pathname:
                      "/gest_fromule_product/Catégories/update_Category",
                    state: { row },
                  });
                }}
              >
                <i className="icofont icofont-eye-alt"></i>
              </IconButton>
            </>
          )}
        </div>
      ),

      button: true,
    },
  ];

  return (
    <div>
      <Breadcrumb
        parent={"Catégories"}
        title={"Liste des Catégories de produits"}
      />
      <Container fluid={true}>
        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader
            className="ml-2"
            style={{ cursor: "pointer", padding: "10px" }}
            onClick={() => {}}
          >
            <span className="float-left">
              <h6 className="mt-1">{"Filtrer"}</h6>
            </span>

            <span className="float-right ">
              <h4>
                {" "}
                <i className="fa fa-angle-down"></i> :{" "}
                <i className="fa fa-angle-up"></i>
              </h4>
            </span>
          </CardHeader>
        </Card>

        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader className="m-0  align-button ">
            <Row>
              <Col className="text-right">
                <span>
                  {profil === "User" ? (
                    <></>
                  ) : (
                    <Button
                      className="btn-pill btn-air-primary "
                      color="primary"
                      onClick={() => {
                        history.push(
                          "/gest_fromule_product/Catégories/new_Category"
                        );
                      }}
                    >
                      + {"Nouvelle Catégorie"}
                    </Button>
                  )}
                </span>
              </Col>
            </Row>
          </CardHeader>

          <CardBody className="pt-2">
            <DataTable
              noHeader
              pagination
              paginationComponentOptions={{
                rowsPerPageText: "Afficher",
                rangeSeparatorText: "/",
                selectAllRowsItem: true,
                selectAllRowsItemText: "all",
              }}
              columns={columns}
              data={listCategroy}
              pointerOnHover={true}
              progressPending=""
              progressComponent={
                <div className="loader-box">
                  <div className="loader-1"></div>
                </div>
              }
            ></DataTable>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default CategorieList;
