import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Breadcrumb,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../layout/breadcrumb";
import distributorService from "../../services/distribitor/distributorService";
const generator = require("generate-password");
const SouDist = () => {
  const [confirmPass, setConfirmPass] = useState(false);

  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState([]);
  const generaetePassword = () => {
    var genereatedPass = generator.generate({
      length: 10,
      numbers: true,
    });
    setConfirmPass(false);
    setPassword(genereatedPass);
    setConfirmPassword(genereatedPass);
    setDistribitor((distribitor) => ({
      ...distribitor,
      password: genereatedPass,
    }));
  };
  const history = useHistory();
  const [distribitor, setDistribitor] = useState({
    commission: 0,
    commissiontype: "",
    dateCreation: new Date().toISOString(),
    email: "",
    firstName: "",
    idType: "eef575da-c070-4514-b254-3d7f1d71cd51",
    idprofile: "f7d5a9a8-d123-43f4-8f97-328e4b4f6d3d",
    isActive: 1,
    isDeleted: 0,
    lastName: "",
    logo: "",
    parentid: localStorage.getItem("idDist"),
    password: "",
    phone: "",
    socialReason: "",
    subdistributor: false,
    type: "",
    typesale: "",
  });
  useEffect(() => {
    if(localStorage.getItem("role") !== "Distributeur"){
      history.goBack()
    }else if(localStorage.getItem("role") == "Distributeur" && !JSON.parse(localStorage.getItem("sous-rev"))){
      history.goBack()
    }
    distributorService
      .getDisrtibutorbyId(localStorage.getItem("idDist"))
      .then((res) => {
        setDistribitor((distribitor) => ({
          ...distribitor,
          typesale: res.data.typesale,
        }));
      });
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setDistribitor((distribitor) => ({ ...distribitor, [name]: value }));
  };

  console.log(distribitor);
  const valider = () => {
    distributorService.createDistributorUser(distribitor).then((res) => {
      if (res.status == 200) {
        // distributorService.sendMail({
        //   login: distribitor.email,
        //   password: distribitor.password,
        //   sendTo: distribitor.email,
        //   text: "Bienvenue chez Garanty",
        // });
        history.push("/sous-dist-list");
        toast.success("Opération terminée avec succès");
      }
    });
  };
  return (
    <Fragment>
      <Breadcrumbs parent="Revendeur" title="Revendeur" />
      <Container fluid={true}>
        <Form className="needs-validation" noValidate="">
          <Row>
            <Col>
              <Card className="shadow-lg p-0 shadow-showcase">
                <CardHeader className="b-l-primary pb-2">
                  <h5>Détails du compte</h5>
                </CardHeader>
                <CardBody className="pt-1">
                  <div className="form-row">
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom01">
                        Revendeur
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="socialReason"
                        type="text"
                        placeholder="Revendeur"
                        innerRef=""
                        value={distribitor.socialReason}
                        onChange={handleChange}
                      />
                      <span></span>
                    </Col>

                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom01">
                      Description
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="type"
                        type="text"
                        placeholder="Description"
                        innerRef=""
                        value={distribitor.type}
                        onChange={handleChange}
                      />
                      <span></span>
                    </Col>
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom01">
                        Nom
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="firstName"
                        type="text"
                        placeholder="Nom"
                        innerRef=""
                        value={distribitor.firstName}
                        onChange={handleChange}
                      />
                      <span></span>
                    </Col>
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom01">
                        Prénom
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="lastName"
                        type="text"
                        placeholder="Prénom"
                        innerRef=""
                        value={distribitor.lastName}
                        onChange={handleChange}
                      />
                      <span></span>
                    </Col>
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom01">
                        Mobile
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="phone"
                        type="text"
                        placeholder="Mobile"
                        innerRef=""
                        value={distribitor.phone}
                        onChange={handleChange}
                      />
                      <span></span>
                    </Col>
                    {/* <Col md="4 mb-3">
                      <Label htmlFor="validationCustom01">
                        Type Commission
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <select
                        name="commissiontype"
                        style={{
                          height: "38px",
                          borderRadius: "5px",
                          border: "1px solid",
                          borderColor: "#b8b8b8",
                          fontSize: "large",
                          position: "relative",
                          width: "100%",
                          paddingLeft: "10px",
                        }}
                        onChange={handleChange}
                      >
                        <option value="%">%</option>
                        <option value="forfait">forfait</option>
                      </select>
                      <span></span>
                    </Col> */}
                    {/* <Col md="4 mb-3">
                      <Label htmlFor="validationCustom01">
                       Commission
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="commission"
                        type="text"
                        placeholder="commission"
                        innerRef=""
                        value={distribitor.commission}
                        onChange={handleChange}
                      />
                    </Col> */}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </Container>
      <Container fluid={true}>
        <Form className="needs-validation" noValidate="">
          <Row>
            <Col>
              <Card className="shadow-lg p-0 shadow-showcase">
                <CardHeader className="b-l-secondary pb-2">
                  <h5> Détails de connexion</h5>
                </CardHeader>
                <CardBody className="pt-1">
                  <div className="form-row">
                    <Col md="3 mb-2">
                      <Label htmlFor="validationCustom01">E-mail</Label>
                      <span style={{ color: "red" }}>*</span>
                      <Input
                        className="form-control"
                        name="email"
                        type="text"
                        placeholder="E-mail"
                        innerRef=""
                        value={distribitor.email}
                        onChange={handleChange}
                      />

                      <div className="valid-feedback">Looks good!</div>
                    </Col>
                    <Col md="3 mb-2">
                      <Label htmlFor="validationCustom02">Mot de passe</Label>
                      <span style={{ color: "red" }}>*</span>
                      <Input
                        className="form-control"
                        name="password"
                        type="text"
                        placeholder="password"
                        value={password}
                        onChange={handleChange}
                      />

                      <div className="valid-feedback">Looks good!</div>
                    </Col>
                    <Col md="3 mb-2">
                      <Label htmlFor="validationCustom02">confirmation</Label>
                      <span style={{ color: "red" }}>*</span>
                      <Input
                        className="form-control"
                        name="confirmPassword"
                        type="text"
                        placeholder="confirm Password"
                        value={password}
                      />
                    </Col>
                    <Col md="3 m-auto">
                      <Button
                        className="btn-pill btn-air-primary float-right"
                        color="primary"
                        onClick={() => generaetePassword()}
                      >
                        Générer
                      </Button>
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Button
                color="danger"
                type="reset"
                className="float-left"
                onClick={() => history.push("/sous-dist-list")}
              >
                Retour
              </Button>
            </Col>
            <Col>
              <Button color="primary" className="float-right" onClick={valider}>
                Valider
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </Fragment>
  );
};

export default SouDist;
