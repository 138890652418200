import usersService from "../../services/users/usersService";

export const FETCH_USERS_REQUEST = "FETCH_USERS_REQUEST";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAILURE = "FETCH_USERS_FAILURE";
export const ADD_USERS_REQUEST = "ADD_USERS_REQUEST";

export const fetchUsers = () => {
  return (dispatch) => {
    const uid= localStorage.getItem('id')
    dispatch(fetchUsersRequest());
    usersService
      .getAllUsers(uid)
      .then((response) => {
        // response.data is the UserS
        const Users = response.data;
        dispatch(fetchUsersSuccess(Users));
      })
      .catch((error) => {
        // error.message is the error message
        dispatch(fetchUsersFailure(error.message));
      });
  };
};

export const fetchUsersRequest = () => {
  return {
    type: FETCH_USERS_REQUEST
  };
};

export const fetchUsersSuccess = (users) => {
  return {
    type: FETCH_USERS_SUCCESS,
    payload: users
  };
};

export const fetchUsersFailure = (error) => {
  return {
    type: FETCH_USERS_FAILURE,
    payload: error
  };
};

export const saveUser = (data) => {
  return (dispatch) => {
    dispatch(addUserRequest(data));
   
  };
};

export const addUserRequest = (user) => {
  return {
    type: ADD_USERS_REQUEST,
    payload: user
  };
};

