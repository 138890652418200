import familyService from "../../services/family/familyService";

export const FETCH_FAMILYS_REQUEST = "FETCH_FAMILYS_REQUEST";
export const FETCH_FAMILYS_SUCCESS = "FETCH_FAMILYS_SUCCESS";
export const FETCH_FAMILYS_FAILURE = "FETCH_FAMILYS_FAILURE";
export const ADD_FAMILYS_REQUEST = "ADD_FAMILYS_REQUEST";
export const ADD_FAMILYS_SUCCESS = "ADD_FAMILYS_SUCCESS";
export const ADD_FAMILYS_FAILURE = "ADD_FAMILYS_FAILURE";

export const fetchFamily = () => {
  return (dispatch) => {
    dispatch(fetchFamilysRequest());
    familyService
      .getAllFamily()
      .then((response) => {
        // response.data is the FAMILYS
        const familys = response.data;
        dispatch(fetchFamilysSuccess(familys));
      })
      .catch((error) => {
        // error.message is the error message
        dispatch(fetchFamilysFailure(error.message));
      });
  };
};

export const fetchFamilysRequest = () => {
  return {
    type: FETCH_FAMILYS_REQUEST
  };
};

export const fetchFamilysSuccess = (familys) => {
  return {
    type: FETCH_FAMILYS_SUCCESS,
    payload: familys
  };
};

export const fetchFamilysFailure = (error) => {
  return {
    type: FETCH_FAMILYS_FAILURE,
    payload: error
  };
};

export const addFamily = (data) => {
  return (dispatch) => {
    dispatch(addFamilysRequest(data));
    familyService.createNewFamily(data).then(
      (response) => {
        dispatch(addFamilysSuccess(response.data));
      },
      (error) => {
        dispatch(addFamilysFailure(error.messege));
      }
    );
  };
};

export const addFamilysRequest = (family) => {
  return {
    type: ADD_FAMILYS_REQUEST,
    payload: family
  };
};

export const addFamilysSuccess = (family) => {
  return {
    type: ADD_FAMILYS_SUCCESS,
    payload: family
  };
};

export const addFamilysFailure = (error) => {
  return {
    type: ADD_FAMILYS_FAILURE,
    payload: error
  };
};
