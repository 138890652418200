import  {adminAxios}  from "../../config/axiosConfig/InstancesAxios";

export default {

  getAllCourtier: (uid) => {
    return adminAxios.post("/getAllBrokerCompany", null, {params:{uid}});
  },

  createNewCourtier: (courtierModel) => {
    return adminAxios.post("/createBrokerCompany", courtierModel)
  },

  updateCourtier: (model) => {
    return adminAxios.post("/updateBrokerCompany",model)
  }

};