import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";
import Select from "react-select";
import Breadcrumb from "../../layout/breadcrumb";
import contractServices from "../../services/contract/contractServices";
import { saveContractDetails } from "../../redux/revendeur/distributorActions";
import { useSelector } from "react-redux";
import usersService from "../../services/users/usersService";
const PaymentDetails = () => {
  const history = useHistory();
  const location = useLocation();
  console.log(location.state);
  const [paymentMode, setPaymentMode] = useState();
  const [selectOption, setOptionSelected] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [facture, setFacture] = useState(null);
  const details = useSelector(
    ({ distributorReducers }) => distributorReducers.contarctDetails
  );

  useEffect(() => {
    console.log(
      Object.keys(details[2]).map((el) => ({
        idproductInfo: details[2][el].id,
        value: details[2][el].value,
      }))
    );
    if (
      localStorage.getItem("role") !== "Distributeur" &&
      !(localStorage.getItem("role") === "FrontOffice")
    ) {
      history.goBack();
    } else if (!(details[0] && details[1] && details[2])) {
      history.push("/new-sale");
    }
    contractServices.getTypePayment().then((response) => {
      setPaymentMode(
        response.data
          ?.filter((el) => el.num > 2)
          .map((el) => ({
            value: el.idpaymentType,
            label: el.name,
            num: el.num,
          }))
      );
    });
  }, []);

  const onchangeSelect = (item) => {
    setOptionSelected(item);
    console.log(item);
  };
  var newDate = new Date();
  var thisMonth = newDate.getUTCMonth();
  var plusMois = 12;
  var plus24mois = 24;
  var endDate = new Date();
  endDate.setUTCMonth(thisMonth + plusMois);
  var endDate2 =new Date();
  endDate2.setUTCMonth(thisMonth + plus24mois);
  const handelChange = (e) => {
    const { value } = e.target;
    setFacture(value);
  };
  const onSubmit = () => {
    if (details[1].idcustomer) {
      var idC = details[1].idcustomer;
    } else {
      idC = null;
    }
    let contract = {
      cguAssurance: 0,
      cguWebSite: 0,
      customerContractDto: {
        adress: details[1]?.adress,
        birthdate: details[1]?.birthdate,
        cin: details[1]?.cin,
        city: details[1]?.city,
        email: details[1]?.email,
        firstName: details[1]?.firstName,
        idcountry: details[1]?.idcountry,
        idcustomer: idC,
        lastName: details[1]?.lastName,
        phone: details[1]?.phone,
        postalCode: details[1]?.postalCode,
        street: details[1]?.street,
        socialReason: details[1]?.socialReason,
        taxRegistrationNumber: details[1]?.taxRegistrationNumber,
      },
      dateCreation: new Date().toISOString(),
      endDate: details[0]?.other == 2 ? endDate2.toISOString() : endDate.toISOString(),
      facture_dist: facture,
      iddistributor: localStorage.getItem("idDist"),
      idformula: details[0]?.idformula,
      idcountry: "1203e7fc-60a8-4cf7-a93f-ae369eacfa08",
      idtypePayment: selectOption?.value,
      inclusion: "0",
      listproducts: {
        idproduct: details[0]?.id_product,
        listvalueinfosDto: Object.keys(details[2]).map((el) => ({
          idproductInfo: details[2][el].id,
          value: details[2][el].value,
        })),
      },
      noticeDownloaded: 0,
      startDate: new Date().toISOString(),
      frontingHtAssurance: details[0]?.frontingHtAssurance,
      frontingTtcAssurance: details[0]?.frontingTtcAssurance,
      frontingHtCourtier: details[0]?.frontingHtCourtier,
      margeDistributeurHt: details[0]?.margeDistributeurHt,
      margeDistributeurTtc: details[0]?.margeDistributeurTtc,
      netPriceHt: details[0]?.netPriceHt,
      netPriceTtc: details[0]?.netPriceTtc,
      tua: details[0]?.tua,
      typeCustomer: details[1]?.typeCustomer,
      iduserG: localStorage.getItem("id"),
    };
    setDisabled(true);
    if (selectOption?.value) {
      contractServices.createContract(contract).then((res) => {
        if (res.data) {
          history.push({
            pathname: "/document",
            state: [res.data.idcontract, contract],
          });
        }
      });
    }
  };

  return (
    <div>
      <Breadcrumb parent={"Nouvelle Vente"} title={"Détails du Paiment"} />
      <Container fluid={true}>
        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader className="m-0 pb-0">
            <Row>
              <Col className="text-center">
                <h3>Votre Commande</h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody
            className="pt-2"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <h4 style={{ marginLeft: "10px" }}>Informations personnelles</h4>
            <br />
            <div className="row row-member" style={{ marginLeft: "10%" }}>
              <div className="col-5">
                <h6 className="label-member">Prénom :</h6>
                <h6 className="label-member">Nom :</h6>
                <h6 className="label-member">Adresse Email :</h6>
                <h6 className="label-member">Tél 1 :</h6>
                <h6 className="label-member">Adresse :</h6>
                <h6 className="label-member">Ville :</h6>
              </div>
              <div className="col-7">
                <h6 className="label-member">{details[1]?.firstName}</h6>
                <h6 className="label-member">{details[1]?.lastName}</h6>
                <h6 className="label-member">{details[1]?.email} </h6>
                <h6 className="label-member">{details[1]?.phone}</h6>
                <h6 className="label-member">{details[1]?.adress}</h6>
                <h6 className="label-member">{details[1]?.city}</h6>
              </div>
            </div>
            <br />
            <h4 style={{ marginLeft: "10px" }}>Votre Devis</h4>
            <br />
            {details[3] ? (
              <>
                {[...Array(details[3])].map((e, i) => (
                  <div
                    key={i}
                    className="row row-member"
                    style={{ marginLeft: "10%", marginBottom: "8px" }}
                  >
                    <div className="col-5">
                      <h6 className="label-member">Produit {i + 1}:</h6>
                      <h6 className="label-member">
                        {details[0]?.family.familyName == "Smartphone"
                          ? "IMEI :"
                          : "Numéro de série :"}
                      </h6>
                    </div>
                    <div className="col-7">
                      <h6 className="label-member">
                        {details[0]?.family.familyName === "Pack Électroménager"
                          ? details[0]?.family.familyName +
                            " " +
                            details[2]?.["Marque"]?.value
                          : details[0]?.family.familyName +
                            " " +
                            details[2]?.["Marque" + i]?.value}
                      </h6>
                      <h6 className="label-member">
                        {details[2]?.["Numero serie" + i]?.value}{" "}
                      </h6>
                    </div>
                  </div>
                ))}
                <div
                  className="row row-member"
                  style={{ marginLeft: "10%", marginBottom: "20px" }}
                >
                  <div className="col-5">
                    <h6 className="label-member">Date d'adhésion :</h6>
                    <h6 className="label-member">
                      Date de fin (ou renouvellement) :
                    </h6>
                    <h6 className="label-member">Formule de garantie :</h6>
                    <h6 className="label-member">Plafond de garantie :</h6>
                    <h6 className="label-member">Prime d'assurance :</h6>
                  </div>
                  <div className="col-7">
                    <h6 className="label-member">
                      {new Date().toLocaleDateString()}{" "}
                    </h6>
                    <h6 className="label-member">
                      {details[0]?.other == 2 ? endDate2.toLocaleDateString() : endDate.toLocaleDateString()}{" "}
                    </h6>
                    <h6 className="label-member"> {details[0]?.name}</h6>
                    <h6 className="label-member">
                      {details[0]?.ceilingGuarantee} {"DT"}
                    </h6>
                    <h6 className="label-member">
                      {details[0]?.price?.toFixed(3)} {"DT"}
                    </h6>
                  </div>
                </div>
              </>
            ) : (
              <div
                className="row row-member"
                style={{ marginLeft: "10%", marginBottom: "20px" }}
              >
                <div className="col-5">
                  <h6 className="label-member">Produit:</h6>
                  <h6 className="label-member">
                    {details[0]?.family.familyName == "Smartphone"
                      ? "IMEI :"
                      : "Numéro de série :"}
                  </h6>
                  <h6 className="label-member">Date d'adhésion :</h6>
                  <h6 className="label-member">
                    Date de fin (ou renouvellement) :
                  </h6>
                  <h6 className="label-member">Formule de garantie :</h6>
                  <h6 className="label-member">Plafond de garantie :</h6>
                  <h6 className="label-member">Prime d'assurance :</h6>
                </div>
                <div className="col-7">
                  <h6 className="label-member">
                    {details[0]?.family.familyName +
                      " " +
                      details[2]?.["Marque"]?.value}{" "}
                  </h6>
                  <h6 className="label-member">
                    {details[2]?.["Numero serie"]?.value}{" "}
                  </h6>
                  <h6 className="label-member">
                    {new Date().toLocaleDateString()}{" "}
                  </h6>
                  <h6 className="label-member">
                    {details[0]?.other == 2 ? endDate2.toLocaleDateString() : endDate.toLocaleDateString()}{" "}
                  </h6>
                  <h6 className="label-member"> {details[0]?.name}</h6>
                  <h6 className="label-member">
                    {details[0]?.ceilingGuarantee} {"DT"}
                  </h6>
                  <h6 className="label-member">
                    {details[0]?.price?.toFixed(3)} {"DT"}
                  </h6>
                </div>
              </div>
            )}
          </CardBody>
        </Card>
        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader className="m-0 pb-0">
            <Row>
              <Col className="text-right"></Col>
            </Row>
          </CardHeader>

          <CardBody className="pt-2">
            <Form className="needs-validation" noValidate="">
              <div className="form-row">
                <Col
                  md="5 mb-6"
                  style={{
                    marginTop: "35px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <h6>Montant à payer: {details[0]?.price?.toFixed(3)} DT</h6>
                </Col>
                <Col md="5 mb-6">
                  <Label
                    style={{ fontSize: "16px", fontWeight: "400" }}
                    htmlFor="validationCustom01"
                  >
                    Mode de paiment
                    <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Select onChange={onchangeSelect} options={paymentMode} />
                  <br />
                </Col>
                <Col
                  md="5 mb-6"
                  style={{
                    marginTop: "35px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                ></Col>
                <Col md="5 mb-6">
                  <Label
                    style={{ fontSize: "16px", fontWeight: "400" }}
                    htmlFor="validationCustom01"
                  >
                    Facture
                    <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    style={{ height: "50px" }}
                    className="form-control"
                    name="facture"
                    type="text"
                    placeholder="Facture"
                    onChange={handelChange}
                    required={true}
                  />
                  <br />
                </Col>
              </div>
              <br />
              <Row>
                <Col md="5 mb-6">
                  <Button
                    color="danger"
                    className="float-left"
                    style={{ width: "55%", height: "45px" }}
                    onClick={() => {
                      history.push("/info-product");
                    }}
                  >
                    Retour
                  </Button>
                </Col>
                <Col md="5 mb-6">
                  <Button
                    color="primary"
                    className="float-right"
                    onClick={onSubmit}
                    disabled={disabled}
                    style={{ width: "55%", height: "45px" }}
                  >
                    Valider
                  </Button>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default PaymentDetails;
