import React, { useState, useEffect, Fragment } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  Media,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

import assuranceService from "../../../services/assurance/assuranceService";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Dropzone from "react-dropzone-uploader";
import { findLastIndex } from "lodash";

const CreateAssurance = ({ location, i18n }) => {
  const history = useHistory();
  const [assurance, setAssurance] = useState({
    dateCreation: new Date().toISOString(),
    isActive: 1,
    phone: "",
    socialReason: "",
    taxIndetification: "",
  });
  useEffect(() => {}, []);
  const [checked, setChecked] = useState(true);
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAssurance((assurance) => ({ ...assurance, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSubmitted(true);
    await assuranceService.createAssurance(assurance);
    toast.success("Assurance created successfuly");
    history.push("/gest_intervenant/assurance");
  };

  return (
    <Fragment>
      <Breadcrumb parent="Assurance" title="Nouvelle Assurance" />
      <Container fluid={true}>
        <Form
          className="needs-validation"
          noValidate=""
          onSubmit={handleSubmit}
        >
          <Row>
            <Col>
              <Card className="shadow-lg p-0 shadow-showcase">
                <CardHeader className="b-l-primary pb-2">
                  {/* <h5>Info Generale</h5> */}
                </CardHeader>
                <CardBody className="pt-1">
                  <div className="form-row">
                    {/* <Col md="4 mb-3">
                      <Label htmlFor="validationCustom01">
                        Assurance
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="Assurance"
                        type="text"
                        placeholder="Assurance"
                        innerRef=""
                        // value={assurance.socialReason}
                        // onChange={handleChange}
                      />
                      <span></span>
                    </Col> */}
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom01">
                        Assurance
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="socialReason"
                        type="text"
                        placeholder="Assurance"
                        innerRef=""
                        value={assurance.socialReason}
                        onChange={handleChange}
                      />
                      <span></span>
                    </Col>
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom01">
                        Matricule Fiscale
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="taxIndetification"
                        type="text"
                        placeholder="Identification Tax"
                        innerRef=""
                        value={assurance.taxIndetification}
                        onChange={handleChange}
                      />
                      <span></span>
                    </Col>

                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom01">
                        Téléphone
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="phone"
                        type="text"
                        placeholder="Téléphone"
                        innerRef=""
                        value={assurance.phone}
                        onChange={handleChange}
                      />
                      <span></span>
                    </Col>
                    <Col md="4 mb-3">
                      <div
                        class="form-check form-switch"
                        style={{
                          // display: "flex",
                          // justifyContent: "center",
                          textAlign: "center",
                          // marginTop: "3%",
                        }}
                      >
                        <input
                          class="form-check-input"
                          // style={{ marginTop: "7%" }}
                          name="isActive"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                          checked={checked}
                          value="0"
                          onClick={(e) =>
                            checked
                              ? setChecked(false) & handleChange(e)
                              : setChecked(true) &
                                setAssurance((assurance) => ({
                                  ...assurance,
                                  isActive: 1,
                                }))
                          }
                        />
                        <label
                          class="form-check-label"
                          for="flexSwitchCheckDefault"
                        >
                          Actif
                        </label>
                      </div>
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Button
                color="danger"
                type="reset"
                className="float-left"
                onClick={() => history.push("/gest_intervenant/assurance")}
              >
                Retour
              </Button>
            </Col>
            <Col>
              <Button color="primary" type="submit" className="float-right">
                Valider
              </Button>
            </Col>
          </Row>
          {/* <Button color="primary">{config.button}</Button>
                  <Button color="primary">{config.button}</Button> */}
        </Form>
      </Container>
    </Fragment>
  );
};

export default CreateAssurance;
