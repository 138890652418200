import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import { decode as base64_decode, encode as base64_encode } from "base-64";
import Breadcrumb from "../../layout/breadcrumb";
import NumberFormat from "react-number-format";
import { useHistory, useLocation } from "react-router";
import contractServices from "../../services/contract/contractServices";
import { useSelector } from "react-redux";
import FileSaver from "file-saver";

const DownloadDoc = () => {
  const details = useSelector(
    ({ distributorReducers }) => distributorReducers.contarctDetails
  );
  const history = useHistory();
  const location = useLocation();
  const [contract, setContract] = useState();
  const [Verticalcenter, setVerticalcenter] = useState(false);
  const [modal, setModal] = useState(false);
  let incl = location.inclusion;
  useEffect(() => {
    if (
      localStorage.getItem("role") !== "Distributeur" &&
      !(localStorage.getItem("role") === "FrontOffice")
    ) {
      history.goBack();
    }
    setVerticalcenter(true);
    console.log(location?.state[0]);
    let id = location?.state[0];
    
    contractServices.getContractbyId(id).then((res) => {
      console.log(res.data);
      setContract(res.data);
    });
  }, []);

  const downloadPdf = (data, el) => {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });
    FileSaver.saveAs(blob, "contrat " + el.num);
  };
  const onSubmit = () => {
    let idContract = location.state[0];
    console.log(idContract);
    contractServices
      .generateContratBase64(idContract)
      .then(async (res) => {
        const words = res.data.split("%%EOF");
        await downloadPdf(words[1], contract);
        history.push({ pathname: "/historique" });
      })
      .catch((Error) => {
        console.log(Error);
      });
  };
  const toggle = () => setModal(!modal);
  const Verticalcentermodaltoggle = () => {
    setVerticalcenter(!Verticalcenter);
  };
  return (
    <div>
      <Breadcrumb
        parent={"Nouvelle Vente"}
        title={"Téléchargement des documents"}
      />
      <Container fluid={true}>
        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader className="m-0 pb-0">
            <Row>
              <Col className="text-right"></Col>
            </Row>
          </CardHeader>

          <CardBody className="pt-2">
            <div className="card-box">
              <div className="w-100 d-flex-j-c mb-5">
                <span
                  className="d-flex-j-c title-member "
                  style={{ flex: "1", height: "53px", alignItems: "center" }}
                >
                  Bulletin d'adhésion{" "}
                </span>

                <span
                  className="d-flex-j-c dot-value"
                  style={{
                    width: "148px",
                    height: "53px",
                    backgroundColor: "#F4F2FF",
                    borderRadius: "10px",
                    alignItems: "center",
                  }}
                >
                  N° {contract?.num}
                </span>
              </div>
              <p
                style={{
                  font: "normal normal normal 10px/12px Century Gothic",
                  marginBottom: "40px",
                }}
              >
                Contrat d’assurance collective à adhésion facultative N° 2022.406.000002 régi par le Code des Assurances promulgué par la loi N° : 92-124 du 09 03 1992 ainsi que les
                conditions générales « Assurance Multirisques Informatique » sous MF N° 365/7 du 18/12/1997 et les conditions particulières qui suivent, souscrit par la Société
                Tunisienne des Réparations et des Garanties (STRG), sise Tour Narcisse, Avenue du Japon, 1073 Montplaisir (RNE n° 1681780K), auprès de la société CARTE
                ASSURANCE, . au capital de 25 000 000 dinars sise Lot BC4 – Centre Urbain Nord Tunis Nord Tunis, 1003 Tunis, avec comme gestionnaire de services la société
                GARANTY dont les coordonnées figurent sur le Bulletin d’Adhésion.
              </p>
              <BandTitle text={"ADHERANT N°" + contract?.num} />
              <div className="row row-member">
                <div className="col-5">
                  <p className="label-member">Produit d'assurance :</p>
                  <p className="label-member">Nom :</p>
                  <p className="label-member">Prénom :</p>
                  <p className="label-member">Adresse :</p>
                  <p className="label-member">Code postal :</p>
                  <p className="label-member">Ville :</p>
                  <p className="label-member">Tél 1 :</p>
                  <p className="label-member">E-mail :</p>
                </div>
                <div className="col-7">
                  <p className="label-member">
                    {contract?.formula?.family?.familyName}
                  </p>
                  <p className="label-member">{contract?.customer?.lastName}</p>
                  <p className="label-member">
                    {contract?.customer?.firstName}
                  </p>
                  <p className="label-member">{contract?.customer?.adress}</p>
                  <p className="label-member">
                    {contract?.customer?.postalCode}
                  </p>
                  <p className="label-member">{contract?.customer?.city}</p>
                  <p className="label-member">{contract?.customer?.phone}</p>
                  <p className="label-member">{contract?.customer?.email}</p>
                </div>
              </div>
              <BandTitle text="CONDITION D'ASSURANCE" />
              <div className="row row-member">
                <div className="col-5">
                  <p className="label-member">Date d'adhésion : </p>
                  <p className="label-member">
                    Date d'effet de la couverture :
                  </p>
                  <p className="label-member">
                    Date de fin (ou renouvellement) :
                  </p>
                  <p className="label-member"> Formule de garantie :</p>
                  <p className="label-member">Plafond de garantie :</p>
                  <p className="label-member">Moyen de paiement :</p>
                  {incl ? (
                    <></>
                  ) : (
                    <p className="label-member">Cotisation (annuelle) :</p>
                  )}
                </div>
                <div className="col-7" style={{ marginTop: "0px" }}>
                  <p className="label-member">
                    {new Date(contract?.startDate).toLocaleDateString()}
                  </p>
                  <p className="label-member">
                    {new Date(contract?.startDate).toLocaleDateString()}
                  </p>
                  <p className="label-member">
                    {new Date(contract?.endDate).toLocaleDateString()}
                  </p>
                  <p className="label-member">{contract?.formula?.name}</p>
                  <p className="label-member">
                    {contract?.formula?.ceilingGuarantee}
                    {" DT"}
                  </p>
                  <p className="label-member">{contract?.paymentType?.name}</p>
                  {incl ? (
                    <></>
                  ) : (
                    <NumberFormat
                      className="label-member"
                      style={{ marginLeft: "1px" }}
                      value={`${contract?.totalAmount} DT`}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  )}
                </div>
              </div>
              {incl ? (
                <></>
              ) : (
                <>
                  <BandTitle text="CARACTERISTIQUES DU PRODUIT ASSURE" />
                  <div className="row row-member mb-5">
                    <div className="col-5">
                      <p className="label-member">Type de produit :</p>
                      <p className="label-member">Marque :</p>
                      <p className="label-member">Modèle :</p>
                      <p className="label-member">Valeur d'achat TTC :</p>
                      <p className="label-member">N° de série (ou IMEI) :</p>
                    </div>
                    {details[3] ? (
                      <div className="col-7">
                        <p className="label-member">
                          {contract?.formula?.family?.familyName}
                        </p>
                        <p className="label-member">
                          {contract?.formula?.family?.familyName ==
                          "Pack Électroménager"
                            ? details[2]?.["Marque"]?.value
                            : [...Array(details[3])].map(
                                (e, i) =>
                                  details[2]?.["Marque" + i]?.value + " /"
                              )}
                        </p>
                        <p className="label-member">
                          {contract?.formula?.family?.familyName == "Optique"
                            ? [...Array(details[3])].map(
                                (e, i) =>
                                  details[2]?.["codeCouleur" + i]?.value + " /"
                              )
                            : [...Array(details[3])].map(
                                (e, i) =>
                                  details[2]?.["Modele" + i]?.value + " /"
                              )}
                        </p>
                        <p className="label-member">
                          {[...Array(details[3])].map(
                            (e, i) =>
                              details[2]?.["Valeurduproduit" + i]?.value +
                              " DT /"
                          )}
                        </p>
                        <p className="label-member">
                          {[...Array(details[3])].map(
                            (e, i) =>
                              details[2]?.["Numero serie" + i]?.value + " /"
                          )}
                        </p>
                      </div>
                    ) : (
                      <div className="col-7">
                        <p className="label-member">
                          {contract?.formula?.family?.familyName}
                        </p>
                        <p className="label-member">
                          {" "}
                          {details[2]?.["Marque"]?.value}
                        </p>
                        <p className="label-member">
                          {contract?.formula?.family?.familyName == "Optique"
                            ? details[2]?.["codeCouleur"]?.value
                            : details[2]?.["Modele"]?.value}
                        </p>
                        <p className="label-member">
                          {details[2]?.["Valeurduproduit"]?.value}
                          {" DT"}
                        </p>
                        <p className="label-member">
                          {details[2]?.["Numero serie"]?.value}
                        </p>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
            <Row>
              <Col md="5 mb-6">
                <Button
                  color="danger"
                  className="float-left"
                  style={{ width: "55%", height: "45px" }}
                  onClick={() => {
                    history.push("/detail-paiment");
                  }}
                >
                  Retour
                </Button>
              </Col>
              <Col md="5 mb-6">
                <Button
                  color="primary"
                  className="float-right"
                  onClick={onSubmit}
                  style={{ width: "55%", height: "45px" }}
                >
                  Télécharger
                </Button>
              </Col>
            </Row>
            <Modal
              isOpen={Verticalcenter}
              toggle={Verticalcentermodaltoggle}
              centered
            >
              <ModalHeader toggle={Verticalcentermodaltoggle}></ModalHeader>
              <ModalBody>
                <Row>
                  <p style={{ textAlign: "center", fontSize: "15px" }}>
                    Prière de télécharger le Bulletin d'adhésion, l'imprimer et
                    le faire signer par le clien
                  </p>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={Verticalcentermodaltoggle}>
                  Continuer
                </Button>
              </ModalFooter>
            </Modal>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default DownloadDoc;
function BandTitle({ text }) {
  return (
    <div
      style={{
        paddingLeft: "22px",
        font: " normal normal bold 16px/25px Century Gothic",
        height: "46px",
        width: "100%",
        borderRadius: "10px",
        backgroundColor: "#F4F2FF",
        display: "flex",
        alignItems: "center",
        marginBottom: "30px",
        marginTop: "30px",
      }}
    >
      {text}
    </div>
  );
}
