import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import CardDash from "./dashCard/CardDash";
import Breadcrumb from "../../layout/breadcrumb";
import contractServices from "../../services/contract/contractServices";
import DataTable from "react-data-table-component";
import distributorService from "../../services/distribitor/distributorService";
const Dashboard = () => {
  const history = useHistory();
  const [contractList, setContractList] = useState();
  const [sousDistList, setSousDistList] = useState();
  const [total, setTotal] = useState();
  const [tota, setTota] = useState();
  const [totaDist, setTotaDist] = useState();
  const [totalDist, setTotalDist] = useState();
  useEffect(() => {
    if (localStorage.getItem("role") !== "Distributeur") {
      history.goBack();
    }
    const id = localStorage.getItem("idDist");
    distributorService.getDisrtibutorbyId(id).then((res) => {});
    contractServices.getAllContractbyDisributor(id).then((res) => {
      var filtredStatus = res.data?.filter((el) => el.status !== "C");
      var tot = 0;
      var totaDis = 0;
      filtredStatus?.forEach((element) => {
        tot += element.totalAmount;
        totaDis += element.margeDistributeurTtc;
      });
      setTotaDist(totaDis);
      setTotal(tot);
    });
    contractServices.getAllContractsByDistAndMonth(id).then((res) => {
      console.log(res);
      var filtredStatus = res.data?.filter((el) => el.status !== "C");
      var totDist = 0;
      var tota = 0;
      filtredStatus?.forEach((element) => {
        totDist += element.totalAmount;
        tota += element.margeDistributeurTtc;
      });
      setTota(tota);
      setTotalDist(totDist);
    });
  }, []);
  const versEtat = () => {
    // history.push("/etat-de-vente/par-mois");
  };
  return (
    <div>
      <Breadcrumb parent={"Distributeur"} title={"Tableau de bord"} />
      <Container fluid={true}>
        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader className="m-0 pb-0">
            <Row>{/* <Col className="text-right"></Col> */}</Row>
          </CardHeader>
          <CardBody className="pt-2">
            <Row md="12 mb-3" style={{ justifyContent: "center" }}>
              <CardDash
                name="Primes TTC du mois"
                total={`${
                  totalDist?.toFixed(3) >= 0 ? totalDist?.toFixed(3) : "0"
                } DT`}
                icon={`${process.env.PUBLIC_URL}/assets/images/chart.svg`}
                pointer={true}
                onClick={versEtat}
              />
              <CardDash
                name={
                  JSON.parse(localStorage.getItem("sous-rev"))
                    ? "Marge distributeur TTC du mois"
                    : "Marge TTC du mois"
                }
                total={`${tota?.toFixed(3) >= 0 ? tota?.toFixed(3) : "0"} DT`}
                icon={`${process.env.PUBLIC_URL}/assets/images/commission.png`}
                pointer={true}
                onClick={versEtat}
              />
              {sousDistList ? (
                <>
                  <CardDash
                    name="Marge revendeur TTC du mois"
                    total={`${
                      totalDist?.toFixed(3) >= 0 ? totalDist?.toFixed(3) : "0"
                    } DT`}
                    icon={`${process.env.PUBLIC_URL}/assets/images/commission.png`}
                  />
                </>
              ) : (
                <></>
              )}
              <CardDash
                name="Primes du mois à reverser"
                total={`${
                  totalDist?.toFixed(3) >= 0
                    ? (totalDist - tota)?.toFixed(3)
                    : "0"
                } DT`}
                icon={`${process.env.PUBLIC_URL}/assets/images/reverse.png`}
                pointer={true}
                onClick={versEtat}
              />
            </Row>
            <Row md="12 mb-3" style={{ justifyContent: "center" }}>
              <CardDash
                name="Total primes TTC"
                total={`${total?.toFixed(3) >= 0 ? total?.toFixed(3) : "0"} DT`}
                icon={`${process.env.PUBLIC_URL}/assets/images/chart.svg`}
              />
              <CardDash
                name={
                  JSON.parse(localStorage.getItem("sous-rev"))
                    ? "Total marge distributeur TTC"
                    : "Total marge TTC"
                }
                total={`${
                  totaDist?.toFixed(3) >= 0 ? totaDist?.toFixed(3) : "0"
                } DT`}
                icon={`${process.env.PUBLIC_URL}/assets/images/commission.png`}
              />
              {sousDistList ? (
                <>
                  <CardDash
                    name="Marge revendeur TTC"
                    total={`${(((total / 1000000) * 93) / 100).toFixed(3)} DT`}
                    icon={`${process.env.PUBLIC_URL}/assets/images/commission.png`}
                  />
                </>
              ) : (
                <></>
              )}
              <CardDash
                name="Total des primes reversées"
                total={`${
                  (total - totaDist).toFixed(3) >= 0
                    ? (total - totaDist).toFixed(3)
                    : "0"
                } DT`}
                icon={`${process.env.PUBLIC_URL}/assets/images/reverse.png`}
              />
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default Dashboard;
