import React, { useState, useEffect, Fragment, useCallback } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  Media,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import ImageViewer from "react-simple-image-viewer";

import { useHistory } from "react-router-dom";
import Dropzone from "react-dropzone-uploader";
import { findLastIndex } from "lodash";
import dataForm from "./datForm.json";

import { Avatar } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { fetchFamily } from "../../../redux/family/familyActions";
import contractServices from "../../../services/contract/contractServices";
import ReactDatePicker from "react-datepicker";
const ContactPayment = ({ location }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  //   const update = location.state.update;
  const row = location.state.row;
  const [listType, setListType] = useState([]);
  const [date, setDate] = useState(new Date());
  const [modeP, setModeP] = useState(null);
  const [data, setData] = useState({});
  const [customer, setCustomer] = useState({});

  console.log({ date });

  const listFamily = useSelector(({ familyReducers }) => {
    return familyReducers.familys;
  });

  React.useEffect(() => {
    contractServices.getTypePayment().then((res) => {
      setListType(res.data);
    });

    contractServices.getContractbyId(row.idcontract).then((res) => {
      setData(res.data);
      const c = res.data.customer;
      reset(res.data);
      setValue(
        "client",
        c.socialReason || `${c.civility} ${c.firstName} ${c.lastName}`
      );
      //   setCustomer({
      //     client: c.socialReason || `${c.civility} ${c.firstName} ${c.lastName}`,
      //     phone:c.phone,
      //     cin:c.cin,
      //     num:res.data.num,

      //   });
    });
  }, []);
  //   React.useEffect(() => {
  //     dispatch(fetchFamily());

  //     reset(row);
  //     if (row.facturephoto) {
  //       const facArray = row.facturephoto.split("/");
  //       setFacture({
  //         name: facArray[facArray.length - 1],
  //         path: row.facturephoto,
  //       });
  //     }
  //     if (type === "smartphone") {
  //       if (row.prodphoto) {
  //         const array = row.prodphoto.split(",");
  //         setImages(array.filter(Boolean));
  //       }
  //     } else {
  //       setImages([row.prodphoto]);
  //     }
  //   }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    setValue,
  } = useForm();

  const onSubmit = async (e) => {
    e.preventDefault();
    const res = await contractServices.updateStatusOfContract({
      idContract: row.idcontract,
      status: "V",
    });
    if (res.status == 200) {
      toast.success("L'operation a été effectuée avec succès.");
      history.push("/gest_client_contrat/contrats");
    } else {
      toast.error("Erreur lors de la validation du contrat.");
    }
  };
  return (
    <Fragment>
      <Breadcrumb parent="Contrat" title={" Enregistrer un paiement"} />
      <Container fluid={true}>
        <Form className="needs-validation" noValidate="" onSubmit={onSubmit}>
          <Row>
            <Col>
              <Card className="shadow-lg p-0 shadow-showcase">
                <CardHeader className="b-l-primary pb-2"></CardHeader>

                <CardBody className="pt-1">
                  <div className="form-row mt-2">
                    {dataForm.map((el) => (
                      <Col md="6 mb-3">
                        <Label
                          htmlFor="validationCustom01"
                          className="mt-2 mb-1"
                        >
                          {el.label}
                          <span style={{ color: "red" }}>*</span>
                        </Label>

                        <input
                          className="form-control"
                          type="text"
                          //   value={customer[el.name]}
                          {...register(el.name)}
                          disabled={true}
                        />
                      </Col>
                    ))}
                    <Col md="6 mb-4 mt-2">
                      <Label className="mb-1" htmlFor="validationCustom01">
                        Moyen de paiemen
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <select
                        className="form-control"
                        name="idfamily"
                        type="select"
                        onChange={(e) => setModeP(e.target.value)}
                      >
                        {listType.map((el) => (
                          <option hidden={el.num == 1} value={el.idpaymentType}>
                            {el.name}
                          </option>
                        ))}
                      </select>
                      <span></span>
                    </Col>
                    <Col md="6 mb-4 mt-2">
                      <Label className="mb-1" htmlFor="validationCustom01">
                        Date de paiement
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <ReactDatePicker
                        required
                        className="form-control digits"
                        selected={date}
                        onChange={(e) => {
                          setDate(e);
                        }}
                      />
                      {/* <input
                        className="form-control"
                        // name="idfamily"
                        type="date"
                        defaultValue={new Date().toLocaleDateString()}
                        // placeholder="Famille"

                        // disabled={previewOnly}
                      ></input> */}
                      <span></span>
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Button
                color="danger"
                type="reset"
                className="float-left"
                onClick={() => history.push("/gest_client_contrat/contrats")}
              >
                Retour
              </Button>
            </Col>
            <Col>
              <Button color="primary" type="submit" className="float-right">
                Valider
              </Button>
            </Col>
          </Row>
          {/* <Button color="primary">{config.button}</Button>
                  <Button color="primary">{config.button}</Button> */}
        </Form>
      </Container>
    </Fragment>
  );
};

export default ContactPayment;
