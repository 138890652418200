import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import IconButton from "@material-ui/core/IconButton";
import distributorService from "../../../services/distribitor/distributorService";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Badge,
} from "reactstrap";

import Breadcrumb from "../../../layout/breadcrumb";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import voucherService from "../../../services/voucherService";

export const VoucherList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [listVouchers, setListVouchers]=useState([])
  const [listDist, setListDist]=useState([])
  useEffect(()=>{
    voucherService.getAllVouchers(localStorage.getItem('id')).then(res=>{
      if(res.data){
        setListVouchers(res.data)
      }
    })
    distributorService.getAllDistributors(localStorage.getItem('id')).then(res=>{
      if(res.data){
        setListDist(res.data)
      }
    })
  },[])

  const columns = [
    {
      name: "Famille",
      selector: "idformule.family.familyName",
      sortable: true,
    },
    {
      name: "Formule",
      selector: "idformule.name",
      sortable: true,
    },
    {
      name: "Distributeur",
      selector: "iddistributor.socialReason",
      sortable: true,
    },
    {
      name: "Statut",
      cell: (row) => (
          <Badge color={row.is_used === 1 ? "success" : "warning"}>
            {row.is_used === 1 ?  "Consommé" : 'Non consommé'}
          </Badge>
        ),
    },
    {
      name: "action",
      cell: (row) => (
        <div>
          <IconButton
            color="primary"
            onClick={() => {
              console.log(row);
              history.push({
                pathname: "/voucher/add",
                state: { row , vue: true },
              });
            }}
          >
            <i className="icofont icofont-eye-alt"></i>
          </IconButton>
        </div>
      ),

      button: true,
    },
  ];
  return (
    <div>
      <Breadcrumb parent={"Bon d'achat"} title={"liste Des Bon d'achats"} />
      <Container fluid={true}>
        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader
            className="ml-2"
            style={{ cursor: "pointer", padding: "10px" }}
            onClick={() => {}}
          >
            <span className="float-left">
              <h6 className="mt-1">{"filter"}</h6>
            </span>

            <span className="float-right ">
              <h4>
                {" "}
                <i className="fa fa-angle-down"></i> :{" "}
                <i className="fa fa-angle-up"></i>
              </h4>
            </span>
          </CardHeader>
        </Card>

        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader className="m-0  align-button ">
            <Row>
              <Col className="text-right">
                <span>
                  <Button
                    className="btn-pill btn-air-primary "
                    color="primary"
                    onClick={() => {
                      history.push("/voucher/add");
                    }}
                  >
                    + {"Nouveau Bon d'achat"}
                  </Button>
                </span>
              </Col>
            </Row>
          </CardHeader>

          <CardBody className="pt-2">
            <DataTable
              noHeader
              pagination
              paginationComponentOptions={{
                rowsPerPageText: "Afficher",
                rangeSeparatorText: "/",
                selectAllRowsItem: true,
                selectAllRowsItemText: "all",
              }}
              columns={columns}
              data={listVouchers}
              pointerOnHover={true}
              progressPending=""
              progressComponent={
                <div className="loader-box">
                  <div className="loader-1"></div>
                </div>
              }
            ></DataTable>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};
