import { contarctAxios } from "../../config/axiosConfig/InstancesAxios";

export default {
  getAllUsers: (uid) => {
    return contarctAxios.post("/getAllCustomers", null, {params:{uid}});
  },

  createUser: (Model) => {
    return contarctAxios.post("/createCustomer", Model);
  },

  updateUser: (Model) => {
    return contarctAxios.post("/updateCustomer", Model);
  },

  sendCode:(Model) => {
    return contarctAxios.post("/sendCode",Model)
  },

  checkCodeConfirmation: (Model) => {
    return contarctAxios.post("/checkCodeConfirmation",Model)
  },

  sendMail: (Model) => {
    return contarctAxios.post("/sendMail",Model)
  },
  getAllUsersInclusion:(uid)=>{
    return contarctAxios.post("/getAllCustomersInclusion", null, {
      params: { uid },
    });
  }
};
