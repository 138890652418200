import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import IconButton from "@material-ui/core/IconButton";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Badge,
} from "reactstrap";

import Breadcrumb from "../../../layout/breadcrumb";
import { useHistory } from "react-router-dom";
import { fetchUsers } from "../../../redux/users/usersActions";
import SweetAlert from "sweetalert2";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";

const UsersList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const listUsers = useSelector(({ usersReducers }) => usersReducers.users);
  const [list, setList] = useState(listUsers);
  const [listF,setListF]= useState([])
  const [handleFilter, setHandleFilter] = useState(true);
  const profil = localStorage.getItem("profil")
  const [filteredValues, setFilteredValues] = useState({
    client: "",
    mobile: "",
    cin: "",
    typeCustomer: 0,
    status: 0,
    email: "",
  });
  const handleFilterValues = (value, name) => {
    setFilteredValues({ ...filteredValues, [name]: value });
  };

  React.useEffect(() => {
    setList(listUsers);
  }, [listUsers]);

  const filterData = () => {
    const filtredList = listUsers.filter((el) => {
      return (
        `${el.firstName}  ${el.lastName} ${el.socialReason || ""}`
          .toLowerCase()
          .includes(filteredValues.client.toLowerCase()) &&
        el.email.toLowerCase().includes(filteredValues.email.toLowerCase()) &&
        String(el.cin).includes(filteredValues.cin) &&
        (filteredValues.typeCustomer != 0
          ? el.typeCustomer == filteredValues.type
          : true) &&
        String(el.phone).includes(filteredValues.mobile) &&
        (filteredValues.status === "V"
          ? el.status == filteredValues.status
          : filteredValues.status == 0
          ? true
          : el.status != "V")
      );
    });
    setListF(filtredList);
    setList(filtredList);
  };

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  const role = localStorage.getItem("role");
  const columns = [
    {
      name: "Nom et Prénom / Raison sc",
      selector: (row) =>
        `${row.firstName + " " + row.lastName}` +
        `${row.socialReason !== null ? row.socialReason : ""}`,
      sortable: true,
    },
    {
      name: "E-mail",
      selector: "email",
      sortable: true,
    },
    {
      name: " Cin / MF",
      selector: "cin",
      sortable: true,
    },
    {
      name: "Mobile",
      selector: "phone",
      sortable: true,
    },
    {
      name: "Type",
      selector: "typeCustomer",
      sortable: true,
    },
    {
      name: "Actif",
      cell: (row) =>
        row.status == "V" ? (
          <Badge color="success">{"Oui"}</Badge>
        ) : (
          <Badge color="danger">{"Non"}</Badge>
        ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          {role === "Garanty" && profil === "Admin" ? (
            <>
              <IconButton
                color="primary"
                onClick={() => {
                  console.log(row);
                  history.push({
                    pathname: "/gest_client_contrat/clients/Update_client",
                    state: { row },
                  });
                }}
              >
                <i className="icofont icofont-eye-alt"></i>
              </IconButton>
              <IconButton
                color="primary"
                onClick={() => {
                  console.log(row);
                  history.push({
                    pathname: "/gest_client_contrat/clients/Update_client",
                    state: { row, update: true },
                  });
                }}
              >
                <i className="icofont icofont-ui-edit"></i>
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                color="primary"
                onClick={() => {
                  console.log(row);
                  history.push({
                    pathname: "/gest_client_contrat/clients/Update_client",
                    state: { row },
                  });
                }}
              >
                <i className="icofont icofont-eye-alt"></i>
              </IconButton>
            </>
          )}
        </div>
      ),

      button: true,
    },
  ];

  return (
    <div>
      <Breadcrumb parent={"Clients"} title={"Liste des Clients"} />
      <Container fluid={true}>
        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader
            className="ml-2"
            style={{ cursor: "pointer", padding: "10px" }}
            onClick={() => {
              setHandleFilter(!handleFilter);
            }}
          >
            <span className="float-left">
              <h6 className="mt-1">{"Filtre"}</h6>
            </span>

            <span className="float-right ">
              <h4>
                {handleFilter ? (
                  <i className="fa fa-angle-down"></i>
                ) : (
                  <i className="fa fa-angle-up"></i>
                )}
              </h4>
            </span>
          </CardHeader>
          <CardBody className="pt-0" hidden={handleFilter}>
            <Form className="theme-form">
              <Row>
                <Col md="3 mb-2">
                  <FormGroup>
                    <Label className="col-form-label ">
                      Nom et prénom / R.S
                    </Label>
                    <Input
                      onChange={(e) =>
                        handleFilterValues(e.target.value, "client")
                      }
                      className="form-control"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="3 mb-2">
                  <FormGroup>
                    <Label className="col-form-label "> Email </Label>
                    <Input
                      onChange={(e) =>
                        handleFilterValues(e.target.value, "email")
                      }
                      className="form-control"
                      type="text"
                    />
                  </FormGroup>
                </Col>

                <Col md="3 mb-2">
                  <FormGroup>
                    <label className="col-form-label"> Cin/MF</label>
                    <Input
                      onChange={(e) =>
                        handleFilterValues(e.target.value, "cin")
                      }
                      className="form-control"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                {/* <Col md="3 mb-2">
                  <FormGroup>
                    <label className="col-form-label"> Produit</label>
                    <Input className="form-control" type="text" />
                  </FormGroup>
                </Col> */}
                <Col md="3 mb-2">
                  <FormGroup>
                    <label className="col-form-label"> Type</label>
                    <Input
                      type="select"
                      name="select"
                      className="form-control digits"
                      defaultValue={0}
                      onChange={(e) => {
                        handleFilterValues(e.target.value, "typeCustomer");
                      }}
                    >
                      <option value={0}>{"Tout"}</option>
                      <option value={"p"}>Personne physique</option>
                      <option value={"M"}>personne morale</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="3 mb-2">
                  <FormGroup>
                    <label className="col-form-label"> Mobile</label>
                    <Input
                      onChange={(e) =>
                        handleFilterValues(e.target.value, "mobile")
                      }
                      className="form-control"
                      type="number"
                    />
                  </FormGroup>
                </Col>

                <Col md="3 mb-3">
                  <FormGroup>
                    <Label className="col-form-label">Actif</Label>
                    <Input
                      type="select"
                      name="select"
                      className="form-control digits"
                      defaultValue={0}
                      onChange={(e) =>
                        handleFilterValues(e.target.value, "status")
                      }
                    >
                      <option value={0}>{"Tout"}</option>
                      <option value={"V"}>{"Oui "}</option>
                      <option value={"B"}>{"Non"}</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
            <Row>
              <Col>
                <Button
                  onClick={filterData}
                  className="btn-pill btn-air-success"
                  color="success"
                >
                  Recherche
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader
            className="b-l-primary align-button"
            style={{
              padding: "15px",
              textAlign: "end",
            }}
          >
            {role === "FrontOffice" || profil === "User" ? (
              <></>
            ) : (
              <Button className="ml-3" color="success" outline>
                Exporter
              </Button>
            )}
            {role === "Courtier " || role === "Assurance " || profil === "User" ? (
              <></>
            ) : (
              <Col className="text-right">
                <span>
                  <Button
                    className="btn-pill btn-air-primary "
                    color="primary"
                    onClick={() => {
                      history.push("/gest_client_contrat/clients/new_client");
                    }}
                  >
                    + {"Nouveau Client"}
                  </Button>
                </span>
              </Col>
            )}
          </CardHeader>

          <CardBody className="pt-2">
            <DataTable
              noHeader
              pagination
              paginationComponentOptions={{
                rowsPerPageText: "Afficher",
                rangeSeparatorText: "/",
                selectAllRowsItem: true,
                selectAllRowsItemText: "all",
              }}
              noDataComponent={<div>Aucun enregistrement trouvé</div>}
              columns={columns}
              data={role === "FrontOffice" ?  listF || [] : list || []}
              pointerOnHover={true}
              progressPending=""
              progressComponent={
                <div className="loader-box">
                  <div className="loader-1"></div>
                </div>
              }
            ></DataTable>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default UsersList;
