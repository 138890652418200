import React, {
  useState,
  useEffect,
  Fragment,
  useCallback,
  useMemo,
  useRef,
} from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  Media,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ImageViewer from "react-simple-image-viewer";

import { useHistory } from "react-router-dom";
import Dropzone from "react-dropzone-uploader";
import { findLastIndex } from "lodash";
import dataForm from "./formSmart.json";
import dataFormG from "./formGlass.json";
import dataFormTV from "./formTV.json";
import dataFormPC from "./formPC.json";
import dataFormElectro from "./formElectro.json";
import dataFormMontre from "./formMontre.json";
import LoadingPage from "../../../components/LoadingPage";
import { Avatar } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { fetchFamily } from "../../../redux/family/familyActions";
import { fetchFormula } from "../../../redux/formula/formulaActions";
import DatePicker from "react-datepicker";
import contractServices from "../../../services/contract/contractServices";
import SinistreServices from "../../../services/sinistre/SinistreServices";
import Select from "react-select";
import { fetchUsers } from "../../../redux/users/usersActions";
import productService from "../../../services/product/productService";
import { format, subDays } from "date-fns";
import FileSaver from "file-saver";
const UpdateSinistre = ({ location }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const refClient = useRef();
  const refContract = useRef();
  const [loading, setLoading] = useState(false)
  const refTypologies = useRef();
  const [pieceDispo,setPieceDispo]=useState(false)
  const [pieceNonDispo,setPieceNonDispo]=useState(false)
  const [rdvFix,setRdvFix]=useState(false)
  const [remiserdvFix , setRemiserdvFix] = useState(false)
  const [rdvRmbrFix , setRdvRmbrFix] = useState(false)
  const [griser,setGriser] = useState(false)
  console.log(refClient.current);
  const update = location.state?.update;
  const preview = location.state?.preview;
  console.log(subDays(new Date(), -1));
  const row = location.state?.row;
  // const type = row?.contractProduct?.product?.category.name;
  const [type, setType] = useState(
    row?.contractProduct?.product?.category.name.toLowerCase()
  );
  
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [devrdv, setdevrdv] = useState(false);
  const [images, setImages] = useState([]);
  const [facture, setFacture] = useState(null);
  const [file, setFile] = useState(null);

  const [listContract, setListContract] = useState([]);

  const [typologie, setTypologie] = useState([]);
  const [client, setClient] = useState(null);
  const [selectedTypologies, setSelectedTypologie] = useState(null);
  const [devis, setDevis] = useState(null)
  const [hide,setHide]= useState(false)
  const [refusDto,setRefusDto] =  useState({})
  const [priseRdvDto, setPriseRdvDto]= useState ({})
  const [priseRdvRemiseDto, setPriseRdvRemiseDto]= useState ({})
  const listUsers = useSelector(({ usersReducers }) => usersReducers.users);
  const listFormula = useSelector(
    ({ formulaReducers }) => formulaReducers.formulas
  );
  console.log(listFormula);
  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  const pieceDispoModel = () => setPieceDispo(!pieceDispo)
  const pieceNonDispoModel = () => setPieceNonDispo(!pieceNonDispo)
  const fixRdvModel = () => setRdvFix(!rdvFix)
  const fixRdvRemiseModel = ()=> setRemiserdvFix(!remiserdvFix)
  const fixRdvRemb = () => setRdvRmbrFix(!rdvRmbrFix)
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setDevis(value) 
  };
  const handleChangeMnt = (e)=>{
    const { name, value } = e.target;
    setRefusDto((refusDto)=>({
      ...refusDto,[name]:value
    }))
  }
  const handleChangePRdv = (e) => {
    const { name, value } = e.target;
    setPriseRdvDto((priseRdvDto)=>({
      ...priseRdvDto,[name]:value
    }))
  };

  const handleChangePRdvRemise = (e) => {
    const { name, value } = e.target;
    setPriseRdvRemiseDto((priseRdvRemiseDto)=>({
      ...priseRdvRemiseDto,[name]:value
    }))
  };

  React.useEffect(() => {
    dispatch(fetchFormula());
    dispatch(fetchUsers());
    if ((preview || update) && row) {
      console.log(row);
      const clientData = row.contractProduct.contract.customer;
      const listTypologies =
        row.contractProduct.contract.formula.breakdownFormulas.map((el) => ({
          value: el.breakdownType.idbreakdown,
          label: el.breakdownType.name,
        }));
      setClient({
        value: clientData.idcustomer,
        label: `${clientData.civility || ""} ${clientData.firstName} ${
          clientData.lastName
        }`,
        data: clientData,
      });
      setTypologie(listTypologies);
      reset({
        ...row,
        idcontract: {
          value: row.contractProduct.contract.idcontract,
          label: row.contractProduct.contract.num,
          data: row.contractProduct.contract,
        },
        client: {
          value: clientData.idcustomer,
          label: `${clientData.civility || ""} ${clientData.firstName} ${
            clientData.lastName
          }`,
          data: clientData,
        },
        selectedTypologies: [],
        constatedate: row.constatedate
          ? new Date(row.constatedate).toISOString().slice(0, 10)
          : "",
      });
    }
    if (row?.facturephoto) {
      const facArray = row.facturephoto.split("/");
      setFacture({
        name: facArray[facArray.length - 1],
        path: row.facturephoto,
      });
    }
    if (row?.prodphoto) {
      const array = row.prodphoto.split(",");
      setImages(array.filter(Boolean));
    }
  }, []);
  const uploadFiles = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        let uploadedImage1 = "";
        let uploadedImage2 = "";
        let uploadFacture = "";
        let promises = [];

        if (file) {
          promises.push(
            contractServices.uploadFile(file[0]).then((res) => {
              uploadFacture = res.data;
            })
          );
        }
        if (getValues("img1")) {
          promises.push(
            contractServices.uploadFile(getValues("img1")[0]).then((res) => {
              uploadedImage1 = res.data;
            })
          );
        }
        if (getValues("img2")) {
          promises.push(
            contractServices.uploadFile(getValues("img2")[0]).then((res) => {
              uploadedImage2 = res.data;
            })
          );
        }

        Promise.all(promises).then((res) => {
          resolve({ uploadedImage1, uploadedImage2, uploadFacture });
        });
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  };

  React.useEffect(() => {
    if (client?.value)
      contractServices
        .getAllcontractswithoutpaginationByUser(client.value)
        .then((res) => {
          console.log(client.value);
          console.log(res);
          setValue("selectedTypologies", []);
          setListContract(
            res?.data?.map((el) => ({
              value: el.idcontract,
              label: el.numContract,
              data: el,
            }))
          );
        });
  }, [client]);
  console.log(type);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    setValue,
  } = useForm();
  const downloadPdf = (data, el) => {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });
    FileSaver.saveAs(blob, "sinistre " + el.numSinistre +" "+ el.content);
  };
  const onSubmit = async (dataToSend) => {
    setGriser(true)
    setLoading(true)
    if (!client) {
      refClient.current?.focus();
      return;
    }
    if (!dataToSend.idcontract) {
      refContract.current?.focus();
      return;
    }
    if (selectedTypologies.length === 0) {
      refTypologies.current?.focus();
      return;
    }
    if (file && getValues("img1") && getValues("img2")) {
    var resUploadFiles = await uploadFiles();
    }
    //     uploadFacture: "http://197.13.17.43:8080/documents/sample.pdf"
    // uploadedImage1: "http://197.13.17.43:8080/documents/Sans titre.png"
    // uploadedImage2: "http://197.13.17.43:8080/documents/serveur.png"

    let data = {
      ...dataToSend,
      listIdBreakDownType: selectedTypologies.map((el) => el.value),
    };
    if (resUploadFiles?.uploadFacture)
      data.facturephoto = resUploadFiles.uploadFacture;
    if (resUploadFiles?.uploadedImage1 || resUploadFiles?.uploadedImage2)
      data.prodphoto =
        resUploadFiles.uploadedImage1 +
        "," +
        resUploadFiles.uploadedImage2 +
        ",";

    if (data.contractProduct?.idcontractProduct)
      data.idCP = data.contractProduct?.idcontractProduct;
    else {
      const res = await productService.getcontractProductByIdContract(
        data.idcontract.value
      );
      if (res.status === 200) {
        console.log("res.data.idcontractProduct", res);
        data.idCP = res.data[0].idcontractProduct;
      } else return;
    }
    delete data.client;
    delete data.contractProduct;
    delete data.selectedTypologies;
    delete data.img1;
    delete data.img2;

    console.log({ data });
    const response = await SinistreServices.createSinistre({...data, inclusion:0});
    console.log({ response });
    if (response.status == 201){
      setLoading(false)
      // SinistreServices.GenerateConstatSinistre(response.data.idsinister).then(async res=>{
      //   const words = res.data.split("%%EOF");
      //       await downloadPdf(words[1], {numSinistre : row.num ,content: ""});
      // })
      history.push("/gest_sinistre/sinistre")
    }
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="Sinistre"
        title={update && row ? "Modification d'un sinistre" : update ? "Création sinistre" : "Sinistre"}
      />
      <Container fluid={true}>
        {isViewerOpen && (
          <ImageViewer
            src={images}
            currentIndex={currentImage}
            disableScroll={true}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        )}
        <Form
          className="needs-validation"
          noValidate=""
          onSubmit={handleSubmit(onSubmit)}
        >
          <Row>
            <Col>
              <Card className="shadow-lg p-0 shadow-showcase">
                <CardHeader
                  className="b-l-primary"
                  style={{
                    padding: "15px",
                    textAlign: "end",
                  }}
                >
                  <Button
                    disabled={!client}
                    onClick={() => {
                      history.push({
                        pathname: "/gest_client_contrat/clients/Update_client",
                        state: {
                          back: true,
                          row: {
                            ...client.data,
                          },
                        },
                      });
                    }}
                    color="primary"
                    outline
                  >
                    Fiche Client
                  </Button>
                  <Button
                    disabled={!getValues("idcontract")}
                    onClick={() => {
                      history.push({
                        pathname: "/vueContrat",
                        state: {
                          row: {
                            idcontract: getValues("idcontract").value,
                          },
                        },
                      });
                    }}
                    className="ml-3"
                    color="success"
                    outline
                  >
                    Contrat
                  </Button>
                </CardHeader>
                <CardBody className="pt-1">
                {loading ? (<LoadingPage />) :
                 ( <div className="form-row">
                    <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                        Client
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Select
                        ref={refClient}
                        value={getValues("client")}
                        isDisabled={!!row}
                        onChange={(val) => {
                          console.log({ val });
                          setValue("idcontract","");
                          setClient(val);
                          setValue("client", val);
                        }}
                        options={listUsers.map((el) => ({
                          value: el.idcustomer,
                          label: `${el.civility || ""} ${el.firstName} ${
                            el.lastName
                          }`,
                          data: el,
                        }))}
                      />

                      <span></span>
                    </Col>
                    <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                        Num Contrat
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Select
                        ref={refContract}
                        value={getValues("idcontract")}
                        isDisabled={!!row}
                        onChange={(val) => {
                          console.log({ val });
                          setValue("idcontract", val);
                          setType(val.data.family.toLowerCase());
                          contractServices
                            .getContractbyId(val.value)
                            .then((res) => {
                              console.log(res);
                              setValue("idFormula", res.data.formula.idformula);
                              setTypologie(
                                res.data.formula.breakdownFormulas.map(
                                  (el) => ({
                                    value: el.breakdownType.idbreakdown,
                                    label: el.breakdownType.name,
                                  })
                                )
                              );
                            });
                        }}
                        options={listContract}
                      />

                      <span></span>
                    </Col>
                    <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                        Choisissez la typologie du sinistre
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Select
                        ref={refTypologies}
                        value={selectedTypologies}
                        // value={selectedTypologies}
                        onChange={(e) => {
                          setValue("selectedTypologies", e);
                          setSelectedTypologie(e);
                        }}
                        isMulti
                        options={typologie}
                        isDisabled={!!row}
                      ></Select>
                      <span></span>
                    </Col>
                    {row && (
                      <Col md="6 mb-3">
                        <Label htmlFor="validationCustom01">
                          Num Sinistre
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <input
                          className="form-control"
                          name=" num_sinis"
                          type="text"
                          placeholder=" Num Sinistre"
                          {...register("num", { required: true })}
                          disabled={update || preview}
                        />
                        <span></span>
                      </Col>
                    )}
                    <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                        Produit
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      {/* <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        {...register("contractProduct.product.name", {
                          required: true,
                        })}
                        disabled={!update}
                      /> */}
                      <select
                        className="form-control"
                        name="produit"
                        type="select"
                        // value={row?.contractProduct.contract.formula.name}
                        // placeholder="Produit"
                        {...register("idFormula", {
                          required: true,
                        })}
                        disabled={!!row}
                      >
                        <option value="">Select... </option>
                        {listFormula?.map((el) => (
                          <option value={el.idformula}>{el.name}</option>
                        ))}
                      </select>
                      <span></span>
                    </Col>
                    <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                        Date
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <input
                        className="form-control"
                        name="date"
                        type="date"
                        max={format(subDays(new Date(), 0), "yyyy-MM-dd")}
                        min={format(subDays(new Date(), 4), "yyyy-MM-dd")}
                        {...register("date", {
                          required: true,
                        })}
                        disabled={!update}
                      />
                      <span></span>
                    </Col>
                    <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                        Status
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      {/* <input
                        className="form-control"
                        name="status"
                        type="text"
                        placeholder="Status"
                        innerRef=""
                        disabled={!update}
                      /> */}
                      <select
                        className="form-control"
                        name="produit"
                        type="select"
                        // placeholder="Produit"
                        {...register("status", {
                          required: true,
                        })}
                        disabled={!update}
                      >
                        <option value={""}>Select...</option>
                        <option value={1}>Validé</option>
                        <option value={0}>Non validé</option>
                      </select>
                      <span></span>
                    </Col>
                    {/* <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                        Localisation
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <input
                        className="form-control"
                        name="localisation"
                        type="text"
                        placeholder="Localisation"
                        innerRef=""
                        disabled={!update}
                        {...register("location", {
                          required: true,
                        })}
                      />
                      <span></span>
                    </Col> */}
                  </div>)
                }
                </CardBody>
              </Card>
              {type && (
                <Card className="shadow-lg p-0 shadow-showcase">
                  <CardHeader className="b-l-primary pb-2">
                    {/* <h5>Info Generale</h5> */}
                  </CardHeader>
                  {loading ? (<LoadingPage />) :
                    (<CardBody className="pt-1">
                    <div className="form-row mt-2">
                      {(type.includes('optique') ? dataFormG : type.includes('tv') ? dataFormTV : type.includes('pc portable') ? dataFormPC : type.includes('lectro') ? dataFormElectro : type.includes('quipement') ? dataFormElectro : type.includes('montre') ? dataFormMontre : dataForm).map(
                        (el) => (
                          <Col md="6 mb-3">
                            <Label
                              htmlFor="validationCustom01"
                              className="mt-2 mb-1"
                            >
                              {el.label}
                              <span style={{ color: "red" }}>*</span>
                            </Label>
                            {el.type == "text" && (
                              <input
                                className="form-control"
                                type="text"
                                // value={row[el.name]}
                                {...register(el.name, {
                                  required: true,
                                })}
                                disabled={!update}
                              />
                            )}
                            {el.type == "select" && (
                              <select
                                className="form-control"
                                type="text"
                                // value={row[el.name]}
                                {...register(el.name, {
                                  required: true,
                                  validate: (value) => value != 0,
                                })}
                                disabled={!update}
                              >
                                <option value={""}>{"Select..."}</option>
                                {el.list.map((val) => (
                                  <option value={val}>{val}</option>
                                ))}
                              </select>
                            )}
                            {el.type == "date" && (
                              <input
                                className="form-control"
                                type="date"
                                // name="date"
                                {...register(el.name, {
                                  required: true,
                                })}
                                disabled={!update}
                              />
                            )}
                            {el.type == "image" && (
                              <Row>
                                <Col md={images[el.position] ? "10" : "12"}>
                                  <input
                                    className="form-control"
                                    type="file"
                                    {...register(el.name, {
                                      // required: !images[el.position],
                                      onChange: (e) => {
                                        let imgs = [...images];
                                        imgs[el.position] = e.target.files[0]
                                          ? URL.createObjectURL(
                                              e.target.files[0]
                                            )
                                          : null;
                                        setImages(imgs);
                                      },
                                    })}
                                    disabled={!update}
                                  />
                                </Col>

                                {images[el.position] ? (
                                  <Col md="2" className="align-self-center">
                                    <div
                                      className="avatar ratio"
                                      style={{
                                        // width: "40px",
                                        height: "40px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <Media
                                        body
                                        className="img-40 rounded-circle"
                                        onClick={() =>
                                          openImageViewer(el.position)
                                        }
                                        src={
                                          images[el.position]
                                          // ? typeof images[el.position] ===
                                          //   "string"
                                          //   ? images[el.position]
                                          //   : URL.createObjectURL(
                                          //       images[el.position]
                                          //     )
                                          // : ""
                                        }
                                      />
                                    </div>
                                  </Col>
                                ) : null}
                              </Row>
                            )}
                            {el.type == "file" && (
                              <input
                                // required={!facture}
                                className="form-control"
                                type="file"
                                // name="date"
                                // {...register(el.name, {
                                //   required: true,
                                // })}
                                onChange={(e) => setFile(e.target.files)}
                                disabled={!update}
                              />
                            )}
                            {el.type == "file" && facture && update && (
                              <div style={{ marginTop: "10px" }}>
                                <a
                                  target="_blank"
                                  href={facture?.path}
                                  download={facture.name}
                                >
                                  {facture.name}
                                </a>
                              </div>
                            )}
                            <span></span>
                          </Col>
                        )
                      )}
                    </div>
                    </CardBody>)
                  }
                </Card>
              )}
            </Col>
          </Row>

          <Row>
            <Col md="3">
              <Button
                color="danger"
                type="reset"
                className="float-left"
                onClick={() => history.push("/gest_sinistre/sinistre")}
              >
                Retour
              </Button>
            </Col>
            <Col md="9">
              {update && (
                <Button color="primary" type="submit" className="float-right" disabled={griser}>
                  Valider
                </Button>
              )}
                  {row?.internal_status === 1 ? 
                  <>
                  <Button
                    onClick={() => {
                      setLoading(true)
                      SinistreServices.UpdateSinistre({
                        idsinister: row.idsinister,
                        internal_status: 2,}).then((res)=>{
                          if(res.status == 201){
                            setLoading(false)
                            history.push("/gest_sinistre/sinistre")
                          }
                        })
                      }}
                    className="ml-3 float-right"
                    color="success"
                    outline
                  >
                    Documents conformes
                  </Button>
                  <Button
                    className="float-right"
                    onClick={() => {
                      setLoading(true)
                      SinistreServices.UpdateSinistre({
                        idsinister: row.idsinister,
                        internal_status: -2,
                        status :2,
                        close_reason: "Doc non conformes",
                      }).then((res)=>{
                        if(res.status == 201){
                          setLoading(false)
                          history.push("/gest_sinistre/sinistre")
                        }
                      })
                    }}
                    color="primary"
                    outline
                  >
                    Documents non conformes
                  </Button>
                  </> : row?.internal_status === 2 ? 
                  <>
                  <Button
                    onClick={() => {
                      pieceDispoModel()
                      }}
                    className="ml-3 float-right"
                    color="success"
                    outline
                  >
                    Pièce disponible
                  </Button>
                  <Button
                    className="float-right"
                    onClick={() => {
                      pieceNonDispoModel()
                    }}
                    color="primary"
                    outline
                  >
                    Pièce non disponible
                  </Button>
                  </> : row?.internal_status === 4 ?
                   <> 
                  <Button
                    onClick={() => {
                      // setLoading(true)
                      // SinistreServices.UpdateSinistre({
                      //   idsinister: row.idsinister,
                      //   internal_status: 5,
                      // }).then((res)=>{
                      //   if(res.status == 201){
                      //     SinistreServices.generatepaymentreceipt(res.data.idsinister).then(async res=>{
                      //       const words = res.data.split("%%EOF");
                      //           await downloadPdf(words[1], {numSinistre : row.num ,content: ""});
                      //     })
                      //     setLoading(false)
                      //     history.push("/gest_sinistre/sinistre")
                      //   }
                      // })
                      fixRdvModel()
                      setdevrdv(true)
                      }}
                    className="ml-3 float-right"
                    color="success"
                    outline
                  >
                    Acceptation Devis
                  </Button>
                  <Button
                    className="float-right"
                    onClick={() => {
                      // setLoading(true)
                      // SinistreServices.UpdateSinistre({
                      //   idsinister: row.idsinister,
                      //   internal_status: -1,
                      //   status :2,
                      //   close_reason: "non accept du devis",
                      // }).then((res)=>{
                      //   if(res.status == 201){
                      //     setLoading(false)
                      //     history.push("/gest_sinistre/sinistre")
                      //   }
                      // })
                      pieceNonDispoModel()
                      setHide(true)

                    }}
                    color="primary"
                    outline
                  >
                    Refus Devis
                  </Button>
                  </>:  row?.internal_status === 5 ?
                  <>
                  <Button
                    onClick={() => {
                      setLoading(true)
                      SinistreServices.UpdateSinistre({
                        idsinister: row.idsinister,
                        internal_status: 3,
                      }).then((res)=>{
                        if(res.status == 201){
                          setLoading(false)
                          history.push("/gest_sinistre/sinistre")
                        }
                      })
                      }}
                    className="ml-3 float-right"
                    color="success"
                    outline
                  >
                    Paiement reçu
                  </Button>
                  <Button
                    className="float-right"
                    onClick={() => {
                      setLoading(true)
                      SinistreServices.UpdateSinistre({
                        idsinister: row.idsinister,
                        internal_status: -1,
                        status :2,
                        close_reason: "refus de paie surcoût",
                      }).then((res)=>{
                        if(res.status == 201){
                          setLoading(false)
                          history.push("/gest_sinistre/sinistre")
                        }
                      })
                    }}
                    color="primary"
                    outline
                  >
                    Clôturer
                  </Button>
                  </>: row?.internal_status === 6 ?
                  <>
                  <Button
                    onClick={() => {
                      setLoading(true)
                      SinistreServices.UpdateSinistre({
                        idsinister: row.idsinister,
                        internal_status: 3,
                      }).then((res)=>{
                        if(res.status == 201){
                          setLoading(false)
                          history.push("/gest_sinistre/sinistre")
                        }
                      })
                      }}
                    className="ml-3 float-right"
                    color="success"
                    outline
                  >
                    pièce disponible
                  </Button>
                  <Button
                    className="float-right"
                    onClick={() => {
                      setLoading(true)
                      SinistreServices.UpdateSinistre({
                        idsinister: row.idsinister,
                        internal_status: -1,
                        status :2,
                        close_reason: "Pièce non dispo",
                      }).then((res)=>{
                        if(res.status == 201){
                          setLoading(false)
                          history.push("/gest_sinistre/sinistre")
                        }
                      })
                    }}
                    color="primary"
                    outline
                  >
                    Clôturer
                  </Button>
                  </>: row?.internal_status === 16 ?
                   <Button
                    className="float-right"
                    onClick={() => {
                      fixRdvRemb()
                    }}
                    color="primary"
                    outline
                  >
                   Fixé RDV
                  </Button> : row?.internal_status === 7 ?
                  <Button
                  className="float-right"
                  onClick={() => {
                    setLoading(true)
                    SinistreServices.UpdateSinistre({
                      idsinister: row.idsinister,
                      internal_status: -1,
                      status :3,
                      close_reason: "Rembour effectué",
                    }).then((res)=>{
                      if(res.status == 201){
                        setLoading(false)
                        history.push("/gest_sinistre/sinistre")
                      }
                    })
                  }}
                  color="primary"
                  outline
                >
                 Clôturer
                </Button> :  row?.internal_status === 3 ?
                   <Button
                    className="float-right"
                    onClick={() => {
                      fixRdvModel()
                    }}
                    color="primary"
                    outline
                  >
                  Fixé RDV
                  </Button> : row?.internal_status === 8 ?
                   <Button
                   className="float-right"
                   onClick={() => {
                    setLoading(true)
                    SinistreServices.UpdateSinistre({
                      idsinister: row.idsinister,
                      internal_status: 9,
                    }).then((res)=>{
                      if(res.status == 201){
                        setLoading(false)
                        history.push("/gest_sinistre/sinistre")
                      }
                    })
                   }}
                   color="primary"
                   outline
                 >
                 Equipement Reçu
                 </Button> : row?.internal_status === 9 ?
                  <>
                  <Button
                    onClick={() => {
                      setLoading(true)
                      SinistreServices.UpdateSinistre({
                        idsinister: row.idsinister,
                        internal_status: 10,
                      }).then((res)=>{
                        if(res.status == 201){
                          setLoading(false)
                          history.push("/gest_sinistre/sinistre")
                        }
                      })
                      }}
                    className="ml-3 float-right"
                    color="success"
                    outline
                  >
                    Expertise conforme
                  </Button>
                  <Button
                    className="float-right"
                    onClick={() => {
                      setLoading(true)
                      SinistreServices.UpdateSinistre({
                        idsinister: row.idsinister,
                        internal_status: -1,
                        status :2,
                        close_reason: "Exper inte non conforme",
                      }).then((res)=>{
                        if(res.status == 201){
                          setLoading(false)
                          history.push("/gest_sinistre/sinistre")
                        }
                      })
                    }}
                    color="primary"
                    outline
                  >
                   Expertise non conforme
                  </Button>
                  </>: row?.internal_status === 10 ?
                   <Button
                   className="float-right"
                   onClick={() => {
                     setLoading(true)
                     SinistreServices.UpdateSinistre({
                       idsinister: row.idsinister,
                       internal_status: 11,
                     }).then((res)=>{
                       if(res.status == 201){
                         setLoading(false)
                         history.push("/gest_sinistre/sinistre")
                       }
                     })
                   }}
                   color="primary"
                   outline
                 >
                  Envoyé au réparateur
                 </Button>: row?.internal_status === 11 ?
                 <>
                 <Button
                    onClick={() => {
                      setLoading(true)
                      SinistreServices.UpdateSinistre({
                        idsinister: row.idsinister,
                        internal_status: 15,
                      }).then((res)=>{
                        if(res.status == 201){
                          setLoading(false)
                          history.push("/gest_sinistre/sinistre")
                        }
                      })
                      }}
                    className="ml-3 float-right"
                    color="success"
                    outline
                  >
                    En réparation
                  </Button>
                  <Button
                    className="float-right"
                    onClick={() => {
                      setLoading(true)
                      SinistreServices.UpdateSinistre({
                        idsinister: row.idsinister,
                        internal_status: 7,
                      }).then((res)=>{
                        if(res.status == 201){
                          setLoading(false)
                          history.push("/gest_sinistre/sinistre")
                        }
                      })
                    }}
                    color="primary"
                    outline
                  >
                   Non réparable
                  </Button>
                 </>: row?.internal_status === 15 ?
                 <>
                  <Button
                    className="float-right"
                    onClick={() => {
                      setLoading(true)
                      SinistreServices.UpdateSinistre({
                        idsinister: row.idsinister,
                        internal_status: 12,
                      }).then((res)=>{
                        if(res.status == 201){
                          setLoading(false)
                          history.push("/gest_sinistre/sinistre")
                        }
                      })
                    }}
                    color="primary"
                    outline
                  >
                   Réparation terminée
                  </Button>
                 </> : row?.internal_status === 12 ?
                 <>
                 <Button
                    onClick={() => {
                      setLoading(true)
                      SinistreServices.UpdateSinistre({
                        idsinister: row.idsinister,
                        internal_status: 13,
                      }).then((res)=>{
                        if(res.status == 201){
                          setLoading(false)
                          history.push("/gest_sinistre/sinistre")
                        }
                      })
                      }}
                    className="ml-3 float-right"
                    color="success"
                    outline
                  >
                    Réparation conforme
                  </Button>
                  <Button
                    className="float-right"
                    onClick={() => {
                      setLoading(true)
                      SinistreServices.UpdateSinistre({
                        idsinister: row.idsinister,
                        internal_status: 10,
                      }).then((res)=>{
                        if(res.status == 201){
                          setLoading(false)
                          history.push("/gest_sinistre/sinistre")
                        }
                      })
                    }}
                    color="primary"
                    outline
                  >
                   Réparation non conforme
                  </Button>
                 </> : row?.internal_status === 13 ?
                  <Button
                  className="float-right"
                  onClick={() => {
                    fixRdvRemiseModel()
                  }}
                  color="primary"
                  outline
                >
                Fixé RDV
                </Button>: row?.internal_status === 14 ?
                <>
                <Button
                  className="float-right"
                  onClick={() => {
                    setLoading(true)
                    SinistreServices.UpdateSinistre({
                      idsinister: row.idsinister,
                      internal_status: -1,
                      status :1,
                    }).then((res)=>{
                      if(res.status == 201){
                        setLoading(false)
                        history.push("/gest_sinistre/sinistre")
                      }
                     
                    })
                  }}
                  color="primary"
                  outline
                >
                Livré
                </Button>
                </>:
                <>
                </>
                  }
            </Col>
          </Row>
        </Form>
        <Modal
          isOpen={pieceDispo}
          toggle={pieceDispoModel}
          centered
        >
          <ModalHeader toggle={pieceDispoModel} style={{padding: "0.5rem 0.5rem"}}></ModalHeader>
          <ModalBody>
          <Col md="8 mb-3">
                      <Label htmlFor="validationCustom01">
                      Montant Devis
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="devis"
                        type="number"
                        onChange={handleChange}
                        placeholder="Montant Devis"
                      />
                      <span></span>
                    </Col>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={()=>{
                 setLoading(true)
                      SinistreServices.UpdateSinistre({
                        idsinister: row.idsinister,
                        quotation: devis,}).then((res)=>{
                          if(res.status == 201){
                            setLoading(false)
                            history.push("/gest_sinistre/sinistre")
                          }
                        })
            }}>
            Valider
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={pieceNonDispo}
          toggle={pieceNonDispoModel}
          centered
        >
          <ModalHeader toggle={pieceNonDispoModel} style={{padding: "0.5rem 0.5rem"}}> Décision client </ModalHeader>
          <ModalBody>
            
          <Row>
          {!hide ?<Col md="12 mb-3">
                  <Button
                    onClick={() => {
                      setLoading(true)
                      SinistreServices.UpdateSinistre({
                        idsinister: row.idsinister,
                        internal_status: 6}).then((res)=>{
                          if(res.status == 201){
                            setLoading(false)
                            history.push("/gest_sinistre/sinistre")
                          }
                        })
                      }}
                    className="ml-3 float-right"
                    color="success"
                    outline
                  >
                    Attente de disponibilité
                  </Button>
                  <Button
                    className="float-left"
                    onClick={() => {
                      setHide(true)
                    }}
                    color="primary"
                    outline
                  >
                    Refus d'attente
                  </Button>
          </Col> : <></>}
          </Row>
          <Row>
          {hide ? 
          <>
          <Col md="6 mb-3">
              <Label htmlFor="validationCustom01">
                Montant remboursement
                <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                className="form-control"
                name="mntRembr"
                type="number"
                onChange={handleChangeMnt}
                placeholder="Montant remboursement"
              />
          </Col> 
          <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                      Date de remboursement 
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <input
                        className="form-control"
                        name="dateRembr"
                        type="date"
                        onChange={handleChangeMnt}
                      />
                      <span></span>
            </Col>
          </>
          : <></>}
          </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={()=>{
              setLoading(true)
              SinistreServices.UpdateSinistre({
                idsinister: row.idsinister,
                refund_amount:refusDto.mntRembr,
                refund_date: refusDto.dateRembr,
                internal_status: 16
              }).then((res)=>{
                  if(res.status == 201){
                    setLoading(false)
                    history.push("/gest_sinistre/sinistre")
                  }
                })
            }}>
            Valider
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={rdvFix}
          toggle={fixRdvModel}
          centered
        >
          <ModalHeader toggle={fixRdvModel} style={{padding: "0.5rem 0.5rem"}}> Fixation RDV </ModalHeader>
          <ModalBody>
          <Row>
          <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                      Date RDV
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <input
                        className="form-control"
                        name="dateRDV"
                        type="date"
                        onChange={handleChangePRdv}
                      />
                      <span></span>
          </Col>
          <Col md="6 mb-3">
              <Label htmlFor="validationCustom01">
              Mode de livraison
                <span style={{ color: "red" }}>*</span>
              </Label>
              <select
                className="form-control"
                name="deliveryMode"
                type="select"
                onChange={handleChangePRdv}
              >
                  <option value={""}>Select...</option>
                  <option value={1}>Interne</option>
                  <option value={2}>Aramex</option>
              </select>
          </Col> 
          </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={()=>{
              if(devrdv){
                 setLoading(true)
                      SinistreServices.UpdateSinistre({
                        idsinister: row.idsinister,
                        internal_status: 5,
                      }).then((res)=>{
                        if(res.status == 201){
                          SinistreServices.generatepaymentreceipt(res.data.idsinister).then(async res=>{
                            const words = res.data.split("%%EOF");
                                await downloadPdf(words[1], {numSinistre : row.num , content: "Reçu de paiement"});
                          })
                          setLoading(false)
                          history.push("/gest_sinistre/sinistre")
                        }
                      })
              }else{
                setLoading(true)
                SinistreServices.UpdateSinistre({
                  idsinister: row.idsinister,
                  delivery_mode:priseRdvDto.deliveryMode,
                  delivery_date: priseRdvDto.dateRDV,
                  internal_status: 8
                }).then((res)=>{
                    if (res.status == 201){
                      SinistreServices.GenerateConstatSinistre(res.data.idsinister).then(async res=>{
                        const words = res.data.split("%%EOF");
                            await downloadPdf(words[1], {numSinistre : row.num ,content: "Constat de Prise en Charge"});
                      })
                      setLoading(false)
                      history.push("/gest_sinistre/sinistre")
                    }
                  })
              }
            }}>
            Valider
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={rdvRmbrFix}
          toggle={fixRdvRemb}
          centered
        >
          <ModalHeader toggle={fixRdvRemb} style={{padding: "0.5rem 0.5rem"}}> Fixation RDV </ModalHeader>
          <ModalBody>
          <Row>
          <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                      Date RDV
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <input
                        className="form-control"
                        name="dateRDV"
                        type="date"
                        onChange={handleChangePRdv}
                      />
                      <span></span>
          </Col>
          <Col md="6 mb-3">
              <Label htmlFor="validationCustom01">
              Mode de livraison
                <span style={{ color: "red" }}>*</span>
              </Label>
              <select
                className="form-control"
                name="deliveryMode"
                type="select"
                onChange={handleChangePRdv}
              >
                  <option value={""}>Select...</option>
                  <option value={1}>Interne</option>
                  <option value={2}>Aramex</option>
              </select>
          </Col> 
          </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={()=>{
              setLoading(true)
              SinistreServices.UpdateSinistre({
                idsinister: row.idsinister,
                delivery_mode:priseRdvDto.deliveryMode,
                delivery_date: priseRdvDto.dateRDV,
                internal_status: 7
              }).then((res)=>{
                  if(res.status == 201){
                    SinistreServices.generaterefundreceipt(res.data.idsinister).then(async res=>{
                      const words = res.data.split("%%EOF");
                          await downloadPdf(words[1], {numSinistre : row.num ,content: "Reçu de remboursement"});
                    })
                    setLoading(false)
                    history.push("/gest_sinistre/sinistre")
                  }
                })
            }}>
            Valider
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={remiserdvFix}
          toggle={fixRdvRemiseModel}
          centered
        >
          <ModalHeader toggle={fixRdvRemiseModel} style={{padding: "0.5rem 0.5rem"}}> Fixation RDV </ModalHeader>
          <ModalBody>
          <Row>
          <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                      Date RDV
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <input
                        className="form-control"
                        name="dateRDVRemise"
                        type="date"
                        onChange={handleChangePRdvRemise}
                      />
                      <span></span>
          </Col>
          <Col md="6 mb-3">
              <Label htmlFor="validationCustom01">
              Mode de livraison
                <span style={{ color: "red" }}>*</span>
              </Label>
              <select
                className="form-control"
                name="deliveryMode"
                type="select"
                // onChange={handleChangePRdv}
              >
                  <option value={""}>Select...</option>
                  <option value={1}>Interne</option>
                  <option value={2}>Prestataire</option>
              </select>
          </Col> 
          </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={()=>{
              setLoading(true)
              SinistreServices.UpdateSinistre({
                idsinister: row.idsinister,
                internal_status: 14
              }).then((res)=>{
                if (res.status == 201){
                  SinistreServices.generateDechargeSinistre(res.data.idsinister).then(async res=>{
                    const words = res.data.split("%%EOF");
                        await downloadPdf(words[1], {numSinistre : row.num ,content: "Décharge de remise en etat"});
                  })
                  setLoading(false)
                  history.push("/gest_sinistre/sinistre")
                }
                })
            }}>
            Valider
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </Fragment>
  );
};

export default UpdateSinistre;

{
  /* <DatePicker
                                className="form-control digits"
                                selected={
                                  getValues("constatedate")
                                    ? new Date(getValues("constatedate"))
                                    : ""
                                }
                                {...register("constatedate", {
                                  required: true,
                                })}
                                onChange={(e) => {
                                  console.log(e);
                                  setValue("date", "2022-01-01");
                                }}
                              /> */
}
