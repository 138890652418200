import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";
import Select from "react-select";
import Breadcrumb from "../../layout/breadcrumb";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { saveContractDetails } from "../../redux/revendeur/distributorActions";
import contractServices from "../../services/contract/contractServices";
import { toast } from "react-toastify";
import moment from "moment";
const ProductInformation = () => {
  const [list, setList] = useState();
  const [listProductInfo, setListProductInfo] = useState({});
  const [option, setListOption] = useState([
    { label: "Lunette de vue", value: "Lunette de vue" },
    { label: "Lunette de soleil", value: "Lunette de soleil" },
  ]);
  const [option2, setListOption2] = useState([
    { label: "Verres de correction", value: "Verres de correction" },
    { label: "Verres solaires", value: "Verres solaires" },
    {
      label: "Verres de correction et solaires",
      value: "Verres de correction et solaires",
    },
  ]);
  const [option3, setListOption3] = useState([]);
  const [selectOption, setOptionSelected] = useState(null);
  const [selectOption2, setOptionSelected2] = useState(null);
  const [selectOption3, setOptionSelected3] = useState(null);
  const [inclusion, setInclusion] = useState(false);
  const [paymentInclusion, setPaymentInclusion] = useState(null);
  const [facture, setFacture] = useState(null);
  const [productId, setProductID] = useState(null);
  const [graietChamps, setGraietChamps]= useState(null)
  const history = useHistory();
  const dispatch = useDispatch();
  const details = useSelector(
    ({ distributorReducers }) => distributorReducers.contarctDetails
  );
  const distID = localStorage.getItem("idDist")
  var newDate = new Date();
  var thisMonth = newDate.getUTCMonth();
  var plusMois = 12;
  var plus24mois = 24;
  var endDate = new Date();
  endDate.setUTCMonth(thisMonth + plusMois);
  var endDate2 =new Date();
  endDate2.setUTCMonth(thisMonth + plus24mois);
  const handelChange = (e) => {
    const { value } = e.target;
    setFacture(value);
  };
  const handelChangeGra = (e) => {
    const { name,value } = e.target;
    setGraietChamps((graietChamps)=>({...graietChamps,[name]: value}));
  };
 
  useEffect(() => {
    console.log(details[0]);
    if (
      localStorage.getItem("role") !== "Distributeur" &&
      !(localStorage.getItem("role") === "FrontOffice")
    ) {
      history.goBack();
    } else if (!details[0]) {
      history.push("/new-sale");
    }

    setInclusion(details[0].family.familyName.includes("Inclusion"));
    const name = details[0].family.familyName;
    contractServices.getProductByName(name).then((res) => {
      setProductID(res.data.idproduct)
      contractServices.getProductInfo(res.data.idproduct).then((res) => {

        setList(res.data.sort((a, b) => a.orderDisplay - b.orderDisplay));
      });
      contractServices.getMarqueByProduct(res.data.idproduct).then((res) => {
        
        setListOption3(
          res?.data?.map((el) => ({
            value: el,
            label: el,
          }))
        );
      });
    });
    contractServices.getTypePayment().then((response) => {
      setPaymentInclusion(response.data?.filter((el) => el.num === 7));
    });
  }, [details]);

  const onChangeSelect = (e) => {
    setOptionSelected(e);
  };
  const onChangeSelect2 = (e) => {
    setOptionSelected2(e);
  };
  const onChangeSelect3 = (e) => {
    console.log(e);
    setOptionSelected3(e);
  };
  const handleChange = (e) => {
    const { name, value, id } = e.target;
    setListProductInfo((listProductInfo) => ({
      ...listProductInfo,
      [name]: { value, id },
    }));
    if (!(details[0].family.familyName === "Optique")) {
      list?.map(async (el, key) => {
        if (el.type == "select") {
          let name = el.name;
          let value = selectOption3?.label;
          let id = el.idproductInfo;
          await setListProductInfo((listProductInfo) => ({
            ...listProductInfo,
            [name]: { value, id },
          }));
        }
        // else if (el.type == "date") {
        //   let name = el.name;
        //   let value = moment(new Date()).format("yyyy-MM-DD");
        //   let id = el.idproductInfo;
        //   await setListProductInfo((listProductInfo) => ({
        //     ...listProductInfo,
        //     [name]: { value, id },
        //   }));
        // }
      });
    } else {
      return;
    }
  };
console.log(productId);

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log(paymentInclusion);
    console.log(productId);
    if (inclusion) {
      
      if (details[1].idcustomer) {
        var idC = details[1].idcustomer;
      } else {
        idC = null;
      }
      let contract = {
        cguAssurance: 0,
        cguWebSite: 0,
        customerContractDto: {
          adress: details[1]?.adress,
          birthdate: details[1]?.birthdate,
          cin: details[1]?.cin,
          city: details[1]?.city,
          email: details[1]?.email,
          firstName: details[1]?.firstName,
          idcountry: details[1]?.idcountry,
          idcustomer: idC,
          lastName: details[1]?.lastName,
          phone: details[1]?.phone,
          postalCode: details[1]?.postalCode,
          street: details[1]?.street,
          socialReason: details[1]?.socialReason,
          taxRegistrationNumber: details[1]?.taxRegistrationNumber,
        },
        dateCreation: new Date().toISOString(),
        endDate: details[0]?.other == 2 ? endDate2.toISOString() : endDate.toISOString(),
        facture_dist: facture,
        iddistributor: localStorage.getItem("idDist"),
        idformula: details[0]?.idformula,
        idcountry: "1203e7fc-60a8-4cf7-a93f-ae369eacfa08",
        idtypePayment: paymentInclusion[0].idpaymentType,
        listproducts: {
          idproduct: productId,
          listvalueinfosDto: Object.keys(listProductInfo).map((el) => ({
            idproductInfo: listProductInfo[el]?.id,
            value: listProductInfo[el]?.value,
          })),
        },
        noticeDownloaded: 0,
        startDate: new Date().toISOString(),
        frontingHtAssurance: details[0]?.frontingHtAssurance,
        frontingTtcAssurance: details[0]?.frontingTtcAssurance,
        frontingHtCourtier: details[0]?.frontingHtCourtier,
        margeDistributeurHt: details[0]?.margeDistributeurHt,
        margeDistributeurTtc: details[0]?.margeDistributeurTtc,
        netPriceHt: details[0]?.netPriceHt,
        netPriceTtc: details[0]?.netPriceTtc,
        tua: details[0]?.tua,
        inclusion:"1",
        numero_carte_distributor: graietChamps?.numGr,
        numero_carte_garanty: graietChamps?.numGar,
        typeCustomer: details[1]?.typeCustomer,
        iduserG: localStorage.getItem("id"),
        num:null
      };
      contractServices.createContract(contract).then((res) => {
        if (res.status ===200) {
          history.push({
            pathname: "/document",
            state: [res.data.idcontract, contract],inclusion:true
          });
        }else if(res.status=== 208){
          toast.error("Numéro de carte Graiet déjà existant");
        }
      });
    } else {
      await dispatch(
        saveContractDetails([
          details[0],
          details[1],
          listProductInfo,
          details[2],
        ])
      );
      history.push({
        pathname: "/detail-paiment",
      });
    }
  };

  return (
    <div>
      <Breadcrumb
        parent={"Nouvelle Vente"}
        title={"Caractéristiques du produit"}
      />
      <Container fluid={true}>
        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader className="m-0 pb-0">
            <Row>
              <Col className="text-right"></Col>
            </Row>
          </CardHeader>

          <CardBody className="pt-2">
            <Form
              className="needs-validation"
              noValidate=""
              onSubmit={onSubmit}
            >
              {details[2] ? (
                [...Array(details[2])].map((e, i) => (
                  <>
                    <h6>Produit {i + 1}</h6>
                    <div className="form-row" key={i}>
                      {list?.map((el, key) => {
                        if (el.type === "file") {
                          return <></>;
                        }
                        if (
                          el.type === "select" &&
                          el.label === "Type de lunette"
                        ) {
                          return (
                            <Col md="5 mb-6">
                              <Label
                                style={{ fontSize: "16px", fontWeight: "400" }}
                                htmlFor="validationCustom01"
                              >
                                {el.label}
                                <span style={{ color: "red" }}>*</span>
                              </Label>
                              <Select
                                required
                                value={selectOption}
                                onChange={onChangeSelect}
                                options={option}
                              />
                              <br />
                            </Col>
                          );
                        }
                        if (
                          el.type === "select" &&
                          el.label === "Types de verres"
                        ) {
                          return (
                            <Col md="5 mb-6">
                              <Label
                                style={{ fontSize: "16px", fontWeight: "400" }}
                                htmlFor="validationCustom01"
                              >
                                {el.label}
                                <span style={{ color: "red" }}>*</span>
                              </Label>
                              <Select
                                required
                                id={el.idproductInfo}
                                value={selectOption2}
                                onChange={onChangeSelect2}
                                options={option2}
                              />
                              <br />
                            </Col>
                          );
                        }
                        if (el.type === "date") {
                          return (
                            <Col md="5 mb-6">
                              <Label
                                style={{ fontSize: "16px", fontWeight: "400" }}
                                htmlFor="validationCustom01"
                              >
                                {el.label}
                                <span style={{ color: "red" }}>*</span>
                              </Label>
                              <Input
                                key={key}
                                id={el.idproductInfo}
                                style={{ height: "50px" }}
                                className="form-control"
                                name={el.name}
                                type={el.type}
                                // defaultValue={moment(new Date()).format(
                                //   "yyyy-MM-DD"
                                // )}
                                required
                                onChange={handleChange}
                              />
                              <br />
                            </Col>
                          );
                        }
                        if (el.type === "select" && el.label === "Marque") {
                          return (
                            <Col md="5 mb-6">
                              <Label
                                style={{ fontSize: "16px", fontWeight: "400" }}
                                htmlFor="validationCustom01"
                              >
                                {el.label}
                                <span style={{ color: "red" }}>*</span>
                              </Label>
                              <Select
                                id={el.idproductInfo}
                                value={selectOption3}
                                onChange={onChangeSelect3}
                                options={option3}
                                required
                              />
                              <br />
                            </Col>
                          );
                        } else {
                          return (
                            <Col md="5 mb-6">
                              <Label
                                style={{ fontSize: "16px", fontWeight: "400" }}
                                htmlFor="validationCustom01"
                              >
                                {el.label}
                                <span style={{ color: "red" }}>*</span>
                              </Label>
                              <Input
                                required
                                key={key}
                                id={el.idproductInfo}
                                style={{ height: "50px" }}
                                className="form-control"
                                name={el.name + i}
                                type={el.type}
                                placeholder={el.label}
                                onChange={handleChange}
                              />
                              <br />
                            </Col>
                          );
                        }
                      })}
                    </div>
                  </>
                ))
              ) : (
                <div className="form-row">
                  {list?.map((el, key) => {
                    if (el.type === "file") {
                      return <></>;
                    }
                    if (
                      el.type === "select" &&
                      el.label === "Type de lunette"
                    ) {
                      return (
                        <Col md="5 mb-6">
                          <Label
                            style={{ fontSize: "16px", fontWeight: "400" }}
                            htmlFor="validationCustom01"
                          >
                            {el.label}
                            <span style={{ color: "red" }}>*</span>
                          </Label>
                          <Select
                            value={selectOption}
                            onChange={onChangeSelect}
                            options={option}
                            required
                          />
                          <br />
                        </Col>
                      );
                    }
                    if (
                      el.type === "select" &&
                      el.label === "Types de verres"
                    ) {
                      return (
                        <Col md="5 mb-6">
                          <Label
                            style={{ fontSize: "16px", fontWeight: "400" }}
                            htmlFor="validationCustom01"
                          >
                            {el.label}
                            <span style={{ color: "red" }}>*</span>
                          </Label>
                          <Select
                            id={el.idproductInfo}
                            value={selectOption2}
                            onChange={onChangeSelect2}
                            options={option2}
                            required
                          />
                          <br />
                        </Col>
                      );
                    }
                    if (el.type === "date") {
                      return (
                        <Col md="5 mb-6">
                          <Label
                            style={{ fontSize: "16px", fontWeight: "400" }}
                            htmlFor="validationCustom01"
                          >
                            {el.label}
                            <span style={{ color: "red" }}>*</span>
                          </Label>
                          <Input
                            key={key}
                            id={el.idproductInfo}
                            style={{ height: "50px" }}
                            className="form-control"
                            name={el.name}
                            type={el.type}
                            required
                            onChange={handleChange}
                          />
                          <br />
                        </Col>
                      );
                    }
                    if (el.type === "select" && el.label === "Marque") {
                      return (
                        <Col md="5 mb-6">
                          <Label
                            style={{ fontSize: "16px", fontWeight: "400" }}
                            htmlFor="validationCustom01"
                          >
                            {el.label}
                            <span style={{ color: "red" }}>*</span>
                          </Label>
                          <Select
                            id={el.idproductInfo}
                            value={selectOption3}
                            onChange={onChangeSelect3}
                            options={option3}
                            required
                          />
                          <br />
                        </Col>
                      );
                    } else {
                      return (
                        <Col md="5 mb-6">
                          <Label
                            style={{ fontSize: "16px", fontWeight: "400" }}
                            htmlFor="validationCustom01"
                          >
                            {el.label}
                            <span style={{ color: "red" }}>*</span>
                          </Label>
                          <Input
                            required
                            key={key}
                            id={el.idproductInfo}
                            style={{ height: "50px" }}
                            className="form-control"
                            name={el.name}
                            type={el.type}
                            placeholder={el.label}
                            onChange={handleChange}
                          />
                          <br />
                        </Col>
                      );
                    }
                  })}
                  {inclusion ? <Col md="5 mb-6">
                  <Label
                    style={{ fontSize: "16px", fontWeight: "400" }}
                    htmlFor="validationCustom01"
                  >
                    Facture
                    <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    style={{ height: "50px" }}
                    className="form-control"
                    name="facture"
                    type="text"
                    placeholder="Facture"
                    onChange={handelChange}
                    required={true}
                  />
                  <br />
                </Col>:<></>}
                {
                  distID === "808a622f-6e71-4ec9-bd4a-834af72e4e35" ? <>
                  <Col md="5 mb-6">
                  <Label
                    style={{ fontSize: "16px", fontWeight: "400" }}
                    htmlFor="validationCustom01"
                  >
                    Numéro de carte Graiet
                    <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    style={{ height: "50px" }}
                    className="form-control"
                    name="numGr"
                    type="text"
                    pattern=".{13,13}" 
                    oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                    title="13 characters length required" 
                    placeholder="Numéro de carte Graiet"
                    onChange={handelChangeGra}
                    required={true}
                  />
                  <br />
                </Col>
                <Col md="5 mb-6">
                <Label
                  style={{ fontSize: "16px", fontWeight: "400" }}
                  htmlFor="validationCustom01"
                >
                  Numéro de carte Garanty
                  <span style={{ color: "red" }}>*</span>
                </Label>
                <Input
                  style={{ height: "50px" }}
                  className="form-control"
                  name="numGar"
                  type="number"
                  placeholder="Numéro de carte Garanty"
                  onChange={handelChangeGra}
                  required={false}
                />
                <br />
              </Col>
              </>
                :<></>
                }
                </div>
              )}
              <br />
              <Row>
                <Col md="5 mb-6">
                  <Button
                    color="danger"
                    className="float-left"
                    style={{ width: "55%", height: "45px" }}
                    onClick={() => {
                      history.push("/fiche-client");
                    }}
                  >
                    Retour
                  </Button>
                </Col>
                <Col md="5 mb-6">
                  <Button
                    color="primary"
                    className="float-right"
                    // onClick={onSubmit}
                    style={{ width: "55%", height: "45px" }}
                  >
                    Valider
                  </Button>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default ProductInformation;
