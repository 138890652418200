import { IconButton } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Row,
} from "reactstrap";
import BudgeComponent from "../../components/BudgeComponent";
import Breadcrumbs from "../../layout/breadcrumb";
import distributorService from "../../services/distribitor/distributorService";

const ListSousDist = () => {
  const history = useHistory();
  const [sousDistList, setSousDistList] = useState();
  useEffect(() => {
    if(localStorage.getItem("role") !== "Distributeur"){
      history.goBack()
    }else if(localStorage.getItem("role") == "Distributeur" && !JSON.parse(localStorage.getItem("sous-rev"))){
      history.goBack()
    }
    const id = localStorage.getItem("idDist");
    distributorService.getallDistbyParentid(id).then((res) => {
      console.log(res.data);
      setSousDistList(res.data);
    });
  }, []);
  const columns = [
    {
      name: "Revendeur",
      selector: "socialReason",
      sortable: true,
    },
    // { name: "Commission", selector: "commission", sortable: true },
    {
      name: "Type",
      selector: "type",
      sortable: true,
    },
    {
      name: "Actif",
      cell: (row) => <BudgeComponent status={1} />,
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <IconButton
            color="primary"
            onClick={() => {
              history.push({
                pathname: "/Update-sousDist",
                state: { row },
              });
            }}
          >
            <i className="icofont icofont-eye-alt"></i>
          </IconButton>
          <IconButton
            color="primary"
            onClick={() => {
              console.log(row);
              history.push({
                pathname: "/Update-sousDist",
                state: { row, update: true },
              });
            }}
          >
            <i className="icofont icofont-ui-edit"></i>
          </IconButton>
        </div>
      ),

      button: true,
    },
  ];
  return (
    <div>
      <Breadcrumbs parent={"Revendeur"} title={"Liste des revendeurs"} />
      <Container fluid={true}>
        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader className="m-0  align-button ">
            <Row>
              <Col className="text-right">
                <span>
                  <Button
                    className="btn-pill btn-air-primary "
                    color="primary"
                    onClick={() => {
                      history.push("/sous-dist");
                    }}
                  >
                    {"Nouveau Revendeur"}
                  </Button>
                </span>
                &nbsp;
              </Col>
            </Row>
          </CardHeader>

          <CardBody className="pt-2">
            <DataTable
              noHeader
              pagination
              paginationComponentOptions={{
                rowsPerPageText: "Afficher",
                rangeSeparatorText: "/",
                selectAllRowsItem: true,
                selectAllRowsItemText: "all",
              }}
              columns={columns}
              data={sousDistList}
              pointerOnHover={true}
              progressPending=""
              progressComponent={
                <div className="loader-box">
                  <div className="loader-1"></div>
                </div>
              }
            ></DataTable>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default ListSousDist;
