import React, { useState, useEffect, Fragment } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  Label,
  Input,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import distributorService from "../../../services/distribitor/distributorService";
import { fetchFamily } from "../../../redux/family/familyActions";
import el from "date-fns/esm/locale/el/index.js";

const CreateRevendeur = ({ location, i18n }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const listFamily = useSelector(({ familyReducers }) => {
    return familyReducers.familys;
  });
  const [option, setListOption] = useState([]);
  const [selectOption, setOptionSelected] = useState(null);
  const [distribitor, setDistribitor] = useState({
    dateCreation: new Date().toISOString(),
    commission: 0,
    type: "",
    isActive: 1,
    isDeleted: 0,
    socialReason: "",
    subdistributor: false,
    commissiontype: "%",
    typesale: "",
  });
  useEffect(() => {
    dispatch(fetchFamily());
  }, []);
  useEffect(() => {
    setListOption(
      listFamily?.map((el) => ({
        value: el.idfamily,
        label: el.familyName,
      }))
    );
  }, [listFamily]);
  const [submitted, setSubmitted] = useState(false);
  const [checkedActif, setCheckedActif] = useState(true);
  const [checked, setChecked] = useState(false);
  const onchangeSelect = (item) => {
    setOptionSelected(item);
    let typeList = []
  item.forEach(element => {
    typeList.push(element.value)
  });
  
    setDistribitor((distribitor) => ({ ...distribitor, typesale: JSON.stringify(typeList) }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setDistribitor((distribitor) => ({ ...distribitor, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    console.log(distribitor);
    await distributorService.createDistrubitor(distribitor);
    toast.success("distributor created successfuly");
    history.push("/gest_intervenant/revendeurs");
  };

  return (
    <Fragment>
      <Breadcrumb parent="Revendeur" title="Nouveau Revendeur" />
      <Container fluid={true}>
        <Form
          className="needs-validation"
          noValidate=""
          onSubmit={handleSubmit}
        >
          <Row>
            <Col>
              <Card className="shadow-lg p-0 shadow-showcase">
                <CardHeader className="b-l-primary pb-2">
                  {/* <h5>Info Generale</h5> */}
                </CardHeader>
                <CardBody className="pt-1">
                  <div className="form-row">
                    <Col md="4 mb-3">
                      <Label
                        htmlFor="validationCustom01"
                        style={{ fontSize: "16px", fontWeight: "400" }}
                      >
                        Revendeur
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="socialReason"
                        type="text"
                        placeholder="Revendeur"
                        innerRef=""
                        value={distribitor.socialReason}
                        onChange={handleChange}
                      />
                      <span></span>
                    </Col>

                    <Col md="4 mb-3">
                      <Label
                        htmlFor="validationCustom01"
                        style={{ fontSize: "16px", fontWeight: "400" }}
                      >
                        Type
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="type"
                        type="text"
                        placeholder="Type"
                        innerRef=""
                        value={distribitor.type}
                        onChange={handleChange}
                      />
                      <span></span>
                    </Col>
                    <Col md="4 mb-4">
                      <Label
                        style={{ fontSize: "16px", fontWeight: "400" }}
                        htmlFor="validationCustom01"
                      >
                        Type Vente Produit
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Select
                        value={selectOption}
                        onChange={onchangeSelect}
                        options={option}
                        isMulti
                      />
                      <span></span>
                    </Col>
                    <Col md="4 mb-3">
                      <Label
                        htmlFor="validationCustom01"
                        style={{ fontSize: "16px", fontWeight: "400" }}
                      >
                        Type Commission
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <select
                        name="commissiontype"
                        style={{
                          height: "38px",
                          borderRadius: "5px",
                          border: "1px solid",
                          borderColor: "#b8b8b8",
                          fontSize: "large",
                          position: "relative",
                          width: "100%",
                          paddingLeft: "10px",
                        }}
                        onChange={handleChange}
                      >
                        <option value="%">%</option>
                        <option value="forfait">forfait</option>
                      </select>
                      <span></span>
                    </Col>
                    <Col md="4 mb-3">
                      <Label
                        htmlFor="validationCustom01"
                        style={{ fontSize: "16px", fontWeight: "400" }}
                      >
                        Commission
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="commission"
                        type="text"
                        placeholder="Commission"
                        innerRef=""
                        value={distribitor.commission}
                        onChange={handleChange}
                      />
                      <span></span>
                    </Col>
                    <Col md="4 mb-3">
                      <div
                        class="form-check form-switch"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          textAlign: "center",
                          marginTop: "20px",
                          padding: "0",
                        }}
                      >
                        <label
                          class="form-check-label"
                          for="flexSwitchCheckDefault"
                        >
                          Actif
                        </label>
                        <input
                          class="form-check-input"
                          style={{ marginTop: "25px", marginLeft: "4px" }}
                          name="isActive"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                          checked={checkedActif}
                          value="0"
                          onClick={() => {
                            if (checkedActif) {
                              setCheckedActif(false);
                              setDistribitor((distribitor) => ({
                                ...distribitor,
                                isActive: "0",
                              }));
                            } else {
                              setCheckedActif(true);
                              setDistribitor((distribitor) => ({
                                ...distribitor,
                                isActive: "1",
                              }));
                            }
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="4 mb-3">
                      <div
                        class="form-check form-switch"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          textAlign: "center",
                          marginTop: "20px",
                          padding: "0",
                        }}
                      >
                        <label
                          class="form-check-label"
                          for="flexSwitchCheckDefault"
                        >
                          Gère des sous-revendeurs
                        </label>
                        <input
                          class="form-check-input"
                          style={{ marginTop: "25px", marginLeft: "4px" }}
                          name="isActive"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                          checked={checked}
                          value="0"
                          onClick={() => {
                            if (checked) {
                              setChecked(false);
                              setDistribitor((distribitor) => ({
                                ...distribitor,
                                subdistributor: false,
                              }));
                            } else {
                              setChecked(true);
                              setDistribitor((distribitor) => ({
                                ...distribitor,
                                subdistributor: true,
                              }));
                            }
                          }}
                        />
                      </div>
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Button
                color="danger"
                type="reset"
                className="float-left"
                onClick={() => history.push("/gest_intervenant/revendeurs")}
              >
                Retour
              </Button>
            </Col>
            <Col>
              <Button color="primary" type="submit" className="float-right">
                Valider
              </Button>
            </Col>
          </Row>
          {/* <Button color="primary">{config.button}</Button>
                  <Button color="primary">{config.button}</Button> */}
        </Form>
      </Container>
    </Fragment>
  );
};

export default CreateRevendeur;
