import React, { Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
const Footer = (props) => {
  return (
    <Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row style={{display:"flex", justifyContent:"center"}}>
            <Col md="6" className="footer-copyright" style={{display:"flex", justifyContent:"center"}}>
              <p className="mb-0" style={{ textAlige: "center" }}>
                © 2022 Garanty <br /> Made by VEGANET
              </p>
            </Col>
            {/* <Col md="6">
                <p className="pull-right mb-0">Hand crafted & made with <i className="fa fa-heart font-secondary"></i></p>
              </Col> */}
          </Row>
        </Container>
      </footer>
    </Fragment>
  );
};

export default Footer;
