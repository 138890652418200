import React from "react";
import { Badge } from "reactstrap";

function BudgeComponent({ status }) {
  return status ? (
    <Badge color="success">Actif</Badge>
  ) : (
    <Badge color="danger">Inactif</Badge>
  );
}
export default BudgeComponent;
