import productService from "../../services/product/productService";

export const FETCH_PRODUCTS_REQUEST = "FETCH_PRODUCTS_REQUEST";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAILURE = "FETCH_PRODUCTS_FAILURE";
export const ADD_PRODUCTS_REQUEST = "ADD_PRODUCTS_REQUEST";
export const ADD_PRODUCTS_SUCCESS = "ADD_PRODUCTS_SUCCESS";
export const ADD_PRODUCTS_FAILURE = "ADD_PRODUCTS_FAILURE";

export const fetchProduct = () => {
  return (dispatch) => {
    dispatch(fetchProductsRequest());
    productService
      .getAllProduct()
      .then((response) => {
        // response.data is the PRODUCTS
        console.log("hello",response)
        const products = response.data;
        dispatch(fetchProductsSuccess(products));
      })
      .catch((error) => {
        // error.message is the error message
        dispatch(fetchProductsFailure(error.message));
      });
  };
};

export const fetchProductsRequest = () => {
  return {
    type: FETCH_PRODUCTS_REQUEST
  };
};

export const fetchProductsSuccess = (products) => {
  return {
    type: FETCH_PRODUCTS_SUCCESS,
    payload: products
  };
};

export const fetchProductsFailure = (error) => {
  return {
    type: FETCH_PRODUCTS_FAILURE,
    payload: error
  };
};

export const addProduct = (data) => {
  return (dispatch) => {
    dispatch(addProductsRequest(data));
    productService
    .createNewProduct(data)
    .then(
      (response) => {
        dispatch(addProductsSuccess(response.data));
      },
      (error) => {
        dispatch(addProductsFailure(error.messege));
      }
    );
  };
};

export const addProductsRequest = () => {
  return {
    type: ADD_PRODUCTS_REQUEST
  };
};

export const addProductsSuccess = (product) => {
  return {
    type: ADD_PRODUCTS_SUCCESS,
    payload: product
  };
};

export const addProductsFailure = (error) => {
  return {
    type: ADD_PRODUCTS_FAILURE,
    payload: error
  };
};