import React, { useEffect } from "react";
import { useState } from "react";
import { Eye, EyeOff } from "react-feather";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../layout/breadcrumb";
import distributorService from "../../services/distribitor/distributorService";
import userGarantieService from "../../services/userGarantie/userGarantieService";
const generator = require("generate-password");
const CreateUserDist = () => {
  const id = localStorage.getItem("idDist");
  const history = useHistory();
  const location = useLocation()
  const vue = location?.state?.vue
  const update = location?.state?.update
  const [confirmPass, setConfirmPass] = useState({
    confPassword:""
  });
  const [show, setShow]= useState(false)
  const [password, setPassword] = useState({password:""});
  const [confirmPassword, setConfirmPassword] = useState({confPassword:""});
  const [userDist, setUserDist] = useState(vue ? location.state.row : update? {
    email: location.state.row.email,
    firstName: location.state.row.firstName,
    idType: location.state.row.typeUser.id,
    iddisributor: location.state.row.disrtibutor.iddisributor,
    idprofile:location.state.row.profile.idprofile,
    iduserG: location.state.row.iduserG,
    isActive: location.state.row.isActive,
    isDeleted: location.state.row.isDeleted,
    lastName: location.state.row.lastName,
    phone: location.state.row.phone,
    } : {
    dateCreation: new Date().toISOString(),
    email: "",
    firstName: "",
    idType: "eef575da-c070-4514-b254-3d7f1d71cd51",
    iddisributor: id,
    idprofile: "d4023138-8067-45e2-a04f-9f44bcb4779a",
    isActive: "1",
    isDeleted: 0,
    lastName: "",
    password: "",
    phone: "",
  } );
  useEffect(()=>{
    if(localStorage.getItem("role") !== "Distributeur"){
      history.goBack()
     }
  },[])
  const generaetePassword = () => {
    var genereatedPass = generator.generate({
      length: 10,
      numbers: true,
    });
    setConfirmPass(false);
    setPassword((password)=>({...password, password: genereatedPass}));
    setConfirmPassword((confirmPassword)=>({...confirmPassword, confPassword: genereatedPass}));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDist((userDist) => ({ ...userDist, [name]: value }));
  };
  const handleChangepass = (e) => {
    const { name, value } = e.target;
    setConfirmPassword((confirmPassword) => ({ ...confirmPassword, [name]: value }));
  
  };
  const handleChangepass2 = (e) => {
    const { name, value } = e.target;
    setPassword((password) => ({ ...password, [name]: value }));
  
  };
  const valider = async() => {
    if(update){
      if(password.password){
        userGarantieService.updateUser({...userDist, password: password.password}).then(res=>{
          // console.log(res)
          if(res.status == 200){
            history.push("/user-dist-list")
            toast.success("Opération terminée avec succès");
          }
        })
      }else{
        userGarantieService.updateUser(userDist).then(res=>{
          // console.log(res)
          if(res.status == 200){
            history.push("/user-dist-list")
            toast.success("Opération terminée avec succès");
          }
        })
      }
    }else{
      userGarantieService.createUser({...userDist, password: password.password ? password.password : userDist.password,}).then((res) => {
        if(res.status == 200){
            history.push("/user-dist-list")
            toast.success("Opération terminée avec succès");
        }
      });
    }
  };

  return (
    <div>
      <Breadcrumbs parent="Distributeur" title="Nouvel utilisateur" />
      <Container fluid={true}>
        <Form className="needs-validation" noValidate="">
          <Row>
            <Col>
              <Card className="shadow-lg p-0 shadow-showcase">
                <CardHeader className="b-l-primary pb-2">
                  <h5>Détails du compte</h5>
                </CardHeader>
                <CardBody className="pt-1">
                  <div className="form-row">
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom01">
                        Nom
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="firstName"
                        type="text"
                        placeholder="Nom"
                        innerRef=""
                        value={userDist.firstName}
                        onChange={handleChange}
                        disabled={vue}
                      />
                      <span></span>
                    </Col>
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom01">
                        Prénom
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="lastName"
                        type="text"
                        placeholder="Prénom"
                        innerRef=""
                        value={userDist.lastName}
                        onChange={handleChange}
                        disabled={vue}
                      />
                      <span></span>
                    </Col>
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom01">
                        Mobile
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="phone"
                        type="text"
                        placeholder="Mobile"
                        innerRef=""
                        value={userDist.phone}
                        onChange={handleChange}
                        disabled={vue}
                      />
                      <span></span>
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </Container>
      <Container fluid={true}>
        <Form className="needs-validation" noValidate="">
        <Row>
              <Col>
                <Card className="shadow-lg p-0 shadow-showcase">
                  <CardHeader className="b-l-secondary pb-2">
                    <h5> Détails de connexion</h5>
                  </CardHeader>
                  <CardBody className="pt-1">
                    <div className="form-row">
                      <Col md="3 mb-2">
                        <Label htmlFor="validationCustom01">E-mail</Label>
                        <span style={{ color: "red" }}>*</span>
                        <Input
                          className="form-control"
                          name="email"
                          type="text"
                          placeholder="E-mail"
                          innerRef=""
                          value={userDist.email}
                          onChange={handleChange}
                          required
                          disabled={vue}
                        />

                        <div className="valid-feedback">Looks good!</div>
                      </Col>
                      <Col md="3 mb-2">
                        <Label htmlFor="validationCustom02">Mot de passe</Label>
                        <span style={{ color: "red" }}>*</span>
                        <Input
                          className="form-control"
                          name="password"
                          type={show ? "text" : "password"}
                          placeholder="password"
                          value={password.password ? password.password : userDist.password}
                          onChange={handleChangepass2}
                          required
                          disabled={vue}
                        />

                        <div className="valid-feedback">Looks good!</div>
                      </Col>
                      <Col md="3 mb-2">
                        <Label htmlFor="validationCustom02">confirmation</Label>
                        <span style={{ color: "red" }}>*</span>
                        <Input
                          className="form-control"
                          name="confPassword"
                          type={show ? "text" : "password"}
                          placeholder="confirm Password"
                          value={confirmPassword.confPassword ? confirmPassword.confPassword : confirmPass.confPassword }
                          onChange={handleChangepass}
                          required
                          disabled={vue}
                        />
                      </Col>
                      <Col md="1 m-auto">
                        { show ?  
                        <Eye 
                          style={{marginTop:"20px"}}
                          onClick={()=>{
                            setShow(!show)
                          }}

                        /> :
                        <EyeOff 
                          style={{marginTop:"20px"}}
                          onClick={()=>{
                            setShow(!show)
                          }}
                        />}
                      </Col>
                      <Col md="1 m-auto">
                        <Button
                          className="btn-pill btn-air-primary float-right"
                          color="primary"
                          onClick={() => generaetePassword()}
                        >
                          Générer
                        </Button>
                      </Col>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

          <Row>
            <Col>
              <Button
                color="danger"
                type="reset"
                className="float-left"
                onClick={() => history.push("/user-dist-list")}
              >
                Retour
              </Button>
            </Col>
            <Col>
              <Button color="primary" className="float-right" onClick={valider}>
                Valider
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default CreateUserDist;
