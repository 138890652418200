import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import IconButton from "@material-ui/core/IconButton";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Badge,
} from "reactstrap";

import Breadcrumb from "../../../layout/breadcrumb";
import { useHistory } from "react-router-dom";

import SweetAlert from "sweetalert2";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import Switch from "react-switch";
import { fetchDistributor } from "../../../redux/revendeur/distributorActions";
import { useDispatch, useSelector } from "react-redux";
import BudgeComponent from "../../../components/BudgeComponent";
import distributorService from "../../../services/distribitor/distributorService";
import download from "downloadjs";

const RevendeurList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [filteredValues, setFilteredValues] = useState({
    dist: "",
    type: "",
    parent: "",
  });
  const [listDistributors, setListDistributors] = useState();
  const [originList, setOriginList] = useState();
  const [handleFilter, setHandleFilter] = useState(true);
  const role = localStorage.getItem("role");
  const uid = localStorage.getItem("id");
  useEffect(() => {
    distributorService.getAllDistributors(uid).then((response) => {
      setListDistributors(response.data);
      setOriginList(response.data);
    });
    dispatch(fetchDistributor());
  }, []);
  const profil = localStorage.getItem("profil")
  const handleFilterValues = (value, name) => {
    setFilteredValues({ ...filteredValues, [name]: value });
    console.log(filteredValues);
  };
  const filterData = () => {
    const filtredList = originList.filter((el) => {
      return (
        el.socialReason
          ?.toLowerCase()
          .includes(filteredValues.dist.toLowerCase()) &&
        el.type?.toLowerCase().includes(filteredValues.type.toLowerCase())
        // &&
        // (filteredValues.parentidstring != 0
        //   ? typeof(el.parentidstring) === "string" || typeof(el.parentidstring) === "number" ? el.parentidstring : JSON.stringify(el.parentidstring) === filteredValues.parent
        //   : true)
      );
    });
    // console.log(filtredList);
    setListDistributors(filtredList);
  };
  const downloadXlsx = (data) => {
    download(atob(data), "distribiteur_list.xlsx", {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
  };
  const columns = [
    {
      name: "Revendeur",
      selector: "socialReason",
      sortable: true,
    },
    // { name: "Commission", selector: "commission", sortable: true },
    { name: "Type", selector: "type", sortable: true },
    {
      name: "Actif",
      cell: (row) => <BudgeComponent status={row.isActive} />,
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          {profil === "User" ? (
            <IconButton
            color="primary"
            onClick={() => {
              history.push({
                pathname: "/gest_intervenant/revendeurs/update_revendeur",
                state: { row },
              });
            }}
          >
            <i className="icofont icofont-eye-alt"></i>
          </IconButton>
          ) : (
            <>
              <IconButton
                color="primary"
                onClick={() => {
                  // console.log(row);
                  history.push({
                    pathname: "/gest_intervenant/revendeurs/update_revendeur",
                    state: { row, update: true },
                  });
                }}
              >
                <i className="icofont icofont-ui-edit"></i>
              </IconButton>
              <IconButton
                color="primary"
                onClick={() => {
                  // console.log(row);
                  history.push({
                    pathname: "/gest_intervenant/revendeurs/update_revendeur",
                    state: { row },
                  });
                }}
              >
                <i className="icofont icofont-eye-alt"></i>
              </IconButton>
            </>
          )}
        </div>
      ),

      button: true,
    },
  ];
  const columnsC = [
    {
      name: "Revendeur",
      selector: "socialReason",
      sortable: true,
    },
    {
      name: "Commission",
      selector: "commission",
      sortable: true,
    },
    {
      name: "Type",
      selector: "type",
      sortable: true,
    },
  ];
  return (
    <div>
      <Breadcrumb parent={"Revendeur"} title={"Liste des revendeurs"} />
      <Container fluid={true}>
        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader
            className="ml-2"
            style={{ cursor: "pointer", padding: "10px" }}
            onClick={() => {
              setHandleFilter(!handleFilter);
            }}
          >
            <span className="float-left">
              <h6 className="mt-1">{"Filtrer"}</h6>
            </span>

            <span className="float-right ">
              <h4>
                <h4>
                  {handleFilter ? (
                    <i className="fa fa-angle-down"></i>
                  ) : (
                    <i className="fa fa-angle-up"></i>
                  )}
                </h4>
              </h4>
            </span>
          </CardHeader>

          <CardBody className="pt-0" hidden={handleFilter}>
            <Form className="theme-form">
              <Row>
                <Col md="3 mb-2">
                  <FormGroup>
                    <label className="col-form-label"> Revendeur</label>
                    <Input
                      onChange={(e) =>
                        handleFilterValues(e.target.value, "dist")
                      }
                      className="form-control"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="3 mb-2">
                  <FormGroup>
                    <label className="col-form-label"> Type</label>
                    <Input
                      onChange={(e) =>
                        handleFilterValues(e.target.value, "type")
                      }
                      className="form-control"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="3 mb-2">
                  <FormGroup>
                    <label className="col-form-label">Parent existe</label>
                    <Input
                      type="select"
                      name="select"
                      className="form-control digits"
                      defaultValue={0}
                      onChange={(e) => {
                        handleFilterValues(e.target.value, "parent");
                      }}
                    >
                      <option value={0}>{"Tous"}</option>
                      <option value={"null"}>Oui</option>
                      <option value={"hi"}>Non</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
            <Button
              className="btn-pill btn-air-success"
              color="success"
              onClick={filterData}
            >
              Recherche
            </Button>
          </CardBody>
        </Card>

        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader className="m-0  align-button ">
            <Row>
              {role === "Courtier " || profil === "User" ? (
                <></>
              ) : (
                <Col className="text-right">
                  <Button
                    className="btn-pill btn-air-primary "
                    color="primary"
                    onClick={() => {
                      history.push(
                        "/gest_intervenant/revendeurs/new_revendeur"
                      );
                    }}
                  >
                    + {"Nouveau Revendeur"}
                  </Button>
                  <Button
                    className="ml-3"
                    color="success"
                    outline
                    onClick={() => {
                      distributorService
                        .excel(localStorage.getItem("id"))
                        .then((res) => {
                          downloadXlsx(res.data);
                        });
                    }}
                  >
                    Exporter
                  </Button>
                  {/* <span>{listDistributors.length}</span> */}
                </Col>
              )}
            </Row>
          </CardHeader>

          <CardBody className="pt-2">
            <DataTable
              noHeader
              pagination
              paginationComponentOptions={{
                rowsPerPageText: "Afficher",
                rangeSeparatorText: "/",
                selectAllRowsItem: true,
                selectAllRowsItemText: "all",
              }}
              columns={role === "Courtier " ? columnsC : columns}
              data={listDistributors}
              pointerOnHover={true}
              progressPending=""
              progressComponent={
                <div className="loader-box">
                  <div className="loader-1"></div>
                </div>
              }
            ></DataTable>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default RevendeurList;
