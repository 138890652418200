import React, { useState, useEffect, Fragment } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {Eye , EyeOff} from "react-feather"
import {
  Media,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import userGarantieService from "../../../services/userGarantie/userGarantieService";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Dropzone from "react-dropzone-uploader";
import { findLastIndex } from "lodash";
import Select from "react-select";
import { fetchProfile } from "../../../redux/profile/profileActions";
import { fetchTypeUser } from "../../../redux/typeUser/typeUserActions";
import distributorService from "../../../services/distribitor/distributorService";
import { fetchDistributor } from "../../../redux/revendeur/distributorActions";
import { fetchCourtier } from "../../../redux/courtier/courtierActions";
import { fetchAssurance } from "../../../redux/assurance/assuranceActions";
const generator = require("generate-password");
const CreateUserGaranty = ({ location, i18n }) => {
  const history = useHistory();
  const [userGarantie, setUserGarantie] = useState({
    dateCreation: new Date().toISOString(),
    email: "",
    firstName: "",
    isActive: "1",
    lastName: "",
    password: "",
    phone: "",
  });
  
  const [mailInscription, setMailInscription] = useState({
    sendTo: "",
    subject: "Inscription au Garanty",
    text: "",
    type: "inscription",
  });
  const [confirmPass, setConfirmPass] = useState({
    confPassword:""
  });
  const [show, setShow]= useState(false)
  const [password, setPassword] = useState({password:""});
  const [confirmPassword, setConfirmPassword] = useState({confPassword:""});
  const [checked, setChecked] = useState(true);
  const generaetePassword = () => {
    var genereatedPass = generator.generate({
      length: 10,
      numbers: true,
    });
    setPassword((password)=>({...password, password: genereatedPass}));
    setConfirmPassword((confirmPassword)=>({...confirmPassword, confPassword: genereatedPass}));
  };

  const listDistributors = useSelector(
    ({ distributorReducers }) => distributorReducers.distributors
  );

  const listTypeUser = useSelector(
    ({ typeUserReducers }) => typeUserReducers.typeUsers
  );

  const listCourtier = useSelector(
    ({ courtierReducers }) => courtierReducers.courtiers
  );

  const listAssurance = useSelector(
    ({ assuranceReducers }) => assuranceReducers.assurances
  );

  const listProfile = useSelector(
    ({ profileReducers }) => profileReducers.profiles
  );

  const [typeUserOption, setTypeUserOption] = useState();
  const [distributorOption, setDistributorOption] = useState();
  const [assuranceOption, setAssuranceOption] = useState();
  const [courtierOption, setCourtierOption] = useState();
  const [profileOption, setProfileOption] = useState();

  const [selectTypeUserOption, selectSetTypeUserOption] = useState(null);
  const [selectDistributorOption, selectSetDistributorOption] = useState(null);
  const [selectAssuranceOption, selectSetAssuranceOption] = useState(null);
  const [selectCourtierOption, selectSetCourtierOption] = useState(null);
  const [selectProfileOption, selectSetProfileOption] = useState(null);

  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    setTypeUserOption(
      listTypeUser.map((el) => ({
        value: el.id,
        label: el.designation,
      }))
    );
  }, [listTypeUser]);

  useEffect(() => {
    setDistributorOption(
      listDistributors.map((el) => ({
        value: el.iddisributor,
        label: el.socialReason,
      }))
    );
  }, [listDistributors]);

  useEffect(() => {
    setAssuranceOption(
      listAssurance.map((el) => ({
        value: el.idinsurance,
        label: el.socialReason,
      }))
    );
  }, [listAssurance]);

  useEffect(() => {
    setCourtierOption(
      listCourtier.map((el) => ({
        value: el.idbroker,
        label: el.socialReason,
      }))
    );
  }, [listCourtier]);

  useEffect(() => {
    setProfileOption(
      listProfile.map((el) => ({
        value: el.idprofile,
        label: el.name,
      }))
    );
  }, [listProfile]);

  const onchangeSelectTypeUser = (item) => {
    selectSetTypeUserOption(item);
  };
  const onchangeSelectDistributorr = (item) => {
    selectSetDistributorOption(item);
  };
  const onchangeSelectAssurance = (item) => {
    selectSetAssuranceOption(item);
  };
  const onchangeSelectCourtier = (item) => {
    selectSetCourtierOption(item);
  };
  const onchangeSelectProfile = (item) => {
    selectSetProfileOption(item);
  };
  React.useEffect(() => {
    dispatch(fetchProfile());
    dispatch(fetchTypeUser());
    dispatch(fetchDistributor());
    dispatch(fetchCourtier());
    dispatch(fetchAssurance());
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserGarantie((userGarantie) => ({ ...userGarantie, [name]: value }));
  
  };
  const handleChangepass = (e) => {
    const { name, value } = e.target;
    setConfirmPassword((confirmPassword) => ({ ...confirmPassword, [name]: value }));
  
  };
  const handleChangepass2 = (e) => {
    const { name, value } = e.target;
    setPassword((password) => ({ ...password, [name]: value }));
  
  };
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      Object.values(userGarantie).filter((el) => !el).length >= 2 ||
      !selectTypeUserOption?.value ||
      !selectProfileOption?.value
    ) {
      console.log(Object.values(userGarantie).filter((el) => !el).length);
      console.log(userGarantie);
      toast.warn("Prière de renseigner tous les champs obligatoires");
      return;
    }
    console.log(userGarantie);
    let email = userGarantie.email;
    let broker = selectCourtierOption?.value;
    let insurance = selectAssuranceOption?.value;
    let profile = selectProfileOption?.value;
    let idType = selectTypeUserOption?.value;
    let distribitor = selectDistributorOption?.value;
    setMailInscription((mailInscription) => ({
      ...mailInscription,
      sendTo: email,
    }));
    setSubmitted(true);

    const res = await userGarantieService.createUser({
      ...userGarantie,
      idType: idType,
      idprofile: profile,
      idbroker: broker,
      idinsurance: insurance,
      iddisributor: distribitor,
      password: password.password ? password.password : userGarantie.password,
      isDeleted: 0,
    });
    // await userGarantieService.sendMail({ ...mailInscription, sendTo: email });

    if (res.status === 200) {
      // distributorService.sendMail({
      //   login: email,
      //   password: password ? password : userGarantie.password,
      //   sendTo: email,
      //   text: "Bienvenue chez Garanty",
      // });
      toast.success("Opération terminée avec succès");
      history.push("/gest_utilisateur/utilisateurs");
    }
  };

  if (selectTypeUserOption?.label === "Assurance ") {
    return (
      <Fragment>
        <Breadcrumb
          parent="Gestion des Utilisateurs / Utilisateur"
          title="Nouvel utilisateur"
        />
        <Container fluid={true}>
          <Form
            className="needs-validation"
            noValidate=""
            onSubmit={handleSubmit}
          >
            <Row>
              <Col>
                <Card className="shadow-lg p-0 shadow-showcase">
                  <CardHeader className="b-l-primary pb-2">
                    <h5>Détails du compte</h5>
                  </CardHeader>
                  <CardBody className="pt-1">
                    <div className="form-row">
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom02">
                          Type Utilisateur
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Select
                          value={selectTypeUserOption}
                          onChange={onchangeSelectTypeUser}
                          options={typeUserOption}
                        />
                      </Col>

                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom02">
                          Assurance
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Select
                          value={selectAssuranceOption}
                          onChange={onchangeSelectAssurance}
                          options={assuranceOption}
                        />
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom02">
                          Profil
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Select
                          value={selectProfileOption}
                          onChange={onchangeSelectProfile}
                          options={profileOption}
                        />
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom01">
                          Nom
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          className="form-control"
                          name="firstName"
                          type="text"
                          placeholder="Nom"
                          value={userGarantie.firstName}
                          onChange={handleChange}
                        />
                        <span></span>
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom01">
                          Prénom
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          className="form-control"
                          name="lastName"
                          type="text"
                          placeholder="Prénom"
                          value={userGarantie.lastName}
                          onChange={handleChange}
                        />
                        <span></span>
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom01">
                          Mobile
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          className="form-control"
                          name="phone"
                          type="text"
                          placeholder="Mobile"
                          value={userGarantie.phone}
                          onChange={handleChange}
                        />
                        <span></span>
                      </Col>
                      <Col md="4 mb-3">
                        <div
                          class="form-check form-switch"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3%",
                          }}
                        >
                          <input
                            class="form-check-input"
                            style={{ marginTop: "7%" }}
                            name="isActive"
                            type="checkbox"
                            id="flexSwitchCheckDefault"
                            checked={checked}
                            value="0"
                            onClick={(e) =>
                              checked
                                ? setChecked(false) & handleChange(e)
                                : setChecked(true) &
                                  setUserGarantie((userGarantie) => ({
                                    ...userGarantie,
                                    isActive: 1,
                                  }))
                            }
                          />
                          <label
                            class="form-check-label"
                            for="flexSwitchCheckDefault"
                          >
                            Actif
                          </label>
                        </div>
                      </Col>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col>
                <Card className="shadow-lg p-0 shadow-showcase">
                  <CardHeader className="b-l-secondary pb-2">
                    <h5> Détails de connexion</h5>
                  </CardHeader>
                  <CardBody className="pt-1">
                    <div className="form-row">
                      <Col md="3 mb-2">
                        <Label htmlFor="validationCustom01">E-mail</Label>
                        <span style={{ color: "red" }}>*</span>
                        <Input
                          className="form-control"
                          name="email"
                          type="text"
                          placeholder="E-mail"
                          value={userGarantie.email}
                          onChange={handleChange}
                          required
                        />

                        <div className="valid-feedback">Looks good!</div>
                      </Col>
                      <Col md="3 mb-2">
                        <Label htmlFor="validationCustom02">Mot de passe</Label>
                        <span style={{ color: "red" }}>*</span>
                        <Input
                          className="form-control"
                          name="password"
                          type={show ? "text" : "password"}
                          placeholder="password"
                          value={password.password ? password.password : userGarantie.password}
                          onChange={handleChangepass2}
                          required
                        />

                        <div className="valid-feedback">Looks good!</div>
                      </Col>
                      <Col md="3 mb-2">
                        <Label htmlFor="validationCustom02">confirmation</Label>
                        <span style={{ color: "red" }}>*</span>
                        <Input
                          className="form-control"
                          name="confPassword"
                          type={show ? "text" : "password"}
                          placeholder="confirm Password"
                          value={confirmPassword.confPassword ? confirmPassword.confPassword : confirmPass.confPassword}
                          onChange={handleChangepass}
                          required
                        />
                      </Col>
                      <Col md="1 m-auto">
                        { show ?  
                        <Eye 
                          style={{marginTop:"20px"}}
                          onClick={()=>{
                            setShow(!show)
                          }}

                        /> :
                        <EyeOff 
                          style={{marginTop:"20px"}}
                          onClick={()=>{
                            setShow(!show)
                          }}
                        />}
                      </Col>
                      <Col md="1 m-auto">
                        <Button
                          className="btn-pill btn-air-primary float-right"
                          color="primary"
                          onClick={() => generaetePassword()}
                        >
                          Générer
                        </Button>
                      </Col>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col>
                <Button
                  color="danger"
                  type="reset"
                  className="float-left"
                  onClick={() => history.push("/gest_utilisateur/utilisateurs")}
                >
                  Retour
                </Button>
              </Col>
              <Col>
                <Button color="primary" type="submit" className="float-right">
                  Valider
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
      </Fragment>
    );
  } else if (selectTypeUserOption?.label === "Courtier ") {
    console.log(selectTypeUserOption);
    return (
      <Fragment>
        <Breadcrumb
          parent="Gestion des Utilisateurs / Utilisateur"
          title="Nouvel utilisateur"
        />
        <Container fluid={true}>
          <Form
            className="needs-validation"
            noValidate=""
            onSubmit={handleSubmit}
          >
            <Row>
              <Col>
                <Card className="shadow-lg p-0 shadow-showcase">
                  <CardHeader className="b-l-primary pb-2">
                    <h5>Détails du compte</h5>
                  </CardHeader>
                  <CardBody className="pt-1">
                    <div className="form-row">
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom02">
                          Type Utilisateur
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Select
                          value={selectTypeUserOption}
                          onChange={onchangeSelectTypeUser}
                          options={typeUserOption}
                        />
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom02">
                          Courtier
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Select
                          value={selectCourtierOption}
                          onChange={onchangeSelectCourtier}
                          options={courtierOption}
                        />
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom02">
                          Profil
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Select
                          value={selectProfileOption}
                          onChange={onchangeSelectProfile}
                          options={profileOption}
                        />
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom01">
                          Nom
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          className="form-control"
                          name="firstName"
                          type="text"
                          placeholder="Nom"
                          value={userGarantie.firstName}
                          onChange={handleChange}
                        />
                        <span></span>
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom01">
                          Prénom
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          className="form-control"
                          name="lastName"
                          type="text"
                          placeholder="Prénom"
                          value={userGarantie.lastName}
                          onChange={handleChange}
                        />
                        <span></span>
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom01">
                          Mobile
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          className="form-control"
                          name="phone"
                          type="text"
                          placeholder="Mobile"
                          value={userGarantie.phone}
                          onChange={handleChange}
                        />
                        <span></span>
                      </Col>
                      <Col md="4 mb-3">
                        <div
                          class="form-check form-switch"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3%",
                          }}
                        >
                          <input
                            class="form-check-input"
                            style={{ marginTop: "7%" }}
                            type="checkbox"
                            id="flexSwitchCheckDefault"
                            checked={checked}
                            value="0"
                            onClick={(e) =>
                              checked
                                ? setChecked(false) & handleChange(e)
                                : setChecked(true) &
                                  setUserGarantie((userGarantie) => ({
                                    ...userGarantie,
                                    isActive: 1,
                                  }))
                            }
                          />
                          <label
                            class="form-check-label"
                            for="flexSwitchCheckDefault"
                          >
                            Actif
                          </label>
                        </div>
                      </Col>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col>
                <Card className="shadow-lg p-0 shadow-showcase">
                  <CardHeader className="b-l-secondary pb-2">
                    <h5> Détails de connexion</h5>
                  </CardHeader>
                  <CardBody className="pt-1">
                    <div className="form-row">
                      <Col md="3 mb-2">
                        <Label htmlFor="validationCustom01">E-mail</Label>
                        <span style={{ color: "red" }}>*</span>
                        <Input
                          className="form-control"
                          name="email"
                          type="text"
                          placeholder="E-mail"
                          value={userGarantie.email}
                          onChange={handleChange}
                          required
                        />

                        <div className="valid-feedback">Looks good!</div>
                      </Col>
                      <Col md="3 mb-2">
                        <Label htmlFor="validationCustom02">Mot de passe</Label>
                        <span style={{ color: "red" }}>*</span>
                        <Input
                          className="form-control"
                          name="password"
                          type={show ? "text" : "password"}
                          placeholder="password"
                          value={password.password ? password.password : userGarantie.password}
                          onChange={handleChangepass2}
                          required
                        />

                        <div className="valid-feedback">Looks good!</div>
                      </Col>
                      <Col md="3 mb-2">
                        <Label htmlFor="validationCustom02">confirmation</Label>
                        <span style={{ color: "red" }}>*</span>
                        <Input
                          className="form-control"
                          name="confPassword"
                          type={show ? "text" : "password"}
                          placeholder="confirm Password"
                          value={confirmPassword.confPassword ? confirmPassword.confPassword : confirmPass.confPassword}
                          onChange={handleChangepass}
                          required
                        />
                      </Col>
                      <Col md="1 m-auto">
                        { show ?  
                        <Eye 
                          style={{marginTop:"20px"}}
                          onClick={()=>{
                            setShow(!show)
                          }}

                        /> :
                        <EyeOff 
                          style={{marginTop:"20px"}}
                          onClick={()=>{
                            setShow(!show)
                          }}
                        />}
                      </Col>
                      <Col md="1 m-auto">
                        <Button
                          className="btn-pill btn-air-primary float-right"
                          color="primary"
                          onClick={() => generaetePassword()}
                        >
                          Générer
                        </Button>
                      </Col>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col>
                <Button
                  color="danger"
                  type="reset"
                  className="float-left"
                  onClick={() => history.push("/gest_utilisateur/utilisateurs")}
                >
                  Retour
                </Button>
              </Col>
              <Col>
                <Button color="primary" type="submit" className="float-right">
                  Valider
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
      </Fragment>
    );
  } else if (selectTypeUserOption?.label === "Distributeur") {
    console.log(selectTypeUserOption);
    return (
      <Fragment>
        <Breadcrumb
          parent="Gestion des Utilisateurs / Utilisateur"
          title="Nouvel utilisateur"
        />
        <Container fluid={true}>
          <Form
            className="needs-validation"
            noValidate=""
            onSubmit={handleSubmit}
          >
            <Row>
              <Col>
                <Card className="shadow-lg p-0 shadow-showcase">
                  <CardHeader className="b-l-primary pb-2">
                    <h5>Détails du compte</h5>
                  </CardHeader>
                  <CardBody className="pt-1">
                    <div className="form-row">
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom02">
                          Type Utilisateur
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Select
                          value={selectTypeUserOption}
                          onChange={onchangeSelectTypeUser}
                          options={typeUserOption}
                        />
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom02">
                          Revendeur
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Select
                          value={selectDistributorOption}
                          onChange={onchangeSelectDistributorr}
                          options={distributorOption}
                        />
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom02">
                          Profil
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Select
                          value={selectProfileOption}
                          onChange={onchangeSelectProfile}
                          options={profileOption}
                        />
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom01">
                          Nom
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          className="form-control"
                          name="firstName"
                          type="text"
                          placeholder="Nom"
                          value={userGarantie.firstName}
                          onChange={handleChange}
                        />
                        <span></span>
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom01">
                          Prénom
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          className="form-control"
                          name="lastName"
                          type="text"
                          placeholder="Prénom"
                          value={userGarantie.lastName}
                          onChange={handleChange}
                        />
                        <span></span>
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom01">
                          Mobile
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          className="form-control"
                          name="phone"
                          type="text"
                          placeholder="Mobile"
                          value={userGarantie.phone}
                          onChange={handleChange}
                        />
                        <span></span>
                      </Col>
                      <Col md="4 mb-3">
                        <div
                          class="form-check form-switch"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3%",
                          }}
                        >
                          <input
                            class="form-check-input"
                            style={{ marginTop: "7%" }}
                            type="checkbox"
                            id="flexSwitchCheckDefault"
                            checked={checked}
                            value="0"
                            onClick={(e) =>
                              checked
                                ? setChecked(false) & handleChange(e)
                                : setChecked(true) &
                                  setUserGarantie((userGarantie) => ({
                                    ...userGarantie,
                                    isActive: 1,
                                  }))
                            }
                          />
                          <label
                            class="form-check-label"
                            for="flexSwitchCheckDefault"
                          >
                            Actif
                          </label>
                        </div>
                      </Col>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col>
                <Card className="shadow-lg p-0 shadow-showcase">
                  <CardHeader className="b-l-secondary pb-2">
                    <h5> Détails de connexion</h5>
                  </CardHeader>
                  <CardBody className="pt-1">
                    <div className="form-row">
                      <Col md="3 mb-2">
                        <Label htmlFor="validationCustom01">E-mail</Label>
                        <span style={{ color: "red" }}>*</span>
                        <Input
                          className="form-control"
                          name="email"
                          type="text"
                          placeholder="E-mail"
                          innerRef=""
                          value={userGarantie.email}
                          onChange={handleChange}
                          required
                        />

                        <div className="valid-feedback">Looks good!</div>
                      </Col>
                      <Col md="3 mb-2">
                        <Label htmlFor="validationCustom02">Mot de passe</Label>
                        <span style={{ color: "red" }}>*</span>
                        <Input
                          className="form-control"
                          name="password"
                          type={show ? "text" : "password"}
                          placeholder="password"
                          value={password.password ? password.password : userGarantie.password}
                          onChange={handleChangepass2}
                          required
                        />

                        <div className="valid-feedback">Looks good!</div>
                      </Col>
                      <Col md="3 mb-2">
                        <Label htmlFor="validationCustom02">confirmation</Label>
                        <span style={{ color: "red" }}>*</span>
                        <Input
                          className="form-control"
                          name="confPassword"
                          type={show ? "text" : "password"}
                          placeholder="confirm Password"
                          value={confirmPassword.confPassword ? confirmPassword.confPassword : confirmPass.confPassword}
                          onChange={handleChangepass}
                          required
                        />
                      </Col>
                      <Col md="1 m-auto">
                        { show ?  
                        <Eye 
                          style={{marginTop:"20px"}}
                          onClick={()=>{
                            setShow(!show)
                          }}

                        /> :
                        <EyeOff 
                          style={{marginTop:"20px"}}
                          onClick={()=>{
                            setShow(!show)
                          }}
                        />}
                      </Col>
                      <Col md="1 m-auto">
                        <Button
                          className="btn-pill btn-air-primary float-right"
                          color="primary"
                          onClick={() => generaetePassword()}
                        >
                          Générer
                        </Button>
                      </Col>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col>
                <Button
                  color="danger"
                  type="reset"
                  className="float-left"
                  onClick={() => history.push("/gest_utilisateur/utilisateurs")}
                >
                  Retour
                </Button>
              </Col>
              <Col>
                <Button color="primary" type="submit" className="float-right">
                  Valider
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Breadcrumb
          parent="Gestion des Utilisateurs / Utilisateur"
          title="Nouvel utilisateur"
        />
        <Container fluid={true}>
          <Form
            className="needs-validation"
            noValidate=""
            onSubmit={handleSubmit}
          >
            <Row>
              <Col>
                <Card className="shadow-lg p-0 shadow-showcase">
                  <CardHeader className="b-l-primary pb-2">
                    <h5>Détails du compte</h5>
                  </CardHeader>
                  <CardBody className="pt-1">
                    <div className="form-row">
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom02">
                          Type Utilisateur
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Select
                          value={selectTypeUserOption}
                          onChange={onchangeSelectTypeUser}
                          options={typeUserOption}
                        />
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom02">
                          Profil
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Select
                          value={selectProfileOption}
                          onChange={onchangeSelectProfile}
                          options={profileOption}
                        />
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom01">
                          Nom
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          className="form-control"
                          name="firstName"
                          type="text"
                          placeholder="Nom"
                          innerRef=""
                          value={userGarantie.firstName}
                          onChange={handleChange}
                          required
                        />
                        <span></span>
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom01">
                          Prénom
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          className="form-control"
                          name="lastName"
                          type="text"
                          placeholder="Prénom"
                          innerRef=""
                          value={userGarantie.lastName}
                          onChange={handleChange}
                          required
                        />
                        <span></span>
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom01">
                          Mobile
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          className="form-control"
                          name="phone"
                          type="text"
                          placeholder="Mobile"
                          innerRef=""
                          value={userGarantie.phone}
                          onChange={handleChange}
                          required
                        />
                        <span></span>
                      </Col>
                      <Col md="4 mb-3">
                        <div
                          class="form-check form-switch"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3%",
                          }}
                        >
                          <input
                            class="form-check-input"
                            style={{ marginTop: "7%" }}
                            type="checkbox"
                            id="flexSwitchCheckDefault"
                            checked={checked}
                            value="0"
                            onClick={(e) =>
                              checked
                                ? setChecked(false) & handleChange(e)
                                : setChecked(true) &
                                  setUserGarantie((userGarantie) => ({
                                    ...userGarantie,
                                    isActive: 1,
                                  }))
                            }
                          />
                          <label
                            class="form-check-label"
                            for="flexSwitchCheckDefault"
                          >
                            Actif
                          </label>
                        </div>
                      </Col>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col>
                <Card className="shadow-lg p-0 shadow-showcase">
                  <CardHeader className="b-l-secondary pb-2">
                    <h5> Détails de connexion</h5>
                  </CardHeader>
                  <CardBody className="pt-1">
                    <div className="form-row">
                      <Col md="3 mb-2">
                        <Label htmlFor="validationCustom01">E-mail</Label>
                        <span style={{ color: "red" }}>*</span>
                        <Input
                          className="form-control"
                          name="email"
                          type="text"
                          placeholder="E-mail"
                          innerRef=""
                          value={userGarantie.email}
                          onChange={handleChange}
                          required
                        />

                        <div className="valid-feedback">Looks good!</div>
                      </Col>
                      <Col md="3 mb-2">
                        <Label htmlFor="validationCustom02">Mot de passe</Label>
                        <span style={{ color: "red" }}>*</span>
                        <Input
                          className="form-control"
                          name="password"
                          type={show ? "text" : "password"}
                          placeholder="password"
                          value={password.password ? password.password : userGarantie.password}
                          onChange={handleChangepass2}
                          required
                        />

                        <div className="valid-feedback">Looks good!</div>
                      </Col>
                      <Col md="3 mb-2">
                        <Label htmlFor="validationCustom02">confirmation</Label>
                        <span style={{ color: "red" }}>*</span>
                        <Input
                          className="form-control"
                          name="confPassword"
                          type={show ? "text" : "password"}
                          placeholder="confirm Password"
                          value={confirmPassword.confPassword ? confirmPassword.confPassword : confirmPass.confPassword}
                          onChange={handleChangepass}
                          required
                        />
                      </Col>
                      <Col md="1 m-auto">
                        { show ?  
                        <Eye 
                          style={{marginTop:"20px"}}
                          onClick={()=>{
                            setShow(!show)
                          }}

                        /> :
                        <EyeOff 
                          style={{marginTop:"20px"}}
                          onClick={()=>{
                            setShow(!show)
                          }}
                        />}
                      </Col>
                      <Col md="1 m-auto">
                        <Button
                          className="btn-pill btn-air-primary float-right"
                          color="primary"
                          onClick={() => generaetePassword()}
                        >
                          Générer
                        </Button>
                      </Col>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col>
                <Button
                  color="danger"
                  type="reset"
                  className="float-left"
                  onClick={() => history.push("/gest_utilisateur/utilisateurs")}
                >
                  Retour
                </Button>
              </Col>
              <Col>
                <Button color="primary" type="submit" className="float-right">
                  Valider
                </Button>
              </Col>
            </Row>
            {/* <Button color="primary">{config.button}</Button>
                  <Button color="primary">{config.button}</Button> */}
          </Form>
        </Container>
      </Fragment>
    );
  }
};

export default CreateUserGaranty;
