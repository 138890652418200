import React from "react";
import { Row, Col } from "reactstrap";
export const DefaultPearlsSteps = (props) => {
  return (
    <Row>
      <Col className="u-pearl done col-6">
        <span className="u-pearl-number">1</span>
        <span className="u-pearl-title">Vos données personnelles</span>
      </Col>
      <Col className="u-pearl current col-6">
        <span className="u-pearl-number">2</span>
        <span className="u-pearl-title">Votre mot de passe</span>
      </Col>
     
    </Row>
  );
};
