import { adminAxios } from "../../config/axiosConfig/InstancesAxios";

export default {
  getuserbyid: (id) => {
    return adminAxios.post("/getuserbyid", id);
  },
  getAllUsers: (uid) => {
    return adminAxios.post("/getAllUsers", null, { params: { uid } });
  },

  createUser: (Model) => {
    return adminAxios.post("/createUser", Model);
  },

  updateUser: (Model) => {
    return adminAxios.post("/updateUser", Model);
  },

  sendMail: (Model) => {
    return adminAxios.post("/sendMail", Model);
  },
  getAllusersbyDistributor: (iddistributor) => {
    return adminAxios.post("/getAllusersbyDistributor", null, {
      params: { iddistributor },
    });
  },
  deleteUser: (iduser) => {
    return adminAxios.post("/deleteUser", null, { params: { iduser } });
  },
};
