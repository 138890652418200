import React, { useState, useEffect, Fragment } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  Media,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  ButtonGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import usersService from "../../../services/users/usersService";
import { saveUser } from "../../../redux/users/usersActions";
import StepZilla from "react-stepzilla";
import { useHistory } from "react-router-dom";
import Dropzone from "react-dropzone-uploader";
import { findLastIndex } from "lodash";
import { DefaultPearlsSteps } from "./steps2";
import { useDispatch, useSelector } from "react-redux";
const defaultparlssteps = [
  { name: "Step 6", component: <DefaultPearlsSteps /> }
];
const generator = require("generate-password");

const UpdateUser2 = ({ location, i18n }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [submitted, setSubmitted] = useState(false);

  const [confirmPass, setConfirmPass] = useState(false);

  const [mailInscription, setMailInscription] = useState({
    sendTo: "",
    subject: "Inscription au Garanty",
    text: "",
    type: "inscription"
  });
  const [password, setPassword] = useState([]);
  const [confirmPassword, setConfirmPassword] = useState([]);
  const user = useSelector(({ usersReducers }) => usersReducers.user);
  const [user1, setUser1] = useState(user);
  const code = generator.generate({
    length: 5,
    numbers: true
  });

  const generaetePassword = () => {
    var genereatedPass = generator.generate({
      length: 10,
      numbers: true
    });
    setConfirmPass(false);
    setPassword(genereatedPass);
    setConfirmPassword(genereatedPass);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser1((user1) => ({ ...user1, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = user1.email;
    setSubmitted(true);
    await setMailInscription((mailInscription) => ({
      ...mailInscription,
      sendTo: email
    }));
    await usersService
      .UpdateUser({ ...user1, password: password, codeVerification: code })
      .then((resp) => {
        const User = resp.data;
        dispatch(saveUser(User));
      });
    // await usersService.sendMail({ ...mailInscription, sendTo: email });
    history.push("/gest_client_contrat/clients");
  };
  return (
    <Fragment>
      <Breadcrumb parent="Client" title="Modifier Client" />
      <Container fluid={true}>
        <Col sm="12">
          <Card>
            <CardHeader>
              <h5 style={{ textAlign: "center" }}>
                Créer votre compte Garanty en 3 étapes !
              </h5>
            </CardHeader>
            <CardBody>
              <StepZilla
                steps={defaultparlssteps}
                showSteps={false}
                showNavigation={false}
              />
            </CardBody>
          </Card>
        </Col>

        <Form
          className="needs-validation"
          noValidate=""
          onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Card className="shadow-lg p-0 shadow-showcase">
                <CardHeader className="b-l-primary pb-2">
                  {/* <h5>Info Generale</h5> */}
                </CardHeader>
                <CardBody className="pt-1">
                  <div className="form-row">
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom01">
                        Mot de passe
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="password"
                        type="text"
                        placeholder="mot de passe"
                        defaultValue={password}
                        onChange={handleChange}
                      />
                      <span></span>
                    </Col>
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom01">
                        Confirmez votre mot de passe
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="confirmPassword"
                        type="text"
                        defaultValue={confirmPassword}
                        placeholder="confirme mot de passe"
                        required="true"
                      />
                      <span></span>
                    </Col>
                    <Col md="3 m-auto">
                      <Button
                        className="btn-pill btn-air-primary float-right"
                        color="primary"
                        onClick={() => generaetePassword()}>
                        generatePassword
                      </Button>
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Button
                color="danger"
                type="reset"
                className="float-left"
                onClick={() =>
                  history.push("/gest_client_contrat/clients/new_client")
                }>
                Retour
              </Button>
            </Col>
            <Col>
              <Button color="primary" className="float-right" type="submit">
                Valider
              </Button>
            </Col>
          </Row>
          {/* <Button color="primary">{config.button}</Button>
                  <Button color="primary">{config.button}</Button> */}
        </Form>
      </Container>
    </Fragment>
  );
};

export default UpdateUser2;
