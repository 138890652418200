import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import contractServices from "../../services/contract/contractServices";
import IconButton from "@material-ui/core/IconButton";
import Breadcrumb from "../../layout/breadcrumb";
import moment from "moment";
const Historique = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [contractList, setContractList] = useState();
  const [handleFilter, setHandleFilter] = useState(true);
  const [optionFilter, setListOptionFilter] = useState([
    { label: "Cin", value: "cin" },
    { label: "Num Contart", value: "numContract" },
    { label: "Famille", value: "famille" },
    { label: "Nom", value: "lastName" },
    { label: "Prénom", value: "firstName" },
    { label: "Formule", value: "formula" },
    { label: "Date de début", value: "dateDebut" },
    { label: "Date de fin", value: "dateFin" },
    { label: "Tax", value: "tax" },
  ]);
  const [selectOptionFilter, setOptionSelectedFilter] = useState(null);
  useEffect(() => {
    if(localStorage.getItem("role") !== "Distributeur"){
      history.goBack()
    }
    const id = localStorage.getItem("idDist");
    contractServices.getAllContractbyDisributor(id).then((res) => {
      setContractList(res.data);
    });
  }, []);
  const onChangeSelect = (item) => {
    setOptionSelectedFilter(item);
  };
  const columns = [
    {
      name: "N° Contrat",
      selector: "num",
      sortable: true,
    },
    {
      name: "Nom",
      selector: "customer.lastName",
      sortable: true,
    },
    {
      name: "Prénom",
      selector: "customer.firstName",
      sortable: true,
    },
    {
      name: "N° Cin",
      selector: "customer.cin",
      sortable: true,
    },
    {
      name: "Email",
      selector: "customer.email",
      sortable: true,
    },
    {
      name: "Téléphone",
      selector: "customer.phone",
      sortable: true,
    },
    {
      name: "Date Début",
      selector: (row) => `${moment(row.startDate).format("DD/MM/yyyy")}`,
      sortable: true,
    },
    {
      name: "Date Fin",
      selector: (row) => `${moment(row.endDate).format("DD/MM/yyyy")}`,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <IconButton
            color="primary"
            onClick={() => {
              console.log(row);
              history.push({
                pathname: "/vueContrat",
                state: { row },
              });
            }}
          >
            <i className="icofont icofont-eye-alt"></i>
          </IconButton>
        </div>
      ),

      button: true,
    },
  ];

  return (
    <div>
      <Breadcrumb parent={"Historique"} title={"Liste Des Contrats"} />
      <Container fluid={true}>
        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader
            className="ml-2"
            style={{ cursor: "pointer", padding: "10px" }}
            onClick={() => {
              setHandleFilter(!handleFilter);
            }}
          >
            <span className="float-left">
              <h6 className="mt-1">{"filter"}</h6>
            </span>

            <span className="float-right ">
              <h4>
                {handleFilter ? (
                  <i className="fa fa-angle-down"></i>
                ) : (
                  <i className="fa fa-angle-up"></i>
                )}
              </h4>
            </span>
          </CardHeader>

          <CardBody className="pt-0" hidden={handleFilter}>
            <Form className="theme-form">
              <Row>
                <Col md="4 mb-4">
                  <Select
                    value={selectOptionFilter}
                    onChange={onChangeSelect}
                    options={optionFilter}
                  />
                </Col>
                <Col md="4 mb-4">
                  <Input
                    className="form-control"
                    type="text"
                    onChange=""
                    value=""
                  />
                </Col>
              </Row>
            </Form>
            <Button
              className="btn-pill btn-air-success"
              color="success"
              onClick=""
            >
              Recherche
            </Button>
          </CardBody>

          <CardBody className="pt-2">
            <DataTable
              noHeader
              pagination
              paginationComponentOptions={{
                rowsPerPageText: "Ligne Par Page",
                rangeSeparatorText: "/",
                selectAllRowsItem: true,
                selectAllRowsItemText: "all",
              }}
              columns={columns}
              data={contractList || []}
              noDataComponent={<div>Aucun enregistrement à afficher</div>}
              pointerOnHover={true}
              progressPending=""
              progressComponent={
                <div className="loader-box">
                  <div className="loader-1"></div>
                </div>
              }
            ></DataTable>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default Historique;
