import { adminAxios } from "../../config/axiosConfig/InstancesAxios";

export default {
  getAllBreakdownType: () => {
    return adminAxios.post("/getAllBreakDownType", {});
  },

  createNewBreakdownType: (breakdownTypeModel) => {
    return adminAxios.post("/createBreakDownType", breakdownTypeModel);
  },

  getAllBreakdownTypeByFamily: (idfamily) => {
    return adminAxios.post("/getAllBreakDownTypebyFamily/", null, {
      params: {
        idfamily
      }
    });
  }
};
