import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import IconButton from "@material-ui/core/IconButton";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Badge,
} from "reactstrap";
import Breadcrumb from "../../../layout/breadcrumb";
import { useHistory } from "react-router-dom";
import SweetAlert from "sweetalert2";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";
import { fetchFamily } from "../../../redux/family/familyActions";
import BudgeComponent from "../../../components/BudgeComponent";

const FamilyList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const listFamily = useSelector(
    ({ familyReducers }) => familyReducers.familys
  );
  const profil = localStorage.getItem("profil");
  useEffect(() => {
    dispatch(fetchFamily());
  }, []);

  const columns = [
    {
      name: "Famille",
      selector: "familyName",
      sortable: true,
    },
    // {
    //   name: "Description",
    //   selector: "description",
    //   sortable: true,
    // },
    {
      name: "Actif",
      cell: (row) => (
        <BudgeComponent status={row.isenabled} />

        // <div class="form-check form-switch">
        //   <input
        //     class="form-check-input"
        //     name="isActive"
        //     type="checkbox"
        //     id={row.iduserG}
        //     // checked={checked}
        //     value="0"
        //     // onClick={() => setChecked(false) & console.log(row,"---",checked) }
        //   />
        // </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          {profil === "User" ? (
            <IconButton
              color="primary"
              onClick={() => {
                history.push({
                  pathname: "/gest_fromule_product/familles/update_family",
                  state: { row },
                });
              }}
            >
              <i className="icofont icofont-eye-alt"></i>
            </IconButton>
          ) : (
            <>
              <IconButton
                color="primary"
                onClick={() => {
                  console.log(row);
                  history.push({
                    pathname: "/gest_fromule_product/familles/update_family",
                    state: { row, update: true },
                  });
                }}
              >
                <i className="icofont icofont-ui-edit"></i>
              </IconButton>
              <IconButton
                color="primary"
                onClick={() => {
                  history.push({
                    pathname: "/gest_fromule_product/familles/update_family",
                    state: { row },
                  });
                }}
              >
                <i className="icofont icofont-eye-alt"></i>
              </IconButton>
            </>
          )}
        </div>
      ),

      button: true,
    },
  ];

  return (
    <div>
      <Breadcrumb parent={"Familles"} title={" Liste des Familles"} />
      <Container fluid={true}>
        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader
            className="ml-2"
            style={{ cursor: "pointer", padding: "10px" }}
            onClick={() => {}}
          >
            <span className="float-left">
              <h6 className="mt-1">{"Filtrer"}</h6>
            </span>

            <span className="float-right ">
              <h4>
                <i className="fa fa-angle-down"></i> :{" "}
                <i className="fa fa-angle-up"></i>
              </h4>
            </span>
          </CardHeader>

          <CardBody className="pt-0" hidden="">
            <Form className="theme-form">
              <Row>
                <Col md="12 mb-2">
                  <FormGroup>
                    <Label className="col-form-label ">Famille</Label>
                    <Input
                      className="form-control"
                      type="text"
                      onChange=""
                      value=""
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
            <Button
              className="btn-pill btn-air-success"
              color="success"
              onClick=""
            >
              Recherche
            </Button>
          </CardBody>
        </Card>

        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader className="m-0  align-button ">
            <Row>
              <Col className="text-right">
                <span>
                  {profil === "User" ? (
                    <></>
                  ) : (
                    <Button
                      className="btn-pill btn-air-primary "
                      color="primary"
                      onClick={() => {
                        history.push(
                          "/gest_fromule_product/familles/new_family"
                        );
                      }}
                    >
                      + {"Nouvelle Famille"}
                    </Button>
                  )}
                </span>
              </Col>
            </Row>
          </CardHeader>

          <CardBody className="pt-2">
            <DataTable
              noHeader
              pagination
              paginationComponentOptions={{
                rowsPerPageText: "Afficher",
                rangeSeparatorText: "/",
                selectAllRowsItem: true,
                selectAllRowsItemText: "all",
              }}
              columns={columns}
              data={listFamily || []}
              pointerOnHover={true}
              progressPending=""
              progressComponent={
                <div className="loader-box">
                  <div className="loader-1"></div>
                </div>
              }
            ></DataTable>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default FamilyList;
