import {
  FETCH_COURTIERS_REQUEST,
  FETCH_COURTIERS_SUCCESS,
  FETCH_COURTIERS_FAILURE,
  ADD_COURTIERS_REQUEST,
  ADD_COURTIERS_SUCCESS,
  ADD_COURTIERS_FAILURE
} from "./courtierActions";

const initialState = {
  loading: false,
  courtiers: [],
  error: ""
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COURTIERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_COURTIERS_SUCCESS:
      return {
        loading: false,
        courtiers: action.payload,
        error: ""
      };
    case FETCH_COURTIERS_FAILURE:
      return {
        loading: false,
        courtiers: [],
        error: action.payload
      };

    case ADD_COURTIERS_REQUEST:
      return {
        registering: true
      };
    case ADD_COURTIERS_SUCCESS:
      return {
        loading: false,
        courtier: [...(state.courtier || []) , action.payload ],
        error: ""
      };
    case ADD_COURTIERS_FAILURE:
      return {
        loading: false,
        courtier: [],
        error: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
