import {combineReducers} from 'redux'
import Customizer from './customizer/reducer'
import familyReducers from './family/familyReducers'
import categoryReducer from './category/categoryReducer'
import productReducers from './product/productReducers'
import courtierReducers from './courtier/courtierReducers'
import breakdownTypeReducers from './breakdownType/breakdownTypeReducers'
import formulaReducers from './formula/formulaReducers'
import distributorReducers from './revendeur/distributorReducers'
import assuranceReducers from './assurance/assuranceReducers'
import profileReducers from './profile/profileReducers'
import typeUserReducers from './typeUser/typeUserReducers'
import userGarantieReducers from './userGarantie/userGarantieReducers'
import usersReducers from './users/usersReducers'
const reducers = combineReducers({
    Customizer,
    familyReducers,
    categoryReducer,
    productReducers,
    courtierReducers,
    breakdownTypeReducers,
    formulaReducers,
    distributorReducers,
    assuranceReducers,
    profileReducers,
    typeUserReducers,
    userGarantieReducers,
    usersReducers
});

export default reducers;