// dashbaord
import Default from "../components/dashboard/default";
import Ecommerce from "../components/dashboard/ecommerce";
//
import FamilyList from "../pages/views/family/FamilysList";
import CategorieList from "../pages/views/categories/CategorisList";
import FormulaList from "../pages/views/formula/FormulaList";
import ProductList from "../pages/views/products/ProductsList";
import BreakdownTypeList from "../pages/views/breakdown type/BreakdownTypeList";
import CreateFamily from "../pages/views/family/CreateFamily";
import CreateCategory from "../pages/views/categories/CreateCategory";
import CreateProduct from "../pages/views/products/CreateProduct";
import CreateFormule from "../pages/views/formula/CreateFormule";
import CreateBreakdown from "../pages/views/breakdown type/CreateBreakdown";
import UpdateProduct from "../pages/views/products/UpdateProduct";
import UpdateFormule from "../pages/views/formula/UpdateFormula";
import UpdateFamily from "../pages/views/family/UpdateFamily";
import UpdateCategorie from "../pages/views/categories/UpdateCategorie";
import UpdateBreakdown from "../pages/views/breakdown type/UpdateBreakdown";
//
import UsersList from "../pages/views/users/UsersList";
import ContractList from "../pages/views/contracts/ContractList";
import CreateUser from "../pages/views/users/CreateUser";
import CreateUser2 from "../pages/views/users/CreateUser2";
import CreateContract from "../pages/views/contracts/CreateContract";
import UpdateUser from "../pages/views/users/UpdateUser1";
import UpdateUser2 from "../pages/views/users/UpdateUser2";
import UpdateContrat from "../pages/views/contracts/UpdateContart";
//
import SinisterList from "../pages/views/sinister/SinisterList";
import InterventionList from "../pages/views/intervention/InterventionList";
import CreateIntervention from "../pages/views/intervention/CreateIntervention";
import CreateSinistre from "../pages/views/sinister/CreateSinistre";
import UpdateSinistre from "../pages/views/sinister/UpdateSinistre";
import UpdateIntervention from "../pages/views/intervention/UpdateIntervention";
//
import CourtierList from "../pages/views/courtier/CourtierList";
import ReparateurList from "../pages/views/reparateur/ReparateurList";
import RevendeurList from "../pages/views/revendeur/RevendeurList";
import AssuranceList from "../pages/views/assurance/AssuranceList";
import CreateAssurance from "../pages/views/assurance/CreateAssurance";
import CreateCourtier from "../pages/views/courtier/CreateCourtier";
import CreateReparateur from "../pages/views/reparateur/CreateReparateur";
import CreateRevendeur from "../pages/views/revendeur/CreateRevendeur";
import UpdateRevendeur from "../pages/views/revendeur/UpdateRevendeur";
import UpdateReparateur from "../pages/views/reparateur/UpdateReparateur";
import UpdateCourtier from "../pages/views/courtier/UpdateCourtier";
import UpdateAssurance from "../pages/views/assurance/UpdateAssurance";
//
import ProfileList from "../pages/views/profils/ProfileList";
import UserTypeList from "../pages/views/type_users/UserTypeList";
import UserGarantyList from "../pages/views/User_Garantie/UserGarantyList";
import CreateUserType from "../pages/views/type_users/CreateUserType";
import CreateProfile from "../pages/views/profils/CreateProfile";
import CreateUserGaranty from "../pages/views/User_Garantie/CreateUserGatanty";
import UpdateProfile from "../pages/views/profils/UpdateProfile";
import UpdateUserType from "../pages/views/type_users/UpdateUserType";
import UpdateUserGaranty from "../pages/views/User_Garantie/UpdateUserGaranty";
//
import NewSale from "../pages/distributeur/NewSale";
import CustomerCard from "../pages/distributeur/CustomerCard";
import ProductInformation from "../pages/distributeur/ProductInformation";
import PaymentDetails from "../pages/distributeur/PaymentDetails";
import DownloadDoc from "../pages/distributeur/DownloadDoc";
import Historique from "../pages/distributeur/Historique";
import VueContract from "../pages/distributeur/VueContract";
import Dashboard from "../pages/distributeur/Dashboard";

import DashboardC from "../pages/views/courtier/Dashboard";
import VenteGlobalD from "../pages/distributeur/VenteGlobal";
import VenteParMoisD from "../pages/distributeur/VenteParMois";
import VenteGlobal from "../pages/views/courtier/VenteGlobal";
import VenteParMois from "../pages/views/courtier/VenteParMois";
import DashboardA from "../pages/views/assurance/DashboardA";
import Notice from "../pages/distributeur/Notice";
import Manuel from "../pages/distributeur/Manuel";
import SouDist from "../pages/distributeur/SouDist";
import ListSousDist from "../pages/distributeur/ListSousDist";
import CreateUserDist from "../pages/distributeur/CreateUserDist";
import ListUserDist from "../pages/distributeur/ListUserDist";
import UpdateSousDist from "../pages/distributeur/UpdateSousDist";
import ContactPayment from "../pages/views/contracts/ContactPayment";
import EditAccount from "../pages/views/editAccount/EditAccount";
import ContractInclusionList from "../pages/views/contract_inclusion/ContractInclusionList";
import UsersInclusion from "../pages/views/users_inclusion/UsersInclusion";
import {VoucherList} from "../pages/views/voucher/VoucherList";
import VoucherAdd from "../pages/views/voucher/VoucherAdd";
import SinisterListInclusion from "../pages/views/sinistre_inclusion/SinisterList";
import UpdateSinistreInclusion from "../pages/views/sinistre_inclusion/UpdateSinistre";
export const routes = [
  { path: `${process.env.PUBLIC_URL}/dashboard/default`, Component: Default },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/ecommerce`,
    Component: Ecommerce,
  },
  // Gestion des Utilisateur
  {
    path: `${process.env.PUBLIC_URL}/gest_utilisateur/profils`,
    Component: ProfileList,
  },
  {
    path: `${process.env.PUBLIC_URL}/Userprofil`,
    Component: EditAccount,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_utilisateur/user_type`,
    Component: UserTypeList,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_utilisateur/utilisateurs`,
    Component: UserGarantyList,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_utilisateur/profils/new_profile`,
    Component: CreateProfile,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_utilisateur/user_type/new_user_type`,
    Component: CreateUserType,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_utilisateur/utilisateurs/new_utilisateur`,
    Component: CreateUserGaranty,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_utilisateur/profils/Update_profile`,
    Component: UpdateProfile,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_utilisateur/user_type/Update_user_type`,
    Component: UpdateUserType,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_utilisateur/utilisateurs/Update_utilisateur`,
    Component: UpdateUserGaranty,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_utilisateur/utilisateurs/utilisateur`,
    Component: UpdateUserGaranty,
  },

  // Gestion des Intervenant
  {
    path: `${process.env.PUBLIC_URL}/gest_intervenant/courtiers`,
    Component: CourtierList,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_intervenant/reparateur`,
    Component: ReparateurList,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_intervenant/revendeurs`,
    Component: RevendeurList,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_intervenant/assurance`,
    Component: AssuranceList,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_intervenant/assurance/new_assurance`,
    Component: CreateAssurance,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_intervenant/courtiers/new_courtier`,
    Component: CreateCourtier,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_intervenant/reparateur/new_reparateur`,
    Component: CreateReparateur,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_intervenant/revendeurs/new_revendeur`,
    Component: CreateRevendeur,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_intervenant/revendeurs/update_revendeur`,
    Component: UpdateRevendeur,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_intervenant/reparateur/update_reparateur`,
    Component: UpdateReparateur,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_intervenant/courtiers/update_courtier`,
    Component: UpdateCourtier,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_intervenant/assurance/update_assurance`,
    Component: UpdateAssurance,
  },
  // Gestion des formules et produit
  {
    path: `${process.env.PUBLIC_URL}/gest_fromule_product/familles`,
    Component: FamilyList,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_fromule_product/Catégories`,
    Component: CategorieList,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_fromule_product/Formules`,
    Component: FormulaList,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_fromule_product/Produits`,
    Component: ProductList,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_fromule_product/Type_de_panne`,
    Component: BreakdownTypeList,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_fromule_product/Produits/new_Product`,
    Component: CreateProduct,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_fromule_product/Formules/new_Formule`,
    Component: CreateFormule,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_fromule_product/familles/new_family`,
    Component: CreateFamily,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_fromule_product/Catégories/new_Category`,
    Component: CreateCategory,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_fromule_product/Type_de_panne/new_BreakdownType`,
    Component: CreateBreakdown,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_fromule_product/Produits/update_Product`,
    Component: UpdateProduct,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_fromule_product/Formules/update_Formule`,
    Component: CreateFormule,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_fromule_product/familles/update_family`,
    Component: UpdateFamily,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_fromule_product/Catégories/update_Category`,
    Component: UpdateCategorie,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_fromule_product/Type_de_panne/Update_BreakdownType`,
    Component: UpdateBreakdown,
  },

  // Gestion des clients et contrats
  {
    path: `${process.env.PUBLIC_URL}/gest_client_contrat/clients`,
    Component: UsersList,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_client_contrat/contrats`,
    Component: ContractList,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_client_contrat/contrats/payment`,
    Component: ContactPayment,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_client_contrat/clients/new_client`,
    Component: CreateUser,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_client_contrat/clients/new_client2`,
    Component: CreateUser2,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_client_contrat/contrats/new_contrat`,
    Component: CreateContract,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_client_contrat/clients/Update_client`,
    Component: UpdateUser,
  },

  {
    path: `${process.env.PUBLIC_URL}/gest_client_contrat/clients/Update_client2`,
    Component: UpdateUser2,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_client_contrat/contrats/Update_contrat`,
    Component: UpdateContrat,
  },

  // Gestion des sinistres
  {
    path: `${process.env.PUBLIC_URL}/gest_sinistre/sinistre`,
    Component: SinisterList,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_sinistre/sinistre_inclusion`,
    Component: SinisterListInclusion,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_sinistre/interventions`,
    Component: InterventionList,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_sinistre/interventions/new_intervention`,
    Component: CreateIntervention,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_sinistre/sinistre/new_sinistre`,
    Component: CreateSinistre,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_sinistre/sinistre/update_sinistre`,
    Component: UpdateSinistre,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_sinistre/sinistre/update_sinistre_inclusion`,
    Component: UpdateSinistreInclusion,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_sinistre/interventions/update_intervention`,
    Component: UpdateIntervention,
  },
  // distributeur route
  {
    path: `${process.env.PUBLIC_URL}/new-sale`,
    Component: NewSale,
  },
  {
    path: `${process.env.PUBLIC_URL}/historique`,
    Component: Historique,
  },
  {
    path: `${process.env.PUBLIC_URL}/fiche-client`,
    Component: CustomerCard,
  },
  {
    path: `${process.env.PUBLIC_URL}/info-product`,
    Component: ProductInformation,
  },
  {
    path: `${process.env.PUBLIC_URL}/detail-paiment`,
    Component: PaymentDetails,
  },
  {
    path: `${process.env.PUBLIC_URL}/document`,
    Component: DownloadDoc,
  },
  {
    path: `${process.env.PUBLIC_URL}/vueContrat`,
    Component: VueContract,
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/distributeur`,
    Component: Dashboard,
  },
  {
    path: `${process.env.PUBLIC_URL}/dist/etat-de-vente/Global`,
    Component: VenteGlobalD,
  },
  // {
  //   path: `${process.env.PUBLIC_URL}/dist/etat-de-vente/par-mois`,
  //   Component: VenteParMoisD,
  // },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/courtier`,
    Component: DashboardA,
  },
  {
    path: `${process.env.PUBLIC_URL}/etat-de-vente/Global`,
    Component: VenteGlobal,
  },
  // {
  //   path: `${process.env.PUBLIC_URL}/etat-de-vente/par-mois`,
  //   Component: VenteParMois,
  // },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/assurance`,
    Component: DashboardA,
  },
  {
    path: `${process.env.PUBLIC_URL}/Notice`,
    Component: Notice,
  },
  {
    path: `${process.env.PUBLIC_URL}/Manuel-utilisation`,
    Component: Manuel,
  },
  {
    path: `${process.env.PUBLIC_URL}/Sous-Dist`,
    Component: SouDist,
  },
  {
    path: `${process.env.PUBLIC_URL}/Sous-Dist-list`,
    Component: ListSousDist,
  },
  {
    path: `${process.env.PUBLIC_URL}/user-Dist-list`,
    Component: ListUserDist,
  },
  {
    path: `${process.env.PUBLIC_URL}/user-Dist`,
    Component: CreateUserDist,
  },
  {
    path: `${process.env.PUBLIC_URL}/Update-sousDist`,
    Component: UpdateSousDist,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_client/listUsers_inclusion`,
    Component: UsersInclusion,
  },
  {
    path: `${process.env.PUBLIC_URL}/gest_client_contrat/contract_inclusion`,
    Component: ContractInclusionList,
  },
  {
    path: `${process.env.PUBLIC_URL}/voucher/list`,
    Component: VoucherList,
  },
  {
    path: `${process.env.PUBLIC_URL}/voucher/add`,
    Component: VoucherAdd,
  },
];
