import {
  FETCH_DISTRIBUTORS_REQUEST,
  FETCH_DISTRIBUTORS_SUCCESS,
  FETCH_DISTRIBUTORS_FAILURE,
  FETCH_DISTRIBUTORS_CONTRACT,
} from "./distributorActions";

const initialState = {
  loading: false,
  contarctDetails: [],
  distributors: [],
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DISTRIBUTORS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_DISTRIBUTORS_SUCCESS:
      return {
        loading: false,
        contarctDetails: [],
        distributors: action.payload,
        error: "",
      };
    case FETCH_DISTRIBUTORS_FAILURE:
      return {
        loading: false,
        contarctDetails: [],
        distributors: [],
        error: action.payload,
      };
    case FETCH_DISTRIBUTORS_CONTRACT:
      return {
        loading: false,
        contarctDetails: action.payload,
        distributors: [],
        error: "",
      };
    default:
      return state;
  }
};

export default reducer;
