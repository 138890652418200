import { adminAxios } from "../config/axiosConfig/InstancesAxios";

export default {
    getAllVouchers: (uid) => {
    return adminAxios.post("/getAllVouchers", null, {params:{uid}});
  },
  createVoucher:(Model)=>{
    return adminAxios.post("/createVoucher", Model)
  }

};