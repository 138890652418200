import React, { useState, useEffect, Fragment } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  Label,
  Input,
} from "reactstrap";
import { saveUser } from "../../../redux/users/usersActions";
import StepZilla from "react-stepzilla";
import { useHistory, useLocation } from "react-router-dom";
import Dropzone from "react-dropzone-uploader";
import { findLastIndex } from "lodash";
import { DefaultPearlsSteps } from "./stepsComponent";
import { useDispatch } from "react-redux";
import usersService from "../../../services/users/usersService";
import contractServices from "../../../services/contract/contractServices";
import SinistreServices from "../../../services/sinistre/SinistreServices";
const defaultparlssteps = [
  { name: "Step 6", component: <DefaultPearlsSteps /> },
];
const UpdateUser = ({}) => {
  let location = useLocation();
  const update = location.state?.update;
  const back = location.state?.back;
  console.log(location.state?.row);
  const [password, setPassword] = useState([]);
  const [confirmPassword, setConfirmPassword] = useState([]);
  const [user, setUser] = useState(
    location.state
      ? {
          adress: location.state.row.adress,
          cin: location.state.row.cin,
          city: location.state.row.city,
          codeVerification: location.state.row.codeVerification,
          dateCreation: new Date().toISOString(),
          email: location.state.row.email,
          firstName: location.state.row.firstName,
          isblacklisted: location.state.row.isblacklisted,
          iscgu: location.state.row.iscgu,
          isnewsletter: location.state.row.isnewsletter,
          lastName: location.state.row.lastName,
          password: location.state.row.password,
          phone: location.state.row.phone,
          postalCode: location.state.row.postalCode,
          socialReason: location.state.row.socialReason,
          status: location.state.row.status,
          street: location.state.row.street,
          taxRegistrationNumber: location.state.row.taxRegistrationNumber,
          typeCustomer: location.state.row.typeCustomer,
        }
      : {}
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(true);
  const [clientContracts, setClientContracts] = useState([]);
  const [clientSinistres, setClientSinistres] = useState([]);
  const [showDiv, setShowDiv] = useState(
    user.typeCustomer == "P" ? false : true
  );

  useEffect(() => {
    if (location.state?.row.idcustomer) {
      contractServices
        .getAllcontractsbyCustomer(location.state?.row.idcustomer)
        .then((res) => {
          setClientContracts({
            data: res.data,
          });
        });
      SinistreServices.getSinistreByCustomer(location.state?.row.idcustomer).then(res=>{
        console.log("hiiiii",res.data);
        setClientSinistres({
          data: res.data
        })
      })
    }
  }, []);
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((user) => ({ ...user, [name]: value }));
    console.log(user.isblacklisted);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setSubmitted(true);
    console.log(user);
    usersService.updateUser(user).then((resp) => {
      if (resp.status == 200) {
        history.push("/gest_client_contrat/clients");
      }
      const User = resp.data;
      dispatch(saveUser(User));
    });
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="Clients"
        title={update ? "Modifier Client" : "Fiche Client"}
      />
      <Container fluid={true}>
        <Col sm="12"></Col>
        <Form
          className="needs-validation"
          noValidate=""
          onSubmit={handleSubmit}
        >
          <Row>
            <Col>
              <Card className="shadow-lg p-0 shadow-showcase">
              <CardHeader
                  className="b-l-primary"
                  style={{
                    padding: "15px",
                    textAlign: "end",
                  }}
                >
                  <Button
                    disabled={update}
                    onClick={() => {
                      history.push({
                        pathname: "/gest_client_contrat/contrats",
                        state: {
                          back: true,
                          row: clientContracts.data,
                        },
                      });
                    }}
                    color="primary"
                    outline
                  >
                    Contrats
                  </Button>
                  <Button
                    disabled={update}
                    // onClick={() => {
                    //   history.push({
                    //     pathname: "/gest_sinistre/sinistre",
                    //     state: {
                    //       back: true,
                    //       row: clientSinistres.data,
                          
                    //     },
                    //   });
                    // }}
                    className="ml-3"
                    color="success"
                    outline
                  >
                    Sinistres
                  </Button>
                </CardHeader>
                <CardBody className="pt-1">
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      marginTop: "8px",
                      marginBottom: "8px",
                    }}
                  >
                    <Col
                      xl="4"
                      md="8"
                      lg="12"
                      sm="12"
                      className="button-group-mb-sm xl-50"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {/* <ButtonGroup> */}
                      <div className="radio">
                        <input
                          id="radio23"
                          type="radio"
                          name="typeCustomer"
                          defaultValue="P"
                          onChange={handleChange}
                          onClick={() => setShowDiv(false)}
                          checked={user.typeCustomer === "P"}
                          disabled={!update}
                          required={user.typeCustomer ? false : true}
                        />
                        <Label htmlFor="radio23">Personne physique</Label>
                      </div>

                      <div className="radio">
                        <input
                          id="radio24"
                          type="radio"
                          name="typeCustomer"
                          defaultValue="M"
                          onChange={handleChange}
                          onClick={() => setShowDiv(true)}
                          checked={user.typeCustomer === "M"}
                          disabled={!update}
                        />
                        <Label htmlFor="radio24">
                          Personne Morale (Société)
                        </Label>
                      </div>
                    </Col>
                  </Row>
                  <div className="form-row">
                    <Col
                      md="6 mb-3"
                      style={{ display: showDiv ? "block" : "none" }}
                    >
                      <Label htmlFor="validationCustom01">
                        Matricule fiscale
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="taxRegistrationNumber"
                        type="text"
                        placeholder="Matricule fiscale"
                        value={user.taxRegistrationNumber}
                        onChange={handleChange}
                        disabled={!update}
                        required={user.typeCustomer === "P" ? false : true}
                      />
                      <span></span>
                    </Col>
                    <Col
                      md="6 mb-3"
                      style={{ display: showDiv ? "block" : "none" }}
                    >
                      <Label htmlFor="validationCustom01">
                        Raison Sociale
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="socialReason"
                        type="text"
                        placeholder="Nom de la Société"
                        value={user.socialReason}
                        onChange={handleChange}
                        disabled={!update}
                        required={user.typeCustomer === "P" ? false : true}
                      />
                      <span></span>
                    </Col>
                    <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                        {showDiv ? "Nom du gérant" : "Nom "}
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="firstName"
                        type="text"
                        placeholder="Nom"
                        value={user.firstName}
                        onChange={handleChange}
                        disabled={!update}
                        required="true"
                      />
                      <span></span>
                    </Col>
                    <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                        {showDiv ? "Prénom du gérant " : "Prénom"}
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="lastName"
                        type="text"
                        placeholder="Prénom"
                        value={user.lastName}
                        onChange={handleChange}
                        disabled={!update}
                        required="true"
                      />
                      <span></span>
                    </Col>
                    <Col
                      md="6 mb-3"
                      style={{ display: showDiv ? "block" : "none" }}
                    >
                      <Label htmlFor="validationCustom01">
                        Activité
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="status"
                        type="select"
                        placeholder="status"
                        value={user.status}
                        onChange={handleChange}
                        disabled={!update}
                        required={user.typeCustomer === "P" ? false : true}
                      />
                      <span></span>
                    </Col>
                    <Col
                      md="6 mb-3"
                      style={{ display: showDiv ? "none" : "block" }}
                    >
                      <Label htmlFor="validationCustom01">
                        N° CIN
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="cin"
                        type="number"
                        placeholder="Cin"
                        value={user.cin}
                        onChange={handleChange}
                        disabled={!update}
                        required={user.typeCustomer === "M" ? false : true}
                      />
                      <span></span>
                    </Col>

                    <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                        Numéro de Téléphone
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="phone"
                        type="number"
                        placeholder="Numéro de Téléphone"
                        value={user.phone}
                        onChange={handleChange}
                        disabled={!update}
                        required="true"
                      />
                      <span></span>
                    </Col>
                    <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                        E-mail
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="email"
                        type="text"
                        placeholder="email"
                        value={user.email}
                        onChange={handleChange}
                        disabled={!update}
                        required="true"
                      />
                      <span></span>
                    </Col>
                    <Col md="4 mb-3">
                      <div
                        class="form-check form-switch"
                        style={{
                          // display: "flex",
                          // justifyContent: "center",
                          textAlign: "center",
                          // marginTop: "3%",
                        }}
                      >
                        <input
                          class="form-check-input"
                          // style={{ marginTop: "7%" }}
                          name="isActive"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                          checked={checked}
                          value="B"
                          onClick={() => {
                            if (checked) {
                              setChecked(false);
                              setUser((user) => ({
                                ...user,
                                status: "B",
                              }));
                            } else {
                              setChecked(true);
                              setUser((user) => ({
                                ...user,
                                status: "V",
                              }));
                            }
                          }}
                          disabled={!update}
                        />
                        <label
                          class="form-check-label"
                          for="flexSwitchCheckDefault"
                        >
                          Actif
                        </label>
                      </div>
                    </Col>
                  </div>
                  <div className="checkbox">
                    <input
                      id="checkbox-light-13"
                      type="checkbox"
                      name="isnewsletter"
                      defaultValue="1"
                      onChange={handleChange}
                      checked={user.isnewsletter == 1 ? true : false}
                      disabled={!update}
                    />
                    <Label htmlFor="checkbox-light-13">
                      Inscrire à la newsletter
                    </Label>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Button
                color="danger"
                type="reset"
                className="float-left"
                onClick={() => {
                  back
                    ? history.goBack()
                    : history.push("/gest_client_contrat/clients");
                }}
              >
                Retour
              </Button>
            </Col>
            {update ? (
              <Col>
                <Button color="primary" type="submit" className="float-right">
                  Valider
                </Button>
              </Col>
            ) : (
              <></>
            )}
          </Row>
        </Form>
      </Container>
    </Fragment>
  );
};

export default UpdateUser;
