import { adminAxios } from "../../config/axiosConfig/InstancesAxios";

export default {
  getAllFamily: () => {
    return adminAxios.post("/findEnableFamilies", {});
  },

  createNewFamily: (familyModel) => {
    return adminAxios.post("/createFamily", familyModel);
  },
  getFamilybyId: (idFamily) => {
    return adminAxios.post("/getFamilybyId", null, { params: { idFamily } });
  },
  getFamilybyParentId: (idFamily) => {
    return adminAxios.post("/getFamilybyParentId", null, {
      params: { idFamily },
    });
  },
};
