import React, { useState, useEffect, Fragment } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  Media,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";

import profileService from "../../../services/profile/profileService";
import { useHistory, useLocation } from "react-router-dom";
import Dropzone from "react-dropzone-uploader";
import { findLastIndex } from "lodash";

const UpdateProfile = () => {
  let location = useLocation();
  const history = useHistory();
  const [profile, setProfile] = useState(
    location.state
      ? {
          code: location.state.row.code,
          dateCreation: new Date().toISOString(),
          idprofile: location.state.row.idprofile,
          name: location.state.row.name
        }
      : {}
  );
  useEffect(() => {}, []);

  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile((profile) => ({ ...profile, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSubmitted(true);
    if (profile.name && profile.code) {
      await profileService.updateProfile(profile);
      toast.success("profile updated successfuly");
      history.push("/gest_utilisateur/profils");
    }
  };

  return (
    <Fragment>
      <Breadcrumb parent="Profile" title="Modifier Profile" />
      <Container fluid={true}>
        <Form
          className="needs-validation"
          noValidate=""
          onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Card className="shadow-lg p-0 shadow-showcase">
                <CardHeader className="b-l-primary pb-2">
                  <h5>Info Generale</h5>
                </CardHeader>
                <CardBody className="pt-1">
                  <div className="form-row">
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom01">
                        Nom
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="name"
                        type="text"
                        placeholder="Nom"
                        innerRef=""
                        defaultValue={profile.name}
                        onChange={handleChange}
                      />
                      <span></span>
                    </Col>
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom01">
                        Code
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="code"
                        type="text"
                        placeholder="Prénom"
                        innerRef=""
                        defaultValue={profile.code}
                        onChange={handleChange}
                      />
                      <span></span>
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* <Row>
            <Col>
              <Card className="shadow-lg p-0 shadow-showcase">
                <CardHeader className="b-l-secondary pb-2">
                  <h5> connexion</h5>
                </CardHeader>
                <CardBody className="pt-1">
                  <div className="form-row">
                    <Col md="3 mb-2">
                      <Label htmlFor="validationCustom01">E-mail</Label>
                      <span style={{ color: "red" }}>*</span>
                      <Input
                        className="form-control"
                        name="email"
                        type="text"
                        placeholder="E-mail"
                        innerRef=""
                        // value={login}
                        // onChange={(e) => setLogin(e.target.value)}
                      />
                      
                      {/* <div className="valid-feedback">Looks good!</div> */}
          {/* </Col>
                    <Col md="3 mb-2">
                      <Label htmlFor="validationCustom02">psw</Label>
                      <span style={{ color: "red" }}>*</span>
                      <Input
                        className="form-control"
                        name="password"
                        type="text"
                        placeholder="password"
                        innerRef=""
                        // value={password}
                        // onChange={(e) => setPassword(e.target.value)}
                      />
                      
                      {/* <div className="valid-feedback">Looks good!</div> */}
          {/* </Col>
                    <Col md="3 mb-2">
                      <Label htmlFor="validationCustom02">confirmation</Label>
                      <span style={{ color: "red" }}>*</span>
                      <Input
                        className="form-control"
                        name="confirmPassword"
                        type="text"
                        placeholder="confirm Password"
                        innerRef=""
                        onChange=""
                      />
                      
                    </Col>
                    <Col md="3 m-auto">
                      <Button className="btn-pill btn-air-primary float-right" color="primary" onClick="">
                        generatePassword
                      </Button>
                    </Col>
                  </div>
                </CardBody>
              </Card> */}
          {/* </Col> */}
          {/* </Row> */}

          <Row>
            <Col>
              <Button
                color="danger"
                type="reset"
                className="float-left"
                onClick={() => history.push("/gest_utilisateur/profils")}>
                Retour
              </Button>
            </Col>
            <Col>
              <Button color="primary" type="submit" className="float-right">
                Valider
              </Button>
            </Col>
          </Row>
          {/* <Button color="primary">{config.button}</Button>
                  <Button color="primary">{config.button}</Button> */}
        </Form>
      </Container>
    </Fragment>
  );
};

export default UpdateProfile;
