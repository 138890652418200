import React, { useState, useEffect, Fragment } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  Media,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  Label,
  Input,
} from "reactstrap";

import { useHistory, useLocation } from "react-router-dom";

const UpdateContrat = () => {
  const history = useHistory();
  const location = useLocation();
  const update = location.state.update;
  
  return (
    <Fragment>
      <Breadcrumb
        parent="Contrat"
        title={update ? "Modification Contrat" : "Contrat"}
      />
      <Container fluid={true}>
        <Form className="needs-validation" noValidate="" onSubmit="">
          <Row>
            <Col>
              <Card className="shadow-lg p-0 shadow-showcase">
                <CardHeader
                  className="b-l-primary"
                  style={{
                    padding: "15px",
                    textAlign: "end",
                  }}
                >
                  
                </CardHeader>
                <CardBody className="pt-1">
                  <div className="form-row">
                    <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                        Num Contrat
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="num_cont"
                        type="text"
                        placeholder="Num Contrat"
                        innerRef=""
                        disabled={!update}
                      />
                      <span></span>
                    </Col>
                    <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                        Client
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="client"
                        type="select"
                        placeholder="Client"
                        innerRef=""
                        disabled={!update}
                      />
                      <span></span>
                    </Col>
                    <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                        Revendeur
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="revendeur"
                        type="select"
                        placeholder="Revendeur"
                        innerRef=""
                        disabled={!update}
                      />
                      <span></span>
                    </Col>

                    <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                        Date De Debut
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="ddd"
                        type="date"
                        placeholder=""
                        innerRef=""
                        disabled={!update}
                      />
                      <span></span>
                    </Col>
                    <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                        Date De Fin
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="ddf"
                        type="date"
                        placeholder=""
                        innerRef=""
                        disabled={!update}
                      />
                      <span></span>
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Button
                color="danger"
                type="reset"
                className="float-left"
                onClick={() => history.push("/gest_client_contrat/contrats")}
              >
                Retour
              </Button>
            </Col>
            <Col>
              <Button color="primary" type="submit" className="float-right">
                Valider
              </Button>
            </Col>
          </Row>
          {/* <Button color="primary">{config.button}</Button>
                  <Button color="primary">{config.button}</Button> */}
        </Form>
      </Container>
    </Fragment>
  );
};

export default UpdateContrat;
