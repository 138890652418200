import React, { useState, useEffect, Fragment } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  Media,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";

import { useHistory } from "react-router-dom";
import Dropzone from "react-dropzone-uploader";
import { findLastIndex } from "lodash";
import Switch from "react-switch";
import { useDispatch, useSelector } from "react-redux";
import { fetchFamily } from "../../../redux/family/familyActions";
import { addBreakdownType } from "../../../redux/breakdownType/breakdownTypeActions";
import Select from "react-select";

const CreateBreakdown = ({ location, i18n }) => {
  const history = useHistory();

  const [breakdownType, setBreakdownType] = useState({
    dateCreation: new Date().toISOString(),
    description: "",
    idfamily: "",
    name: ""
  });

  const dispatch = useDispatch();

  const [submitted, setSubmitted] = useState(false);

  const [selectOption, setOptionSelected] = useState(null);

  const [optionFamily, setListOptionFamily] = useState([]);

  const listFamily = useSelector(({ familyReducers }) => {
    return familyReducers.familys;
  });

  useEffect(() => {
    dispatch(fetchFamily());
  }, []);

  useEffect(() => {
    setListOptionFamily(
      listFamily.map((el) => ({
        value: el.idfamily,
        label: el.familyName
      }))
    );
  }, [listFamily]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBreakdownType((breakdownType) => ({ ...breakdownType, [name]: value }));
  };

  const onchangeSelect = (item) => {
    console.log(item);
    setOptionSelected(item);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setSubmitted(true);
    if (breakdownType.name && breakdownType.description) {
      dispatch(
        addBreakdownType({ ...breakdownType, idfamily: selectOption.value })
      );
    }
    console.log(breakdownType);
  };

  return (
    <Fragment>
      <Breadcrumb parent="Type de panne" title="Nouveau Panne" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <h5>Type de panne</h5>
              </CardHeader>
              <CardBody>
                <Form
                  className="needs-validation"
                  noValidate=""
                  onSubmit={handleSubmit}>
                  <div className="form-row">
                    <Col md="6 mb-4">
                      <Label htmlFor="validationCustom02">
                        Famille
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Select
                        value={selectOption}
                        onChange={onchangeSelect}
                        options={optionFamily}
                      />
                    </Col>
                    <Col md="6 mb-4">
                      <Label htmlFor="validationCustom01">
                        Non De Panne
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="name"
                        type="text"
                        placeholder="Non De Panne"
                        value={breakdownType.name}
                        onChange={handleChange}
                      />
                      <span></span>
                    </Col>
                    <Col md="6 mb-4">
                      <Label htmlFor="validationCustom02">
                        Description
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="description"
                        type="text"
                        placeholder="Description"
                        value={breakdownType.description}
                        onChange={handleChange}
                      />
                      <span></span>
                    </Col>
                  </div>

                  <Row>
                    <Col>
                      <Button
                        color="danger"
                        type="reset"
                        className="float-left"
                        onClick={() =>
                          history.push("/gest_fromule_product/Type_De_Panne")
                        }>
                        Retour
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        color="primary"
                        type="submit"
                        className="float-right">
                        Validé
                      </Button>
                    </Col>
                  </Row>
                  {/* <Button color="primary">{config.button}</Button>
                  <Button color="primary">{config.button}</Button> */}
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CreateBreakdown;
