import { adminAxios } from "../../config/axiosConfig/InstancesAxios";

export default {
  getAllTypeUser: (uid) => {
    return adminAxios.post("/getAllTypeusers", null, { params: { uid } });
  },

  createTypeUser: (Model) => {
    return adminAxios.post("/createTypeUser", Model);
  },

  updateTypeUser: (Model) => {
    return adminAxios.post("/updateTypeUser", Model);
  },
};
