import React, { useState, useEffect, Fragment } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {
  Media,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import Select from "react-select";
import { useHistory, useLocation } from "react-router-dom";
import { fetchFamily } from "../../../redux/family/familyActions";
import { useDispatch, useSelector } from "react-redux";
import familyService from "../../../services/family/familyService";
import formulaService from "../../../services/formula/formulaService";
import distributorService from "../../../services/distribitor/distributorService";
import voucherService from "../../../services/voucherService";
import { toast } from "react-toastify";
import contractServices from "../../../services/contract/contractServices";
const VoucherAdd = () => {
  const history = useHistory();
  const location = useLocation();
  const vue = location?.state?.vue;
  const [voucher, setVoucher] = useState({
    date_activation: new Date().toISOString(),
    date_creation: new Date().toISOString(),
    iddistributor: "",
    idformule: "",
    iduser: localStorage.getItem("id"),
  });
  const [optionFA, setListOptionFA] = useState([]);
  const [optionFO, setListOptionFO] = useState([]);
  const [optionP, setListOptionP] = useState([]);
  const [optionD, setListOptionD] = useState([]);
  const [selectOptionD, setOptionSelectedD] = useState(null);
  const [selectOptionFA, setOptionSelectedFA] = useState(null);
  const [selectOptionFO, setOptionSelectedFO] = useState(null);
  const [selectOptionP, setOptionSelectedP] = useState(null);
  const [numContrat, setNumContrat] = useState();
  const [client, setClient] = useState();
  const listFamily = useSelector(({ familyReducers }) => {
    return familyReducers.familys;
  });
  useEffect(() => {
    if (vue) {
      setOptionSelectedFO({
        value: location.state.row.idformule.idformula,
        label: location.state.row.idformule.name,
      });
      setOptionSelectedFA({
        value: location.state.row.idformule.family.idfamily,
        label: location.state.row.idformule.family.familyName,
      });
      setOptionSelectedD({
        value: location.state.row.iddistributor.iddisributor,
        label: location.state.row.iddistributor.socialReason,
      });
    }
    if (location?.state?.row?.is_used === 1 && vue) {
      contractServices
        .getContractbyId(location.state.row.idcontract)
        .then((res) => {
          console.log(res);
          if (res.data) {
            setNumContrat(res.data.num);
            setClient(
              res.data.customer.firstName + " " + res.data.customer.lastName
            );
          }
        });
    } else {
      familyService.getAllFamily().then((res) => {
        setListOptionFA(
          res.data.map((el) => ({
            value: el.idfamily,
            label: el.familyName,
          }))
        );
      });
      distributorService
        .getAllDistributors(localStorage.getItem("id"))
        .then((res) => {
          if (res.data) {
            setListOptionD(
              res.data.map((el) => ({
                value: el.iddisributor,
                label: el.socialReason,
              }))
            );
          }
        });
    }
  }, [listFamily]);

  const onchangeSelectFA = (item) => {
    setListOptionFO([]);
    setOptionSelectedFO(null);
    if (item.label === "Multimédia" || item.label === "Électroménager") {
      familyService.getFamilybyParentId(item.value).then((res) => {
        setListOptionP(
          res?.data?.map((el) => ({
            value: el.idfamily,
            label: el.familyName,
          }))
        );
      });
    } else {
      formulaService.getAllformulasbyFamily(item.value).then((res) => {
        setListOptionFO(
          res?.data?.map((el) => ({
            value: el.fomula.idformula,
            label: el.fomula.name,
          }))
        );
      });
    }
    setOptionSelectedFA(item);
  };
  const onchangeSelectP = (item) => {
    formulaService.getAllformulasbyFamily(item.value).then((res) => {
      setListOptionFO(
        res?.data?.map((el) => ({
          value: el.fomula.idformula,
          label: el.fomula.name,
        }))
      );
    });
    setOptionSelectedP(item);
  };
  const onchangeSelectD = (item) => {
    setOptionSelectedD(item);
    setVoucher((voucher) => ({ ...voucher, iddistributor: item.value }));
  };
  const onchangeSelectFO = (item) => {
    setOptionSelectedFO(item);
    setVoucher((voucher) => ({ ...voucher, idformule: item.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    voucherService.createVoucher(voucher).then((res) => {
      if (res.data) {
        history.push("/voucher/list");
        toast.success("Bon d'achat creer avec succès");
      }
    });
  };
  return (
    <Fragment>
      <Breadcrumb
        parent="Bon d'achat"
        title={vue ? "Bon D'achat" : "Nouveau Bon d'achat"}
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form
                  className="needs-validation"
                  noValidate=""
                  onSubmit={handleSubmit}
                >
                  <div className="form-row">
                    {vue ? (
                      <>
                        <Col md="6 mb-4">
                          <Label htmlFor="validationCustom01">
                            Code
                            <span style={{ color: "red" }}>*</span>
                          </Label>
                          <Input
                            className="form-control"
                            name="code"
                            disabled
                            type="text"
                            placeholder="Code"
                            defaultValue={location.state.row.code}
                          />
                          <span></span>
                        </Col>
                        <Col md="6 mb-4">
                          <Label htmlFor="validationCustom01">
                            Date ajout
                            <span style={{ color: "red" }}>*</span>
                          </Label>
                          <Input
                            className="form-control"
                            name="Date ajout"
                            disabled
                            type="text"
                            placeholder="Date ajout"
                            defaultValue={location.state.row.date_creation}
                          />
                          <span></span>
                        </Col>
                        <Col md="6 mb-4">
                          <Label htmlFor="validationCustom01">
                            date activation
                            <span style={{ color: "red" }}>*</span>
                          </Label>
                          <Input
                            className="form-control"
                            name="date activation"
                            disabled
                            type="text"
                            placeholder="date activation"
                            defaultValue={location.state.row.date_activation}
                          />
                          <span></span>
                        </Col>
                      </>
                    ) : (
                      <></>
                    )}
                    <Col md="6 mb-4">
                      <Label htmlFor="validationCustom02">
                        Famille
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Select
                        isDisabled={vue}
                        value={selectOptionFA}
                        onChange={onchangeSelectFA}
                        options={optionFA}
                      />
                    </Col>
                    {selectOptionFA?.label === "Multimédia" ||
                    selectOptionFA?.label === "Électroménager" ? (
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom02">
                          Produit
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Select
                          isDisabled={vue}
                          value={selectOptionP}
                          onChange={onchangeSelectP}
                          options={optionP}
                        />
                      </Col>
                    ) : (
                      <></>
                    )}
                    <Col md="6 mb-4">
                      <Label htmlFor="validationCustom02">
                        Formule
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Select
                        isDisabled={vue}
                        value={selectOptionFO}
                        onChange={onchangeSelectFO}
                        options={optionFO}
                      />
                    </Col>
                    <Col md="6 mb-4">
                      <Label htmlFor="validationCustom02">
                        Distributeur
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Select
                        isDisabled={vue}
                        value={selectOptionD}
                        onChange={onchangeSelectD}
                        options={optionD}
                      />
                    </Col>
                    {location?.state?.row?.is_used === 1 && vue ? (
                      <>
                        <Col md="6 mb-4">
                          <Label htmlFor="validationCustom01">
                            Date use
                            <span style={{ color: "red" }}>*</span>
                          </Label>
                          <Input
                            className="form-control"
                            name="Date use"
                            disabled
                            type="text"
                            placeholder="Date use"
                            defaultValue={location.state.row.date_use}
                          />
                          <span></span>
                        </Col>
                        <Col md="6 mb-4">
                          <Label htmlFor="validationCustom01">
                            contrat
                            <span style={{ color: "red" }}>*</span>
                          </Label>
                          <Input
                            className="form-control"
                            name="contrat"
                            disabled
                            type="text"
                            placeholder="contrat"
                            defaultValue={numContrat}
                          />
                          <span></span>
                        </Col>
                        <Col md="6 mb-4">
                          <Label htmlFor="validationCustom01">
                            client
                            <span style={{ color: "red" }}>*</span>
                          </Label>
                          <Input
                            className="form-control"
                            name="client"
                            disabled
                            type="text"
                            placeholder="client"
                            defaultValue={client}
                          />
                          <span></span>
                        </Col>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  <Row>
                    <Col>
                      <Button
                        color="danger"
                        type="reset"
                        className="float-left"
                        onClick={() => history.push("/voucher/list")}
                      >
                        Retour
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        color="primary"
                        type="submit"
                        className="float-right"
                      >
                        Validé
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default VoucherAdd;
