import axios from "axios";
import { contarctAxios } from "../../config/axiosConfig/InstancesAxios";
import { adminAxios } from "../../config/axiosConfig/InstancesAxios";
export default {
  checkStepers: (model) => {
    return contarctAxios.post("/checkStepers", model);
  },

  getCustomerByCin: (cin, uid) => {
    return contarctAxios.post("/getCustomerByCin", null, {
      params: { cin, uid },
    });
  },
  getContractbyId: (idContract) => {
    return contarctAxios.post("/getContractbyId", null, {
      params: { idContract },
    });
  },

  getAllContractbyDisributor: (iddisributor) => {
    return contarctAxios.post("/getAllcontractsbyDisributor", null, {
      params: { iddisributor },
    });
  },
  getAllcontractsbyCustomer: (idcustomer) => {
    return contarctAxios.post("/getAllcontractsbyCustomer", null, {
      params: { idcustomer },
    });
  },
  getAllcontractswithoutpaginationInclusion: (idUser) => {
    return contarctAxios.post(
      "/getAllcontractswithoutpaginationInclusion",
      null,
      {
        params: { idUser },
      }
    );
  },
  importContractsInclusion: (file, id_formula, id_product, id_user) => {
    console.log(file);
    let formData = new FormData();
    formData.append("file", file);
    return contarctAxios.post("/importContractsInclusion", formData, {
      params: { id_formula, id_product, id_user },
    });
  },

  getProductInfo: (idproduct) => {
    return adminAxios.post("/getAllproductinfobyproduct", null, {
      params: { idproduct },
    });
  },
  getTypePayment: () => {
    return contarctAxios.get("/getListTypePayment", {});
  },
  payment: (data) => {
    return contarctAxios.post("/payment", data);
  },
  createContract: (Model) => {
    return contarctAxios.post("/createContract", Model);
  },
  generateContrat: (idConstructionSite) => {
    return contarctAxios.post("/generateContrat", null, {
      params: { idConstructionSite },
    });
  },
  sign: (model) => {
    return contarctAxios.post("/ngsign", model);
  },
  getProductByName: (name) => {
    return adminAxios.post("/getProductByName", null, { params: { name } });
  },
  getMarqueByProduct: (idProduct) => {
    return adminAxios.post("/getMarqueByProduct", null, {
      params: { idProduct },
    });
  },
  generateContrat: (idConstructionSite) => {
    return contarctAxios.post("/generateContrat", null, {
      params: { idConstructionSite },
    });
  },
  generateContratBase64: (idConstructionSite) => {
    return contarctAxios.post("/generateContratB64", null, {
      params: { idConstructionSite },
    });
  },
  getAllContractsByDistAndMonth: (iddistributor) => {
    return contarctAxios.post("/getAllcontractsByDistribitorandmonth", null, {
      params: { iddistributor },
    });
  },
  getAllcontractswithoutpagination: (idUser) => {
    return contarctAxios.post("/getAllcontractswithoutpagination", null, {
      params: { idUser },
    });
  },
  getAllcontractswithoutpaginationByUser: (idcustomer) => {
    return contarctAxios.post("/getAllcontractsbyCustomer", null, {
      params: { idcustomer },
    });
  },
  getAllcontractsBymois: () => {
    return contarctAxios.post("/getAllcontractsBymois", {});
  },
  xlExport: (iddistributor) => {
    return contarctAxios.post("/excel", null, { params: { iddistributor } });
  },
  getAllcontractsParentandDist: (iddistributor) => {
    return contarctAxios.post("/getallContractsbyparent", null, {
      params: { iddistributor },
    });
  },
  updateStatusOfContract: (data) => {
    return contarctAxios.post("/updateStatusOfContract", data);
  },
  uploadFile: (file) => {
    let formData = new FormData();
    formData.append("file", file);
    return contarctAxios.post("/upload", formData);
  },
  exportexcel: () => {
    return contarctAxios.post("/exportexcel", {});
  },
  exportBHexcel: () => {
    return contarctAxios.post("/exportBHexcel", {});
  },
  cancelContract: (Model) => {
    return contarctAxios.post("/cancelContract", Model);
  },
  filterContract: (Model) => {
    return contarctAxios.post("/filterContract", Model);
  },
  exportexcelwithfilter: (Model) => {
    return contarctAxios.post("/exportexcelwithfilter", Model);
  },
  updateStatusOfContracts: (Model) => {
    return contarctAxios.post("/updateStatusOfContracts", Model);
  },
  exportBHexcelwithfilter: (Model) => {
    return contarctAxios.post("/exportBHexcelwithfilter", Model);
  },
};
