import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Button,
  Input,
} from "reactstrap";
import contractServices from "../../../services/contract/contractServices";
import CardDash from "../../../pages/distributeur/dashCard/CardDash";
import { useHistory, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from "moment";
const Sample = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [handleFilter, setHandleFilter] = useState(true);
  const back = location?.state?.back;
  const [contractList, setContractList] = useState();
  const [total, setTotal] = useState(0);
  const [commisToT, setCommisToT] = useState(0);
  const [fronBHTot, setFrontBHTot] = useState(0);
  const [fronCourtTot, setFronCourTot] = useState(0);
  const [revendeurttc, setRevendeurTTc] = useState(0);
  const [commisToTMn, setCommisToTMn] = useState();
  const [fronBHTotMn, setFrontBHTotMn] = useState();
  const [fronCourtTotMn, setFronCourTotMn] = useState();
  const [totalMonth, setTotalMonth] = useState();
  const [filteredValues, setFilteredValues] = useState({
    dateDebut: "",
    dateFin: "",
    idUser: localStorage.getItem("id"),
    idDistributor: null,
    inclusion:0
  });
  const handleFilterValues = (value, name) => {
    setFilteredValues({ ...filteredValues, [name]: value });
  };
  const filterData = async () => {
    var Ftot = 0;
    var FfronBH = 0;
    var FfronCour = 0;
    var Fcomm = 0;
    var Frev = 0;
    const filtredList = (
      await contractServices.filterContract({
        ...filteredValues,
        dateDebut: moment(filteredValues.dateDebut).add(1, "hour"),
        dateFin: moment(filteredValues.dateFin).add(1, "hour"),
      })
    ).data.filter((el) => el.status !== "C");
    filtredList?.forEach((element) => {
      Ftot += element.totalAmount;
      FfronBH += element.frontingHtAssurance;
      FfronCour += element.frontingHtCourtier;
      Fcomm += element.margeDistributeurTtc;
      Frev += element.margeRevendeurTtc;
    });
    setCommisToT(Fcomm);
    setFrontBHTot(FfronBH);
    setFronCourTot(FfronCour);
    setTotal(Ftot);
    setRevendeurTTc(Frev);
  };

  useEffect(() => {
    if (localStorage.getItem("role") !== "Garanty") {
      history.goBack();
    }
    const id = localStorage.getItem("id");
    contractServices
      .filterContract(filteredValues)
      .then((res) => {
        var filtredStatus = res.data?.filter((el) => el.status !== "C");
        var tot = 0;
        var fronBH = 0;
        var fronCour = 0;
        var comm = 0;
        var rev = 0;
        filtredStatus?.forEach((element) => {
          tot += element.totalAmount;
          fronBH += element.frontingHtAssurance;
          fronCour += element.frontingHtCourtier;
          comm += element.margeDistributeurTtc;
          rev += element.margeRevendeurTtc;
        });
        setCommisToT(comm);
        setFrontBHTot(fronBH);
        setFronCourTot(fronCour);
        setTotal(tot);
        setRevendeurTTc(rev);
      })
      .catch((Error) => {
        console.log(Error);
        setCommisToT(0);
        setFrontBHTot(0);
        setFronCourTot(0);
        setTotal(0);
      });

    // contractServices.getAllcontractsBymois().then((res) => {
    //   // console.log(res);
    //   var totM = 0;
    //   var fronBH = 0;
    //   var fronCour = 0;
    //   var comm = 0;
    //   res.data.forEach((element) => {
    //     totM += element.totalAmount;
    //     fronBH += element.formula.frontingHtAssurance;
    //     fronCour += element.formula.frontingHtCourtier;
    //     comm += element.formula.margeDistributeurHt;
    //   });
    //   setCommisToTMn(comm);
    //   setFrontBHTotMn(fronBH);
    //   setFronCourTotMn(fronCour);
    //   setTotalMonth(totM);
    // }).catch((Error)=>{
    //   setCommisToTMn(0);
    //   setFrontBHTotMn(0);
    //   setFronCourTotMn(0);
    //   setTotalMonth(0);
    // });
  }, []);
  return (
    <Fragment>
      <Breadcrumb parent="Dashbord" title={"Tableau de bord"} />
      <Container fluid={true}>
        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader
            className="ml-2"
            style={{ cursor: "pointer", padding: "10px" }}
            onClick={() => {
              setHandleFilter(!handleFilter);
            }}
          >
            <span className="float-left">
              <h6 className="mt-1">{"Filtrer"}</h6>
            </span>

            <span className="float-right ">
              <h4>
                {handleFilter ? (
                  <i className="fa fa-angle-down"></i>
                ) : (
                  <i className="fa fa-angle-up"></i>
                )}
              </h4>
            </span>
          </CardHeader>
          <CardBody className="pt-0" hidden={back ? true : handleFilter}>
            <Form className="theme-form">
              <Row>
                <Col md="3 mb-2">
                  <FormGroup>
                    <label className="col-form-label"> Du</label>
                    <DatePicker
                      className="form-control digits"
                      dateFormat="dd/MM/yyyy"
                      selected={filteredValues.dateDebut || ""}
                      onChange={(e) => {
                        handleFilterValues(e, "dateDebut");
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="3 mb-2">
                  <FormGroup>
                    <label className="col-form-label"> Au</label>
                    <DatePicker
                      className="form-control digits"
                      dateFormat="dd/MM/yyyy"
                      selected={filteredValues.dateFin || ""}
                      onChange={(e) => {
                        handleFilterValues(e, "dateFin");
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="3 mb-2">
                  <FormGroup>
                    <label className="col-form-label">Type Vente </label>
                    <Input
                      type="select"
                      name="select"
                      className="form-control digits"
                      defaultValue={""}
                      onChange={(e) =>
                        handleFilterValues(e.target.value, "inclusion")
                      }
                    >
                      <option value={0}>{"vente normal"}</option>
                      <option value={1}>{"vente inclusion"}</option>
                      
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
            <Row>
              <Col>
                <Button
                  onClick={filterData}
                  className="btn-pill btn-air-success"
                  color="success"
                >
                  Recherche
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card className="shadow-lg p-3 shadow-showcase">
          <CardBody className="pt-2">
            {/* <Row md="12 mb-3">
              <CardDash
                name="Prime TTC du mois"
                total={`${(totalMonth / 1000).toFixed(3)} DT`}
                icon={`${process.env.PUBLIC_URL}/assets/images/chart.svg`}
                pointer={true}
                // onClick={versEtat}
              />
              <CardDash
                name="Frais de fronting BHA"
                total={`${(fronBHTotMn / 1).toFixed(3)} DT`}
                icon={`${process.env.PUBLIC_URL}/assets/images/commission.png`}
                pointer={true}
                // onClick={versEtat}
              />

              <CardDash
                name="Frais de Fronting GST"
                total={`${(fronCourtTotMn / 1).toFixed(3)} DT`}
                icon={`${process.env.PUBLIC_URL}/assets/images/reverse.png`}
                pointer={true}
                // onClick={versEtat}
              />
              <CardDash
                name="Marge dst TTC du mois"
                total={`${(commisToTMn / 1).toFixed(3)} DT`}
                icon={`${process.env.PUBLIC_URL}/assets/images/reverse.png`}
                pointer={true}
                // onClick={versEtat}
              />
              <CardDash
                name="Prime nette GARANTY"
                total={`${(
                  (totalMonth / 1000).toFixed(3) -
                  commisToTMn -
                  fronCourtTotMn -
                  fronBHTotMn
                ).toFixed(3)} DT`}
                icon={`${process.env.PUBLIC_URL}/assets/images/reverse.png`}
                pointer={true}
                // onClick={versEtat}
              />
            </Row> */}
            <Row md="12 mb-3">
              <CardDash
                name="Total prime TTC"
                total={`${total.toFixed(3)} DT`}
                icon={`${process.env.PUBLIC_URL}/assets/images/chart.svg`}
              />
              <CardDash
                name="Total frais de fronting La CARTE"
                total={`${fronBHTot.toFixed(3)} DT`}
                icon={`${process.env.PUBLIC_URL}/assets/images/commission.png`}
              />
              {/* <CardDash
                name="Total frais de fronting GST HT"
                total={`0.000 DT`}
                icon={`${process.env.PUBLIC_URL}/assets/images/commission.png`}
              /> */}
              <CardDash
                name="Total commission dst TTC"
                total={`${(commisToT + revendeurttc).toFixed(3)} DT`}
                icon={`${process.env.PUBLIC_URL}/assets/images/commission.png`}
              />
              <CardDash
                name="Total prime à reverser"
                total={`${(total.toFixed(3) - commisToT - revendeurttc).toFixed(
                  3
                )} DT`}
                icon={`${process.env.PUBLIC_URL}/assets/images/reverse.png`}
              />
            </Row>
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
};

export default Sample;
