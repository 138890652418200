import {
  Home,
  CreditCard,
  Airplay,
  RotateCcw,
  Users,
  BookOpen,
  Layers,
  Tool,
  Clipboard,
  FileText,
  User,
  Framer,
  UserCheck,
  Save,
} from "react-feather";

export const BROKERMENUS = [
  {
    title: "Dashboard",
    icon: Home,
    type: "subLink",
    active: false,
    path: `${process.env.PUBLIC_URL}/dashboard/courtier`,
  },
  {
    title: "État des ventes",
    icon: BookOpen,
    type: "sub",
    active: false,
    children: [
      {
        path: `${process.env.PUBLIC_URL}/etat-de-vente/Global`,
        title: "État Global",
        type: "link",
      },
      // {
      //   path: `${process.env.PUBLIC_URL}/etat-de-vente/par-mois`,
      //   title: "État mensuel",
      //   type: "link",
      // },
    ],
  },
  {
    title: "Clients",
    icon: UserCheck,
    type: "subLink",
    path: `${process.env.PUBLIC_URL}/gest_client_contrat/clients`,
    active: false,
  },
  {
    title: "Contrats",
    icon: FileText,
    type: "subLink",
    path: `${process.env.PUBLIC_URL}/gest_client_contrat/contrats`,
    active: false,
  },
  {
    title: "Sinistres",
    icon: Clipboard,
    type: "subLink",
    path: `${process.env.PUBLIC_URL}/gest_sinistre/sinistre`,
    active: false,
  },
];
export const ASSURANCEMENUS = [
  {
    title: "Dashboard",
    icon: Home,
    type: "subLink",
    active: false,
    path: `${process.env.PUBLIC_URL}/dashboard/assurance`,
  },
  {
    title: "État des ventes",
    icon: BookOpen,
    type: "sub",
    active: false,
    children: [
      {
        path: `${process.env.PUBLIC_URL}/etat-de-vente/Global`,
        title: "État Global",
        type: "link",
      },
      // {
      //   path: `${process.env.PUBLIC_URL}/etat-de-vente/par-mois`,
      //   title: "État mensuel",
      //   type: "link",
      // },
    ],
  },
  {
    title: "Clients",
    icon: UserCheck,
    type: "subLink",
    path: `${process.env.PUBLIC_URL}/gest_client_contrat/clients`,
    active: false,
  },
  // {
  //   title: "Clients Inclusion",
  //   icon: UserCheck,
  //   type: "subLink",
  //   path: `${process.env.PUBLIC_URL}/gest_client/listUsers_inclusion`,
  //   active: false,
  // },
  {
    title: "Contrats",
    icon: FileText,
    type: "subLink",
    path: `${process.env.PUBLIC_URL}/gest_client_contrat/contrats`,
    active: false,
  },
  {
    title: "Contrats Inclusion",
    icon: FileText,
    type: "subLink",
    path: `${process.env.PUBLIC_URL}/gest_client_contrat/contract_inclusion`,
    active: false,
  },
  {
    title: "Sinistres",
    icon: Clipboard,
    type: "subLink",
    path: `${process.env.PUBLIC_URL}/gest_sinistre/sinistre`,
    active: false,
  },
];
export const DISTMENUITEMSAD = [
  {
    title: "Dashboard",
    icon: Home,
    type: "subLink",
    active: false,
    path: `${process.env.PUBLIC_URL}/dashboard/distributeur`,
  },
  {
    title: "Nouvelle Vente",
    icon: Airplay,
    type: "subLink",
    active: false,
    path: `${process.env.PUBLIC_URL}/new-sale`,
  },
  {
    title: "État des ventes",
    icon: BookOpen,
    type: "sub",
    active: false,
    children: [
      {
        path: `${process.env.PUBLIC_URL}/dist/etat-de-vente/Global`,
        title: "État Global",
        type: "link",
      },
      // {
      //   path: `${process.env.PUBLIC_URL}/dist/etat-de-vente/par-mois`,
      //   title: "État mensuel",
      //   type: "link",
      // },
    ],
  },
  {
    title: "Historique",
    icon: RotateCcw,
    type: "subLink",
    active: false,
    path: `${process.env.PUBLIC_URL}/historique`,
  },
  {
    title: "Notice d'information",
    icon: Save,
    type: "subLink",
    active: false,
    path: `${process.env.PUBLIC_URL}/Notice`,
  },
  // {
  //   title: "Manuel d'utilisation",
  //   icon: Save,
  //   type: "subLink",
  //   active: false,
  //   path: `${process.env.PUBLIC_URL}/Manuel-utilisation`,
  // },
  {
    title: "Gestion des utilisateurs",
    icon: Users,
    type: "subLink",
    active: false,
    path: `${process.env.PUBLIC_URL}/user-dist-list`,
  },
];
export const SOUSDISTMENUITEMS = [
  {
    title: "Dashboard",
    icon: Home,
    type: "subLink",
    active: false,
    path: `${process.env.PUBLIC_URL}/dashboard/distributeur`,
  },
  {
    title: "Nouvelle Vente",
    icon: Airplay,
    type: "subLink",
    active: false,
    path: `${process.env.PUBLIC_URL}/new-sale`,
  },
  {
    title: "État des ventes",
    icon: BookOpen,
    type: "sub",
    active: false,
    children: [
      {
        path: `${process.env.PUBLIC_URL}/dist/etat-de-vente/Global`,
        title: "État Global",
        type: "link",
      },
      // {
      //   path: `${process.env.PUBLIC_URL}/dist/etat-de-vente/par-mois`,
      //   title: "État mensuel",
      //   type: "link",
      // },
    ],
  },
  {
    title: "Historique",
    icon: RotateCcw,
    type: "subLink",
    active: false,
    path: `${process.env.PUBLIC_URL}/historique`,
  },
  {
    title: "Notice d'information",
    icon: Save,
    type: "subLink",
    active: false,
    path: `${process.env.PUBLIC_URL}/Notice`,
  },
  // {
  //   title: "Manuel d'utilisation",
  //   icon: Save,
  //   type: "subLink",
  //   active: false,
  //   path: `${process.env.PUBLIC_URL}/Manuel-utilisation`,
  // },
  {
    title: "Gestion des utilisateurs",
    icon: Users,
    type: "subLink",
    active: false,
    path: `${process.env.PUBLIC_URL}/user-dist-list`,
  },
  {
    title: "Gestions des revendeurs",
    icon: Users,
    type: "subLink",
    active: false,
    path: `${process.env.PUBLIC_URL}/sous-dist-list`,
  },
];
export const MENUITEMS = [
  {
    title: "Dashboard",
    icon: Home,
    type: "subLink",
    active: false,
    path: `${process.env.PUBLIC_URL}/dashboard/default`,
  },
  {
    title: "Gestion Des Utilisateurs ",
    icon: Users,
    type: "sub",
    active: false,
    children: [
      {
        path: `${process.env.PUBLIC_URL}/gest_utilisateur/user_type`,
        title: "Type utilisateurs",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/gest_utilisateur/profils`,
        title: "Profils",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/gest_utilisateur/utilisateurs`,
        title: "Utilisateurs",
        type: "link",
      },
    ],
  },
  {
    title: "Intervenants ",
    icon: User,
    type: "sub",
    active: false,
    children: [
      {
        path: `${process.env.PUBLIC_URL}/gest_intervenant/assurance`,
        title: "Assurance",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/gest_intervenant/courtiers`,
        title: "Courtiers",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/gest_intervenant/revendeurs`,
        title: "Revendeurs",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/gest_intervenant/reparateur`,
        title: "Réparateurs",
        type: "link",
      },
    ],
  },
  {
    title: "Formules et produits",
    icon: Framer,
    type: "sub",
    active: false,
    children: [
      {
        path: `${process.env.PUBLIC_URL}/gest_fromule_product/familles`,
        title: "Familles",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/gest_fromule_product/Catégories`,
        title: "Catégories",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/gest_fromule_product/Type_de_panne`,
        title: "Type de panne",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/gest_fromule_product/Formules`,
        title: "Formules",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/gest_fromule_product/Produits`,
        title: "Produits",
        type: "link",
      },
    ],
  },
  {
    title: "Clients",
    icon: UserCheck,
    type: "subLink",
    path: `${process.env.PUBLIC_URL}/gest_client_contrat/clients`,
    active: false,
  },
 
  {
    title: "Contrats",
    icon: FileText,
    type: "subLink",
    path: `${process.env.PUBLIC_URL}/gest_client_contrat/contrats`,
    active: false,
  },
  {
    title: "Contrats Inclusion",
    icon: FileText,
    type: "subLink",
    path: `${process.env.PUBLIC_URL}/gest_client_contrat/contract_inclusion`,
    active: false,
  },
  {
    title: "Sinistres",
    icon: Clipboard,
    type: "subLink",
    path: `${process.env.PUBLIC_URL}/gest_sinistre/sinistre`,
    active: false,
  },
  {
    title: "Sinistres Inclusion",
    icon: Clipboard,
    type: "subLink",
    path: `${process.env.PUBLIC_URL}/gest_sinistre/sinistre_inclusion`,
    active: false,
  },
  {
    title: "Réparations",
    icon: Tool,
    type: "subLink",
    path: `${process.env.PUBLIC_URL}/gest_sinistre/interventions`,
    active: false,
  },
  {
    title: "Bon d'achats",
    icon: Clipboard,
    type: "subLink",
    path: `${process.env.PUBLIC_URL}/voucher/list`,
    active: false,
  },
  {
    title: "État des ventes",
    icon: BookOpen,
    type: "sub",
    active: false,
    children: [
      {
        path: `${process.env.PUBLIC_URL}/etat-de-vente/Global`,
        title: "État Global",
        type: "link",
      },
      // {
      //   path: `${process.env.PUBLIC_URL}/etat-de-vente/par-mois`,
      //   title: "État mensuel",
      //   type: "link",
      // },
    ],
  },
];
export const FRONTOFFICE = [
  {
    title: "Clients",
    icon: UserCheck,
    type: "subLink",
    path: `${process.env.PUBLIC_URL}/gest_client_contrat/clients`,
    active: false,
  },
  {
    title: "Contrats",
    icon: FileText,
    type: "subLink",
    path: `${process.env.PUBLIC_URL}/gest_client_contrat/contrats`,
    active: false,
  },
  {
    title: "Contrats Inclusion",
    icon: FileText,
    type: "subLink",
    path: `${process.env.PUBLIC_URL}/gest_client_contrat/contract_inclusion`,
    active: false,
  },
  {
    title: "Sinistres",
    icon: Clipboard,
    type: "subLink",
    path: `${process.env.PUBLIC_URL}/gest_sinistre/sinistre`,
    active: false,
  },
  {
    title: "Sinistres Inclusion",
    icon: Clipboard,
    type: "subLink",
    path: `${process.env.PUBLIC_URL}/gest_sinistre/sinistre_inclusion`,
    active: false,
  },
  {
    title: "Nouvelle Vente",
    icon: Airplay,
    type: "subLink",
    active: false,
    path: `${process.env.PUBLIC_URL}/new-sale`,
  },
];
export const DISTMENUITEMS = [
  {
    title: "Nouvelle Vente",
    icon: Airplay,
    type: "subLink",
    active: false,
    path: `${process.env.PUBLIC_URL}/new-sale`,
  },
  {
    title: "Notice d'information",
    icon: Save,
    type: "subLink",
    active: false,
    path: `${process.env.PUBLIC_URL}/Notice`,
  },
  // {
  //   title: "Manuel d'utilisation",
  //   icon: Save,
  //   type: "subLink",
  //   active: false,
  //   path: `${process.env.PUBLIC_URL}/Manuel-utilisation`,
  // },
];
