import React, { useState, useEffect, Fragment } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  Media,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import { addCourtier } from "../../../redux/courtier/courtierActions";

import { useHistory } from "react-router-dom";
import Dropzone from "react-dropzone-uploader";
import { findLastIndex } from "lodash";

const CreateCourtier = ({ location, i18n }) => {
  const history = useHistory();
  const [courtier, setCourtier] = useState({
    commission: 0,
    isActive: 1,
    isDeleted: 0,
    socialReason: "",
  });
  const [checked, setChecked] = useState(true);
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const courtierAdd = useSelector(
    ({ courtierReducers }) => courtierReducers.courtiers
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCourtier((courtier) => ({ ...courtier, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSubmitted(true);

    await dispatch(addCourtier(courtier));
    toast.success("courtier created successfuly");
    history.push("/gest_intervenant/courtiers");
  };

  return (
    <Fragment>
      <Breadcrumb parent="Courtier" title="Nouveau Courtier" />
      <Container fluid={true}>
        <Form
          className="needs-validation"
          noValidate=""
          onSubmit={handleSubmit}
        >
          <Row>
            <Col>
              <Card className="shadow-lg p-0 shadow-showcase">
                <CardHeader className="b-l-primary pb-2">
                  {/* <h5>Info Generale</h5> */}
                </CardHeader>
                <CardBody className="pt-1">
                  <div className="form-row">
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom01">
                        Courtier
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="socialReason"
                        type="text"
                        placeholder="courtier"
                        innerRef=""
                        value={courtier.socialReason}
                        onChange={handleChange}
                      />
                      <span></span>
                    </Col>
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom01">
                        Adresse
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="Adresse"
                        type="text"
                        placeholder="Adresse"
                        innerRef=""
                        value=""
                        // onChange=""
                      />
                      <span></span>
                    </Col>
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom01">
                        Matricule Fiscale
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="ident_tax"
                        type="text"
                        placeholder="Matricule Fiscale"
                        innerRef=""
                        value=""
                        // onChange=""
                      />
                      <span></span>
                    </Col>
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom01">
                        Téléphone
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="tel"
                        type="text"
                        placeholder="Téléphone"
                        innerRef=""
                        value=""
                        // onChange=""
                      />
                      <span></span>
                    </Col>
                    <Col md="4 mb-3">
                      <div
                        class="form-check form-switch"
                        style={{
                          textAlign: "center",
                          marginTop: "32px",
                        }}
                      >
                        <input
                          class="form-check-input"
                          // style={{ marginTop: "7%" }}
                          name="isActive"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                          checked={checked}
                          value="0"
                          onClick={(e) =>
                            checked
                              ? setChecked(false) & handleChange(e)
                              : setChecked(true) &
                                setCourtier((courtier) => ({
                                  ...courtier,
                                  isActive: 1,
                                }))
                          }
                        />
                        <label
                          class="form-check-label"
                          for="flexSwitchCheckDefault"
                        >
                          Actif
                        </label>
                      </div>
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Button
                color="danger"
                type="reset"
                className="float-left"
                onClick={() => history.push("/gest_intervenant/courtiers")}
              >
                Retour
              </Button>
            </Col>
            <Col>
              <Button color="primary" type="submit" className="float-right">
                Valider
              </Button>
            </Col>
          </Row>
          {/* <Button color="primary">{config.button}</Button>
                  <Button color="primary">{config.button}</Button> */}
        </Form>
      </Container>
    </Fragment>
  );
};

export default CreateCourtier;
