import { adminAxios } from "../../config/axiosConfig/InstancesAxios";

export default {
  getAllProfiles: (uid) => {
    return adminAxios.post("/getAllProfiles", null, { params: { uid } });
  },

  createProfile: (Model) => {
    return adminAxios.post("/createProfile", Model);
  },

  updateProfile: (Model) => {
    return adminAxios.post("/updateProfile", Model);
  },
};
