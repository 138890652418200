import React, { useState, useEffect, Fragment } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  Media,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";

import typeUserService from "../../../services/typeUser/typeUserService";
import { useHistory, useLocation } from "react-router-dom";
import Dropzone from "react-dropzone-uploader";
import { findLastIndex } from "lodash";

const UpdateUserType = () => {
  let location = useLocation();
  const history = useHistory();

  const [typeUser, setTypeUser] = useState(
    location.state
      ? {
          code: location.state.row.code,
          designation: location.state.row.designation,
          id: location.state.row.id
        }
      : {}
  );
  const [submitted, setSubmitted] = useState(false);
  useEffect(() => {
    console.log(location.state.row);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTypeUser((typeUser) => ({ ...typeUser, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSubmitted(true);
    if (typeUser.designation && typeUser.code) {
      await typeUserService.updateTypeUser(typeUser);
      toast.success("type user updated successfully");
      history.push("/gest_utilisateur/user_type");
    }
  };

  return (
    <Fragment>
      <Breadcrumb parent="Type Utilisateur" title="Modifier Type" />
      <Container fluid={true}>
        <Form
          className="needs-validation"
          noValidate=""
          onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Card className="shadow-lg p-0 shadow-showcase">
                <CardHeader className="b-l-primary pb-2">
                  <h5>Info Generale</h5>
                </CardHeader>
                <CardBody className="pt-1">
                  <div className="form-row">
                    <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                        Désignation
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="designation"
                        type="text"
                        placeholder="Désignation"
                        innerRef=""
                        defaultValue={typeUser.designation}
                        onChange={handleChange}
                      />
                      <span></span>
                    </Col>
                    <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                        Code
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="code"
                        type="text"
                        placeholder="Code"
                        innerRef=""
                        defaultValue={typeUser.code}
                        onChange={handleChange}
                      />
                      <span></span>
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Button
                color="danger"
                type="reset"
                className="float-left"
                onClick={() => history.push("/gest_utilisateur/user_type")}>
                Retour
              </Button>
            </Col>
            <Col>
              <Button color="primary" type="submit" className="float-right">
                Valider
              </Button>
            </Col>
          </Row>
          {/* <Button color="primary">{config.button}</Button>
                  <Button color="primary">{config.button}</Button> */}
        </Form>
      </Container>
    </Fragment>
  );
};

export default UpdateUserType;
