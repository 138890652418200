import distributorService from "../../services/distribitor/distributorService";

export const FETCH_DISTRIBUTORS_REQUEST = "FETCH_DISTRIBUTORS_REQUEST";
export const FETCH_DISTRIBUTORS_SUCCESS = "FETCH_DISTRIBUTORS_SUCCESS";
export const FETCH_DISTRIBUTORS_FAILURE = "FETCH_DISTRIBUTORS_FAILURE";
export const FETCH_DISTRIBUTORS_CONTRACT = "FETCH_DISTRIBUTORS_CONTRACT";

export const saveContractDetails = (data) => {
  return (dispatch) => {
    dispatch(Request(data));
  };
};

export const fetchDistributor = () => {
  return (dispatch) => {
    const uid = localStorage.getItem('id')
    dispatch(fetchDistributorsRequest());
    distributorService
      .getAllDistributors(uid)
      .then((response) => {
        // response.data is the
        console.log(response);
        const distributors = response.data;
        dispatch(fetchDistributorsSuccess(distributors));
      })
      .catch((error) => {
        // error.message is the error message
        dispatch(fetchDistributorsFailure(error.message));
      });
  };
};

export const Request = (data) => {
  return {
    type: FETCH_DISTRIBUTORS_CONTRACT,
    payload: data,
  };
};

export const fetchDistributorsRequest = () => {
  return {
    type: FETCH_DISTRIBUTORS_REQUEST,
  };
};

export const fetchDistributorsSuccess = (distributors) => {
  return {
    type: FETCH_DISTRIBUTORS_SUCCESS,
    payload: distributors,
  };
};

export const fetchDistributorsFailure = (error) => {
  return {
    type: FETCH_DISTRIBUTORS_FAILURE,
    payload: error,
  };
};
