import React, { Fragment, useState } from "react";
import man from "../../assets/images/dashboard/profile.jpg";
import {
  FileText,
  LogIn,
  Mail,
  User,
  MessageSquare,
  Bell,
  Minimize,
  MoreHorizontal,
} from "react-feather";
import { useHistory } from "react-router";
const Rightbar = (props) => {
  const history = useHistory();
  const [mobileToggle, setMobiletoggle] = useState(true);
  const role = localStorage.getItem("role");
  const logOut = () => {
    sessionStorage.removeItem("token");
    localStorage.clear();
    history.push("/login");
  };

  const mobileRightToggle = (mobiletoggle) => {
    if (mobiletoggle) {
      setMobiletoggle(!mobiletoggle);
      document.querySelector(".nav-menus").classList.add("open");
    } else {
      setMobiletoggle(!mobiletoggle);
      document.querySelector(".nav-menus").classList.remove("open");
    }
  };

  //full screen function
  function goFull() {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }
  return (
    <Fragment>
      <div className="nav-right col pull-right right-menu">
        <ul className="nav-menus">
          <li>
            <a className="text-dark" href="#javascript" onClick={goFull}>
              <Minimize />
            </a>
          </li>
          <li className="onhover-dropdown p-0">
            <div className="media profile-media">
              {/* <img className="b-r-10" src={man}   alt=""/> */}
              <div className="media-body">
                <span>{localStorage.getItem("name")}</span>
                <p className="mb-0 font-roboto">
                  {role} <i className="middle fa fa-angle-down"></i>
                </p>
              </div>
            </div>
            <ul
              className="profile-dropdown onhover-show-div"
              style={{ width: "185px", marginRight: "10px" }}
            >
              <li>
                <User />
                <span>Mon compte</span>
              </li>
              {/* <li><Mail/><span>Inbox</span></li> */}
              {/* <li><FileText/><span>Taskboard</span></li> */}
              <li onClick={logOut}>
                <LogIn />
                <span>Se déconnecter</span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div className="d-lg-none mobile-toggle pull-right">
        <MoreHorizontal onClick={() => mobileRightToggle(mobileToggle)} />
      </div>
    </Fragment>
  );
};

export default Rightbar;
