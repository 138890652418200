import categoryService from "../../services/category/categoryService";

export const FETCH_CATEGORYS_REQUEST = "FETCH_CATEGORYS_REQUEST";
export const FETCH_CATEGORYS_SUCCESS = "FETCH_CATEGORYS_SUCCESS";
export const FETCH_CATEGORYS_FAILURE = "FETCH_CATEGORYS_FAILURE";
export const ADD_CATEGORYS_REQUEST = "ADD_CATEGORYS_REQUEST";
export const ADD_CATEGORYS_SUCCESS = "ADD_CATEGORYS_SUCCESS";
export const ADD_CATEGORYS_FAILURE = "ADD_CATEGORYS_FAILURE";

export const fetchCategory = () => {
  return (dispatch) => {
    dispatch(fetchCategorysRequest());
    categoryService
      .getAllCategory()
      .then((response) => {
        // response.data is the CategoryS
        const CategoryS = response.data;
        dispatch(fetchCategorysSuccess(CategoryS));
      })
      .catch((error) => {
        // error.message is the error message
        dispatch(fetchCategorysFailure(error.message));
      });
  };
};

export const fetchCategorysRequest = () => {
  return {
    type: FETCH_CATEGORYS_REQUEST
  };
};

export const fetchCategorysSuccess = (category) => {
  return {
    type: FETCH_CATEGORYS_SUCCESS,
    payload: category
  };
};

export const fetchCategorysFailure = (error) => {
  return {
    type: FETCH_CATEGORYS_FAILURE,
    payload: error
  };
};

export const addCategory = (data) => {
  return (dispatch) => {
    dispatch(addCategorysRequest(data));
    categoryService.createNewCategory(data).then(
      (response) => {
        dispatch(addCategorysSuccess(response.data));
      },
      (error) => {
        dispatch(addCategorysFailure(error.messege));
      }
    );
  };
};

export const addCategorysRequest = () => {
  return {
    type: ADD_CATEGORYS_REQUEST
  };
};

export const addCategorysSuccess = (category) => {
  return {
    type: ADD_CATEGORYS_SUCCESS,
    payload: category
  };
};

export const addCategorysFailure = (error) => {
  return {
    type: ADD_CATEGORYS_FAILURE,
    payload: error
  };
};
