import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { useHistory ,useLocation} from "react-router-dom";
import { Card, CardBody, CardHeader, Container, Row ,Col , Button, FormGroup , Input, Form } from "reactstrap";
import Breadcrumbs from "../../../layout/breadcrumb";
import contractServices from "../../../services/contract/contractServices";
import CardDash from "../../distributeur/dashCard/CardDash";
import DatePicker from "react-datepicker";
import moment from "moment";

const DashboardA = () => {
  const history = useHistory();
  const location = useLocation();
  const [total, setTotal] = useState(0);
  const [commisToT, setCommisToT] = useState(0);
  const [fronBHTot, setFrontBHTot] = useState(0);
  const [fronCourtTot, setFronCourTot] = useState(0);
  const [commisToTMn, setCommisToTMn] = useState(0);
  const [fronBHTotMn, setFrontBHTotMn] = useState(0);
  const [fronCourtTotMn, setFronCourTotMn] = useState(0);
  const [totalMonth, setTotalMonth] = useState(0);
  const [handleFilter, setHandleFilter] = useState(true);
  const back = location?.state?.back;
  const [filteredValues, setFilteredValues] = useState({
    cin: "",
    dateDebut: "",
    dateFin: "",
    famille: "",
    firstName: "",
    formula: "",
    idUser: localStorage.getItem("id"),
    lastName: "",
    idDistributor: null,
    numContract: "",
    status: "",
    tax: "",
    inclusion: 0,
  });
  useEffect(() => {
    if(localStorage.getItem("role") !== "Assurance " && localStorage.getItem("role") !== "Courtier "){
      history.goBack() 
     }
    const id = localStorage.getItem("id");
    // contractServices.getAllcontractswithoutpagination(id)
    if(localStorage.getItem("id") === "6a7d1b9c-254c-455e-a186-6b2a37dd231f"){
      contractServices
    .filterContract({
      cin: "",
      dateDebut: "",
      dateFin: "2024-11-28T00:00:00.000Z",
      famille: "",
      firstName: "",
      formula: "",
      idUser: "4027c224-04fe-4542-be2d-42193bb47407",
      lastName: "",
      numContract: "",
      status: "",
      tax: "",
      inclusion: 0,
    }).then((res) => {
      var filtredStatus = res.data?.filter((el) => el.status !== "C");
      if(res.status === 200){
        var tot = 0;
        var fronBH = 0;
        var fronCour = 0;
        var comm = 0;
        filtredStatus?.forEach((element) => {
          tot += element.totalAmount;
          fronBH += element.frontingHtAssurance;
          fronCour += element.frontingHtCourtier;
          comm += (element.margeDistributeurTtc + element.margeRevendeurTtc);
        });
        setCommisToT(comm);
        setFrontBHTot(fronBH);
        setFronCourTot(fronCour);
        setTotal(tot);
      }else {
        setCommisToT(0);
        setFrontBHTot(0);
        setFronCourTot(0);
        setTotal(0);
      }
    });
    // contractServices.getAllcontractsBymois
    contractServices
      .filterContract({
        cin: "",
        dateDebut: "",
        dateFin: "2024-11-28T00:00:00.000Z",
        famille: "",
        firstName: "",
        formula: "",
        idUser: "4027c224-04fe-4542-be2d-42193bb47407",
        lastName: "",
        numContract: "",
        status: "",
        tax: "",
        inclusion: 0,
      }).then((res) => {
      var filtredStatus = res.data?.filter((el) => el.status !== "C");
        var totM = 0;
        var fronBH = 0;
        var fronCour = 0;
        var comm = 0;
        filtredStatus?.map((element) => {
          totM += element.totalAmount; 
          fronBH += element.frontingHtAssurance;
          fronCour += element.frontingHtCourtier;
          comm += element.margeDistributeurHt;
        });
        setCommisToTMn(comm);
        setFrontBHTotMn(fronBH);
        setFronCourTotMn(fronCour);
        setTotalMonth(totM);
      
    }).catch((Error)=>{
      setCommisToTMn(0);
        setFrontBHTotMn(0);
        setFronCourTotMn(0);
        setTotalMonth(0);
    });
    }
    contractServices
    .filterContract({
      cin: "",
      dateDebut: "",
      dateFin: "",
      famille: "",
      firstName: "",
      formula: "",
      idUser: localStorage.getItem("id"),
      lastName: "",
      idDistributor: null,
      numContract: "",
      status: "",
      tax: "",
      inclusion: 0,
    }).then((res) => {
      var filtredStatus = res.data?.filter((el) => el.status !== "C");
      if(res.status === 200){
        var tot = 0;
        var fronBH = 0;
        var fronCour = 0;
        var comm = 0;
        filtredStatus?.forEach((element) => {
          tot += element.totalAmount;
          fronBH += element.frontingHtAssurance;
          fronCour += element.frontingHtCourtier;
          comm += (element.margeDistributeurTtc + element.margeRevendeurTtc);
        });
        setCommisToT(comm);
        setFrontBHTot(fronBH);
        setFronCourTot(fronCour);
        setTotal(tot);
      }else {
        setCommisToT(0);
        setFrontBHTot(0);
        setFronCourTot(0);
        setTotal(0);
      }
    });
    // contractServices.getAllcontractsBymois
    contractServices
      .filterContract({
        cin: "",
        dateDebut: "",
        dateFin: "",
        famille: "",
        firstName: "",
        formula: "",
        idUser: localStorage.getItem("id"),
        lastName: "",
        idDistributor: null,
        numContract: "",
        status: "",
        tax: "",
        inclusion: 0,
      }).then((res) => {
      var filtredStatus = res.data?.filter((el) => el.status !== "C");
        var totM = 0;
        var fronBH = 0;
        var fronCour = 0;
        var comm = 0;
        filtredStatus?.map((element) => {
          totM += element.totalAmount; 
          fronBH += element.frontingHtAssurance;
          fronCour += element.frontingHtCourtier;
          comm += element.margeDistributeurHt;
        });
        setCommisToTMn(comm);
        setFrontBHTotMn(fronBH);
        setFronCourTotMn(fronCour);
        setTotalMonth(totM);
      
    }).catch((Error)=>{
      setCommisToTMn(0);
        setFrontBHTotMn(0);
        setFronCourTotMn(0);
        setTotalMonth(0);
    });
  }, []);
  const handleFilterValues = (value, name) => {
    setFilteredValues({ ...filteredValues, [name]: value });
  };
  const filterData =  async() => {
     await  contractServices.filterContract({
        ...filteredValues,
        dateDebut: moment(filteredValues.dateDebut).add(1, "hour"),
        dateFin: moment(filteredValues.dateFin).add(1, "hour"),
      }).then(res=>{
        var filtredStatus = res.data?.filter((el) => el.status !== "C");
        var totM = 0;
        var fronBH = 0;
        var fronCour = 0;
        var comm = 0;
        filtredStatus?.map((element) => {
          totM += element.totalAmount; 
          fronBH += element.frontingHtAssurance;
          fronCour += element.frontingHtCourtier;
          comm += element.margeDistributeurHt;
        });
        setCommisToTMn(comm);
        setFrontBHTotMn(fronBH);
        setFronCourTotMn(fronCour);
        setTotalMonth(totM);
      
    }).catch((Error)=>{
      setCommisToTMn(0);
        setFrontBHTotMn(0);
        setFronCourTotMn(0);
        setTotalMonth(0);
    });
      
  }
  return (
    <Fragment>
      <Breadcrumbs parent="Dashbord" title={"Tableau de bord"} />
      <Container fluid={true}>
         { localStorage.getItem("id") === "6a7d1b9c-254c-455e-a186-6b2a37dd231f" ? <></> : <Card className="shadow-lg p-0 shadow-showcase">
      <CardHeader
            className="ml-2"
            style={{ cursor: "pointer", padding: "10px" }}
            onClick={() => {
              setHandleFilter(!handleFilter);
            }}
          >
            <span className="float-left">
              <h6 className="mt-1">{"Filtrer"}</h6>
            </span>

            <span className="float-right ">
              <h4>
                {handleFilter ? (
                  <i className="fa fa-angle-down"></i>
                ) : (
                  <i className="fa fa-angle-up"></i>
                )}
              </h4>
            </span>
          </CardHeader>
          <CardBody className="pt-0" hidden={back ? true : handleFilter}>
            <Form className="theme-form">
              <Row>
                <Col md="3 mb-2">
                  <FormGroup>
                    <label className="col-form-label"> Du</label>
                    <DatePicker
                      className="form-control digits"
                      dateFormat="dd/MM/yyyy"
                      selected={filteredValues.dateDebut || ""}
                      onChange={(e) => {
                        handleFilterValues(e, "dateDebut");
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="3 mb-2">
                  <FormGroup>
                    <label className="col-form-label"> Au</label>
                    <DatePicker
                      className="form-control digits"
                      dateFormat="dd/MM/yyyy"
                      selected={filteredValues.dateFin || ""}
                      onChange={(e) => {
                        handleFilterValues(e, "dateFin");
                      }}
                    />
                  </FormGroup>
                </Col>
                {/* <Col md="3 mb-2">
                  <FormGroup>
                    <label className="col-form-label">Type Vente </label>
                    <Input
                      type="select"
                      name="select"
                      className="form-control digits"
                      defaultValue={""}
                      onChange={(e) =>
                        handleFilterValues(e.target.value, "inclusion")
                      }
                    >
                      <option value={0}>{"vente normal"}</option>
                      <option value={1}>{"vente inclusion"}</option>
                      
                    </Input>
                  </FormGroup>
                </Col> */}
              </Row>
            </Form>
            <Row>
              <Col>
                <Button
                  onClick={filterData}
                  className="btn-pill btn-air-success"
                  color="success"
                >
                  Recherche
                </Button>
              </Col>
            </Row>
          </CardBody>
          </Card>}
        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader className="m-0 pb-0">
            
          </CardHeader>
          <CardBody className="pt-2">
            <Row md="12 mb-3">
              <CardDash
                name="Prime TTC du mois"
                total={`${(totalMonth)?.toFixed(3)} DT`}
                icon={`${process.env.PUBLIC_URL}/assets/images/chart.svg`}
                pointer={true}
                // onClick={versEtat}
              />
              <CardDash
                name="Frais Fronting Assurance du mois"
                total={`${(fronBHTotMn / 1).toFixed(3)} DT`}
                icon={`${process.env.PUBLIC_URL}/assets/images/commission.png`}
                pointer={true}
                // onClick={versEtat}
              />

              {/* <CardDash
                name="Frais Fronting GST TTC du mois"
                total={`${(fronCourtTotMn / 1).toFixed(3)} DT`}
                icon={`${process.env.PUBLIC_URL}/assets/images/reverse.png`}
                pointer={true}
                // onClick={versEtat}
              /> */}
              {/* <CardDash
                name="Marge dst TTC du mois"
                total={`${(commisToTMn / 1).toFixed(3)} DT`}
                icon={`${process.env.PUBLIC_URL}/assets/images/reverse.png`}
                pointer={true}
                // onClick={versEtat}
              /> */}
              <CardDash
                name="Prime Net Garanty TTC du mois"
                total={`${(
                  (totalMonth)?.toFixed(3) -
                  commisToTMn -
                  fronCourtTotMn -
                  fronBHTotMn
                ).toFixed(3)} DT`}
                icon={`${process.env.PUBLIC_URL}/assets/images/reverse.png`}
                pointer={true}
                // onClick={versEtat}
              />
            </Row>
            <Row md="12 mb-3">
              {/* <CardDash
                name="Prime TTC Total"
                total={`${(total)?.toFixed(3)} DT`}
                icon={`${process.env.PUBLIC_URL}/assets/images/chart.svg`}
              />
              <CardDash
                name="Frais Fronting Assurance Total"
                total={`${(fronBHTot / 1).toFixed(3)} DT`}
                icon={`${process.env.PUBLIC_URL}/assets/images/commission.png`}
              /> */}
              {/* <CardDash
                name="Frais Fronting GST TTC Total"
                total={`${(fronCourtTot / 1).toFixed(3)} DT`}
                icon={`${process.env.PUBLIC_URL}/assets/images/reverse.png`}
              /> */}
              {/* <CardDash
                name="Total comlission dst"
                total={`${(commisToT / 1).toFixed(3)} DT`}
                icon={`${process.env.PUBLIC_URL}/assets/images/reverse.png`}
              /> */}
              {/* <CardDash
                name="Prime Net Garanty TTC Total"
                total={`${(
                  (total)?.toFixed(3) -
                  commisToT -
                  fronCourtTot -
                  fronBHTot
                ).toFixed(3)} DT`}
                icon={`${process.env.PUBLIC_URL}/assets/images/reverse.png`}
              /> */}
            </Row>
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
};

export default DashboardA;
