import typeUserService from "../../services/typeUser/typeUserService";

export const FETCH_TYPEUSERS_REQUEST = "FETCH_TYPEUSERS_REQUEST";
export const FETCH_TYPEUSERS_SUCCESS = "FETCH_TYPEUSERS_SUCCESS";
export const FETCH_TYPEUSERS_FAILURE = "FETCH_TYPEUSERS_FAILURE";



export const fetchTypeUser = () => {
  return (dispatch) => {
    const uid= localStorage.getItem("id")
    dispatch(fetchTypeUsersRequest());
    typeUserService
      .getAllTypeUser(uid)
      .then((response) => {
        // response.data is the 
        console.log(response)
        const TypeUsers = response.data;
        dispatch(fetchTypeUsersSuccess(TypeUsers));
      })
      .catch((error) => {
        // error.message is the error message
        dispatch(fetchTypeUsersFailure(error.message));
      });
  };
};

export const fetchTypeUsersRequest = () => {
  return {
    type: FETCH_TYPEUSERS_REQUEST
  };
};

export const fetchTypeUsersSuccess = (TypeUsers) => {
  return {
    type: FETCH_TYPEUSERS_SUCCESS,
    payload: TypeUsers
  };
};

export const fetchTypeUsersFailure = (error) => {
  return {
    type: FETCH_TYPEUSERS_FAILURE,
    payload: error
  };
};


