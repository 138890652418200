import React, { useState, useEffect, Fragment } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  Media,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import Board, { moveCard } from "@asseinfo/react-kanban";
import "@asseinfo/react-kanban/dist/styles.css";

import { useHistory } from "react-router-dom";
import Dropzone from "react-dropzone-uploader";
import { differenceBy } from "lodash";
import Switch from "react-switch";
import Dragula from "react-dragula";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { fetchFamily } from "../../../redux/family/familyActions";
import { addFormula } from "../../../redux/formula/formulaActions";
import { fetchBreakdownTypeByIdFamily } from "../../../redux/breakdownType/breakdownTypeActions";
import formulaService from "../../../services/formula/formulaService";

const CreateFormule = ({ location }) => {
  const history = useHistory();
  const rowData = location.state?.row;
  const update = location.state?.update;
  const previewOnly = !update && rowData?.idformula;

  const [formula, setformula] = useState({
    ageProduct: "",
    dateCreation: new Date().toISOString(),
    frameValue: 0,
    glassValue: 0,
    idfamily: "",
    isassiste: 0,
    isenabled: 1,
    listBreakdownFormulaDto: "",
    maxValueProd: "",
    minValueProd: "",
    name: "",
    nbrIntervYear: "",
    other: "",
    price: "",
  });

  const dispatch = useDispatch();

  const [row, setRow] = useState(null);
  const children = {
    columns: [
      row?.breakdownFormulas || {
        id: 1,
        title: "",
        cards: [],
      },
    ],
  };
  const [selectOption, setOptionSelected] = useState([]);

  const [listBreakdownTypeByFamily, setBreakDownList] = useState([]);

  const [board, setBoard] = useState(children);

  const [breakdownFormule, setBreakdownFormule] = useState([]);

  const listFamily = useSelector(({ familyReducers }) => {
    return familyReducers.familys;
  });

  // const listBreakdownTypeByFamily = useSelector(({ breakdownTypeReducers }) => {
  //   return breakdownTypeReducers.breakdownTypesById;
  // });

  React.useEffect(() => {
    if (rowData?.idformula) {
      formulaService.getFormulaById(rowData.idformula).then((res) => {
        setRow(res.data);
        setBreakdownFormule(
          res.data.breakdownFormulas.map((el) => ({
            idbreakdownType: el.idbreakdownF,
            enable: 1,
          }))
        );
        reset({ ...res.data, idfamily: res.data.family?.idfamily });
        dispatch(
          fetchBreakdownTypeByIdFamily(
            res.data.family?.idfamily,
            handleResponseBreakDown
          )
        );
      });
    } else {
      dispatch(
        fetchBreakdownTypeByIdFamily(
          getValues("idfamily"),
          handleResponseBreakDown
        )
      );
    }
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    setValue,
  } = useForm();

  const onSubmit = (dataB) => {
    dispatch(
      addFormula(
        {
          ...dataB,
          breakdownFormulas: null,
          idformula: row?.idformula,
          isassiste: dataB.isassiste * 1,
          glassValue: 0,
          frameValue: 0,
          nbrIntervYear: dataB.nbrIntervYear * 1,
          frontingTtcAssurance: dataB.frontingTtcAssurance * 1 || 0,
          frontingTtcCourtier: dataB.frontingTtcCourtier * 1 || 0,
          frontingHtAssurance: dataB.frontingHtAssurance || 0,
          frontingHtCourtier: dataB.frontingHtCourtier || 0,
          netPriceHt: dataB.netPriceHt || 0,
          netPriceTtc: dataB.netPriceTtc || 0,
          ageProduct: dataB.ageProduct || 0,
          minValueProd: dataB.minValueProd || 0,
          maxValueProd: dataB.maxValueProd || 0,
          listBreakdownFormulaDto: breakdownFormule,
          isenabled: 1,
        },
        handleResponse
      )
    );
  };

  useEffect(() => {
    setBreakdownFormule(
      board.columns[1]?.cards.map((el) => ({
        idbreakdownType: el.id,
        enable: 1,
      }))
    );
  }, [board]);

  useEffect(() => {
    dispatch(fetchFamily());
  }, []);
  console.log({ board });
  useEffect(() => {
    if (listBreakdownTypeByFamily) {
      setBoard({
        columns: [
          {
            id: 1,
            title: "Liste des pannes",
            cards:
              getValues("idfamily") === row?.family?.idfamily
                ? differenceBy(
                    listBreakdownTypeByFamily,
                    row?.breakdownFormulas.map((el) => el.breakdownType),
                    "idbreakdown"
                  ).map((el) => ({
                    id: el.idbreakdown,
                    title: el.name,
                  }))
                : listBreakdownTypeByFamily.map((el) => ({
                    id: el.idbreakdown,
                    title: el.name,
                  })),
          },
          {
            id: 2,
            title: "Pannes à inclure",
            cards:
              (getValues("idfamily") === row?.family?.idfamily &&
                row?.breakdownFormulas?.map((el) => ({
                  id: el.idbreakdownF,
                  title: el.breakdownType.name,
                }))) ||
              [],
          },
        ],
      });
    }
  }, [listBreakdownTypeByFamily, row]);

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setformula((formula) => ({ ...formula, [name]: value }));
  // };
  const handleResponseBreakDown = (data) => {
    setBreakDownList(data);
  };

  const onchangeSelect = (item) => {
    setValue("idfamily", item.value);
    dispatch(fetchBreakdownTypeByIdFamily(item.value, handleResponseBreakDown));
  };

  const onCardMove = (card, source, destination) => {
    const updatedBoard = moveCard(board, source, destination);

    setBoard(updatedBoard);
  };

  const handleResponse = ({ response, update }) => {
    if (response) {
      toast.success("La formule a été créée avec succès ");
      history.push("/gest_fromule_product/Formules");
    } else {
      toast.error("Erreur lors de la création de formule");
    }
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="Formules"
        title={
          update
            ? "Modifier Formule"
            : row?.idformula
            ? "Formule"
            : "Nouvelle Formule"
        }
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <h5>Formule</h5>
              </CardHeader>
              <CardBody>
                <Form
                  className="needs-validation"
                  noValidate=""
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-row">
                    <Col md="4 mb-4">
                      <Label htmlFor="validationCustom01">
                        Famille
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <select
                        className="form-control"
                        name="idfamily"
                        type="select"
                        placeholder="Famille"
                        innerRef=""
                        disabled={previewOnly}
                        {...register("idfamily")}
                        onChange={(e) => onchangeSelect(e.target)}
                      >
                        {listFamily.map((el) => (
                          <option value={el.idfamily}>{el.familyName}</option>
                        ))}
                      </select>
                      <span></span>
                    </Col>
                    {/* <Col md="4 mb-4">
                      <Label htmlFor="validationCustom01">
                        Famille
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Select
                        value={selectOption}
                        onChange={onchangeSelect}
                        options={optionFamily}
                        innerRef={register("family").ref}
                      />
                      <span></span>
                    </Col> */}
                    <Col md="4 mb-4">
                      <Label htmlFor="validationCustom02">
                        Nom
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <input
                        className="form-control"
                        name="name"
                        type="text"
                        placeholder="Nom"
                        disabled={previewOnly}
                        // value={formula.name}
                        // onChange={handleChange}
                        {...register("name", { required: true })}
                      />
                      <span></span>
                    </Col>
                    <Col md="4 mb-4">
                      <Label htmlFor="validationCustom02">
                        Prix Formule
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <input
                        className="form-control"
                        name="price"
                        type="number"
                        placeholder="Prix Formule"
                        disabled={previewOnly}
                        {...register("price", { required: true })}
                      />
                      <span></span>
                    </Col>
                    <Col md="4 mb-4">
                      <Label htmlFor="validationCustom02">
                        Prix Min Produit
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <input
                        className="form-control"
                        name="minValueProd"
                        type="number"
                        placeholder="Prix Min Produit"
                        disabled={previewOnly}
                        // value={formula.minValueProd}
                        // onChange={handleChange}
                        {...register("minValueProd", { required: true })}
                      />
                      <span></span>
                    </Col>
                    <Col md="4 mb-4">
                      <Label htmlFor="validationCustom02">
                        Prix Max Produit
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <input
                        className="form-control"
                        name="maxValueProd"
                        type="number"
                        placeholder="Prix Max Produit"
                        disabled={previewOnly}
                        // value={formula.maxValueProd}
                        // onChange={handleChange}
                        {...register("maxValueProd", { required: true })}
                      />
                      <span></span>
                    </Col>
                    <Col md="4 mb-4">
                      <Label htmlFor="validationCustom02">
                        Age Produit
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <input
                        className="form-control"
                        name="ageProduct"
                        type="number"
                        placeholder="Age Produit"
                        disabled={previewOnly}
                        // value={formula.ageProduct}
                        // onChange={handleChange}
                        {...register("ageProduct", { required: true })}
                      />
                      <span></span>
                    </Col>
                    <Col md="4 mb-4">
                      <Label htmlFor="validationCustom02">
                        Nbr intervention par ans
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <input
                        className="form-control"
                        name="nbrIntervYear"
                        type="number"
                        placeholder="Nbr intervention par ans"
                        disabled={previewOnly}
                        // value={formula.nbrIntervYear}
                        // onChange={handleChange}
                        {...register("nbrIntervYear", { required: true })}
                      />
                      <span></span>
                    </Col>
                    <Col
                      md="4 mb-4"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "3.5%",
                      }}
                    >
                      <Label htmlFor="validationCustom02">
                        Assisstance Tel
                        <span style={{ color: "red" }}>*</span>
                      </Label>

                      <input
                        className="checkbox"
                        style={{ marginLeft: "45%" }}
                        id="checkbox-light-15"
                        type="checkbox"
                        name="isassiste"
                        disabled={previewOnly}
                        // value="1"
                        // onChange={handleChange}
                        {...register("isassiste", { required: false })}
                      />
                    </Col>
                    <Col md="4 mb-4">
                      <Label htmlFor="validationCustom02">
                        Autre
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <input
                        className="form-control"
                        name="other"
                        type="text"
                        placeholder="autre"
                        disabled={previewOnly}
                        // value={formula.other}
                        // onChange={handleChange}
                        {...register("other", { required: true })}
                      />
                      <span></span>
                    </Col>
                    <Col md="8 mb-4">
                      <Board
                        onCardDragEnd={onCardMove}
                        disableColumnDrag={true}
                        disableCardDrag={previewOnly}
                      >
                        {board}
                      </Board>
                    </Col>
                  </div>
                  <br />

                  <h5>Détails des prix</h5>
                  <br />
                  <br />
                  <Row>
                    <Col md="4 mb-4">
                      <Label htmlFor="validationCustom02">
                        Fronting Assurance HT
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <input
                        className="form-control"
                        name="priceAHT"
                        type="number"
                        step="0.001"
                        placeholder=" Fronting Assurance HT"
                        disabled={previewOnly}
                        {...register("frontingHtAssurance", {
                          required: false,
                        })}
                      />
                      <span></span>
                    </Col>
                    <Col md="4 mb-4">
                      <Label htmlFor="validationCustom02">
                        Fronting Assurance TTC
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <input
                        className="form-control"
                        name="frontingTtcAssurance"
                        placeholder="Fronting Assurance TTC"
                        type="number"
                        step="0.001"
                        disabled={previewOnly}
                        {...register("frontingTtcAssurance", {
                          required: false,
                        })}
                      />
                      <span></span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4 mb-4">
                      <Label htmlFor="validationCustom02">
                        Fronting Courtier HT
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <input
                        className="form-control"
                        name="frontingHtCourtier"
                        type="number"
                        placeholder="Fronting Courtier HT"
                        step="0.001"
                        disabled={previewOnly}
                        {...register("frontingHtCourtier", { required: true })}
                      />
                      <span></span>
                    </Col>
                    <Col md="4 mb-4">
                      <Label htmlFor="validationCustom02">
                        Fronting Courtier TTC
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <input
                        className="form-control"
                        name="frontingTtcCourtier"
                        type="number"
                        placeholder="Fronting Courtier TTC"
                        step="0.001"
                        disabled={previewOnly}
                        // value={formula.minValueProd}
                        // onChange={handleChange}
                        {...register("frontingTtcCourtier", {
                          required: false,
                        })}
                      />
                      <span></span>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="4 mb-4">
                      <Label htmlFor="validationCustom02">
                        Prime net Garanty HT
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <input
                        className="form-control"
                        name="netPriceHt"
                        type="number"
                        disabled={previewOnly}
                        placeholder="Prime net Garanty HT"
                        step="0.001"
                        {...register("netPriceHt", {
                          required: false,
                          valueAsNumber: true,
                        })}
                      />
                      <span></span>
                    </Col>
                    <Col md="4 mb-4">
                      <Label htmlFor="validationCustom02">
                        Prime net Garanty TTC
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <input
                        className="form-control"
                        name="netPriceTtc"
                        placeholder="Prime net Garanty TTC"
                        type="number"
                        step="0.001"
                        disabled={previewOnly}
                        {...register("netPriceTtc", { required: true })}
                      />
                      <span></span>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Button
                        color="danger"
                        type="reset"
                        className="float-left"
                        onClick={() =>
                          history.push("/gest_fromule_product/Formules")
                        }
                      >
                        Retour
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        color="primary"
                        type="submit"
                        className="float-right"
                      >
                        Valider
                      </Button>
                    </Col>
                  </Row>
                  {/* <Button color="primary">{config.button}</Button>
                  <Button color="primary">{config.button}</Button> */}
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CreateFormule;
