import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";
import Breadcrumb from "../../../layout/breadcrumb";
import { fetchAssurance } from "../../../redux/assurance/assuranceActions";
import { fetchCourtier } from "../../../redux/courtier/courtierActions";
import { fetchProfile } from "../../../redux/profile/profileActions";
import { fetchDistributor } from "../../../redux/revendeur/distributorActions";
import { fetchTypeUser } from "../../../redux/typeUser/typeUserActions";
import userGarantieService from "../../../services/userGarantie/userGarantieService";
const UpdateUserGaranty = ({ i18n }) => {
  const location = useLocation();
  const preview = location.state?.preview;
  const history = useHistory();
  const [checked, setChecked] = useState(true);
  const [userGarantie, setUserGarantie] = useState({
    dateCreation: new Date().toISOString(),
    email: location.state?.row.email,
    firstName: location.state?.row.firstName,
    iduserG: location.state?.row.iduserG,
    isActive: location.state?.row.isActive,
    lastName: location.state?.row.lastName,
    phone: location.state?.row.phone,
  });

  const listDistributors = useSelector(
    ({ distributorReducers }) => distributorReducers.distributors
  );

  const listTypeUser = useSelector(
    ({ typeUserReducers }) => typeUserReducers.typeUsers
  );

  const listCourtier = useSelector(
    ({ courtierReducers }) => courtierReducers.courtiers
  );

  const listAssurance = useSelector(
    ({ assuranceReducers }) => assuranceReducers.assurances
  );

  const listProfile = useSelector(
    ({ profileReducers }) => profileReducers.profiles
  );

  const [typeUserOption, setTypeUserOption] = useState();
  const [distributorOption, setDistributorOption] = useState();
  const [assuranceOption, setAssuranceOption] = useState();
  const [courtierOption, setCourtierOption] = useState();
  const [profileOption, setProfileOption] = useState();

  const [selectTypeUserOption, selectSetTypeUserOption] = useState(null);
  const [selectDistributorOption, selectSetDistributorOption] = useState(null);
  const [selectAssuranceOption, selectSetAssuranceOption] = useState(null);
  const [selectCourtierOption, selectSetCourtierOption] = useState(null);
  const [selectProfileOption, selectSetProfileOption] = useState(null);
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  React.useEffect(() => {
    dispatch(fetchProfile());
    dispatch(fetchTypeUser());
    dispatch(fetchDistributor());
    dispatch(fetchCourtier());
    dispatch(fetchAssurance());
  }, []);
  React.useEffect(() => {
    if (location.state?.row) {
      selectSetTypeUserOption({
        value: location.state?.row.typeUser.id,
        label: location.state?.row.typeUser.designation,
      });
      selectSetProfileOption({
        value: location.state?.row.profile.idprofile,
        label: location.state?.row.profile.name,
      });
      selectSetDistributorOption({
        value: location.state?.row.disrtibutor?.iddisributor,
        label: location.state?.row.disrtibutor?.socialReason,
      });
    }
  }, []);
  useEffect(() => {
    setTypeUserOption(
      listTypeUser.map((el) => ({
        value: el.id,
        label: el.designation,
      }))
    );
  }, [listTypeUser]);

  useEffect(() => {
    setDistributorOption(
      listDistributors.map((el) => ({
        value: el.iddisributor,
        label: el.socialReason,
      }))
    );
  }, [listDistributors]);

  useEffect(() => {
    setAssuranceOption(
      listAssurance.map((el) => ({
        value: el.idinsurance,
        label: el.socialReason,
      }))
    );
  }, [listAssurance]);

  useEffect(() => {
    setCourtierOption(
      listCourtier.map((el) => ({
        value: el.idbroker,
        label: el.socialReason,
      }))
    );
  }, [listCourtier]);

  useEffect(() => {
    setProfileOption(
      listProfile.map((el) => ({
        value: el.idprofile,
        label: el.name,
      }))
    );
  }, [listProfile]);

  const onchangeSelectTypeUser = (item) => {
    selectSetTypeUserOption(item);
  };
  const onchangeSelectDistributorr = (item) => {
    selectSetDistributorOption(item);
  };
  const onchangeSelectAssurance = (item) => {
    selectSetAssuranceOption(item);
  };
  const onchangeSelectCourtier = (item) => {
    selectSetCourtierOption(item);
  };
  const onchangeSelectProfile = (item) => {
    selectSetProfileOption(item);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserGarantie((userGarantie) => ({ ...userGarantie, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSubmitted(true);

    if (
      Object.values(userGarantie).filter((el) => !el).length !== 0 ||
      !selectTypeUserOption.value ||
      !selectProfileOption.value
    ) {
      toast.warn("Prière de renseigner tous les champs obligatoires");
      return;
    }

    await userGarantieService.updateUser({
      ...userGarantie,
      idType: selectTypeUserOption?.value,
      idprofile: selectProfileOption?.value,
      idbroker: selectCourtierOption?.value,
      idinsurance: selectAssuranceOption?.value,
      iddisributor: selectDistributorOption?.value,
      isDeleted: 0,
    });
    toast.success("userGarantie Updated successfully");
    history.push("/gest_utilisateur/utilisateurs");
  };
  React.useEffect(() => {
    if (
      selectTypeUserOption?.label === "Assurance " &&
      location.state.row.insuance
    )
      selectSetAssuranceOption({
        value: location.state.row.insuance.idinsurance,
        label: location.state.row.insuance.socialReason,
      });

    if (
      selectTypeUserOption?.label === "Distributeur " &&
      location.state.row?.disrtibutor
    ) {
      selectDistributorOption({
        value: location.state.row.disrtibutor.iddisributor,
        label: location.state.row.disrtibutor.socialReason,
      });
    }

    if (
      selectTypeUserOption?.label === "Courtier " &&
      location.state.row?.brokerCompany
    ) {
      selectSetCourtierOption({
        value: location.state.row.brokerCompany.idbroker,
        label: location.state.row.brokerCompany.socialReason,
      });
    }
  }, [listAssurance]);

  if (selectTypeUserOption?.label === "Assurance ") {
    return (
      <Fragment>
        <Breadcrumb
          parent="Gestion des Utilisateurs / Utilisateur"
          title={preview ? "Utilisateur" : "Modification Utilisateur"}
        />
        <Container fluid={true}>
          <Form
            className="needs-validation"
            noValidate=""
            onSubmit={handleSubmit}
          >
            <Row>
              <Col>
                <Card className="shadow-lg p-0 shadow-showcase">
                  <CardHeader className="b-l-primary pb-2">
                    <h5>Détails du compte</h5>
                  </CardHeader>
                  <CardBody className="pt-1">
                    <div className="form-row">
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom02">
                          Type Utilisateur
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Select
                          isDisabled={preview}
                          required
                          value={selectTypeUserOption}
                          onChange={!preview && onchangeSelectTypeUser}
                          options={typeUserOption}
                        />
                      </Col>

                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom02">
                          Assurance
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Select
                          isDisabled={preview}
                          value={selectAssuranceOption}
                          onChange={onchangeSelectAssurance}
                          options={assuranceOption}
                        />
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom02">
                          Profil
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Select
                          isDisabled={preview}
                          value={selectProfileOption}
                          onChange={onchangeSelectProfile}
                          options={profileOption}
                        />
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom01">
                          Nom
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          disabled={preview}
                          required
                          className="form-control"
                          name="firstName"
                          type="text"
                          placeholder="Nom"
                          value={userGarantie.firstName}
                          onChange={handleChange}
                        />
                        <span></span>
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom01">
                          Prénom
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          disabled={preview}
                          required
                          className="form-control"
                          name="lastName"
                          type="text"
                          placeholder="Prénom"
                          innerRef=""
                          value={userGarantie.lastName}
                          onChange={handleChange}
                        />
                        <span></span>
                      </Col>
                    
                      <Col md="4 mb-3">
                        <div
                          class="form-check form-switch"
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <input
                            class="form-check-input"
                            style={{ marginTop: "7%" }}
                            disabled={preview}
                            name="isActive"
                            type="checkbox"
                            id="flexSwitchCheckDefault"
                            checked={checked}
                            value="B"
                            onClick={() => {
                              if (checked) {
                                setChecked(false);
                                setUserGarantie((userGarantie) => ({
                                  ...userGarantie,
                                  isActive: "0",
                                }));
                              } else {
                                setChecked(true);
                                setUserGarantie((userGarantie) => ({
                                  ...userGarantie,
                                  isActive: "1",
                                }));
                              }
                            }}
                          />
                          <label
                            class="form-check-label"
                            for="flexSwitchCheckDefault"
                          >
                            Actif
                          </label>
                        </div>
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom01">
                          Mobile
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          disabled={preview}
                          required
                          className="form-control"
                          name="phone"
                          type="text"
                          placeholder="Mobile"
                          value={userGarantie.phone}
                          onChange={handleChange}
                        />
                        <span></span>
                      </Col>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col>
                <Card className="shadow-lg p-0 shadow-showcase">
                  <CardHeader className="b-l-secondary pb-2">
                    <h5> Détails de connexion</h5>
                  </CardHeader>
                  <CardBody className="pt-1">
                    <div className="form-row">
                      <Col md="3 mb-2">
                        <Label htmlFor="validationCustom01">E-mail</Label>
                        <span style={{ color: "red" }}>*</span>
                        <Input
                          disabled={preview}
                          required
                          className="form-control"
                          name="email"
                          type="text"
                          placeholder="E-mail"
                          value={userGarantie.email}
                          onChange={handleChange}
                        />

                        <div className="valid-feedback">Looks good!</div>
                      </Col>
                      <Col md="3 mb-2">
                        <Label htmlFor="validationCustom02">Mot de passe</Label>
                        <span style={{ color: "red" }}>*</span>
                        <Input
                          disabled={preview}
                          className="form-control"
                          name="password"
                          type="password"
                          placeholder="Mot de passe"
                          value={userGarantie.password}
                          onChange={handleChange}
                        />

                        <div className="valid-feedback">Looks good!</div>
                      </Col>
                      <Col md="3 mb-2">
                        <Label htmlFor="validationCustom02">
                          Confirmation Mot de passe
                        </Label>
                        <span style={{ color: "red" }}>*</span>
                        <Input
                          disabled={preview}
                          className="form-control"
                          name="confirmPassword"
                          type="password"
                          placeholder="Confirmation Mot de passe"
                        />
                      </Col>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col>
                <Button
                  color="danger"
                  type="reset"
                  className="float-left"
                  onClick={() => history.push("/gest_utilisateur/utilisateurs")}
                >
                  Retour
                </Button>
              </Col>
              {!preview && (
                <Col>
                  <Button color="primary" type="submit" className="float-right">
                    Valider
                  </Button>
                </Col>
              )}
            </Row>
            
          </Form>
        </Container>
      </Fragment>
    );
  } else if (selectTypeUserOption?.label === "Courtier ") {
    return (
      <Fragment>
        <Breadcrumb
          parent="Gestion des Utilisateurs / Utilisateur"
          title={preview ? "Utilisateur" : "Modification Utilisateur"}
        />
        <Container fluid={true}>
          <Form
            className="needs-validation"
            noValidate=""
            onSubmit={handleSubmit}
          >
            <Row>
              <Col>
                <Card className="shadow-lg p-0 shadow-showcase">
                  <CardHeader className="b-l-primary pb-2">
                    <h5>Détails du compte</h5>
                  </CardHeader>
                  <CardBody className="pt-1">
                    <div className="form-row">
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom02">
                          Type Utilisateur
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Select
                          isDisabled={preview}
                          value={userGarantie.idType || selectTypeUserOption}
                          onChange={onchangeSelectTypeUser}
                          options={typeUserOption}
                        />
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom02">
                          Courtier
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Select
                          isDisabled={preview}
                          value={selectCourtierOption}
                          onChange={onchangeSelectCourtier}
                          options={courtierOption}
                        />
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom02">
                          Profil
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Select
                          isDisabled={preview}
                          value={selectProfileOption}
                          onChange={onchangeSelectProfile}
                          options={profileOption}
                        />
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom01">
                          Nom
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          disabled={preview}
                          required
                          className="form-control"
                          name="firstName"
                          type="text"
                          placeholder="Nom"
                          value={userGarantie.firstName}
                          onChange={handleChange}
                        />
                        <span></span>
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom01">
                          Prénom
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          disabled={preview}
                          required
                          className="form-control"
                          name="lastName"
                          type="text"
                          placeholder="Prénom"
                          value={userGarantie.lastName}
                          onChange={handleChange}
                        />
                        <span></span>
                      </Col>
                    
                      <Col md="4 mb-3">
                      <div
                        class="form-check form-switch"
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <input
                          class="form-check-input"
                          style={{ marginTop: "7%" }}
                          name="isActive"
                          type="checkbox"
                          disabled={preview}
                          id="flexSwitchCheckDefault"
                          checked={checked}
                          value="B"
                          onClick={() => {
                            if (checked) {
                              setChecked(false);
                              setUserGarantie((userGarantie) => ({
                                ...userGarantie,
                                isActive: "0",
                              }));
                            } else {
                              setChecked(true);
                              setUserGarantie((userGarantie) => ({
                                ...userGarantie,
                                isActive: "1",
                              }));
                            }
                          }}
                        />
                        <label
                          class="form-check-label"
                          for="flexSwitchCheckDefault"
                        >
                          Actif
                        </label>
                      </div>
                    </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom01">
                          Mobile
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          disabled={preview}
                          required
                          className="form-control"
                          name="phone"
                          type="text"
                          placeholder="Mobile"
                          value={userGarantie.phone}
                          onChange={handleChange}
                        />
                        <span></span>
                      </Col>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col>
                <Card className="shadow-lg p-0 shadow-showcase">
                  <CardHeader className="b-l-secondary pb-2">
                    <h5> Détails de connexion</h5>
                  </CardHeader>
                  <CardBody className="pt-1">
                    <div className="form-row">
                      <Col md="3 mb-2">
                        <Label htmlFor="validationCustom01">E-mail</Label>
                        <span style={{ color: "red" }}>*</span>
                        <Input
                          disabled={preview}
                          required
                          className="form-control"
                          name="email"
                          type="text"
                          placeholder="E-mail"
                          value={userGarantie.email}
                          onChange={handleChange}
                        />

                        <div className="valid-feedback">Looks good!</div>
                      </Col>
                      <Col md="3 mb-2">
                        <Label htmlFor="validationCustom02">Mot de passe</Label>
                        <span style={{ color: "red" }}>*</span>
                        <Input
                          disabled={preview}
                          className="form-control"
                          name="password"
                          type="password"
                          placeholder="Mot de passe"
                          value={userGarantie.password}
                          onChange={handleChange}
                        />

                        <div className="valid-feedback">Looks good!</div>
                      </Col>
                      <Col md="3 mb-2">
                        <Label htmlFor="validationCustom02">
                          Confirmation Mot de passe
                        </Label>
                        <span style={{ color: "red" }}>*</span>
                        <Input
                          disabled={preview}
                          className="form-control"
                          name="confirmPassword"
                          type="password"
                          placeholder="Confirmation Mot de passe"
                        />
                      </Col>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col>
                <Button
                  color="danger"
                  type="reset"
                  className="float-left"
                  onClick={() => history.push("/gest_utilisateur/utilisateurs")}
                >
                  Retour
                </Button>
              </Col>
              {!preview && (
                <Col>
                  <Button color="primary" type="submit" className="float-right">
                    Valider
                  </Button>
                </Col>
              )}
            </Row>
            
          </Form>
        </Container>
      </Fragment>
    );
  } else if (selectTypeUserOption?.label === "Distributeur") {
    return (
      <Fragment>
        <Breadcrumb
          parent="Gestion des Utilisateurs / Utilisateur"
          title={preview ? "Utilisateur" : "Modification Utilisateur"}
        />
        <Container fluid={true}>
          <Form
            className="needs-validation"
            noValidate=""
            onSubmit={handleSubmit}
          >
            <Row>
              <Col>
                <Card className="shadow-lg p-0 shadow-showcase">
                  <CardHeader className="b-l-primary pb-2">
                    <h5>Détails du compte</h5>
                  </CardHeader>
                  <CardBody className="pt-1">
                    <div className="form-row">
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom02">
                          Type Utilisateur
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Select
                          isDisabled={preview}
                          value={selectTypeUserOption}
                          onChange={onchangeSelectTypeUser}
                          options={typeUserOption}
                        />
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom02">
                          Revendeur
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Select
                          isDisabled={preview}
                          value={selectDistributorOption}
                          onChange={onchangeSelectDistributorr}
                          options={distributorOption}
                        />
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom02">
                          Profil
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Select
                          isDisabled={preview}
                          value={selectProfileOption}
                          onChange={onchangeSelectProfile}
                          options={profileOption}
                        />
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom01">
                          Nom
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          disabled={preview}
                          required
                          className="form-control"
                          name="firstName"
                          type="text"
                          placeholder="Nom"
                          value={userGarantie.firstName}
                          onChange={handleChange}
                        />
                        <span></span>
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom01">
                          Prénom
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          disabled={preview}
                          required
                          className="form-control"
                          name="lastName"
                          type="text"
                          placeholder="Prénom"
                          value={userGarantie.lastName}
                          onChange={handleChange}
                        />
                        <span></span>
                      </Col>
                    
                      <Col md="4 mb-3">
                      <div
                        class="form-check form-switch"
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <input
                          class="form-check-input"
                          style={{ marginTop: "7%" }}
                          name="isActive"
                          type="checkbox"
                          disabled={preview}
                          id="flexSwitchCheckDefault"
                          checked={checked}
                          value="B"
                          onClick={() => {
                            if (checked) {
                              setChecked(false);
                              setUserGarantie((userGarantie) => ({
                                ...userGarantie,
                                isActive: "0",
                              }));
                            } else {
                              setChecked(true);
                              setUserGarantie((userGarantie) => ({
                                ...userGarantie,
                                isActive: "1",
                              }));
                            }
                          }}
                        />
                        <label
                          class="form-check-label"
                          for="flexSwitchCheckDefault"
                        >
                          Actif
                        </label>
                      </div>
                    </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom01">
                          Mobile
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          disabled={preview}
                          required
                          className="form-control"
                          name="phone"
                          type="text"
                          placeholder="Mobile"
                          value={userGarantie.phone}
                          onChange={handleChange}
                        />
                        <span></span>
                      </Col>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col>
                <Card className="shadow-lg p-0 shadow-showcase">
                  <CardHeader className="b-l-secondary pb-2">
                    <h5> Détails de connexion</h5>
                  </CardHeader>
                  <CardBody className="pt-1">
                    <div className="form-row">
                      <Col md="3 mb-2">
                        <Label htmlFor="validationCustom01">E-mail</Label>
                        <span style={{ color: "red" }}>*</span>
                        <Input
                          disabled={preview}
                          required
                          className="form-control"
                          name="email"
                          type="text"
                          placeholder="E-mail"
                          value={userGarantie.email}
                          onChange={handleChange}
                        />

                        <div className="valid-feedback">Looks good!</div>
                      </Col>
                      <Col md="3 mb-2">
                        <Label htmlFor="validationCustom02">Mot de passe</Label>
                        <span style={{ color: "red" }}>*</span>
                        <Input
                          disabled={preview}
                          className="form-control"
                          name="password"
                          type="password"
                          placeholder="Mot de passe"
                          value={userGarantie.password}
                          onChange={handleChange}
                        />

                        <div className="valid-feedback">Looks good!</div>
                      </Col>
                      <Col md="3 mb-2">
                        <Label htmlFor="validationCustom02">
                          Confirmation Mot de passe
                        </Label>
                        <span style={{ color: "red" }}>*</span>
                        <Input
                          disabled={preview}
                          className="form-control"
                          name="confirmPassword"
                          type="password"
                          placeholder="Confirmation Mot de passe"
                        />
                      </Col>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col>
                <Button
                  color="danger"
                  type="reset"
                  className="float-left"
                  onClick={() => history.push("/gest_utilisateur/utilisateurs")}
                >
                  Retour
                </Button>
              </Col>
              {!preview && (
                <Col>
                  <Button color="primary" type="submit" className="float-right">
                    Valider
                  </Button>
                </Col>
              )}
            </Row>
            
          </Form>
        </Container>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Breadcrumb
          parent="Gestion des Utilisateurs / Utilisateur"
          title={preview ? "Utilisateur" : "Modification Utilisateur"}
        />
        <Container fluid={true}>
          <Form
            className="needs-validation"
            noValidate=""
            onSubmit={handleSubmit}
          >
            <Row>
              <Col>
                <Card className="shadow-lg p-0 shadow-showcase">
                  <CardHeader className="b-l-primary pb-2">
                    <h5>Détails du compte</h5>
                  </CardHeader>
                  <CardBody className="pt-1">
                    <div className="form-row">
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom02">
                          Type Utilisateur
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Select
                          // defaultValue={userGarantie.id}
                          isDisabled={preview}
                          value={selectTypeUserOption}
                          onChange={onchangeSelectTypeUser}
                          options={typeUserOption}
                        />
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom02">
                          Profil
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Select
                        isDisabled={preview}
                          value={selectProfileOption}
                          onChange={onchangeSelectProfile}
                          options={profileOption}
                        />
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom01">
                          Nom
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          disabled={preview}
                          required
                          className="form-control"
                          name="firstName"
                          type="text"
                          placeholder="Nom"
                          innerRef=""
                          value={userGarantie.firstName}
                          onChange={handleChange}
                        />
                        <span></span>
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom01">
                          Prénom
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          disabled={preview}
                          required
                          className="form-control"
                          name="lastName"
                          type="text"
                          placeholder="Prénom"
                          innerRef=""
                          value={userGarantie.lastName}
                          onChange={handleChange}
                        />
                        <span></span>
                      </Col>
                    
                      <Col md="4 mb-3">
                      <div
                        class="form-check form-switch"
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <input
                          class="form-check-input"
                          style={{ marginTop: "7%" }}
                          name="isActive"
                          type="checkbox"
                          disabled={preview}
                          id="flexSwitchCheckDefault"
                          checked={checked}
                          value="B"
                          onClick={() => {
                            if (checked) {
                              setChecked(false);
                              setUserGarantie((userGarantie) => ({
                                ...userGarantie,
                                isActive: "0",
                              }));
                            } else {
                              setChecked(true);
                              setUserGarantie((userGarantie) => ({
                                ...userGarantie,
                                isActive: "1",
                              }));
                            }
                          }}
                        />
                        <label
                          class="form-check-label"
                          for="flexSwitchCheckDefault"
                        >
                          Actif
                        </label>
                      </div>
                    </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom01">
                          Mobile
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          disabled={preview}
                          required
                          className="form-control"
                          name="phone"
                          type="text"
                          placeholder="Mobile"
                          innerRef=""
                          value={userGarantie.phone}
                          onChange={handleChange}
                        />
                        <span></span>
                      </Col>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col>
                <Card className="shadow-lg p-0 shadow-showcase">
                  <CardHeader className="b-l-secondary pb-2">
                    <h5> Détails de connexion</h5>
                  </CardHeader>
                  <CardBody className="pt-1">
                    <div className="form-row">
                      <Col md="3 mb-2">
                        <Label htmlFor="validationCustom01">E-mail</Label>
                        <span style={{ color: "red" }}>*</span>
                        <Input
                          disabled={preview}
                          required
                          className="form-control"
                          name="email"
                          type="text"
                          placeholder="E-mail"
                          value={userGarantie.email}
                          onChange={handleChange}
                        />

                        <div className="valid-feedback">Looks good!</div>
                      </Col>
                      <Col md="3 mb-2">
                        <Label htmlFor="validationCustom02">Mot de passe</Label>
                        <span style={{ color: "red" }}>*</span>
                        <Input
                          disabled={preview}
                          className="form-control"
                          name="password"
                          type="password"
                          placeholder="Mot de passe"
                          value={userGarantie.password}
                          onChange={handleChange}
                        />

                        <div className="valid-feedback">Looks good!</div>
                      </Col>
                      <Col md="3 mb-2">
                        <Label htmlFor="validationCustom02">
                          Confirmation Mot de passe
                        </Label>
                        <span style={{ color: "red" }}>*</span>
                        <Input
                          disabled={preview}
                          required
                          className="form-control"
                          name="confirmPassword"
                          type="password"
                          placeholder="Confirmation Mot de passe"
                        />
                      </Col>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col>
                <Button
                  color="danger"
                  type="reset"
                  className="float-left"
                  onClick={() => history.push("/gest_utilisateur/utilisateurs")}
                >
                  Retour
                </Button>
              </Col>
              {!preview && (
                <Col>
                  <Button color="primary" type="submit" className="float-right">
                    Valider
                  </Button>
                </Col>
              )}
            </Row>
            
          </Form>
        </Container>
      </Fragment>
    );
  }
};

export default UpdateUserGaranty;
