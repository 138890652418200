import React, { useEffect } from "react";
import { saveAs } from "file-saver";
import { Card, CardBody, CardHeader, Container } from "reactstrap";
import distrbutorServices from "../../services/distribitor/distributorService";
import familyService from "../../services/family/familyService";
const Manuel = () => {
  useEffect(() => {
    distrbutorServices
      .getDisrtibutorbyId(localStorage.getItem("idDist"))
      .then((res) => {
        if (res.status === 200) {
          JSON.parse(res.data.typesale).forEach(async (element) => {
            familyService.getFamilybyId(element).then((res) => {
              console.log(res.data);
              if (res.data.familyName === "Smartphone") {
                saveAs(
                  `${process.env.PUBLIC_URL}/assets/imd/UserGUIDESmartphone.pdf`,
                  "UserGUIDESmartphone.pdf"
                );
              } else if (res.data.familyName === "Optique") {
                saveAs(
                  `${process.env.PUBLIC_URL}/assets/imd/UserGUIDEDOptique.pdf`,
                  "UserGUIDEDOptique.pdf"
                );
              }
            });
          });
        }
      });
  }, []);
  return (
    <div>
      <Container fluid={true}>
        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader></CardHeader>

          <CardBody className="pt-0">
            <h4 style={{ textAlign: "center" }}>
              Téléchargement effectué avec succès
            </h4>
          </CardBody>

          <CardBody className="pt-2"></CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default Manuel;
