import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import IconButton from "@material-ui/core/IconButton";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Badge,
} from "reactstrap";

import Breadcrumb from "../../../layout/breadcrumb";
import { useHistory } from "react-router-dom";

import SweetAlert from "sweetalert2";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { fetchFormula } from "../../../redux/formula/formulaActions";
import Switch from "react-switch";
import BudgeComponent from "../../../components/BudgeComponent";

const FormulaList = () => {
  const history = useHistory();
  const profil = localStorage.getItem("profil");
  const listFormula = useSelector(
    ({ formulaReducers }) => formulaReducers.formulas
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchFormula());
  }, []);

  const columns = [
    {
      name: "Famille",
      selector: "family.familyName",
      sortable: true,
    },
    {
      name: "Nom",
      selector: "name",
      sortable: true,
    },
    {
      name: "Prix",
      selector: "price",
      sortable: true,
    },
    // {
    //   name: "Prix Min Produit",
    //   selector: "minValueProd",
    //   sortable: true
    // },
    // {
    //   name: "Prix Max Produit",
    //   selector: "maxValueProd",
    //   sortable: true
    // },
    {
      name: "Actif",
      cell: (row) => (
        <BudgeComponent status={row.isenabled} />

        // <div class="form-check form-switch">
        //   <input
        //     class="form-check-input"
        //     name="isActive"
        //     type="checkbox"
        //     id={row.iduserG}
        //     // checked={checked}
        //     value="0"
        //     // onClick={() => setChecked(false) & console.log(row,"---",checked) }
        //   />
        // </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          {profil === "User" ? (
            <IconButton
              color="primary"
              onClick={() => {
                history.push({
                  pathname: "/gest_fromule_product/Formules/update_Formule",

                  state: { row },
                });
              }}
            >
              <i className="icofont icofont-eye-alt"></i>
            </IconButton>
          ) : (
            <>
              <IconButton
                color="primary"
                onClick={() => {
                  // console.log(row);
                  history.push({
                    pathname: "/gest_fromule_product/Formules/update_Formule",
                    state: { row, update: true },
                  });
                }}
              >
                <i className="icofont icofont-ui-edit"></i>
              </IconButton>
              <IconButton
                color="primary"
                onClick={() => {
                  history.push({
                    pathname: "/gest_fromule_product/Formules/update_Formule",

                    state: { row },
                  });
                }}
              >
                <i className="icofont icofont-eye-alt"></i>
              </IconButton>
            </>
          )}
        </div>
      ),

      button: true,
    },
  ];

  return (
    <div>
      <Breadcrumb parent={"Formules"} title={"Liste des Formules"} />
      <Container fluid={true}>
        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader
            className="ml-2"
            style={{ cursor: "pointer", padding: "10px" }}
            onClick={() => {}}
          >
            <span className="float-left">
              <h6 className="mt-1">{"Filtrer"}</h6>
            </span>

            <span className="float-right ">
              <h4>
                {" "}
                <i className="fa fa-angle-down"></i> :{" "}
                <i className="fa fa-angle-up"></i>
              </h4>
            </span>
          </CardHeader>

          {/* <CardBody className="pt-0" hidden="">
            <Form className="theme-form">
              <Row>
                <Col md="3 mb-2">
                  <FormGroup>
                    <Label className="col-form-label ">{t("rs")}</Label>
                    <Input className="form-control" type="text" onChange={(e) => setRsc(e.target.value)} value={rsc} />
                  </FormGroup>
                </Col>
                <Col md="3 mb-2">
                  <FormGroup>
                    <Label className="col-form-label ">{t("abbreviation")}</Label>
                    <Input className="form-control" type="text" onChange={(e) => setAbr(e.target.value)} value={abr} />
                  </FormGroup>
                </Col>
                <Col md="3 mb-2">
                  <FormGroup>
                    <label className="col-form-label">{"crDate"}</label>

                    <DatePicker
                      className="form-control digits"
                      selected={startDate}
                      onChange={(e) => {
                        console.log(e);
                        setstartDate(e);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="3 mb-3">
                  <FormGroup>
                    <Label className="col-form-label">{t("actif")} </Label>
                    <Input type="select" name="select" className="form-control digits" defaultValue="1" onChange={(e) => setSelected(e.target.value)}>
                      <option value={1}> {"yes"}</option>
                      <option value={0}>{"no"}</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
            <Button className="btn-pill btn-air-success" color="success" onClick={(e) => filterData()}>
              {t("search")}
            </Button>
          </CardBody> */}
        </Card>

        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader className="m-0  align-button ">
            <Row>
              <Col className="text-right">
                <span>
                  { profil === "User" ? <></> :<Button
                    className="btn-pill btn-air-primary "
                    color="primary"
                    onClick={() => {
                      history.push(
                        "/gest_fromule_product/Formules/new_Formule"
                      );
                    }}
                  >
                    + {"Nouvelle Formule"}
                  </Button>}
                </span>
              </Col>
            </Row>
          </CardHeader>

          <CardBody className="pt-2">
            <DataTable
              noHeader
              pagination
              paginationComponentOptions={{
                rowsPerPageText: "Afficher",
                rangeSeparatorText: "/",
                selectAllRowsItem: true,
                selectAllRowsItemText: "all",
              }}
              columns={columns}
              data={listFormula}
              pointerOnHover={true}
              progressPending=""
              progressComponent={
                <div className="loader-box">
                  <div className="loader-1"></div>
                </div>
              }
            ></DataTable>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default FormulaList;
