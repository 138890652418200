import React, { useState, useEffect, Fragment } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {
  Media,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { saveUser } from "../../../redux/users/usersActions";
import StepZilla from "react-stepzilla";
import { useHistory } from "react-router-dom";
import Dropzone from "react-dropzone-uploader";
import { findLastIndex } from "lodash";
import { DefaultPearlsSteps } from "./stepsComponent";
import { useDispatch } from "react-redux";
import usersService from "../../../services/users/usersService";
const generator = require("generate-password");
const defaultparlssteps = [
  { name: "Step 6", component: <DefaultPearlsSteps /> },
];
const CreateUser = ({ location, i18n }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showDiv, setShowDiv] = useState(false);
  const [password, setPassword] = useState([]);
  const [checked, setChecked] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState([]);
  const [confirmPass, setConfirmPass] = useState(false);
  const generaetePassword = () => {
    var genereatedPass = generator.generate({
      length: 10,
      numbers: true,
    });
    setConfirmPass(false);
    setPassword(genereatedPass);
    setConfirmPassword(genereatedPass);
  };
  const [user, setUser] = useState({
    adress: null,
    cin: "",
    city: null,
    codeVerification: "",
    dateCreation: new Date().toISOString(),
    email: "",
    firstName: "",
    isblacklisted: 0,
    iscgu: 1,
    isnewsletter: 0,
    lastName: "",
    password: "",
    phone: "",
    postalCode: 0,
    socialReason: null,
    status: "V",
    street: null,
    taxRegistrationNumber: null,
    typeCustomer: "",
  });
  const [mailInscription, setMailInscription] = useState({
    sendTo: "",
    subject: "Inscription au Garanty",
    text: "",
    type: "inscription",
  });

  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((user) => ({ ...user, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await usersService
      .createUser({ ...user, password: password })
      .then((resp) => {
        const User = resp.data;
        dispatch(saveUser(User));
      });
    history.push("/gest_client_contrat/clients");
  };
  console.log(user);
  return (
    <Fragment>
      <Breadcrumb parent="Client" title="Nouveau Client" />
      <Container fluid={true}>
        <Form
          className="needs-validation"
          noValidate=""
          onSubmit={handleSubmit}
        >
          <Row>
            <Col>
              <Card className="shadow-lg p-0 shadow-showcase">
                <CardHeader className="b-l-primary pb-2"></CardHeader>
                <CardBody className="pt-1">
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      marginTop: "8px",
                      marginBottom: "8px",
                    }}
                  >
                    <Col
                      xl="4"
                      md="8"
                      lg="12"
                      sm="12"
                      className="button-group-mb-sm xl-50"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="radio">
                        <input
                          id="radio23"
                          type="radio"
                          name="typeCustomer"
                          value="P"
                          onChange={handleChange}
                          onClick={() => setShowDiv(false)}
                          required={user.typeCustomer ? false : true}
                        />
                        <Label htmlFor="radio23">Personne physique</Label>
                      </div>

                      <div className="radio">
                        <input
                          id="radio24"
                          type="radio"
                          name="typeCustomer"
                          value="M"
                          onChange={handleChange}
                          onClick={() => setShowDiv(true)}
                        />
                        <Label htmlFor="radio24">
                          Personne Morale (Société)
                        </Label>
                      </div>
                    </Col>
                  </Row>
                  <div className="form-row">
                    <Col
                      md="6 mb-3"
                      style={{ display: showDiv ? "block" : "none" }}
                    >
                      <Label htmlFor="validationCustom01">
                        Matricule fiscale
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="taxRegistrationNumber"
                        type="text"
                        placeholder="Matricule fiscale"
                        value={user.taxRegistrationNumber}
                        onChange={handleChange}
                        required={user.typeCustomer === "P" ? false : true}
                      />
                      <span></span>
                    </Col>
                    <Col
                      md="6 mb-3"
                      style={{ display: showDiv ? "block" : "none" }}
                    >
                      <Label htmlFor="validationCustom01">
                        Raison Sociale
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="socialReason"
                        type="text"
                        placeholder="Nom de la Société"
                        value={user.socialReason}
                        onChange={handleChange}
                        required={user.typeCustomer === "P" ? false : true}
                      />
                      <span></span>
                    </Col>
                    <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                        {showDiv ? "Nom du gérant" : "Nom "}
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="lastName"
                        type="text"
                        placeholder="Nom"
                        value={user.lastName}
                        onChange={handleChange}
                        required="true"
                      />
                      <span></span>
                    </Col>
                    <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                        {showDiv ? "Prénom du gérant " : "Prénom"}
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="firstName"
                        type="text"
                        placeholder="Prénom"
                        value={user.firstName}
                        onChange={handleChange}
                        required="true"
                      />
                      <span></span>
                    </Col>
                    <Col
                      md="6 mb-3"
                      style={{ display: showDiv ? "block" : "none" }}
                    >
                      <Label htmlFor="validationCustom01">
                        Activité
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="status"
                        type="select"
                        placeholder="status"
                        value={user.status}
                        onChange={handleChange}
                        required={user.typeCustomer === "P" ? false : true}
                      />
                      <span></span>
                    </Col>
                    <Col
                      md="6 mb-3"
                      style={{ display: showDiv ? "none" : "block" }}
                    >
                      <Label htmlFor="validationCustom01">
                        N° CIN
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="cin"
                        type="number"
                        placeholder="Cin"
                        value={user.cin}
                        onChange={handleChange}
                        required={user.typeCustomer === "M" ? false : true}
                      />
                      <span></span>
                    </Col>

                    <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                        Numéro de Téléphone
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="phone"
                        type="number"
                        placeholder="Numéro de Téléphone"
                        value={user.phone}
                        onChange={handleChange}
                        required="true"
                      />
                      <span></span>
                    </Col>
                    <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                        E-mail
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="email"
                        type="text"
                        placeholder="email"
                        value={user.email}
                        onChange={handleChange}
                        required="true"
                      />
                      <span></span>
                    </Col>
                    <Col md="4 mb-3">
                      <div
                        class="form-check form-switch"
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <input
                          class="form-check-input"
                          // style={{ marginTop: "7%" }}
                          name="isActive"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                          checked={checked}
                          value="B"
                          onClick={() => {
                            if (checked) {
                              setChecked(false);
                              setUser((user) => ({
                                ...user,
                                status: "B",
                              }));
                            } else {
                              setChecked(true);
                              setUser((user) => ({
                                ...user,
                                status: "V",
                              }));
                            }
                          }}
                        />
                        <label
                          class="form-check-label"
                          for="flexSwitchCheckDefault"
                        >
                          Actif
                        </label>
                      </div>
                    </Col>
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom01">
                        Mot de passe
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="password"
                        type="text"
                        placeholder="mot de passe"
                        value={password}
                        onChange={handleChange}
                        required="true"
                      />
                    </Col>
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom01">
                        Confirmation mot de passe
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="confirmPassword"
                        type="text"
                        value={confirmPassword}
                        placeholder="confirmer mot de passe"
                        required="true"
                      />
                      <span></span>
                    </Col>
                    <Col md="3 m-auto">
                      <Button
                        className="btn-pill btn-air-primary float-right"
                        color="primary"
                        onClick={() => generaetePassword()}
                      >
                        Générer
                      </Button>
                    </Col>
                  </div>

                  <div className="checkbox">
                    <input
                      id="checkbox-light-13"
                      type="checkbox"
                      name="isnewsletter"
                      value="1"
                      onChange={handleChange}
                    />
                    <Label htmlFor="checkbox-light-13">
                      Inscrire à la newsletter
                    </Label>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Button
                color="danger"
                type="reset"
                className="float-left"
                onClick={() => history.push("/gest_client_contrat/clients")}
              >
                Retour
              </Button>
            </Col>
            <Col>
              <Button color="primary" type="submit" className="float-right">
                Valider
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </Fragment>
  );
};

export default CreateUser;
