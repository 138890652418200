import {
  FETCH_CATEGORYS_REQUEST,
  FETCH_CATEGORYS_SUCCESS,
  FETCH_CATEGORYS_FAILURE,
  ADD_CATEGORYS_REQUEST,
  ADD_CATEGORYS_SUCCESS,
  ADD_CATEGORYS_FAILURE
} from "./categoryActions";

const initialState = {
  loading: false,
  categorys: [],
  error: ""
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CATEGORYS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_CATEGORYS_SUCCESS:
      return {
        loading: false,
        categorys: action.payload,
        error: ""
      };
    case FETCH_CATEGORYS_FAILURE:
      return {
        loading: false,
        categorys: [],
        error: action.payload
      };

    case ADD_CATEGORYS_REQUEST:
      return {
        registering: true
      };
    case ADD_CATEGORYS_SUCCESS:
      return {
        loading: false,
        categorys: [...(state.category || []) , action.payload ],
        error: ""
      };
    case ADD_CATEGORYS_FAILURE:
      return {
        loading: false,
        categorys: [],
        error: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
