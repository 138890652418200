import React, { useState, useEffect } from "react";
import logo from "../assets/images/logo/LogoGaranty.png";
import man from "../assets/images/dashboard/profile.jpg";
import "./logo.css";
import {
  Container,
  Row,
  Col,
  CardBody,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
} from "reactstrap";

import signinService from "../services/auth/signinService";
// import app, { googleProvider,facebookProvider,twitterProvider,githubProvider } from '../data/base'
import { toast, ToastContainer } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";

const Login = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [userLog, setUserLog] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserLog((userLog) => ({ ...userLog, [name]: value }));
  };
  const loginAuth = (e) => {
    e.preventDefault();

    signinService
      .login(userLog)
      .then((response) => {

        if (response.status === 200) {
          window.sessionStorage.setItem("token", response.data.token);
          localStorage.setItem("role", response.data.type_user);
          localStorage.setItem("id", response.data.id);
          localStorage.setItem("idDist", response.data.idDistributor);
          localStorage.setItem("name", response.data.FirstName);
          localStorage.setItem("profil",response.data.profile);
          if (response.data.type_user === "Distributeur") {
            localStorage.setItem("sous-rev", response.data.subdistributor);
            history.push(`${process.env.PUBLIC_URL}/new-sale`);
          } else if (response.data.type_user === "Assurance ") {
            history.push(`${process.env.PUBLIC_URL}/dashboard/assurance`);
          } else if (response.data.type_user === "Courtier ") {
            history.push(`${process.env.PUBLIC_URL}/dashboard/courtier`);
          } else if (response.data.type_user === "FrontOffice") {
            localStorage.setItem("idDist", "a3223b22-564d-40fb-9236-0b99a3e34177");
            history.push(`${process.env.PUBLIC_URL}/gest_client_contrat/clients`);
          } else {
            history.push(`${process.env.PUBLIC_URL}/dashboard/default`);
          }
        } 
      })
      .catch((res) => {
        toast.error(
          "Oppss.. Votre mot de passe non valide ou votre e-mail n'est pas exist ."
        );
      });
  };
  return (
    <div className="page-wrapper">
      <Container fluid={true} className="row col-md-12 p-0">
      <ToastContainer />
        <div className="imgbackground col-md-6"></div>
        <div className="col-md-6 authentication-main m-0">
          <Row>
            <Col md="12">
              <div className=" auth-innerright">
                <div className="authentication-box">
                  <div className="logo">
                    <img src={logo} />
                  </div>
                  <CardBody
                    className="h-100-d-center"
                    style={{ minHeight: "100%" }}
                  >
                    <div className="cont text-center b-light shadow-lg pt-4 pl-1 pr-1 pb-4 shadow-showcase">
                      <Form className="theme-form m-auto" onSubmit={loginAuth}>
                        <Col md="12 mb-1">
                          <h4>LOGIN</h4>
                          <h6>Entrer votre E-mail et Mot de passe</h6>
                        </Col>
                        <FormGroup>
                          <Label className="col-form-label pt-0"></Label>
                          <Input
                            className="form-control"
                            name="email"
                            type="email"
                            placeholder="E-mail"
                            required=""
                            value={userLog.email}
                            onChange={handleChange}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label className="col-form-label"></Label>
                          <Input
                            className="form-control"
                            name="password"
                            type="password"
                            placeholder="Mot de passe"
                            required=""
                            value={userLog.password}
                            onChange={handleChange}
                          />
                        </FormGroup>
                        {/* <div className="checkbox p-0">
                          <a
                            style={{ marginRight: "50px" }}
                            href="https://www.w3schools.com"
                          >
                            Mot de passe oublé
                          </a>
                        </div> */}
                        <FormGroup className="form-row mt-3 mb-0">
                          {loading ? (
                            <Button
                              color="primary btn-block"
                              disabled={loading}
                            >
                              LOADING...
                            </Button>
                          ) : (
                            <Button color="primary btn-block" type="submit">
                              LOGIN
                            </Button>
                          )}
                        </FormGroup>
                      </Form>
                    </div>
                  </CardBody>
                  <div style={{ marginTop: "220px" }}></div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Login;
