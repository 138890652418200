import React, { Fragment, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import "./style.css";
import "react-datepicker/dist/react-datepicker.css";
import App from "./components/app";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { routes } from "./route";
import ScrollToTop from "./layout/scroll_to_top";
import ConfigDB from "./data/customizer/config";
import Signin from "./auth/signin";
import Login from "./pages/authentifaication/login";

const Root = (props) => {
  const [anim, setAnim] = useState("");
  const animation =
    localStorage.getItem("animation") ||
    ConfigDB.data.router_animation ||
    "fade";
  const abortController = new AbortController();

  useEffect(() => {
    setAnim(animation);
    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;
    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Provider store={store}>
        <BrowserRouter basename={`/`}>
          <ScrollToTop />
          <Switch>
            <Route
              path={`${process.env.PUBLIC_URL}/login`}
              component={Signin}
            ></Route>
            <App>
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/`}
                render={() => {
                  if (sessionStorage.getItem("token") !== null) {
                    return (
                      <Redirect
                        to={`${process.env.PUBLIC_URL}/dashboard/default`}
                      />
                    );
                  }
                  return (
                    <Redirect
                      to={`${process.env.PUBLIC_URL}/login`}
                      component={Signin}
                    />
                  );
                }}
              />
              {routes.map(({ Component, path }) => (
                <Route
                  key={path}
                  exact
                  path={`${process.env.PUBLIC_URL}${path}`}
                >
                  {(props) => {
                    {
                      if (sessionStorage.getItem("token") !== null) {
                       
                        return (
                          <div>
                            {props.match != null ? (
                              <Component {...props} />
                            ) : null}
                          </div>
                          
                        );
                      } else
                        return (
                          <Redirect to={`${process.env.PUBLIC_URL}/login`} />
                        );
                    }
                  }}
                </Route>
              ))}
              {/* </TransitionGroup> */}
            </App>
          </Switch>
        </BrowserRouter>
      </Provider>
    </Fragment>
  );
};
ReactDOM.render(<Root />, document.getElementById("root"));

serviceWorker.unregister();
