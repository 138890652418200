import React, { useEffect, useState } from "react";
import { set } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import Select from "react-select";
import { toast } from "react-toastify";
import challenge from "../../assets/images/challenge.png"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Breadcrumb from "../../layout/breadcrumb";
import { fetchFamily } from "../../redux/family/familyActions";
import formulaService from "../../services/formula/formulaService";
import { saveContractDetails } from "../../redux/revendeur/distributorActions";
import contractServices from "../../services/contract/contractServices";
import distributorService from "../../services/distribitor/distributorService";
import familyService from "../../services/family/familyService";
const NewSale = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [Verticalcenter, setVerticalcenter] = useState(false);
  const [typeInput, setTypeInput] = useState(false);
  const [checkCustomer, setCheckCustomer] = useState({
    cin: "",
    email: "m",
    phone: 0,
    taxRegistrationNumber: ".",
    typeCustomer: "P",
  });
  const [checked, setChecked] = useState(true);
  const [formula, setFormula] = useState(false);
  const [breakDown, setBreakDown] = useState([]);
  const [option, setListOption] = useState([]);
  const [optionPeriode, setListOptionPeriode] = useState([{
    value: 1,
    label: "un an d'assurance",
  },
  {
    value: 2,
    label: "deux ans d'assurance",
  }]);
  const [optionProduit, setOptionProduit] = useState([]);
  const [selectOption, setOptionSelected] = useState(null);
  const [selectOptionPeriode, setOptionSelectedPeriode] = useState(null);
  const [selectOptionProduit, setOptionSelectedProduit] = useState(null);
  const [listFormule, setListFormule] = useState(null);
  const [optionFormule, setListOptionFormule] = useState([]);
  const [selectOptionFormule, setOptionSelectedFormule] = useState(null);
  const [formulaVol, setFormulaVol] = useState(null);
  const [inclusion ,setInclusion] = useState(false)
  const [codis ,setCodis] = useState(false)
  const [vol, setVol] = useState(false);
  const [challangePopUp,setChallangePopUp]=useState(true)

  // const toggle = () => setModal(!modal);
  const Verticalcentermodaltoggle = () => {
    if (selectOptionFormule && selectOption) {
      if (vol) {
        setFormula(formulaVol);
        setVerticalcenter(!Verticalcenter);
      } else {
        setVerticalcenter(!Verticalcenter);
      }
    }
  };
  const challangeModel = () => setChallangePopUp(!challangePopUp)

  useEffect(() => {
    if (
      localStorage.getItem("role") !== "Distributeur" &&
      !(localStorage.getItem("role") === "FrontOffice")
    ) {
      history.push("/");
    }
    dispatch(fetchFamily());
  }, []);
  const listFamily = useSelector(({ familyReducers }) => {
    return familyReducers.familys;
  });
  useEffect(() => {
    if (
      localStorage.getItem("role") === "Distributeur" ||
      localStorage.getItem("role") === "FrontOffice"
    ) {
      distributorService
        .getDisrtibutorbyId(localStorage.getItem("idDist"))
        .then(async (res) => {
          localStorage.setItem("parent", res.data.parentidstring);
          let family = [];
          await listFamily.map(async (el) => {
            JSON.parse(res.data.typesale).forEach(async (element) => {
              if (el.idfamily === element) {
                await family.push(el);
              }
            });
          });
          setListOption(
            family?.map((el) => ({
              value: el.idfamily,
              label: el.familyName,
            }))
          );
        });
    }
  }, [listFamily]);

  const onchangeSelectPeriode = (item)=>{
    setOptionSelectedPeriode(item)
    if(selectOptionProduit){
      formulaService.getAllformulasbyFamily(selectOptionProduit.value).then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          let data =res.data.sort((a, b) => a.fomula.price - b.fomula.price)
          setListFormule(data.filter((el)=> el.fomula.other === item.value))
          
        }
      });
    }else{

      formulaService.getAllformulasbyFamily(selectOption.value).then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          let data =res.data.sort((a, b) => a.fomula.price - b.fomula.price)
          console.log(data[0]?.fomula.other);
          
          setListFormule(data.filter((el)=> el.fomula.other === item.value))
          
        }
      });
    }
  }
  const onchangeSelect = (item) => {
    setOptionSelected(item);
    setInclusion(item.label.includes("Inclusion"))
    setCodis(item.label.includes("CODIS"));
    
    if (item.label === "Multimédia"|| item.label === "Multimédia Inclusion" || item.label === "Multimédia Gr Inclusion" || item.label === "Électroménager" || item.label === "Électroménager Gr Inclusion"|| item.label === "Multimédia Inclusion CODIS" || item.label === "Multimédia CODIS" ) {
      familyService.getFamilybyParentId(item.value).then((res) => {
        setOptionProduit(
          res.data?.map((el) => ({
            value: el.idfamily,
            label: el.familyName,
          }))
        );
      });
    }
    if(localStorage.getItem("idDist") === "b5abfadd-bfe4-471f-abe5-af83e5d80f63" && item.label === "Smartphone"){
      setListOptionPeriode([{
        value: 2,
        label: "deux ans d'assurance",
      }])
    }
    if(localStorage.getItem("idDist") === "424c9868-b1b6-4d6e-b55d-41e7f03f5155"){
      setListOptionPeriode([{
        value: 1,
        label: "un an d'assurance",
      }])
    }else{
      setListOptionPeriode([{
        value: 1,
        label: "un an d'assurance",
      },
      {
        value: 2,
        label: "deux ans d'assurance",
      }])
    }
    setOptionSelectedPeriode(null)
    setOptionSelectedFormule(null);
    setFormula(false);
    setBreakDown();
  };
  const onchangeProduit = (item) => {
    setOptionSelectedProduit(item);
    formulaService.getAllformulasbyFamily(item.value).then((res) => {
      if (res.status === 200) {
        console.log(res.data);
        setListFormule(
          res.data.sort((a, b) => a.fomula.price - b.fomula.price)
        );
      }
    });
    if(localStorage.getItem("idDist") === "b5abfadd-bfe4-471f-abe5-af83e5d80f63" && item.label === "PC Portable"){
      setListOptionPeriode([{
        value: 2,
        label: "deux ans d'assurance",
      }])
    }
    if(localStorage.getItem("idDist") === "424c9868-b1b6-4d6e-b55d-41e7f03f5155"){
      setListOptionPeriode([{
        value: 1,
        label: "un an d'assurance",
      }])
    } 
    if(localStorage.getItem("idDist") === "daa96f25-016f-4c14-afd1-cb50211cda57"){
      setListOptionPeriode([{
        value: 2,
        label: "deux ans d'assurance",
      }])
    } 
    else{
      setListOptionPeriode([{
        value: 1,
        label: "un an d'assurance",
      },
      {
        value: 2,
        label: "deux ans d'assurance",
      }])
    }
    setOptionSelectedPeriode(null)
    setOptionSelectedFormule(null);
    setFormula(false);
    setBreakDown();
  };
  const onchangeSelectformule = (item) => {
    setOptionSelectedFormule(item);
    formulaService.getFormulaById(item.value).then((res) => {
      setFormula(res.data);
      formulaService.getFormulaByParentId(item.value).then((res) => {
        setFormulaVol(res.data);
      });
      formulaService.getAllBreakDownByFormula(item.value).then((res) => {
        setBreakDown(res.data);
      });
    });
  };
  console.log(optionFormule);
  useEffect(() => {
    setListOptionFormule(
      listFormule?.map((el) => ({
        value: el.fomula.idformula,
        label:
         inclusion ? el.fomula.name  : codis ? el.fomula.name : el.fomula.name + " / " + (el.fomula.price)?.toFixed(3) + " DT",
      }))
    );
    
  }, [listFormule]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCheckCustomer((checkCustomer) => ({ ...checkCustomer, [name]: value }));
  };
  console.log(checkCustomer);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!checkCustomer.typeCustomer) {
      toast.error("vous êtes une personne Physique ou Morale");
    } else {
      if (checkCustomer.cin && checkCustomer.cin.length === 8) {
        await contractServices.checkStepers(checkCustomer).then((res) => {
          if (res.data == 3) {
            dispatch(saveContractDetails([checkCustomer, formula]));
            toast.info("Ce numéro de Cin est déjà utilisé.");
            history.push("/fiche-client");
          } else if (res.data == 4) {
            toast.error("Matricule Fiscale est déjà utilisé.");
            dispatch(saveContractDetails([checkCustomer, formula]));
            history.push("/fiche-client");
          } else {
            dispatch(saveContractDetails([checkCustomer, formula]));
            history.push("/fiche-client");
          }
        });
      } else if (checkCustomer.typeCustomer == "M") {
        await contractServices.checkStepers(checkCustomer).then((res) => {
          if (res.data == 4) {
            toast.error("Matricule Fiscale est déjà utilisé.");
            dispatch(saveContractDetails([checkCustomer, formula]));
            history.push("/fiche-client");
          } else {
            dispatch(saveContractDetails([checkCustomer, formula]));
            history.push("/fiche-client");
          }
        });
      } else {
        toast.error("Entré votre Num Cin ou Matricule Fiscale");
      }
    }
  };
  return (
    <div>
      <Breadcrumb parent={"Nouvelle Vente"} title={"Choix de l'offre"} />
      <Container fluid={true}>
        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader className="m-0 pb-0">
            <Row>{/* <Col className="text-right"></Col> */}</Row>
          </CardHeader>

          <CardBody className="pt-2">
            <Form className="needs-validation" noValidate="">
              <div className="form-row">
                <Col md="6 mb-4">
                  <Label
                    style={{ fontSize: "16px", fontWeight: "400" }}
                    htmlFor="validationCustom01"
                  >
                    Familles
                    <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Select
                    style={{ height: "50px" }}
                    value={selectOption}
                    onChange={onchangeSelect}
                    options={option}
                  />
                  <span></span>
                </Col>

                {selectOption?.label === "Multimédia" || selectOption?.label === "Multimédia Inclusion" || selectOption?.label === "Multimédia Gr Inclusion" ||
                selectOption?.label === "Électroménager" || selectOption?.label === "Électroménager Gr Inclusion" || selectOption?.label === "Multimédia Inclusion CODIS" || selectOption?.label === "Multimédia CODIS" ? (
                  <>
                    <Col md="6 mb-4"></Col>

                    <Col md="6 mb-4">
                      <Label
                        style={{ fontSize: "16px", fontWeight: "400" }}
                        htmlFor="validationCustom01"
                      >
                        Produits
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Select
                        style={{ height: "50px" }}
                        value={selectOptionProduit}
                        onChange={onchangeProduit}
                        options={optionProduit}
                      />
                      <span></span>
                    </Col>
                  </>
                ) : (
                  <></>
                )}
                <Col md="6 mb-4"></Col>
                {<><Col md="6 mb-4">
                  <Label
                    style={{ fontSize: "16px", fontWeight: "400" }}
                    htmlFor="validationCustom01"
                  >
                    Durée d'assurance 
                    <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Select
                    style={{ height: "50px" }}
                    value={selectOptionPeriode}
                    onChange={onchangeSelectPeriode}
                    options={optionPeriode}
                  />
                  <span></span>
                </Col>
                <Col md="6 mb-4"></Col></>}
                <Col md="6 mb-4">
                  <Label
                    style={{ fontSize: "16px", fontWeight: "400" }}
                    htmlFor="validationCustom02"
                  >
                    Formule de garantie
                    <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Select
                    style={{ height: "50px" }}
                    value={selectOptionFormule}
                    onChange={onchangeSelectformule}
                    options={optionFormule}
                  />
                  <span></span>
                </Col>
                <Col md="6 mb-4"></Col>
                <Col md="6 mb-4">
                  {formulaVol ? (
                    <Col
                      sm={{
                        size: 10,
                      }}
                    >
                      <Input
                        id="checkbox2"
                        type="checkbox"
                        onChange={() => {
                          setVol(!vol);
                        }}
                      />
                      <Label check>
                        Prix option vol {formulaVol?.vol_protection.toFixed(3)}{" "}
                        DT pour confirmé veuillez cocher la case
                      </Label>
                      <br />
                      <h6>
                        Plafond de garantie vol{" "}
                        {formulaVol.ceiling_guarantee_vol} DT
                      </h6>
                    </Col>
                  ) : (
                    <></>
                  )}
                  <br />
                  <h6> Détails de l'offre</h6>
                  <br />
                  {codis ? <h6>2 ANS d'assurance Casse, Vol, oxydation + 1 an d'extension de garantie Constructeur supplémentaire</h6>:<></>}
                  {vol ? (
                    <h6>
                      {(formulaVol?.price / 12)?.toFixed(3)} DT par mois{" / "}
                      {(formulaVol?.price).toFixed(3)} DT par an
                    </h6>
                  ) : (
                     <h6 style={{display:inclusion ? "none": codis ? "none" : "block"}}>
                      {formula ? (formula?.price /12)?.toFixed(3) : "Prix en"} DT par
                      mois{" / "}
                      {formula
                        ? (formula?.price).toFixed(3)
                        : "Prix en"}{" "}
                      DT par an
                    </h6>
                  )}
                  <h6>
                    Prix{" "}
                    {formula.family?.familyName === "Optique"
                      ? "des lunettes"
                      : formula.family?.familyName}{" "}
                    de {formula.minValueProd} DT{" "}
                    {formula.maxValueProd > 9998
                      ? " et + "
                      : `${
                          -formula.maxValueProd ? formula.maxValueProd : ""
                        } DT`}
                  </h6>
                  {inclusion ? <></>:<h6>Plafond de garantie {formula.ceilingGuarantee} DT</h6>}
                  <h6>
                    {formula.nbrProductCovered}{" "}
                    {selectOption?.label === "Optique"
                      ? "paire"
                      : formula.family?.familyName}{" "}
                    {formula.nbrProductCovered > 1 ? "Couverts" : "Couvert"}
                  </h6>
                  <hr style={{ width: "112px", height: "2px" }} />
                  <ul>
                    {breakDown?.map((el, i) => {
                      return <li key={i}>{el?.breakdownType?.name}</li>;
                    })}
                  </ul>
                </Col>
              </div>

              <Row>
                <Col>
                  <Button
                    color="danger"
                    // type="submit"
                    className="float-left"
                    style={{ width: "35%", height: "45px" }}
                    onClick={Verticalcentermodaltoggle}
                  >
                    Valider
                  </Button>
                </Col>
              </Row>
            </Form>
            <Modal
              isOpen={Verticalcenter}
              toggle={Verticalcentermodaltoggle}
              centered
            >
              <ModalHeader toggle={Verticalcentermodaltoggle}></ModalHeader>
              <ModalBody>
                <Row>
                  <Col
                    className="button-group"
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <div className="radio">
                      <input
                        id="radio23"
                        type="radio"
                        name="typeCustomer"
                        value="P"
                        onChange={handleChange}
                        onClick={() => {
                          setTypeInput(false);
                        }}
                        checked
                      />
                      <Label htmlFor="radio23">Personne Physique</Label>
                    </div>

                    <div className="radio">
                      <input
                        id="radio24"
                        type="radio"
                        name="typeCustomer"
                        value="M"
                        onChange={handleChange}
                        onClick={() => {
                          setTypeInput(true);
                        }}
                      />
                      <Label htmlFor="radio24">Personne Morale</Label>
                    </div>
                  </Col>
                </Row>
                <Col md="10 mb-4">
                  <Label htmlFor="validationCustom01">
                    {typeInput ? "Matricule Fiscal" : "Num Cin"}
                    <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    className="form-control"
                    name={
                      checkCustomer.typeCustomer === "M"
                        ? "taxRegistrationNumber"
                        : "cin"
                    }
                    type={
                      checkCustomer.typeCustomer === "M" ? "text" : "number"
                    }
                    placeholder="......."
                    value={
                      checkCustomer.typeCustomer === "M"
                        ? checkCustomer.taxRegistrationNumber
                        : checkCustomer.cin
                    }
                    onChange={handleChange}
                  />
                </Col>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={handleSubmit}>
                  Continuer
                </Button>
              </ModalFooter>
            </Modal>
           {/* { <Modal
              isOpen={challangePopUp}
              toggle={challangeModel}
              centered
            >
              <ModalHeader toggle={challangeModel} style={{padding: "0.5rem 0.5rem"}}></ModalHeader>
              <ModalBody>
               <img src={challenge} className="challange"/>
              </ModalBody>
            </Modal>} */}
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default NewSale;
