import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import IconButton from "@material-ui/core/IconButton";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Badge,
} from "reactstrap";

import Breadcrumb from "../../../layout/breadcrumb";
import { useHistory } from "react-router-dom";

import SweetAlert from "sweetalert2";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { fetchCourtier } from "../../../redux/courtier/courtierActions";
import Switch from "react-switch";
import BudgeComponent from "../../../components/BudgeComponent";

const CourtierList = () => {
  const history = useHistory();
  const role = localStorage.getItem("role");
  const profil = localStorage.getItem("profil");
  const dispatch = useDispatch();
  const listCourtier = useSelector(
    ({ courtierReducers }) => courtierReducers.courtiers
  );

  useEffect(() => {
    dispatch(fetchCourtier());
  }, []);

  const columns = [
    {
      name: "Courtier",
      selector: "socialReason",
      sortable: true,
    },
    {
      name: "Commission",
      selector: "commission",
      sortable: true,
    },
    {
      name: "Adresse",
      selector: "adresse",
      sortable: true,
    },
    // {
    //   name: "Commission",
    //   selector: "commission",
    //   sortable: true,
    // },
    // {
    //   name: "Adresse",
    //   selector: "adresse",
    //   sortable: true,
    // },
    {
      name: "Actif",
      cell: (row) => <BudgeComponent status={row.isActive} />,

      // <div class="form-check form-switch">
      //   <input
      //     class="form-check-input custom-check"
      //     name="isActive"
      //     type="checkbox"
      //     id={row.iduserG}
      //     // checked={checked}
      //     value="0"
      //     // onClick={() => setChecked(false) & console.log(row,"---",checked) }
      //   />
      // </div>
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          {profil === "User" ? (
            <IconButton
              color="primary"
              onClick={() => {
                history.push({
                  pathname: "/gest_intervenant/courtiers/update_courtier",
                  state: { row },
                });
              }}
            >
              <i className="icofont icofont-eye-alt"></i>
            </IconButton>
          ) : (
            <>
              <IconButton
                color="primary"
                onClick={() => {
                  history.push({
                    pathname: "/gest_intervenant/courtiers/update_courtier",
                    state: { row, update: true },
                  });
                }}
              >
                <i className="icofont icofont-ui-edit"></i>
              </IconButton>
              <IconButton
                color="primary"
                onClick={() => {
                  history.push({
                    pathname: "/gest_intervenant/courtiers/update_courtier",
                    state: { row },
                  });
                }}
              >
                <i className="icofont icofont-eye-alt"></i>
              </IconButton>
            </>
          )}
        </div>
      ),

      button: true,
    },
  ];
  const columnsC = [
    {
      name: "Courtier",
      selector: "socialReason",
      sortable: true,
    },
    {
      name: "Adresse",
      selector: "adresse",
      sortable: true,
    },
  ];
  return (
    <div>
      <Breadcrumb parent={"Courtier"} title={"Liste des courtiers"} />
      <Container fluid={true}>
        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader
            className="ml-2"
            style={{ cursor: "pointer", padding: "10px" }}
            onClick={() => {}}
          >
            <span className="float-left">
              <h6 className="mt-1">{"Filtrer"}</h6>
            </span>

            <span className="float-right ">
              <h4>
                <i className="fa fa-angle-down"></i> :
                <i className="fa fa-angle-up"></i>
              </h4>
            </span>
          </CardHeader>

          <CardBody className="pt-0" hidden="">
            <Form className="theme-form">
              <Row>
                <Col md="12 mb-2">
                  <FormGroup>
                    <Label className="col-form-label "></Label>
                    <Input
                      className="form-control"
                      type="text"
                      onChange=""
                      value=""
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
            <Button
              className="btn-pill btn-air-success"
              color="success"
              onClick=""
            >
              Recherche
            </Button>
          </CardBody>
        </Card>

        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader className="m-0  align-button">
            <Row>
              {role === "Courtier " || profil === "User" ? (
                <></>
              ) : (
                <Col className="text-right">
                  <span>
                    <Button
                      className="btn-pill btn-air-primary "
                      color="primary"
                      onClick={() => {
                        history.push(
                          "/gest_intervenant/courtiers/new_courtier"
                        );
                      }}
                    >
                      + {"Nouveau Courtier"}
                    </Button>
                  </span>
                </Col>
              )}
            </Row>
          </CardHeader>

          <CardBody className="pt-2">
            <DataTable
              noHeader
              pagination
              paginationComponentOptions={{
                rowsPerPageText: "Afficher",
                rangeSeparatorText: "/",
                selectAllRowsItem: true,
                selectAllRowsItemText: "all",
              }}
              columns={role === "Courtier " ? columnsC : columns}
              data={listCourtier}
              pointerOnHover={true}
              progressPending=""
              progressComponent={
                <div className="loader-box">
                  <div className="loader-1"></div>
                </div>
              }
            ></DataTable>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default CourtierList;
