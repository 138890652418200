import { adminAxios } from "../../config/axiosConfig/InstancesAxios";

export default {
  getAllFormulas: () => {
    return adminAxios.post("/getAllEnableFormulas", {});
  },

  createNewFormula: (FormulaModel) => {
    return adminAxios.post("/createFormula", FormulaModel);
  },

  createBreakdownFormula: (breakdownFormulaModel) => {
    return adminAxios.post("/createBreakDownFormula", breakdownFormulaModel);
  },

  getAllformulasbyFamily: (idfamily) => {
    return adminAxios.post("/getAllformulasbyFamily", null, {
      params: { idfamily },
    });
  },
  getFormulaByParentId:(idParent)=>{
    return adminAxios.post("/getFormulabyIdParent", null, { params: { idParent } });
  },
  getFormulaById: (idFormula) => {
    return adminAxios.post("/getFormulabyId", null, { params: { idFormula } });
  },

  getAllBreakDownByFormula: (idformula) => {
    return adminAxios.post("/getAllBreakdownbyformula", null, {
      params: { idformula },
    });
  },
};
