import { adminAxios } from "../../config/axiosConfig/InstancesAxios";

export default {
  getAllAssurances: (uid) => {
    return adminAxios.post("/getAllAssurance", null, { params: { uid } });
  },

  createAssurance: (Model) => {
    return adminAxios.post("/createInsuance", Model);
  },

  getAssuranceById: (id) => {
    return adminAxios.post("/getinsuancebyId", null, {
      params: {
        id,
      },
    });
  },

  updateAssurance: (Model) => {
    return adminAxios.post("/updateinsuance", Model);
  },
};
