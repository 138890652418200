import  {adminAxios}  from "../../config/axiosConfig/InstancesAxios";

export default {

  getAllCategory: () => {
    return adminAxios.post("/getAllCategories", {});
  },

  createNewCategory: (categoryModel) => {
    return adminAxios.post("/createCategory", categoryModel)
  }
};