import profileService from "../../services/profile/profileService";

export const FETCH_PROFILES_REQUEST = "FETCH_PROFILES_REQUEST";
export const FETCH_PROFILES_SUCCESS = "FETCH_PROFILES_SUCCESS";
export const FETCH_PROFILES_FAILURE = "FETCH_PROFILES_FAILURE";



export const fetchProfile = () => {
  return (dispatch) => {
    const uid = localStorage.getItem('id')
    dispatch(fetchProfilesRequest());
    profileService
      .getAllProfiles(uid)
      .then((response) => {
        // response.data is the 
        console.log(response)
        const profiles = response.data;
        dispatch(fetchProfilesSuccess(profiles));
      })
      .catch((error) => {
        // error.message is the error message
        dispatch(fetchProfilesFailure(error.message));
      });
  };
};

export const fetchProfilesRequest = () => {
  return {
    type: FETCH_PROFILES_REQUEST
  };
};

export const fetchProfilesSuccess = (profiles) => {
  return {
    type: FETCH_PROFILES_SUCCESS,
    payload: profiles
  };
};

export const fetchProfilesFailure = (error) => {
  return {
    type: FETCH_PROFILES_FAILURE,
    payload: error
  };
};


