import {
  FETCH_BREAKDOWNTYPES_REQUEST,
  FETCH_BREAKDOWNTYPES_SUCCESS,
  FETCH_BREAKDOWNTYPES_FAILURE,
  ADD_BREAKDOWNTYPES_REQUEST,
  ADD_BREAKDOWNTYPES_SUCCESS,
  ADD_BREAKDOWNTYPES_FAILURE,
  FETCH_BREAKDOWNTYPESBYID_REQUEST,
  FETCH_BREAKDOWNTYPESBYID_SUCCESS,
  FETCH_BREAKDOWNTYPESBYID_FAILURE,

} from "./breakdownTypeActions";

const initialState = {
  loading: false,
  breakdownTypes: [],
  breakdownTypesById:[],
  error: ""
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BREAKDOWNTYPES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_BREAKDOWNTYPES_SUCCESS:
      return {
        loading: false,
        breakdownTypes: action.payload,
        error: ""
      };
    case FETCH_BREAKDOWNTYPES_FAILURE:
      return {
        loading: false,
        breakdownTypes: [],
        error: action.payload
      };

    case ADD_BREAKDOWNTYPES_REQUEST:
      return {
        registering: true
      };
    case ADD_BREAKDOWNTYPES_SUCCESS:
      return {
        loading: false,
        breakdownTypes: [...(state.breakdownType || []) , action.payload ],
        error: ""
      };
    case ADD_BREAKDOWNTYPES_FAILURE:
      return {
        loading: false,
        breakdownTypes: [],
        error: action.payload
      };

      case FETCH_BREAKDOWNTYPESBYID_REQUEST:
        return {
          ...state,
          loading: true
        };
      case FETCH_BREAKDOWNTYPESBYID_SUCCESS:
        return {
          loading: false,
          breakdownTypesById: action.payload,
          error: ""
        };
      case FETCH_BREAKDOWNTYPESBYID_FAILURE:
        return {
          loading: false,
          breakdownTypesById: [],
          error: action.payload
        };
    default:
      return state;
  }
};

export default reducer;
