import {
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAILURE,
  ADD_PRODUCTS_REQUEST,
  ADD_PRODUCTS_SUCCESS,
  ADD_PRODUCTS_FAILURE
} from "./productActions";

const initialState = {
  loading: false,
  products: [],
  error: ""
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_PRODUCTS_SUCCESS:
      return {
        loading: false,
        products: action.payload,
        error: ""
      };
    case FETCH_PRODUCTS_FAILURE:
      return {
        loading: false,
        products: [],
        error: action.payload
      };

    case ADD_PRODUCTS_REQUEST:
      return {
        registering: true
      };
    case ADD_PRODUCTS_SUCCESS:
      return {
        loading: false,
        product: [...(state.product || []) , action.payload ],
        error: ""
      };
    case ADD_PRODUCTS_FAILURE:
      return {
        loading: false,
        product: [],
        error: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
