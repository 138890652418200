import breakdownTypeService from "../../services/breakdownType/breakdownTypeService";

export const FETCH_BREAKDOWNTYPES_REQUEST = "FETCH_BREAKDOWNTYPES_REQUEST";
export const FETCH_BREAKDOWNTYPES_SUCCESS = "FETCH_BREAKDOWNTYPES_SUCCESS";
export const FETCH_BREAKDOWNTYPES_FAILURE = "FETCH_BREAKDOWNTYPES_FAILURE";
export const ADD_BREAKDOWNTYPES_REQUEST = "ADD_BREAKDOWNTYPES_REQUEST";
export const ADD_BREAKDOWNTYPES_SUCCESS = "ADD_BREAKDOWNTYPES_SUCCESS";
export const ADD_BREAKDOWNTYPES_FAILURE = "ADD_BREAKDOWNTYPES_FAILURE";
export const FETCH_BREAKDOWNTYPESBYID_REQUEST =
  "FETCH_BREAKDOWNTYPESBYID_REQUEST";
export const FETCH_BREAKDOWNTYPESBYID_SUCCESS =
  "FETCH_BREAKDOWNTYPESBYID_SUCCESS";
export const FETCH_BREAKDOWNTYPESBYID_FAILURE =
  "FETCH_BREAKDOWNTYPESBYID_FAILURE";

export const fetchBreakdownType = () => {
  return (dispatch) => {
    dispatch(fetchBreakdownTypesRequest());
    breakdownTypeService
      .getAllBreakdownType()
      .then((response) => {
        // response.data is the FAMILYS
        const breakdownTypes = response.data;
        dispatch(fetchBreakdownTypesSuccess(breakdownTypes));
      })
      .catch((error) => {
        // error.message is the error message
        dispatch(fetchBreakdownTypesFailure(error.message));
      });
  };
};

export const fetchBreakdownTypesRequest = () => {
  return {
    type: FETCH_BREAKDOWNTYPES_REQUEST,
  };
};

export const fetchBreakdownTypesSuccess = (breakdownTypes) => {
  return {
    type: FETCH_BREAKDOWNTYPES_SUCCESS,
    payload: breakdownTypes,
  };
};

export const fetchBreakdownTypesFailure = (error) => {
  return {
    type: FETCH_BREAKDOWNTYPES_FAILURE,
    payload: error,
  };
};

export const addBreakdownType = (data) => {
  return (dispatch) => {
    dispatch(addBreakdownTypesRequest(data));
    breakdownTypeService.createNewBreakdownType(data).then(
      (response) => {
        dispatch(addBreakdownTypesSuccess(response.data));
      },
      (error) => {
        dispatch(addBreakdownTypesFailure(error.messege));
      }
    );
  };
};

export const addBreakdownTypesRequest = () => {
  return {
    type: ADD_BREAKDOWNTYPES_REQUEST,
  };
};

export const addBreakdownTypesSuccess = (breakdown) => {
  return {
    type: ADD_BREAKDOWNTYPES_SUCCESS,
    payload: breakdown,
  };
};

export const addBreakdownTypesFailure = (error) => {
  return {
    type: ADD_BREAKDOWNTYPES_FAILURE,
    payload: error,
  };
};

export const fetchBreakdownTypeByIdFamily = (idFamily, handleResponse) => {
  return (dispatch) => {
    dispatch(fetchBreakdownTypesByIdFamilyRequest(idFamily));
    breakdownTypeService
      .getAllBreakdownTypeByFamily(idFamily)
      .then((response) => {
        // response.data is the FAMILYS
        const breakdownTypesById = response.data;
        if (typeof handleResponse == "function")
          handleResponse(breakdownTypesById);
        dispatch(fetchBreakdownTypesByIdFamilySuccess(breakdownTypesById));
      })
      .catch((error) => {
        // error.message is the error message
        dispatch(fetchBreakdownTypesByIdFamilyFailure(error.message));
      });
  };
};

export const fetchBreakdownTypesByIdFamilyRequest = (idFamily) => {
  return {
    type: FETCH_BREAKDOWNTYPESBYID_REQUEST,
  };
};

export const fetchBreakdownTypesByIdFamilySuccess = (breakdownTypesById) => {
  return {
    type: FETCH_BREAKDOWNTYPESBYID_SUCCESS,
    payload: breakdownTypesById,
  };
};

export const fetchBreakdownTypesByIdFamilyFailure = (error) => {
  return {
    type: FETCH_BREAKDOWNTYPESBYID_FAILURE,
    payload: error,
  };
};
