import React, { useState, useEffect, Fragment } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  Media,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import Dropzone from "react-dropzone-uploader";
import { findLastIndex } from "lodash";
import { fetchFamily } from "../../../redux/family/familyActions";
import { addProduct } from "../../../redux/product/productActions";
import { fetchCategory } from "../../../redux/category/categoryActions";
const CreateProduct = ({ location, i18n }) => {
  const history = useHistory();

  const [product, setProduct] = useState({
    dateCreation: new Date().toISOString(),
    displayOrder: 0,
    idcategory: "",
    isDeleted: 0,
    name: ""
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCategory());
  }, []);

  useEffect(() => {
    dispatch(fetchFamily());
  }, []);
  const [submitted, setSubmitted] = useState(false);
  const [optionCategory, setListOptionCategory] = useState([]);
  const [optionFamily, setListOptionFamily] = useState([]);

  const listCategory = useSelector(({ categoryReducer }) => {
    return categoryReducer.categorys;
  });
  const listFamily = useSelector(({ familyReducers }) => {
    return familyReducers.familys;
  });

  useEffect(() => {
    setListOptionCategory(
      listCategory.map((el) => ({
        value: el.idcategory,
        label: el.name
      }))
    );
  }, [listCategory]);

  useEffect(() => {
    setListOptionFamily(
      listFamily.map((el) => ({
        value: el.idfamily,
        label: el.familyName
      }))
    );
  }, [listFamily]);

  const [selectOptionCategory, setOptionSelectedCategory] = useState({
    value: null,
    label: null
  });
  const [selectOptionFamily, setOptionSelectedFamily] = useState(null);

  const onchangeSelectCategory = (item) => {
    console.log(item);
    // if(item === null){
    //   setOptionSelectedCategory({
    //     value : null , label: null
    //   })
    // }else{

    setOptionSelectedCategory(item);
  };

  const onchangeSelectFamily = (item) => {
    console.log(item);
    setOptionSelectedFamily(item);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct((product) => ({ ...product, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSubmitted(true);
    if (product.name) {
      await dispatch(
        addProduct({ ...product, idcategory: selectOptionCategory.value })
      );
      toast.success("product created successfully");
      history.push("/gest_fromule_product/Produits");
    }
  };

  return (
    <Fragment>
      <Breadcrumb parent="Produit" title="Nouveau Produit" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <h5>Produit</h5>
              </CardHeader>
              <CardBody>
                <Form
                  className="needs-validation"
                  noValidate=""
                  onSubmit={handleSubmit}>
                  <div className="form-row">
                    <Col md="6 mb-4">
                      <Label htmlFor="validationCustom01">
                        Famille
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Select
                        value={selectOptionFamily}
                        onChange={onchangeSelectFamily}
                        options={optionFamily}
                      />
                    </Col>
                    <Col md="6 mb-4">
                      <Label htmlFor="validationCustom01">
                        Categorie
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Select
                        value={selectOptionCategory}
                        onChange={onchangeSelectCategory}
                        options={optionCategory}
                      />
                    </Col>
                    <Col md="6 mb-4">
                      <Label htmlFor="validationCustom02">
                        Produit
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="name"
                        type="text"
                        placeholder="Produit"
                        innerRef=""
                        value={product.name}
                        onChange={handleChange}
                      />
                      <span></span>
                    </Col>
                  </div>
                  <Row>
                    <Col>
                      <Button
                        color="danger"
                        type="reset"
                        className="float-left"
                        onClick={() =>
                          history.push("/gest_fromule_product/Produits")
                        }>
                        Retour
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        color="primary"
                        type="submit"
                        className="float-right">
                        Validé
                      </Button>
                    </Col>
                  </Row>
                  {/* <Button color="primary">{config.button}</Button>
                  <Button color="primary">{config.button}</Button> */}
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CreateProduct;
