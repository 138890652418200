import formulaService from "../../services/formula/formulaService";

export const FETCH_FORMULAS_REQUEST = "FETCH_FORMULAS_REQUEST";
export const FETCH_FORMULAS_SUCCESS = "FETCH_FORMULAS_SUCCESS";
export const FETCH_FORMULAS_FAILURE = "FETCH_FORMULAS_FAILURE";
export const ADD_FORMULAS_REQUEST = "ADD_FORMULAS_REQUEST";
export const ADD_FORMULAS_SUCCESS = "ADD_FORMULAS_SUCCESS";
export const ADD_FORMULAS_FAILURE = "ADD_FORMULAS_FAILURE";

export const fetchFormula = () => {
  return (dispatch) => {
    dispatch(fetchFormulasRequest());
    formulaService
      .getAllFormulas()
      .then((response) => {
        // response.data is the FORMULAS
        const formulas = response.data;
        dispatch(fetchFormulasSuccess(formulas));
      })
      .catch((error) => {
        // error.message is the error message
        dispatch(fetchFormulasFailure(error.message));
      });
  };
};

export const fetchFormulasRequest = () => {
  return {
    type: FETCH_FORMULAS_REQUEST,
  };
};

export const fetchFormulasSuccess = (formulas) => {
  return {
    type: FETCH_FORMULAS_SUCCESS,
    payload: formulas,
  };
};

export const fetchFormulasFailure = (error) => {
  return {
    type: FETCH_FORMULAS_FAILURE,
    payload: error,
  };
};

export const addFormula = (data, handleResponse) => {
  return (dispatch) => {
    // dispatch(addFormulasRequest(data));
    formulaService
      .createNewFormula(data)
      .then(
        (response) => {
          if (response.status === 200)
            // dispatch(addFormulasSuccess(response.data));
            handleResponse({ update: !!data.idformula, response: true });
        },
        (error) => {
          handleResponse({ update: !!data.idformula, response: false });
          // dispatch(addFormulasFailure(error.messege));
        }
      )
      .catch((er) =>
        handleResponse({ update: !!data.idformula, response: false })
      );
  };
};

export const addFormulasRequest = () => {
  return {
    type: ADD_FORMULAS_REQUEST,
  };
};

export const addFormulasSuccess = (formula) => {
  return {
    type: ADD_FORMULAS_SUCCESS,
    payload: formula,
  };
};

export const addFormulasFailure = (error) => {
  return {
    type: ADD_FORMULAS_FAILURE,
    payload: error,
  };
};
