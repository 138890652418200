import {
  FETCH_FORMULAS_REQUEST,
  FETCH_FORMULAS_SUCCESS,
  FETCH_FORMULAS_FAILURE,
  ADD_FORMULAS_REQUEST,
  ADD_FORMULAS_SUCCESS,
  ADD_FORMULAS_FAILURE
} from "./formulaActions";

const initialState = {
  loading: false,
  formulas: [],
  error: ""
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FORMULAS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_FORMULAS_SUCCESS:
      return {
        loading: false,
        formulas: action.payload,
        error: ""
      };
    case FETCH_FORMULAS_FAILURE:
      return {
        loading: false,
        formulas: [],
        error: action.payload
      };

    case ADD_FORMULAS_REQUEST:
      return {
        registering: true
      };
    case ADD_FORMULAS_SUCCESS:
      return {
        loading: false,
        formulas: [...(state.formula || []) , action.payload ],
        error: ""
      };
    case ADD_FORMULAS_FAILURE:
      return {
        loading: false,
        formulas: [],
        error: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
