import React from "react";
import { Spinner } from "reactstrap";

function LoadingPage() {
  return (
    <div style={{ display: "flex", justifyContent: "center", height: "20rem" }}>
      <Spinner style={{ alignSelf: "center" }} animation="border" />
    </div>
  );
}

export default LoadingPage;
