import React, { useState, useEffect, Fragment } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  Media,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

import { useHistory } from "react-router-dom";
import Dropzone from "react-dropzone-uploader";
import { findLastIndex } from "lodash";

const CreateSinistre = ({ location, i18n }) => {
  const history = useHistory();
  const [file, setFile] = useState(false);
  useEffect(() => {}, []);

  return (
    <Fragment>
      <Breadcrumb parent="Sinistre" title="Nouveau Sinistre " />
      <Container fluid={true}>
        <Form className="needs-validation" noValidate="" onSubmit="">
          <Row>
            <Col>
              <Card className="shadow-lg p-0 shadow-showcase">
                <CardHeader className="b-l-primary pb-2">
                  {/* <h5>Info Generale</h5> */}
                </CardHeader>
                <CardBody className="pt-1">
                  <div className="form-row">
                    <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                        Num Contrat
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name=" num_contrat"
                        type="select"
                        placeholder="Num Contrat"
                        innerRef=""
                      />
                      <span></span>
                    </Col>
                    <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                        Num Sinistre
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name=" num_sinis"
                        type="text"
                        placeholder=" Num Sinistre"
                        innerRef=""
                      />
                      <span></span>
                    </Col>
                    <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                        Produit
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="produit"
                        type="select"
                        placeholder="Produit"
                        innerRef=""
                      />
                      <span></span>
                    </Col>
                    <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                        Date
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="date"
                        type="date"
                        placeholder=""
                        innerRef=""
                      />
                      <span></span>
                    </Col>
                    <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                        Status
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="status"
                        type="text"
                        placeholder="Status"
                        innerRef=""
                      />
                      <span></span>
                    </Col>
                    <Col md="6 mb-3">
                      <Label htmlFor="validationCustom01">
                        Localisation
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        className="form-control"
                        name="localisation"
                        type="text"
                        placeholder="Localisation"
                        innerRef=""
                      />
                      <span></span>
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                color="danger"
                type="reset"
                className="float-left"
                onClick={() => history.push("/gest_sinistre/sinistre")}
              >
                Retour
              </Button>
            </Col>
            <Col>
              <Button color="primary" type="submit" className="float-right">
                Valider
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </Fragment>
  );
};

export default CreateSinistre;
