import React, { useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import IconButton from "@material-ui/core/IconButton";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import Breadcrumb from "../../../layout/breadcrumb";
import { useHistory } from "react-router-dom";
import { fetchUsers } from "../../../redux/userGarantie/userGarantieActions";
import SweetAlert from "sweetalert2";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";
import { fetchTypeUser } from "../../../redux/typeUser/typeUserActions";
import userGarantieService from "../../../services/userGarantie/userGarantieService";

const UserGarantyList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [listUsersGarantie, setListUsersGarantie] = useState([]);
  const listUsers = useSelector(
    ({ userGarantieReducers }) => userGarantieReducers.users
  );
  const listTypeUser = useSelector(
    ({ typeUserReducers }) => typeUserReducers.typeUsers
  );
  useMemo(() => {
    if (listUsers) {
      setListUsersGarantie(listUsers);
    }
  }, [listUsers]);
  useEffect(() => {
    dispatch(fetchUsers());
    dispatch(fetchTypeUser());
  }, []);

  const [handleFilter, setHandleFilter] = useState(true);
  const [filteredValues, setFilteredValues] = useState({
    client: "",
    mobile: "",
    type: 0,
    email: "",
  });
  const handleFilterValues = (value, name) => {
    setFilteredValues({ ...filteredValues, [name]: value });
  };
  const [idUser, setIdUser] = useState("");
  const [userName, setUserName] = useState("");
  const [Verticalcenter, setVerticalcenter] = useState(false);
  const Verticalcentermodaltoggle = () => {
    setVerticalcenter(!Verticalcenter);
  };
  const profil = localStorage.getItem("profil");
  const filterData = () => {
    const filtredList = listUsers.filter((el) => {
      return (
        `${el.firstName}  ${el.lastName} ${el.socialReason || ""}`
          .toLowerCase()
          .includes(filteredValues.client.toLowerCase()) &&
        el.email.toLowerCase().includes(filteredValues.email.toLowerCase()) &&
        (filteredValues.type != 0
          ? el.typeUser.id == filteredValues.type
          : true) &&
        String(el.phone).includes(filteredValues.mobile)
      );
    });

    setListUsersGarantie(filtredList);
  };

  const columns = [
    {
      name: "Nom",
      selector: "firstName",
      sortable: true,
    },
    {
      name: "Prénom",
      selector: "lastName",
      sortable: true,
    },
    {
      name: "E-mail",
      selector: "email",
      sortable: true,
    },
    {
      name: "Mobile",
      selector: "phone",
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row.typeUser?.designation,
      sortable: true,
    },
    {
      name: "Actif",
      cell: (row) => (
        <Badge color="success">{row.isActive ? "Oui" : "Non"}</Badge>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          {profil === "User" ? (
            <IconButton
              color="primary"
              size="small"
              onClick={() => {
                console.log(row);
                history.push({
                  pathname: "/gest_utilisateur/utilisateurs/utilisateur",
                  state: { row, preview: true },
                });
              }}
            >
              <i className="icofont icofont-eye-alt"></i>
            </IconButton>
          ) : (
            <>
              <IconButton
                color="primary"
                size="small"
                onClick={() => {
                  console.log(row);
                  history.push({
                    pathname: "/gest_utilisateur/utilisateurs/utilisateur",
                    state: { row, preview: true },
                  });
                }}
              >
                <i className="icofont icofont-eye-alt"></i>
              </IconButton>
              <IconButton
                color="primary"
                size="small"
                onClick={() => {
                  console.log(row);
                  history.push({
                    pathname:
                      "/gest_utilisateur/utilisateurs/Update_utilisateur",
                    state: { row },
                  });
                }}
              >
                <i className="icofont icofont-ui-edit"></i>
              </IconButton>
              <IconButton
                color="primary"
                size="small"
                onClick={() => {
                  console.log(row);
                  Verticalcentermodaltoggle();
                  setIdUser(row.iduserG);
                  setUserName(row.firstName);
                }}
              >
                <i className="icofont icofont-ui-delete"></i>
              </IconButton>
            </>
          )}
        </div>
      ),

      button: true,
    },
  ];
  const handleSubmit = () => {
    userGarantieService.deleteUser(idUser).then((res) => {
      if (res.status === 200) {
        setVerticalcenter(!Verticalcenter);
      }
    });
  };
  return (
    <div>
      <Breadcrumb
        parent={"Gestion des Utilisateurs / Utilisateur"}
        title={"Liste des utilisateurs"}
      />
      <Container fluid={true}>
        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader
            className="ml-2"
            style={{ cursor: "pointer", padding: "10px" }}
            onClick={() => {
              setHandleFilter(!handleFilter);
            }}
          >
            <span className="float-left">
              <h6 className="mt-1">{"Filtrer"}</h6>
            </span>

            <span className="float-right ">
              <h4>
                {handleFilter ? (
                  <i className="fa fa-angle-down"></i>
                ) : (
                  <i className="fa fa-angle-up"></i>
                )}
              </h4>
            </span>
          </CardHeader>
          <CardBody className="pt-0" hidden={handleFilter}>
            <Form className="theme-form">
              <Row>
                <Col md="3 mb-2">
                  <FormGroup>
                    <Label className="col-form-label ">
                      Nom et prénom / Raison sociale
                    </Label>
                    <Input
                      onChange={(e) =>
                        handleFilterValues(e.target.value, "client")
                      }
                      className="form-control"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="3 mb-2">
                  <FormGroup>
                    <Label className="col-form-label "> Email </Label>
                    <Input
                      onChange={(e) =>
                        handleFilterValues(e.target.value, "email")
                      }
                      className="form-control"
                      type="text"
                    />
                  </FormGroup>
                </Col>

                <Col md="3 mb-2">
                  <FormGroup>
                    <label className="col-form-label"> Mobile</label>
                    <Input
                      onChange={(e) =>
                        handleFilterValues(e.target.value, "mobile")
                      }
                      className="form-control"
                      type="number"
                    />
                  </FormGroup>
                </Col>
                <Col md="3 mb-2">
                  <FormGroup>
                    <label className="col-form-label"> Type</label>
                    <Input
                      type="select"
                      name="select"
                      className="form-control digits"
                      defaultValue={0}
                      onChange={(e) => {
                        handleFilterValues(e.target.value, "type");
                      }}
                    >
                      <option value={0}>{"Tout"}</option>
                      {listTypeUser.map((el) => (
                        <option value={el.id}>{el.designation}</option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
            <Row>
              <Col>
                <Button
                  onClick={filterData}
                  className="btn-pill btn-air-success"
                  color="success"
                >
                  Recherche
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader className="m-0  align-button ">
            <Row>
              <Col className="text-right">
                <span>
                  { profil === "User" ? <></> :<Button
                    className="btn-pill btn-air-primary "
                    color="primary"
                    onClick={() => {
                      history.push(
                        "/gest_utilisateur/utilisateurs/new_utilisateur"
                      );
                    }}
                  >
                    + {" Ajouter utilisateur"}
                  </Button>}
                </span>
               
              </Col>
            </Row>
          </CardHeader>

          <CardBody className="pt-2">
            <DataTable
              noHeader
              pagination
              paginationComponentOptions={{
                rowsPerPageText: "Afficher",
                rangeSeparatorText: "/",
                selectAllRowsItem: true,
                selectAllRowsItemText: "all",
              }}
              columns={columns}
              data={listUsersGarantie}
              pointerOnHover={true}
              progressPending=""
              progressComponent={
                <div className="loader-box">
                  <div className="loader-1"></div>
                </div>
              }
            ></DataTable>
            <Modal
              isOpen={Verticalcenter}
              toggle={Verticalcentermodaltoggle}
              centered
            >
              <Form
                className="needs-validation"
                noValidate=""
                onSubmit={handleSubmit}
              >
                <ModalHeader toggle={Verticalcentermodaltoggle}>
                  suppression d'un utilisateur
                </ModalHeader>
                <ModalBody>
                  <h4>
                    vous êtes sur le point de supprimer l'utilisateur {userName}
                    . Continuer?
                  </h4>
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" type="submit">
                    Continuer
                  </Button>
                </ModalFooter>
              </Form>
            </Modal>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default UserGarantyList;
