import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";
import download from "downloadjs";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import Breadcrumb from "../../../layout/breadcrumb";
import { useHistory, useLocation } from "react-router-dom";
import contractServices from "../../../services/contract/contractServices";
import LoadingPage from "../../../components/LoadingPage";
import { useSelector, useDispatch } from "react-redux";
import distributorService from "../../../services/distribitor/distributorService";
const statusList = {
  V: "Validé ",
  B: "Brouillon",
  AS: "Attente signature",
  AP: "Attente paiement",
  R: "Résilier",
  C: "Annulé",
};
const ContractList = () => {
  const history = useHistory();
  const location = useLocation();
  const back = location?.state?.back;
  const role = localStorage.getItem("role");
  const profil = localStorage.getItem("profil");
  const [listContract, setListContract] = useState([]);
  const [listCF, setListCF] = useState([]);
  const conditionAsuur = {
    cin: "",
    dateDebut: "",
    dateFin: "",
    famille: "",
    firstName: "",
    formula: "",
    idUser: localStorage.getItem("id"),
    lastName: "",
    idDistributor: null,
    numContract: "",
    status: "",
    tax: "",
    inclusion: 0,
  }
  const [handleFilter, setHandleFilter] = useState(true);
  const [num, setNum] = useState(0);
  const [pay, setPay] = useState([]);
  const [cancel, setCancel] = useState({
    idContract: "",
    reasonStatus: "",
  });
  const [loading, setLoading] = useState(false);
  const [Verticalcenter, setVerticalcenter] = useState(false);
  const [Vert, setVert] = useState(false);
  const Verticalcentermodaltoggle = () => {
    setVerticalcenter(!Verticalcenter);
  };
  const Verttoggle = () => {
    setVert(!Vert);
  };
  const [listDistributors, setListDistributors] = useState([]);

  const [filteredValues, setFilteredValues] = useState(localStorage.getItem("id") === "6a7d1b9c-254c-455e-a186-6b2a37dd231f"? {
    cin: "",
    dateDebut: "",
    dateFin: "2024-11-28T00:00:00.000Z",
    famille: "",
    firstName: "",
    formula: "",
    idUser: "4027c224-04fe-4542-be2d-42193bb47407",
    lastName: "",
    numContract: "",
    status: "",
    tax: "",
    inclusion: 0,
  } :{
    cin: "",
    dateDebut: "",
    dateFin: "",
    famille: "",
    firstName: "",
    formula: "",
    idUser: localStorage.getItem("id"),
    lastName: "",
    idDistributor: null,
    numContract: "",
    status: "",
    tax: "",
    inclusion: 0,
  });
  const handleFilterValues = (value, name) => {
    setFilteredValues({ ...filteredValues, [name]: value });
  };
  console.log(filteredValues);

  Date.prototype.addHours = function (h) {
    this.setTime(this.getTime() + h * 60 * 60 * 1000);
    return this;
  };

  useEffect(() => {
    setLoading(true);
    if(localStorage.getItem("id") === "6a7d1b9c-254c-455e-a186-6b2a37dd231f"){
      contractServices
      .filterContract({
        cin: "",
        dateDebut: "",
        dateFin: "2024-11-28T00:00:00.000Z",
        famille: "",
        firstName: "",
        formula: "",
        idUser: "4027c224-04fe-4542-be2d-42193bb47407",
        lastName: "",
        numContract: "",
        status: "",
        tax: "",
        inclusion: 0,
      })
      .then((res) => {
        setListContract(res.data.sort((a, b) => b.numContract - a.numContract));
        setLoading(false);
      });
    }
    contractServices
      .filterContract({
        cin: "",
        dateDebut: "",
        dateFin: "",
        famille: "",
        firstName: "",
        formula: "",
        idUser: localStorage.getItem("id"),
        lastName: "",
        numContract: "",
        status: "",
        tax: "",
        inclusion: 0,
      })
      .then((res) => {
        setListContract(res.data.sort((a, b) => b.numContract - a.numContract));
        setLoading(false);
      });
    distributorService
      .getAllDistributors(localStorage.getItem("id"))
      .then((response) => {
        setListDistributors(response.data);
      });
  }, []);

  const downloadXlsx = (data) => {
    download(atob(data), "data.xlsx", {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
  };

  const filterData = async () => {
    let res = [];
    const filtredList = (
      await contractServices.filterContract({
        ...filteredValues,
        dateDebut: moment(filteredValues.dateDebut).add(1, "hour"),
        dateFin: moment(filteredValues.dateFin).add(1, "hour"),
        idDistributor:
          filteredValues.idDistributor === "Tout"
            ? null
            : filteredValues.idDistributor,
      })
    ).data;
    setListCF(filtredList.sort((a, b) => b.numContract - a.numContract));
    setListContract(filtredList.sort((a, b) => b.numContract - a.numContract));

    filtredList.forEach((el) => {
      res.push({
        idContract: el.idcontract,
        status: "V",
      });
    });

    setPay(res);
  };
  const columnsC = [
    {
      name: "Numéro",
      selector: "numContract",
      sortable: true,
    },
    {
      name: "Client",
      selector: (row) => row.firstName + " " + row.lastName,
      sortable: true,
    },
    {
      name: "Produit",
      selector: "family",
      sortable: true,
    },
    {
      name: "Date fin",
      selector: (row) => moment(row.endDate).format("DD/MM/yyyy"),
      sortable: true,
    },
    {
      name: "Canal",
      cell: (row) => (
        <Badge
          style={{
            color: "#fff",
            backgroundColor: row.canalVente === "web" ? "#007bff" : "#6c757d",
          }}
        >
          {row.canalVente}
        </Badge>
      ),
    },
    {
      name: "Statut",
      cell: (row) =>
        role === "FrontOffice" ? (
          <></>
        ) : (
          <Badge color={row.status === "V" ? "success" : "warning"}>
            {statusList[row.status]}
          </Badge>
        ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div style={{ display: "flex", flexDirection: "row" }}>
          {role === "Courtier " || role === "Assurance " ? (
            <>
              <IconButton
                className="p-1"
                color="primary"
                onClick={() => {
                  console.log(row);
                  history.push({
                    pathname: "/vueContrat",
                    state: { row, contrat: true },
                  });
                }}
              >
                <i className="icofont icofont-eye-alt"></i>
              </IconButton>
            </>
          ) : profil === "User" ? (
            <IconButton
              className="p-1"
              color="primary"
              size="small"
              onClick={() => {
                console.log(row);
                history.push({
                  pathname: "/vueContrat",
                  state: { row, contrat: true },
                });
              }}
            >
              <i className="icofont icofont-eye-alt"></i>
            </IconButton>
          ) : (
            <>
              {/* {row.status === "AP" ? (
                <IconButton
                  className="p-1"
                  color="primary"
                  onClick={() => {
                    console.log(row);
                    history.push({
                      pathname: "/gest_client_contrat/contrats/payment",
                      state: { row },
                    });
                  }}
                >
                  <i className="icofont icofont-money"></i>
                </IconButton>
              ) : (
                <></>
              )} */}
              <IconButton
                className="p-1"
                color="primary"
                onClick={() => {
                  console.log(row);
                  history.push({
                    pathname: "/vueContrat",
                    state: { row, contrat: true },
                  });
                }}
              >
                <i className="icofont icofont-eye-alt"></i>
              </IconButton>
            </>
          )}
        </div>
      ),

      button: true,
    },
  ];

  const columns = [
    {
      name: "N°",
      selector: (row) => (back ? row.num : row.numContract),
      sortable: true,
    },
    {
      name: "Client",
      selector: (row) =>
        back
          ? row.customer.firstName + " " + row.customer.lastName
          : row.firstName + " " + row.lastName,
      sortable: true,
    },
    {
      name: "Produit",
      selector: (row) =>
        back
          ? row.formula.family.familyName + "/" + row.formula.name
          : row.family + "/" + row.formule,
      sortable: true,
    },
    {
      name: "Date fin",
      selector: (row) => moment(row.endDate).format("DD/MM/yyyy"),
      sortable: true,
    },
    {
      name: "Canal",
      cell: (row) => (
        <Badge
          style={{
            color: "#fff",
            backgroundColor: row.canalVente === "web" ? "#007bff" : "#6c757d",
          }}
        >
          {row.canalVente}
        </Badge>
      ),
    },
    {
      name: "Statut",
      selector: (row) =>
        role === "FrontOffice" ? (
          <></>
        ) : (
          <Badge
            color={
              row.status === "V"
                ? "success"
                : row.status === "C"
                ? "primary"
                : "warning"
            }
          >
            {statusList[row.status]}
          </Badge>
        ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div style={{ display: "flex", flexDirection: "row" }}>
          {role === "Courtier " || role === "Assurance " ? (
            <>
              <IconButton
                className="p-1"
                color="primary"
                size="small"
                onClick={() => {
                  console.log(row);
                  history.push({
                    pathname: "/vueContrat",
                    state: { row, contrat: true },
                  });
                }}
              >
                <i className="icofont icofont-eye-alt"></i>
              </IconButton>
              {row.status === "AP" ? (
                <IconButton
                  className="p-1"
                  color="primary"
                  size="small"
                  onClick={() => {
                    console.log(row);
                    history.push({
                      pathname: "/gest_client_contrat/contrats/payment",
                      state: { row },
                    });
                  }}
                >
                  <i className="icofont icofont-money"></i>
                </IconButton>
              ) : (
                <></>
              )}
            </>
          ) : profil === "User" ? (
            <IconButton
              className="p-1"
              color="primary"
              size="small"
              onClick={() => {
                console.log(row);
                history.push({
                  pathname: "/vueContrat",
                  state: { row, contrat: true },
                });
              }}
            >
              <i className="icofont icofont-eye-alt"></i>
            </IconButton>
          ) : (
            <>
              {row.status === "AP" ? (
                <IconButton
                  className="p-1"
                  color="primary"
                  size="small"
                  onClick={() => {
                    console.log(row);
                    history.push({
                      pathname: "/gest_client_contrat/contrats/payment",
                      state: { row },
                    });
                  }}
                >
                  <i className="icofont icofont-money"></i>
                </IconButton>
              ) : (
                <></>
              )}
              {row.status === "C" || role === "FrontOffice" ? (
                <></>
              ) : (
                <>
                  {" "}
                  <IconButton
                    className="p-1"
                    color="primary"
                    size="small"
                    onClick={() => {
                      console.log(row);
                      history.push({
                        pathname:
                          "/gest_client_contrat/contrats/Update_contrat",
                        state: { row, update: true },
                      });
                    }}
                  >
                    <i className="icofont icofont-ui-edit"></i>
                  </IconButton>
                  <IconButton
                    className="p-1"
                    color="primary"
                    size="small"
                    onClick={() => {
                      console.log(row);
                      setNum(row.numContract);
                      Verticalcentermodaltoggle();
                      setCancel((cancel) => ({
                        ...cancel,
                        idContract: row.idcontract,
                      }));
                    }}
                  >
                    <i className="icofont icofont-close"></i>
                  </IconButton>
                </>
              )}
              <IconButton
                className="p-1"
                color="primary"
                size="small"
                onClick={() => {
                  console.log(row);
                  history.push({
                    pathname: "/vueContrat",
                    state: { row, contrat: true },
                  });
                }}
              >
                <i className="icofont icofont-eye-alt"></i>
              </IconButton>
            </>
          )}
        </div>
      ),

      button: true,
    },
  ];

  const handleSubmit = () => {
    contractServices.cancelContract(cancel).then((res) => {
      if (res.status === 200) {
        setVerticalcenter(!Verticalcenter);
      }
    });
  };
  const handleSub = () => {
    contractServices.updateStatusOfContracts(pay).then((res) => {
      if (res.status === 200) {
        setVert(!Vert);
        window.location.reload();
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCancel((cancel) => ({ ...cancel, [name]: value }));
    console.log(cancel);
  };

  return (
    <div>
      <Breadcrumb parent={"Contrats"} title={"Liste des contrats"} />
      <Container fluid={true}>
        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader
            className="ml-2"
            style={{ cursor: "pointer", padding: "10px" }}
            onClick={() => {
              setHandleFilter(!handleFilter);
            }}
          >
            <span className="float-left">
              <h6 className="mt-1">{"Filtrer"}</h6>
            </span>

            <span className="float-right ">
              <h4>
                {handleFilter ? (
                  <i className="fa fa-angle-down"></i>
                ) : (
                  <i className="fa fa-angle-up"></i>
                )}
              </h4>
            </span>
          </CardHeader>

          <CardBody className="pt-0" hidden={back ? true : handleFilter}>
            <Form className="theme-form">
              <Row>
                <Col md="3 mb-2">
                  <FormGroup>
                    <Label className="col-form-label ">Numéro</Label>
                    <Input
                      onChange={(e) =>
                        handleFilterValues(e.target.value, "numContract")
                      }
                      className="form-control"
                      type="number"
                    />
                  </FormGroup>
                </Col>
                <Col md="3 mb-2">
                  <FormGroup>
                    <Label className="col-form-label ">Cin</Label>
                    <Input
                      onChange={(e) =>
                        handleFilterValues(e.target.value, "cin")
                      }
                      className="form-control"
                      type="number"
                    />
                  </FormGroup>
                </Col>
                <Col md="3 mb-2">
                  <FormGroup>
                    <Label className="col-form-label "> Nom </Label>
                    <Input
                      onChange={(e) =>
                        handleFilterValues(e.target.value, "lastName")
                      }
                      className="form-control"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="3 mb-2">
                  <FormGroup>
                    <Label className="col-form-label "> Prénom </Label>
                    <Input
                      onChange={(e) =>
                        handleFilterValues(e.target.value, "firstName")
                      }
                      className="form-control"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="3 mb-2">
                  <FormGroup>
                    <label className="col-form-label"> Famille</label>
                    <Input
                      type="select"
                      name="select"
                      className="form-control digits"
                      defaultValue={""}
                      onChange={(e) =>
                        handleFilterValues(e.target.value, "famille")
                      }
                    >
                      <option value={""}>{"Tous"}</option>
                      <option value={"Smartphone"}>Smartphone</option>
                      <option value={"PC Portable"}>PC Portable</option>
                      <option value={"TV"}>TV</option>
                      <option value={"MacBook"}>MacBook</option>
                      <option value={"Tablette"}>Tablette</option>
                      <option value={"Optique"}>Optique</option>
                      <option value={"Meubles"}>Meubles</option>
                      <option value={"Équipements Froids"}>Équipements Froids</option>
                      <option value={"Équipements Cuisine"}>Équipements Cuisine</option>
                      <option value={"Équipements Ménagers"}>Équipements Ménagers</option>
                      <option value={"Pack Électroménager"}>Pack Électroménager</option>
                    </Input>
                  </FormGroup>
                  {/* <FormGroup>
                    <label className="col-form-label"> Canal</label>
                    <Input
                      type="select"
                      name="select"
                      className="form-control digits"
                      defaultValue={0}
                      onChange={(e) => {
                        handleFilterValues(e.target.value, "canalVente");
                      }}
                    >
                      <option value={0}>{"Tous"}</option>
                      <option value={"web"}>Web</option>
                      <option value={"Distributeur"}>Distributeur</option>
                    </Input>
                  </FormGroup> */}
                </Col>
                <Col md="3 mb-2">
                  <FormGroup>
                    <label className="col-form-label"> Canal</label>
                    <Input
                      type="select"
                      name="select"
                      className="form-control digits"
                      defaultValue={0}
                      onChange={(e) => {
                        handleFilterValues(e.target.value, "canalVente");
                      }}
                    >
                      <option value={0}>{"Tous"}</option>
                      <option value={"web"}>Web</option>
                      <option value={"Distributeur"}>Distributeur</option>
                    </Input>
                  </FormGroup>
                </Col>
                {role ==="Assurance " ? <></> : <> <Col md="3 mb-2">
                  <FormGroup>
                    <label className="col-form-label"> Du</label>
                    <DatePicker
                      className="form-control digits"
                      dateFormat="dd/MM/yyyy"
                      selected={filteredValues.dateDebut || ""}
                      onChange={(e) => {
                        handleFilterValues(e, "dateDebut");
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="3 mb-2">
                  <FormGroup>
                    <label className="col-form-label"> Au</label>
                    <DatePicker
                      className="form-control digits"
                      dateFormat="dd/MM/yyyy"
                      selected={filteredValues.dateFin || ""}
                      onChange={(e) => {
                        handleFilterValues(e, "dateFin");
                      }}
                    />
                  </FormGroup>
                </Col> </>}
                <Col md="3 mb-3">
                  <FormGroup>
                    <Label className="col-form-label">Statut</Label>
                    <Input
                      type="select"
                      name="select"
                      className="form-control digits"
                      defaultValue={""}
                      onChange={(e) =>
                        handleFilterValues(e.target.value, "status")
                      }
                    >
                      {" "}
                      V: "Validé ", B: "Brouillon", AS: "Attente signature", AP:
                      "Attente paiement", R: "Résilier", C: "Annulé",
                      <option value={""}>{"Tout"}</option>
                      <option value={"V"}>{"Validé"}</option>
                      <option value={"B"}>{"Brouillon"}</option>
                      <option value={"AS"}>{"Attente signature"}</option>
                      <option value={"AP"}>{"Attente paiement"}</option>
                      <option value={"R"}>{"Résilier"}</option>
                      <option value={"C"}>{"Annulé"}</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="3 mb-3">
                  <FormGroup>
                    <Label className="col-form-label">Distributeur</Label>
                    <Input
                      type="select"
                      name="select"
                      className="form-control digits"
                      defaultValue={""}
                      onChange={(e) =>
                        handleFilterValues(e.target.value, "idDistributor")
                      }
                    >
                      <option value={null}>Tout</option>
                      {listDistributors.map((el, index) => (
                        <option key={index} value={el.iddisributor}>
                          {el.socialReason}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
            <Row>
              <Col>
                <Button
                  onClick={filterData}
                  className="btn-pill btn-air-success"
                  color="success"
                >
                  Recherche
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader
            className="b-l-primary align-button"
            style={{
              padding: "15px",
              textAlign: "end",
            }}
          >
            {role === "Garanty" ? (
              <Button
                className="ml-3"
                color="success"
                outline
                onClick={() => {
                  Verttoggle();
                }}
              >
                Groupe Paiement
              </Button>
            ) : (
              <></>
            )}
            {role === "FrontOffice" ? (
              <></>
            ) : (
              <Col className="text-right ">
                <Button
                  className="ml-3"
                  color="success"
                  outline
                  onClick={() => {
                    setLoading(true);
                    contractServices
                      .exportexcelwithfilter({
                        ...filteredValues,
                        dateDebut: moment(filteredValues.dateDebut).add(
                          1,
                          "hour"
                        ),
                        dateFin: moment(filteredValues.dateFin).add(1, "hour"),
                      })
                      .then((res) => {
                        downloadXlsx(res.data);
                        setLoading(false);
                      });
                  }}
                >
                  Exporter
                </Button>
              </Col>
            )}
          </CardHeader>

          <CardBody className="pt-2">
            {loading ? (
              <LoadingPage />
            ) : (
              <DataTable
                noHeader
                pagination
                paginationComponentOptions={{
                  rowsPerPageText: "Afficher",
                  rangeSeparatorText: "/",
                  selectAllRowsItem: true,
                  selectAllRowsItemText: "all",
                }}
                columns={role === "Garanty" ? columns : columnsC}
                data={
                  back
                    ? location.state.row
                    : role === "FrontOffice"
                    ? listCF
                    : listContract
                }
                pointerOnHover={true}
                progressPending=""
                noDataComponent={<div>Aucun enregistrement trouvé</div>}
                progressComponent={
                  <div className="loader-box">
                    <div className="loader-1"></div>
                  </div>
                }
              ></DataTable>
            )}
            <Modal
              isOpen={Verticalcenter}
              toggle={Verticalcentermodaltoggle}
              centered
            >
              <Form
                className="needs-validation"
                noValidate=""
                onSubmit={handleSubmit}
              >
                <ModalHeader toggle={Verticalcentermodaltoggle}>
                  Annulation du contrat N°{num}
                </ModalHeader>
                <ModalBody>
                  <p>Vous êtes sur le point d'annuler le contrat N°{num}.</p>

                  <Col md="10 mb-4">
                    <Label htmlFor="validationCustom01">
                      Raison d'annulation
                    </Label>
                    <Input
                      className="form-control"
                      name="reasonStatus"
                      type="text"
                      placeholder="Raison d'annulation"
                      value={cancel.reasonStatus}
                      onChange={handleChange}
                      required={true}
                    />
                  </Col>
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" type="submit">
                    Continuer
                  </Button>
                </ModalFooter>
              </Form>
            </Modal>
            <Modal isOpen={Vert} toggle={Verttoggle} centered>
              <Form
                className="needs-validation"
                noValidate=""
                // onSubmit={handleSub}
              >
                <ModalHeader toggle={Verttoggle}>
                  Validation du paiement en groupe
                </ModalHeader>
                <ModalBody>
                  <p>
                    Vous êtes sur le point de valider le paiement des contrats
                    suivante.
                  </p>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="primary"
                    onClick={() => {
                      handleSub();
                    }}
                  >
                    Continuer
                  </Button>
                </ModalFooter>
              </Form>
            </Modal>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default ContractList;
