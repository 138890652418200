import { IconButton } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import BudgeComponent from "../../components/BudgeComponent";
import Breadcrumbs from "../../layout/breadcrumb";
import userGarantieService from "../../services/userGarantie/userGarantieService";

const ListUserDist = () => {
  const history = useHistory();
  const [usersDistList, setUsersDistList] = useState();
  useEffect(() => {
    if(localStorage.getItem("role") !== "Distributeur"){
      history.goBack()
     }
    const id = localStorage.getItem("idDist");
    userGarantieService.getAllusersbyDistributor(id).then((res) => {
      setUsersDistList(res.data);
    });
  }, []);
  const columns = [
    {
      name: "Nom",
      selector: "firstName",
      sortable: true,
    },
    {
      name: "Prénom",
      selector: "lastName",
      sortable: true,
    },
    {
      name: "E-mail",
      selector: "email",
      sortable: true,
    },
    {
      name: "Mobile",
      selector: "phone",
      sortable: true,
    },
    {
      name: "Type",
      selector: "typeUser.designation",
      sortable: true,
    },
    {
      name: "Actif",
      cell: (row) => <BudgeComponent status={1} />,
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <IconButton
            color="primary"
            onClick={() => {
              history.push({
                pathname: "/user-dist",
                state: { row , vue:true},
              });
            }}
          >
            <i className="icofont icofont-eye-alt"></i>
          </IconButton>
          <IconButton
            color="primary"
            onClick={() => {
              history.push({
                pathname: "/user-dist",
                state: { row , update: true},
              });
            }}
          >
            <i className="icofont icofont-ui-edit"></i>
          </IconButton>
        </div>
      ),

      button: true,
    },
  ];
  return (
    <div>
      <Breadcrumbs parent={"Distributeur"} title={"Liste des utilisateurs"} />
      <Container fluid={true}>
        <Card className="shadow-lg p-0 shadow-showcase">
          <CardHeader className="m-0  align-button ">
            <Row>
              <Col className="text-right">
                <span>
                  <Button
                    className="btn-pill btn-air-primary "
                    color="primary"
                    onClick={() => {
                      history.push("/user-dist");
                    }}
                  >
                    {"Nouvel utilisateur"}
                  </Button>
                </span>
                &nbsp;
              </Col>
            </Row>
          </CardHeader>

          <CardBody className="pt-2">
            <DataTable
              noHeader
              pagination
              paginationComponentOptions={{
                rowsPerPageText: "Afficher",
                rangeSeparatorText: "/",
                selectAllRowsItem: true,
                selectAllRowsItemText: "all",
              }}
              columns={columns}
              data={usersDistList}
              pointerOnHover={true}
              progressPending=""
              progressComponent={
                <div className="loader-box">
                  <div className="loader-1"></div>
                </div>
              }
            ></DataTable>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default ListUserDist;
